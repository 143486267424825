import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import VisibilityIcon from "@material-ui/icons/Visibility";
import _ from "lodash";
import { Radio } from "@material-ui/core";

const config = require("../../config");

const useStyles = makeStyles(theme => ({
  listItem: {
    margin: "5px 0",
    border: `1px dotted #CECECE`,
    boxShadow: "inset -6px 10px 20px -12px rgba(116,134,140,1)",
    borderRadius: "5px",
    padding: "10px !important",
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "48px",
  },
  paper: {
    margin: "1em",
    padding: "20px",
    width: "100%",
    minHeight: "200px",
    backgroundColor: "#f0f0f0",
  },
  removeBtn: {
    padding: "5px",
    borderRadius: "50%",
    border: "1px solid #CCCCCC",
    width: "1rem",
    height: "1rem",
  },
  cardTitle: {
    fontWeight: "bolder",
    marginLeft: "15px",
    fontSize: "16px",
    textOverflow: "ellipsis",
    textDecoration: "underline",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DropArea = ({
  lookup,
  onDropProfile,
  onRemoveProfileFromLookup,
  onToggleAutoSubmitProfileFromLookup,
  onChangeSelectedFinanceProgram,
  onCreateProgramModalHandler,
  onDisplayProgramModalHandler,
  onRemoveLookup,
  queryLoading,
}) => {
  const classes = useStyles();

  const [selectedFinanceProgram, setSelectedFinanceProgram] = useState([]);

  useEffect(() => {
    const initialSelectedFinancePrograms = [];
    lookup.profiles.forEach((profile, idx) => {
      _.set(
        initialSelectedFinancePrograms,
        `[${profile.id}][${idx}]`,
        _.get(profile, "selectedFinancePrograms[0]", "")
      );
    });
    setSelectedFinanceProgram(initialSelectedFinancePrograms);
  }, [lookup, lookup.profiles]);

  const handleChange = (event, profileId, index, lookupId) => {
    const chosenFinanceProgramId = event.target.value;

    const chooseToCreateNewFinanceProgram = chosenFinanceProgramId === "-";
    if (chooseToCreateNewFinanceProgram) {
      onCreateProgramModalHandler(profileId);
    } else {
      const prevFinanceProgram = selectedFinanceProgram[profileId][index];
      _.set(prevFinanceProgram, "[0]", chosenFinanceProgramId);
      setSelectedFinanceProgram(prevFinanceProgram);
      onChangeSelectedFinanceProgram(lookupId, index, chosenFinanceProgramId);
    }
  };

  const [, drop] = useDrop({
    accept: "PROFILE",
    drop: item => onDropProfile(item, lookup.id),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  const getChecked = (profileId, idx, fp) => {
    return _.get(selectedFinanceProgram, `[${profileId}][${idx}]`, "") === fp.financeProgramId;
  };

  return (
    <Paper ref={drop} elevation={3} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h5">Step # {lookup.id}</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  onRemoveLookup(lookup.id);
                }}
              >
                <DeleteIcon fontSize="small" color="secondary" />
              </IconButton>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {_.isEmpty(lookup.profiles) ? (
              <Grid item xs={12}>
                <h3 style={{ textAlign: "center" }}>Start dropping lenders here...</h3>
              </Grid>
            ) : null}
            {lookup.profiles.map((profile, index) => {
              return (
                <Grid
                  key={`${profile.id}_${index}`}
                  item
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  className={classes.listItem}
                >
                  <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={6}>
                        <Grid item xs={10}>
                          <Avatar
                            style={{ display: "inline-flex" }}
                            alt="img"
                            src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${profile.lenderLogo}`}
                          >
                            <ImageIcon textRendering={profile.lenderName} />
                          </Avatar>
                          <Typography variant="caption" className={classes.cardTitle}>
                            {profile.lenderName}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton size="small" onClick={() => onRemoveProfileFromLookup(lookup.id, index)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            size="small"
                            checked={profile.autoSubmit}
                            onChange={() => onToggleAutoSubmitProfileFromLookup(profile.id, lookup.id, index)}
                          />
                        }
                        label="Auto Submit"
                      />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControl className={classes.formControl} fullWidth={true}>
                        <InputLabel id={`fp-selector-lbl-${profile.id}-${index}}`}>Finance Program</InputLabel>
                        <Select
                          labelId={`fp-selector-${profile.id}-lbl-${index}`}
                          id={`fp-selector-${profile.id}-${index}`}
                          value={_.get(selectedFinanceProgram, `[${profile.id}][${index}]`, "")}
                          onChange={e => {
                            handleChange(e, profile.id, index, lookup.id);
                          }}
                          input={<Input />}
                          renderValue={selected => {
                            const v = _.get(
                              _.find(profile.financePrograms, x => x.financeProgramId === selected),
                              "nameInternal",
                              ""
                            );
                            return v;
                          }}
                          MenuProps={MenuProps}
                        >
                          <MenuItem key={`${profile.id}_new_fp-${index}`} value="-">
                            {"Create New"}
                          </MenuItem>
                          {profile.financePrograms &&
                            profile.financePrograms.map((fp, idx) => (
                              <MenuItem
                                key={`${profile.id}-${index}_new_fp_${fp.financeProgramId}`}
                                value={fp.financeProgramId}
                              >
                                <Radio checked={getChecked(profile.id, index, fp)} />
                                <ListItemText primary={fp.nameInternal} />
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    onDisplayProgramModalHandler(profile.id, fp);
                                  }}
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DropArea;
