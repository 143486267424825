import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import ViewListIcon from "@material-ui/icons/ViewList";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { Auth } from "aws-amplify";
import CreditSubmissionsList from "./pages/CreditSubmissions/CreditSubmissionsList";
import AddDealers from "./pages/AddDealers/AddDealers";
//import UserProfile from "./pages/UserProfile/UserProfile";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BarChartIcon from "@material-ui/icons/BarChart";
import TuneIcon from "@material-ui/icons/Tune";
import DealerManagementList from "./pages/DealerMgmt/DealerList";
import DealerDetails from "./pages/DealerMgmt/DealerDetails";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import { Constructor } from "../../pages/DynamicOCA/Constructor";
import { LocalAtmRounded } from "@material-ui/icons";
import ChatPage from "./pages/ChatPage";
import ConsolidatedTasks from "./pages/CreditSubmissions/ConsolidatedTasjs";
import ListAltIcon from "@material-ui/icons/ListAlt";
import BadgedChatIcon from "components/Badge/BadgedChatIcon";
import ProposalDetails from "./pages/CreditSubmissions/ProposalDetails";
import DocList from "./pages/ContractMgmt/DocList";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SendIcon from "@material-ui/icons/Send";
import DnsIcon from "@material-ui/icons/Dns";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Cookies from "js-cookie";
//common pages
import ReportsPage from "./pages/Reports";
import CompanyProfile from "pages/CompanyProfile/CompanyProfile";
import UserProfile from "../../pages/UserProfile";
import LocationList from "../../pages/LocationMgmt/LocationList";
import LocationDetail from "../../pages/LocationMgmt/LocationDetail";
import PageList from "../../pages/VendorOpportunity/PageOpportunitiesList";
import DetailsPage from "../../pages/VendorOpportunity/PageOpportunityDetails";
import SendLink from "../../pages/PaymentQuoteTool/SendLink.js";
import PageLeadList from "../../pages/LeadSheet/LeadsList";
import LeadDetails from "../../pages/LeadSheet/LeadDetails";
import PageRepList from "../../pages/DealerAdmin/RepList";
import PortalConfigurationWizard from "../../pages/PortalConfigurationWizard/PortalConfigurationWizard";
import FinanceProgramMgmt from "../../pages/FinanceProgramMgmt";
import LenderAdmin from "../../pages/LenderAdmin";
import LenderDetails from "../../pages/LenderAdmin/LenderDetails";
import LenderWaterfall from "../../pages/LenderWaterfall";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PQT from "../../pages/PQT";
import { SESSION_TIMEOUT_COOKIE_NAME } from "../../constants";
import { CreditSubmissionDetailsPageV2 } from "../../pages/details";
import CreditSubmissionDetails from "./pages/CreditSubmissions/CreditSubmissionDetails";

async function handleLogout() {
  await Auth.signOut();
  Cookies.remove(SESSION_TIMEOUT_COOKIE_NAME);
  localStorage.clear();
  sessionStorage.clear();
  document.location.pathname = "";
}

export const appRoutes = [
  {
    id: "vo",
    showInSidebarNav: true,
    exact: true,
    path: "/vo",
    name: "Opportunities",
    icon: ViewListIcon,
    component: PageList,
    layout: "/dashboard",
  },
  {
    id: "voId",
    showInSidebarNav: false,
    exact: false,
    path: "/vo/:id",
    name: "Opportunity Detail",
    type: "OpportunityDetail",
    icon: ViewListIcon,
    component: DetailsPage,
    layout: "/dashboard",
    fastTrackOption: true,
  },
  {
    showInSidebarNav: true,
    exact: true,
    path: "/cs",
    name: "Submissions List",
    icon: ViewListIcon,
    component: CreditSubmissionsList,
    layout: "/dashboard",
  },
  {
    showInSidebarNav: false,
    exact: false,
    path: "/cs/:id",
    name: "Transaction Detail",
    icon: ViewListIcon,
    component: CreditSubmissionDetails,
    layout: "/dashboard",
  },
  {
    showInSidebarNav: false,
    exact: false,
    path: "/cs2/:id",
    name: "Transaction Detail",
    icon: ViewListIcon,
    component: CreditSubmissionDetailsPageV2,
    layout: "/dashboard",
  },
  {
    id: "addDealer",
    showInSidebarNav: true,
    exact: true,
    path: "/add",
    name: "Add Dealers",
    icon: NoteAddIcon,
    component: AddDealers,
    layout: "/dashboard",
  },
  {
    showInSidebarNav: true,
    exact: true,
    path: "/dealers",
    name: "Dealer Management",
    icon: DynamicFeedIcon,
    component: DealerManagementList,
    layout: "/dashboard",
  },
  {
    id: "dealerDetails",
    showInSidebarNav: false,
    exact: false,
    path: "/dealers/:dealerId",
    type: "DealerDetails",
    name: "Dealer Details",
    icon: DynamicFeedIcon,
    component: DealerDetails,
    layout: "/dashboard",
  },
  {
    id: "sendoca",
    showInSidebarNav: true,
    exact: true,
    path: "/sendoca",
    name: "Send Application",
    icon: SendIcon,
    component: SendLink,
    layout: "/dashboard",
  },
  {
    id: "pqt",
    showInSidebarNav: false,
    exact: true,
    path: "/pqt",
    name: "Payment Quote Tool",
    icon: MonetizationOnIcon,
    component: PQT,
    layout: "/dashboard",
  },
  {
    id: "leads",
    showInSidebarNav: true,
    exact: true,
    path: "/leads",
    name: "Leads",
    icon: DnsIcon,
    component: PageLeadList,
    layout: "/dashboard",
  },
  {
    id: "leadDetailLeadId",
    showInSidebarNav: false,
    exact: false,
    path: "/leadDetail/:leadId",
    name: "Lead Detail",
    icon: ViewListIcon,
    component: LeadDetails,
    layout: "/dashboard",
  },
  {
    id: "chat",
    showInSidebarNav: false,
    exact: true,
    path: "/chat",
    name: "Chat",
    icon: BadgedChatIcon,
    component: ChatPage,
    layout: "/dashboard",
  },
  {
    showInSidebarNav: true,
    exact: true,
    name: "Tasks",
    path: "/tasks",
    icon: ListAltIcon,
    component: ConsolidatedTasks,
    layout: "/dashboard",
  },
  {
    showInSidebarNav: false,
    exact: false,
    path: "/proposalDetail/:propId",
    name: "Proposal Detail",
    component: ProposalDetails,
    layout: "/dashboard",
  },
  {
    id: "reports",
    showInSidebarNav: true,
    exact: true,
    path: "/reports",
    name: "Reports",
    icon: BarChartIcon,
    component: ReportsPage,
    layout: "/dashboard",
  },
  {
    id: "pcw",
    showInSidebarNav: false,
    exact: true,
    path: "/pcw",
    name: "Portal Configuration Wizard",
    icon: TuneIcon,
    component: PortalConfigurationWizard,
    layout: "/dashboard",
  },
];

export const userRoutes = [
  /*   {
    showInSidebarNav: true,
    exact: true,
    path: "/user-profile",
    name: "User Profile",
    icon: PersonIcon,
    component: UserProfile,
    layout: "/dashboard",
  }, */
  {
    showInSidebarNav: true,
    exact: false,
    path: "/profile/:dynamicsContactId",
    name: "User Profile",
    icon: PersonIcon,
    component: UserProfile,
    layout: "/dashboard",
    dynamic: true,
    dynamicKey: "dynamicsContactId",
    dynamicEntity: "userProfile",
  },
  {
    showInSidebarNav: true,
    exact: true,
    path: "/company-profile",
    name: "Company Profile",
    icon: BusinessIcon,
    component: CompanyProfile,
    layout: "/dashboard",
  },
  {
    id: "acctadmin",
    showInSidebarNav: true,
    exact: true,
    path: "/acctadmin",
    name: "User Management",
    icon: SupervisorAccountIcon,
    component: PageRepList,
    layout: "/dashboard",
  },
  {
    id: "lenderadmin",
    showInSidebarNav: false,
    exact: true,
    path: "/lenderadmin",
    name: "Lender Management",
    icon: PersonAddIcon,
    component: LenderAdmin,
    layout: "/dashboard",
  },
  {
    id: "lender",
    showInSidebarNav: false,
    exact: false,
    path: "/lenderadmin/:dynamicLenderProfileId",
    type: "LenderDetail",
    name: "Lender Details",
    icon: PersonAddIcon,
    component: LenderDetails,
    layout: "/dashboard",
  },
  {
    id: "financePrograms",
    showInSidebarNav: true,
    exact: true,
    path: "/finance-programs",
    name: "Finance Programs",
    icon: LocalAtmRounded,
    component: FinanceProgramMgmt,
    layout: "/dashboard",
  },
  {
    id: "locationMgmt",
    showInSidebarNav: false,
    exact: true,
    path: "/locationMgmt",
    name: "Location Management",
    icon: ViewListIcon,
    component: LocationList,
    layout: "/dashboard",
  },
  {
    id: "locationDetailLocationId",
    showInSidebarNav: false,
    exact: false,
    path: "/locationDetail/:locationId",
    name: "Location Details",
    icon: ViewListIcon,
    component: LocationDetail,
    layout: "/dashboard",
  },
  {
    showInSidebarNav: false,
    exact: true,
    path: "/contract-mgmt",
    name: "Contract Management",
    icon: PostAddIcon,
    component: DocList,
    layout: "/dashboard",
  },
  {
    id: "lenderWaterfall",
    showInSidebarNav: false,
    exact: true,
    path: "/lender-waterfall",
    name: "Lender Waterfall",
    icon: ViewListIcon,
    component: LenderWaterfall,
    layout: "/dashboard",
  },
  {
    showInSidebarNav: true,
    exact: true,
    action: handleLogout,
    name: "Logout",
    icon: ExitToAppIcon,
    layout: "/dashboard",
  },
  {
    id: "constructor",
    showInSidebarNav: false,
    exact: true,
    path: "/constructor",
    name: "OCA Constructor",
    component: Constructor,
    layout: "/dashboard",
  },
];
