import React from "react";
import { makeStyles } from "@material-ui/core";

const styles = {
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,

    "& button": {
      height: "fit-content",
    },
  },
  tableFilters: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    columnGap: 16,
  },
};

const useStyles = makeStyles(styles);

const TableHeader = props => {
  const classes = useStyles();

  return (
    <div className={classes.tableHeader}>
      {props.filterComps && <div className={classes.tableFilters}>{props.filterComps}</div>}
      {props.children}
    </div>
  );
};

export default TableHeader;
