import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
// material ui
import { translateStageCodes } from "utils";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import Error from "@material-ui/icons/ErrorOutline";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Close from "@material-ui/icons/Close";
import { Check } from "@material-ui/icons";
import blue from "@material-ui/core/colors/blue";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import WarningIcon from "@material-ui/icons/Warning";
import CheckIcon from "@material-ui/icons/Check";
// theme components
import Button from "../../components/CustomButtons/Button";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import _ from "lodash";
import { Grid, IconButton, LinearProgress, TextField } from "@material-ui/core";
import { CustomSwitch } from "../../components/CustomSwitch/CustomSwitch";
import { lenderTypesSettings } from "../Prequal/constants";
import SendIcon from "@material-ui/icons/Send";
import DynamicLenderSubmissionMappingModal from "./LenderSubmissionMappingModals/DynamicLenderSubmissionMappingModal";
import LenderDocumentAccess from "./LenderDocumentAccess";
import { lenderTypeMap } from "./LenderSubmissionMappingModals/constants";
import LenderMatrix from "./LenderMatrix/LenderMatrix";
import { CircularProgress } from "@material-ui/core";
import { adminRoles } from "../../pages/Prequal/constants";

const useUiStyles = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  submitButton: {
    marginTop: 20,
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 125,
    maxWidth: "70%",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: "-0,56px",
  },
  draftCheckbox: {
    position: "absolute",
    right: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: theme.spacing(2),
    paddingLeft: 0,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: "60%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  transactionContainer: {
    paddingBottom: "10px",
  },
  transactionInput: {
    borderRadius: "10px",
    marginTop: "20px",
    "& .MuiFormLabel-root": {
      color: "#000000",
      fontWeight: "400",
    },
  },
  createSubHeader: {
    marginTop: 8,
    display: "inline-block",
  },
  optionText: {
    fontSize: 14,
    lineHeight: "16px",
    color: "#333",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  optionSubText: {
    color: "#7A7A7A",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "400",
  },
  optionLenderType: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 300,
    padding: "5px 8px",
    marginLeft: 8,
    borderLeft: "1px solid #DDD",
  },
  transactionHeader: {
    fontSize: 20,
    color: "#000",
    lineHeight: "20px",
    fontWeight: 300,
    marginRight: 30,
  },
  selectItem: {
    border: "1px solid #DADCDF",
    borderRadius: "7px",
    padding: 5,
    position: "relative",
    marginRight: 20,
  },
  selectItemText: {
    display: "inline-block",
    borderBottom: "1px solid #DADCDF",
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
  },
  selectItemSubText: {
    display: "inline-block",
    color: "#737373",
    fontSize: "12px",
    fontWeight: 400,
  },
  selectLender: {
    minWidth: 220,
    "& .MuiSelect-root": {
      overflowX: "auto",
    },
  },
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },
  "@keyframes shake": {
    "0%, 100%": {
      transform: "translateX(0)",
    },
    "10%, 30%, 50%, 70%, 90%": {
      transform: "translateX(-15px)",
    },
    "20%, 40%, 60%, 80%": {
      transform: "translateX(15px)",
    },
  },
  shakeAnimation: {
    animation: "$shake 10s",
    animationIterationCount: "infinite",
  },
  wrapperLoader: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.grey[500],
    width: "100%",
    alignItems: "center",
    margin: "64px 0",

    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      marginBottom: 32,

      "& h4": {
        marginLeft: 8,
      },
    },
  },
}));

const M_BULK_UPDATE_DOCUMENTS_TOGGLE_LENDER_ACCESS = gql`
  mutation($batchUpdateLenderDocumentAccessInput: BatchUpdateLenderDocumentAccessInput!) {
    updateLenderDocumentsAccess(input: $batchUpdateLenderDocumentAccessInput) {
      success
      errorMessage
    }
  }
`;

const SUBMIT_APP = gql`
  mutation($input: CreditSubmissionInputs!) {
    createCreditSubmissions(input: $input)
  }
`;

const INTEGRATION_SETTINGS = gql`
  query($integrationName: String!) {
    integrationSettings(integrationName: $integrationName) {
      accountLevel
      createdDateTime
      integrationAdminAccountId
      integrationName
      integrationSettings
      locationLevel
      modifiedDateTime
      userProfileLevel
    }
  }
`;

const INTEGRATION_SETTINGS_VALUES = gql`
  query($input: IntegrationSettingsValuesInput!) {
    integrationSettingsValues(input: $input) {
      accountId
      createdDateTime
      integrationName
      integrationSettingsValues
      integrationSettingsValuesId
      locationId
      modifiedDateTime
      userProfileId
    }
  }
`;

const GET_LENDER_MATRIX_TEMPLATE = gql`
  query($VOId: ID!) {
    getLenderMatrixTemplate(VOId: $VOId) {
      facts
      rules
      lenderMatrixTemplate
      temperature
      topP
      createdDateTime
    }
  }
`;

// const lenders = [
//   [''                                    , 'Tracking Only - Requires RouteOne Entry', 'Ford Motor Credit'                   , 'N/A'],
//   ['81F5C112-4E7F-EA11-8119-005056B05A0F', 'Submission by Email'                    , 'Test Lender #1'                      , 'Alex Sawatzke'],
//   ['10802EC9-1C80-EA11-8119-005056B05A0F', 'Submission by Email'                    , 'Toyota Industries Commercial Finance', 'Vince Monteleone'],
//   [''                                    , 'Submission by Email'                    , 'Hitachi Capital'                     , 'Jay Saul'],
//   ['3D120421-1D80-EA11-8119-005056B05A0F', 'Submission by Email'                    , 'Isuzu Finance'                       , 'Bob Mooney'],
//   ['414D9E62-8EE7-E011-9518-005056A20000', 'Submission by Email'                    , 'CalFund'                             , 'N/A'],
//   [''                                    , 'Submission by Email'                    , 'Ascentium Capital'                   , 'N/A'],
//   [''                                    , 'Submission by Email'                    , 'AP Finance'                          , 'N/A'],
//   ["F42A8825-D6E7-E211-9509-005056A20000", 'Tracking Only - Requires RouteOne Entry', 'Ally Bank'                           , "Alex Sawatzke"],
//   ["C62AB074-9E7E-EA11-8119-005056B05A0F", 'Submission by Email'                    , 'Test Lender #2'                      , 'Alex Sawatzke'],
// ];

// const lenderList =  {
//   'Ford Motor Credit': ['','Tracking Only - Requires RouteOne Entry', 'N/A'],
//   'Test Lender #1': ['81F5C112-4E7F-EA11-8119-005056B05A0F', 'Submission by Email', 'Alex Sawatzke'],
//   'Toyota Industries Commercial Finance': ['10802EC9-1C80-EA11-8119-005056B05A0F','Submission by Email', 'Vince Monteleone'],
//   'Hitachi Capital': ['','Submission by Email','Jay Saul'],
//   'Isuzu Finance': ['3D120421-1D80-EA11-8119-005056B05A0F','Submission by Email', 'Bob Mooney'],
//   'CalFund': ['414D9E62-8EE7-E011-9518-005056A20000','Submission by Email', 'N/A'],
//   'Ascentium Capital': ['','Submission by Email', 'N/A'],
//   'AP Finance': ['','AP', 'N/A'],
//   'Ally Bank': ["F42A8825-D6E7-E211-9509-005056A20000", "Tracking Only - Requires RouteOne Entry", "Alex Sawatzke"],
//   'Test Lender #2': ['C62AB074-9E7E-EA11-8119-005056B05A0F', 'Submission by Email', 'Alex Sawatzke'],
// }

export default function LenderSubmission(props) {
  const setOpenEquipmentInputModal = props.setOpenEquipmentInputModal;
  const setInvoiceEquipmentTabPage = props.setInvoiceEquipmentTabPage;
  const creditSubmissions = props.creditSubmissions;
  const portalConfiguration = props.portalConfiguration;
  const userProfile = useSelector(state => state.userProfile);
  const classes = { ...useStyles(), ...useUiStyles() };
  const [lenderId, setLenderId] = useState("");
  const [createdDynamicsCreditSubmissionId, setCreatedDynamicsCreditSubmissionId] = useState("");

  const [checkedLenderName, setCheckedLenderName] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [missingCredentials, setMissingCredentials] = useState([]);
  const [isAddEquipment, setAddEquipment] = useState(false);
  const [isSummaryIncluded, setSummaryIncluded] = useState(false);
  const [transactionSummary, setTransactionSummary] = useState(null);
  const [transactionSummaryList, setTransactionSummaryList] = useState(undefined);
  const [transactionLender, setTransactionLender] = useState(null);
  const [openLenderMappingView, setOpenLenderMappingView] = useState(false);

  const [errors, setErrors] = useState({
    model: false,
    make: false,
    year: false,
    truckClass: false,
    wholesaleCost: false,
    totalWholesaleCost: false,
    retailPrice: false,
    serialNumberVin: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [openLenderDocumentAccess, setOpenLenderDocumentAccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressTimer, setProgressTimer] = useState(null);
  const [submittingMessage, setSubmittingMessage] = useState("");
  const [dynamicLenderMappingValues, setDynamicLenderMappingValues] = useState({});
  const [checkingCredentials, setCheckingCredentials] = useState(false);
  const handleClose = () => {
    setOpenAlert(false);
    setOpenLenderDocumentAccess(false);
    setOpenLenderMappingView(false);
    setTimeout(() => {
      setErrorType("");
    }, 1000);
  };

  const lenderTypesSettingsByValue = _.keyBy(lenderTypesSettings, "code");

  const { refetch: getIntegrationSettings } = useQuery(INTEGRATION_SETTINGS, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    skip: true,
  });

  const [isSuperAdmin] = useState(userProfile.adminRole === adminRoles.super);

  const { data: lenderMatrixTemplateData } = useQuery(GET_LENDER_MATRIX_TEMPLATE, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    variables: {
      VOId: props.vo.vendorOpportunityId,
    },
  });

  const { refetch: getIntegrationSettingsValues } = useQuery(INTEGRATION_SETTINGS_VALUES, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    skip: true,
  });
  const [updateLenderDocumentsAccess] = useMutation(M_BULK_UPDATE_DOCUMENTS_TOGGLE_LENDER_ACCESS);
  const hasDynamicLenderMapping =
    checkedLenderName &&
    checkedLenderName.find(cl => cl.submissionReviewShowMappingModal === "ON_DISPLAYS_MAPPING_MODAL") !== undefined;

  const toyotaMinimumDataValidation = () => {
    let result = false;

    let fieldsAnalyzed = {
      model: false,
      make: false,
      year: false,
      truckClass: false,
      wholesaleCost: false,
      totalWholesaleCost: false,
      retailPrice: false,
      attachmentsCost: false,
      attachmentsModel: false,
      attachmentsMake: false,
    };

    try {
      setErrors([]);
      if (
        checkedLenderName.find(item =>
          item.submissionMethod ? item.submissionMethod.toLowerCase().includes("toyota") : false
        )
      ) {
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalyzed.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalyzed.make = true;
            result = true;
          }

          if (!equipmentData.truckClass) {
            fieldsAnalyzed.truckClass = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalyzed.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalyzed.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.totalWholesaleCost) {
            fieldsAnalyzed.totalWholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalyzed.retailPrice = true;
            result = true;
          }

          if (!_.isEmpty(equipmentData.attachments)) {
            equipmentData.attachments.map(a => {
              if (!a.unitCost) {
                fieldsAnalyzed.attachmentsCost = true;
                result = true;
              }
              if (!a.manufacturer) {
                fieldsAnalyzed.attachmentsMake = true;
                result = true;
              }
              if (!a.model) {
                fieldsAnalyzed.attachmentsModel = true;
                result = true;
              }
            });
          }
        } else {
          fieldsAnalyzed = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            attachmentsCost: false,
            attachmentsModel: false,
            attachmentsMake: false,
          };
          result = true;
        }

        setErrors(fieldsAnalyzed);

        if (result) {
          setErrorType("MissingData");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const executeUpdateLenderDocumentsAccess = async (dynamicsCreditSubmissionId, documents) => {
    if (documents) {
      const result = await updateLenderDocumentsAccess({
        variables: {
          batchUpdateLenderDocumentAccessInput: {
            creditSubmissionId: dynamicsCreditSubmissionId,
            documents,
          },
        },
      });
      console.log("saved document access settings for creditsubmission", result);
    }
  };

  const engsValidation = () => {
    let hasErrors = false;
    let fieldsAnalyzed = {
      firstName: false,
      lastName: false,
      phone: false,
      city: false,
      businessName: false,
      email: false,
      equipmentDescription: false,
      invoiceTotalOrAmountRequested: false,
      postalCode: false,
      state: false,
      stateOfIncorp: false,
      apiIdentifierEngs: false,
    };

    try {
      setErrors([]);
      if (checkedLenderName.find(item => (item.lenderChannelCode ? item.lenderChannelCode === 308 : false))) {
        if (_.get(props, "creditApp", false)) {
          const creditApp = props.creditApp;
          const vendorOpportunity = props.vo;
          if (!creditApp.firstName) {
            fieldsAnalyzed.firstName = true;
            hasErrors = true;
          }
          if (!creditApp.lastName) {
            fieldsAnalyzed.lastName = true;
            hasErrors = true;
          }
          if (!creditApp.phone && !creditApp.bizPhone) {
            fieldsAnalyzed.phone = true;
            hasErrors = true;
          }
          if (!creditApp.city) {
            fieldsAnalyzed.city = true;
            hasErrors = true;
          }
          if (!creditApp.businessName) {
            fieldsAnalyzed.businessName = true;
            hasErrors = true;
          }
          if (!creditApp.email) {
            fieldsAnalyzed.email = true;
            hasErrors = true;
          }
          if (!creditApp.equipmentDescription) {
            fieldsAnalyzed.equipmentDescription = true;
            hasErrors = true;
          }
          if (!vendorOpportunity.invoiceTotal && !creditApp.amountRequested) {
            fieldsAnalyzed.invoiceTotalOrAmountRequested = true;
            hasErrors = true;
          }
          if (!creditApp.postalCode) {
            fieldsAnalyzed.postalCode = true;
            hasErrors = true;
          }
          if (!creditApp.state) {
            fieldsAnalyzed.state = true;
            hasErrors = true;
          }
          if (!creditApp.stateOfIncorp) {
            fieldsAnalyzed.stateOfIncorp = true;
            hasErrors = true;
          }
          if (
            !(vendorOpportunity.location && vendorOpportunity.location.apiIdentifierEngs) &&
            !(creditApp.location && creditApp.location.apiIdentifierEngs)
          ) {
            fieldsAnalyzed.apiIdentifierEngs = true;
            hasErrors = true;
          }
        } else {
          fieldsAnalyzed = {
            firstName: true,
            lastName: true,
            phone: true,
            city: true,
            businessName: true,
            email: true,
            equipmentDescription: true,
            invoiceTotalOrAmountRequested: true,
            postalCode: true,
            state: true,
            stateOfIncorp: true,
          };
          hasErrors = true;
        }

        setErrors(fieldsAnalyzed);

        if (hasErrors) {
          setErrorType("MissingData");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return hasErrors;
  };

  const routeOneDataValidation = () => {
    let result = false;
    let fieldsAnalyzed = {
      model: false,
      make: false,
      year: false,
      truckClass: false,
      wholesaleCost: false,
      totalWholesaleCost: false,
      retailPrice: false,
      attachmentsCost: false,
      attachmentsModel: false,
      attachmentsMake: false,
    };

    try {
      if (
        checkedLenderName.find(item =>
          item.submissionMethod ? item.submissionMethod.toLowerCase().includes("routeone") : false
        )
      ) {
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalyzed.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalyzed.make = true;
            result = true;
          }

          if (!equipmentData.truckClass) {
            fieldsAnalyzed.truckClass = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalyzed.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalyzed.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.totalWholesaleCost) {
            fieldsAnalyzed.totalWholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalyzed.retailPrice = true;
            result = true;
          }

          if (!_.isEmpty(equipmentData.attachments)) {
            equipmentData.attachments.map(a => {
              if (!a.unitCost) {
                fieldsAnalyzed.attachmentsCost = true;
                result = true;
              }
              if (!a.manufacturer) {
                fieldsAnalyzed.attachmentsMake = true;
                result = true;
              }
              if (!a.model) {
                fieldsAnalyzed.attachmentsModel = true;
                result = true;
              }
            });
          }
        } else {
          fieldsAnalyzed = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            attachmentsCost: false,
            attachmentsModel: false,
            attachmentsMake: false,
          };
          result = true;
        }

        if (result) {
          setErrorType("No equipment");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const paccarMinimumDataValidation = () => {
    let result = false;

    let fieldsAnalized = {
      model: false,
      make: false,
      year: false,
      wholesaleCost: false,
      retailPrice: false,
      assetType: false,
      cabType: false,
      classType: false,
      locationId: false,
      term: false,
      lenderProduct: false,
    };

    let locationError = false;
    try {
      setErrors([]);
      if (
        checkedLenderName.find(item => item.submissionMethod && item.submissionMethod.toLowerCase().includes("paccar"))
      ) {
        if (_.get(props, "creditApp", false)) {
          const creditApp = props.creditApp;
          if (!creditApp.location || creditApp.location.category !== "Branch") {
            fieldsAnalized.locationId = true;
            result = true;
            locationError = true;
          }
        } else {
          fieldsAnalized = {
            locationId: true,
          };
          locationError = true;
          result = true;
        }
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalized.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalized.make = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalized.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalized.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalized.retailPrice = true;
            result = true;
          }

          if (!equipmentData.assetTypeStr) {
            fieldsAnalized.assetType = true;
            result = true;
          }

          if (!equipmentData.cabType) {
            fieldsAnalized.cabType = true;
            result = true;
          }

          if (!equipmentData.classType) {
            fieldsAnalized.classType = true;
            result = true;
          }

          if (!equipmentData.term) {
            fieldsAnalized.term = true;
            result = true;
          }

          if (!equipmentData.lenderProduct) {
            fieldsAnalized.lenderProduct = true;
            result = true;
          }
        } else {
          fieldsAnalized = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            assetType: true,
            cabType: true,
            classType: true,
            term: true,
            lenderProduct: true,
            locationId: locationError,
          };
          result = true;
        }

        setErrors(fieldsAnalized);

        if (result) {
          setErrorType("MissingPACCARData");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };
  const cnhMinimumDataValidation = () => {
    let result = false;

    let fieldsAnalized = {
      model: false,
      make: false,
      year: false,
      wholesaleCost: false,
      retailPrice: false,
      assetType: false,
      term: false,
      loanType: false,
      serialNumberVin: false,
    };

    try {
      setErrors([]);
      if (checkedLenderName.find(item => item.submissionMethod.toLowerCase().includes("cnh"))) {
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalized.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalized.make = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalized.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalized.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalized.retailPrice = true;
            result = true;
          }

          if (!equipmentData.term) {
            fieldsAnalized.term = true;
            result = true;
          }

          if (!equipmentData.loanType) {
            fieldsAnalized.loanType = true;
            result = true;
          }
          if (!equipmentData.serialNumberVin) {
            fieldsAnalized.serialNumberVin = true;
            result = true;
          }
        } else {
          fieldsAnalized = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            term: true,
            loanType: true,
            serialNumberVin: true,
          };
          result = true;
        }

        setErrors(fieldsAnalized);

        if (result) {
          setErrorType("MissingCNHData");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const checkDLMCredentials = async () => {
    console.log("checking credentials");
    setOpenAlert(true);
    setCheckingCredentials(true);
    let errorType = "";
    const missingCredentials = [];
    await Promise.all(
      checkedLenderName.map(
        cl =>
          new Promise(async resolve => {
            const lenderMapName = lenderTypeMap.get(cl.lenderChannelCode);

            if (!lenderMapName || lenderMapName === "" || lenderMapName === "AMUR") {
              resolve();
            } else {
              const integrationSettings = await getIntegrationSettings({ integrationName: lenderMapName });

              if (integrationSettings?.data.integrationSettings?.integrationName) {
                let locationLevel = integrationSettings?.data.integrationSettings?.locationLevel;
                if (!props.vo.location && locationLevel) {
                  errorType = "MissingDLMLocation";
                } else {
                  const integrationSettingsValues = await getIntegrationSettingsValues({
                    input: {
                      integrationName: lenderMapName,
                      userProfileId: userProfile.id,
                      locationId: props.vo.location?.locationId,
                    },
                  });
                  integrationSettings.data.integrationSettings.integrationSettings.forEach(is => {
                    if (!integrationSettingsValues.data.integrationSettingsValues.integrationSettingsValues[is.name]) {
                      missingCredentials.push(`${is.displayName}`);
                    }
                  });

                  if (missingCredentials.length > 0) {
                    errorType = "MissingDLMCredentials";
                  }

                  if (!integrationSettingsValues?.data?.integrationSettingsValues?.integrationName) {
                    if (locationLevel) {
                      errorType = "MissingDLMLocation";
                    } else {
                      errorType = "MissingDLMCredentials";
                    }
                  }
                }
              }
              resolve();
            }
          })
      )
    );
    setTimeout(() => {
      if (errorType) setErrorType(errorType);
      setMissingCredentials(JSON.parse(JSON.stringify(missingCredentials)));
      setCheckingCredentials(false);
      console.log("credentials checked finished timeout");
    }, 2000);
  };

  const executeSubmission = async documents => {
    setOpenLenderDocumentAccess(false);
    let valid = true;
    if (!errorType) {
      if (!hasDynamicLenderMapping) {
        valid =
          toyotaMinimumDataValidation() ||
          paccarMinimumDataValidation() ||
          cnhMinimumDataValidation() ||
          engsValidation();
      }
      if (!valid) {
        setSubmitting(true);
        const resultSubmission = await submitCreditApp();
        await new Promise(r => setTimeout(r, 2000));

        const dynamicsCreditSubmissionId = _.get(resultSubmission, "data.createCreditSubmissions[0]", null);
        setCreatedDynamicsCreditSubmissionId(dynamicsCreditSubmissionId);
        if (props.callbackSubmissionFinished) {
          props.callbackSubmissionFinished(dynamicsCreditSubmissionId);
        }

        //Execute mutation to save per document access settings

        setOpenAlert(false);
        setSubmitting(false);
        setSubmittingMessage("");

        setCheckedLenderName([]);
        if (documents) {
          await executeUpdateLenderDocumentsAccess(dynamicsCreditSubmissionId, documents);
        }
      }
    }
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const canUseDocumentAccessModal =
      _.find(checkedLenderName, { submissionMethod: "Submission By Email" }) ||
      _.find(checkedLenderName, { submissionMethod: "Manual by Dealer into Lender Portal" });

    console.log(`Can use document access modal: ${canUseDocumentAccessModal}`);
    setSubmittingMessage("Gathering Information");
    setInvoiceEquipmentTabPage(0);

    await checkDLMCredentials();
    setCheckingCredentials(false);
    setOpenAlert(true);

    if (props.vp.showLenderDocsAccessMgmt && canUseDocumentAccessModal) {
      console.log("ready to use document access settings");
      //Promt For Document Access
      setOpenLenderDocumentAccess(true);
      return;
    }

    await executeSubmission(null);
  };

  useEffect(() => {
    if (props.handleSubmitToLenderByProfileId) {
      const lender = _.find(props.lp, { id: props.handleSubmitToLenderByProfileId });
      setCheckedLenderName([lender]);
    }
  }, [props.handleSubmitToLenderByProfileId]);

  const handleSelectLender = lenderProfileId => {
    const selected = checkedLenderName.find(lender => lender.lenderProfileId === lenderProfileId);

    if (selected) {
      // setCheckedLenderName(prevData => prevData.filter(lender => lender.lenderProfileId !== lenderProfileId));
      setCheckedLenderName([selected]);
    }
  };

  const [creditSubmissionRequest] = useMutation(SUBMIT_APP, {
    context: { authRequired: true },
  });

  const submitCreditApp = async sharedDocuments => {
    try {
      await props.handleOpenModal();
      await props.handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Submitting your request to lenders...",
      });

      const creditSubmissionsInfo = checkedLenderName.map(lender => {
        const creditSubmissionValues = {
          lenderProfileId: lender.lenderProfileId,
          lenderContactId: lender.lenderContactId,
          transactionSummary,
          createDraftOnly: false,
          accountId: props.account.id,
          submittedLender: lender.lenderAccountId,
          dynamicsCreditApplicationId: props.creditApp.dynamicsId,
          dynamicsVOId: props.vo.dynamicsVendorOpportunityId,
          dynamicsUserId: userProfile.dynamicsContactId,
          amountRequested: props.vo.invoiceTotal,
        };
        if (dynamicLenderMappingValues[lender.lenderProfileId]) {
          creditSubmissionValues.mappedValues = dynamicLenderMappingValues[lender.lenderProfileId];
        }
        if (sharedDocuments) {
          creditSubmissionValues.sharedDocuments = sharedDocuments;
        }
        return creditSubmissionValues;
      });
      const result = await creditSubmissionRequest({
        variables: {
          input: {
            creditSubmissions: creditSubmissionsInfo,
          },
        },
      });
      console.log(result);
      await props.handleSubmissionMessage({
        title: "Success",
        message: "Submitted Successfully",
      });
      await props.refetch();
      return result;
    } catch (err) {
      console.log(err);

      props.handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const handleChangeCheckedLenderName = event => {
    // setCheckedLenderName(prevData => {
    //   return prevData.concat(event.target.value);
    // });
    setCheckedLenderName([event.target.value]);
  };

  function setDynamicLenderMappingCallback(lenderProfileId, mapping) {
    dynamicLenderMappingValues[lenderProfileId] = mapping;
    setDynamicLenderMappingValues(dynamicLenderMappingValues);
    // console.log("DYNAMIC LENDER VALUES", dynamicLenderMappingValues);
  }

  useEffect(() => {
    setLenderId(checkedLenderName.lenderAccountId);
    routeOneDataValidation();
    if (props.handleSubmitToLenderByProfileId) {
      console.log("Received signal to submit");
      handleSubmit(new Event("submit"));
    }
  }, [checkedLenderName]);

  useEffect(() => {
    if (isAddEquipment) {
      setInvoiceEquipmentTabPage(0);
      const valid = toyotaMinimumDataValidation();
      if (!valid) {
        submitCreditApp().then(res => {
          console.log(res);
        });
      }
      setAddEquipment(false);
    }
  }, [props.equipmentData]);

  useEffect(() => {
    setTransactionSummary(null);
  }, [isSummaryIncluded]);

  useEffect(() => {
    if (creditSubmissions) {
      const transactionInfo = creditSubmissions
        .map(obj => ({ lenderName: obj.lenderName, transactionSummary: obj.transactionSummary }))
        .filter(obj => obj.transactionSummary);
      setTransactionSummaryList(_.uniqWith(transactionInfo, _.isEqual));
    }
  }, [creditSubmissions]);

  useEffect(() => {
    if (!hasDynamicLenderMapping) {
      setSubmitting(props.submitting);
    }
  }, [props.submitting]);

  useEffect(() => {
    if (submitting) {
      setOpenAlert(true);
      const timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
      setProgressTimer(timer);
    }
  }, [submitting]);

  useEffect(() => {
    if (props.lenderSubmitting?.creditSubStage) {
      const stage = props.lenderSubmitting?.creditSubStage;

      if (stage !== 100 && stage !== 101) {
        if (progress !== 0) setProgress(100);
        clearInterval(progressTimer);
        setTimeout(() => {
          console.log("creditSubStage waiting");
          if (props.lenderSubmitting?.apiResult !== "error" || props.lenderSubmitting?.apiResult !== "failure") {
            setSubmitting(false);
            setOpenAlert(false);
            setProgress(0);
            setTimeout(() => {
              setSubmittingMessage("Gathering Information");
            }, 2000);
          }
        }, 3000);
      }
    }
  }, [props.lenderSubmitting]);

  useEffect(() => {
    if (progress > 75) {
      clearInterval(progressTimer);
      setTimeout(() => {
        const stage = props.lenderSubmitting?.creditSubStage;
        if (stage === 100 || stage === 101) {
          setSubmitting(false);
          setProgress(0);
          setTimeout(() => {
            setSubmittingMessage("Gathering Information");
          }, 2000);
        }
      }, 10000);
    }
  }, [progress]);

  return (
    <div className={classes.container}>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        style={{
          width: submitting || props.lenderSubmitting?.apiExpiredCredentials ? "600px" : "",
          width: (errorType.includes("MissingDLM") || checkingCredentials) && 500,
          margin: "auto",
        }}
      >
        {checkingCredentials && (
          <DialogContent className={classes.wrapperLoader}>
            <CircularProgress className="progressLoader" size={48} />
            <div className="headerContainer">
              <h4>Checking Credentials...</h4>
            </div>
          </DialogContent>
        )}
        {!checkingCredentials && submitting && !errorType && (
          <>
            <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
              <IconButton className={classes.closeBtn} onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {props.lenderSubmitting?.apiExpiredCredentials ? (
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    <WarningIcon style={{ fontSize: 32, color: "#ff9800", marginRight: 8 }} />
                    Credentials Expired
                  </h3>
                  <Button
                    color="primary"
                    style={{ margin: "48px auto" }}
                    onClick={() => {
                      props.handleUpdateCredentials(props.lenderSubmitting);
                      setSubmitting(false);
                      setOpenAlert(false);
                      setProgress(0);
                    }}
                  >
                    Update
                  </Button>
                  <p style={{ marginBottom: 16 }}>{translateStageCodes(props.lenderSubmitting?.creditSubStage)}</p>
                </div>
              ) : (
                <>
                  {/* {props.lenderSubmitting?.apiResult === "error" || props.lenderSubmitting?.apiResult === "failure" ? (
                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                      <h3 style={{ display: "flex", justifyContent: "center" }}>
                        <ErrorIcon style={{ fontSize: 32, color: "#f44336", marginRight: 8 }} />
                        Failed to Submit
                      </h3>
                      <p style={{ margin: "48px 0" }}>{translateStageCodes(props.lenderSubmitting?.creditSubStage)}</p>
                    </div>
                  ) : ( */}
                  <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      {props.lenderSubmitting?.creditSubStage === 804790000 && !submittingMessage
                        ? "Submitted to Lender"
                        : "Submitting to Lender..."}
                    </h3>
                    {props.lenderSubmitting?.creditSubStage === 804790000 && !submittingMessage ? (
                      <CheckIcon style={{ fontSize: 84, color: "#4caf50", margin: "32px auto 48px" }} />
                    ) : (
                      <SendIcon
                        className={classes.shakeAnimation}
                        style={{ margin: "32px auto 48px", fontSize: 84, color: "#828282" }}
                      />
                    )}
                    <p style={{ marginBottom: 16 }}>
                      {submittingMessage
                        ? submittingMessage
                        : props.lenderSubmitting?.creditSubStage !== 804790000 &&
                          translateStageCodes(props.lenderSubmitting?.creditSubStage)}
                    </p>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      style={{ width: "75%", margin: "0 auto 32px" }}
                    />
                  </div>
                  {/* )} */}
                </>
              )}
            </DialogContent>
          </>
        )}

        {props.vp.showLenderDocsAccessMgmt && openLenderDocumentAccess ? (
          <LenderDocumentAccess
            classes={classes}
            vo={props.vo}
            g
            vp={props.vp}
            handleClose={handleClose}
            handleSubmitCallback={executeSubmission}
          />
        ) : null}
        {!submitting && hasDynamicLenderMapping && !errorType && !checkingCredentials && (
          <>
            <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
              <h6 style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
                <AccountBalance style={{ marginRight: 8 }} />
                Verify Data For Lender
              </h6>
              <IconButton className={classes.closeBtn} onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              {checkedLenderName.map((cl, key) => {
                if (cl.submissionReviewShowMappingModal === "ON_DISPLAYS_MAPPING_MODAL") {
                  return (
                    <DynamicLenderSubmissionMappingModal
                      key={key}
                      vo={props.vo}
                      vp={props.vp}
                      creditApp={props.creditApp}
                      handleDynamicLenderSubmissionMappingModalClose={handleClose}
                      lender={cl}
                      transactionSummary={isSummaryIncluded ? transactionSummary : null}
                      setDynamicLenderMappingCallback={setDynamicLenderMappingCallback}
                      submitCreditApp={sharedDocuments => {
                        setSubmitting(true);
                        submitCreditApp(sharedDocuments).then(res => {
                          console.log(res);
                          setTimeout(() => {
                            setSubmittingMessage("");
                            setCheckedLenderName([]);
                          }, 2000);
                        });
                      }}
                      handleClose={handleClose}
                      submissionMessage={props.submissionMessage}
                      portalConfiguration={portalConfiguration}
                    />
                  );
                }
              })}
            </DialogContent>
          </>
        )}
        {errorType === "MissingData" && !checkingCredentials && (
          <>
            <DialogTitle id="alert-dialog-title">
              <h6 style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
                <Error style={{ marginRight: 8 }} />
                {hasDynamicLenderMapping ? "Verify Data For Lender" : "Missing data for Lender"}
              </h6>
              <IconButton className={classes.closeBtn} onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              You need to provide the following information in order to submit to this lender:
              <div style={{ marginTop: 16 }}>
                {checkedLenderName &&
                  checkedLenderName.find(cl => cl.submissionReviewShowMappingModal !== "ON_DISPLAYS_MAPPING_MODAL") && (
                    <ul>
                      {errors.make ? <li>Manufacturer</li> : null}
                      {errors.model ? <li>Model</li> : null}
                      {errors.year ? <li>Year</li> : null}
                      {errors.truckClass ? <li>Truck Class</li> : null}
                      {errors.wholesaleCost ? <li>Wholesale Cost</li> : null}
                      {errors.totalWholesaleCost ? <li>Total Wholesale Cost</li> : null}
                      {errors.retailPrice ? <li>Retail Price</li> : null}
                      {errors.attachmentsCost ? <li>Attachment Unit Cost</li> : null}
                      {errors.attachmentsModel ? <li>Attachment Model</li> : null}
                      {errors.attachmentsMake ? <li>Attachment Manufacturer</li> : null}

                      {errors.firstName ? <li>First Name</li> : null}
                      {errors.lastName ? <li>Last Name</li> : null}
                      {errors.businessName ? <li>Business Name</li> : null}
                      {errors.equipmentDescription ? <li>Equipment Description</li> : null}
                      {errors.amountRequested ? <li>Amount Requested</li> : null}
                      {errors.invoiceTotalOrAmountRequested ? <li>Invoice Total or Amount Requested</li> : null}
                      {errors.phone ? <li>Phone</li> : null}
                      {errors.email ? <li>Email</li> : null}
                      {errors.city ? <li>City</li> : null}
                      {errors.postalCode ? <li>Postal Code</li> : null}
                      {errors.state ? <li>State</li> : null}
                      {errors.stateOfIncorp ? <li>State Of Incorporation</li> : null}
                      {errors.location ? <li>Vendor Location must be set to a branch</li> : null}
                      {errors.apiIdentifierEngs ? (
                        <li>
                          The Location selected for this application does not have a corresponding ENGS location. Please
                          select another location. If you believe the location you selected should exist in ENGS, please
                          contact our support.
                        </li>
                      ) : null}
                    </ul>
                  )}
              </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                color="primary"
                autoFocus
                onClick={() => {
                  handleClose(true);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
        {errorType === "No equipment" && !checkingCredentials && (
          <>
            <DialogTitle id="alert-dialog-title">{"No equipment data found"}</DialogTitle>
            <DialogContent>
              Before submitting to RouteOne you may add equipment details if you have them available
            </DialogContent>
            <DialogActions style={{ padding: "24px", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  handleClose();
                  setAddEquipment(true);
                  setInvoiceEquipmentTabPage(1);
                  setOpenEquipmentInputModal(true);
                }}
                color="primary"
                autoFocus
              >
                Add equipment details
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault();
                  handleSubmit(null);
                  handleClose();
                  setErrorType("");
                }}
                color="primary"
                autoFocus
              >
                Submit without equipment details
              </Button>
            </DialogActions>
          </>
        )}
        {errorType === "MissingPACCARData" && !checkingCredentials && (
          <>
            <DialogTitle id="alert-dialog-title">{"Missing data for Lender"}</DialogTitle>
            <DialogContent>
              You need to provide the following information in order to submit to this lender:
              <div>
                <ul>
                  {errors.make ? <li>Manufacturer</li> : null}
                  {errors.model ? <li>Model</li> : null}
                  {errors.year ? <li>Year</li> : null}
                  {errors.truckClass ? <li>Truck Class</li> : null}
                  {errors.wholesaleCost ? <li>Wholesale Cost</li> : null}
                  {errors.totalWholesaleCost ? <li>Total Wholesale Cost</li> : null}
                  {errors.retailPrice ? <li>Retail Price</li> : null}
                  {errors.assetType ? <li>Asset Type</li> : null}
                  {errors.cabType ? <li>Cab Type</li> : null}
                  {errors.classType ? <li>Class Type</li> : null}
                  {errors.term ? <li>Term</li> : null}
                  {errors.lenderProduct ? <li>Product</li> : null}
                  {errors.locationId ? <li>Vendor Location must be set to a branch</li> : null}
                </ul>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </>
        )}
        {errorType === "MissingCNHData" && !checkingCredentials && (
          <>
            <DialogTitle id="alert-dialog-title">{"Missing data for Lender"}</DialogTitle>
            <DialogContent>
              You need to provide the following information in order to submit to this lender:
              <div>
                <ul>
                  {errors.make ? <li>Manufacturer</li> : null}
                  {errors.model ? <li>Model</li> : null}
                  {errors.year ? <li>Year</li> : null}
                  {errors.truckClass ? <li>Truck Class</li> : null}
                  {errors.wholesaleCost ? <li>Wholesale Cost</li> : null}
                  {errors.totalWholesaleCost ? <li>Total Wholesale Cost</li> : null}
                  {errors.retailPrice ? <li>Retail Price</li> : null}
                  {errors.term ? <li>Term</li> : null}
                  {errors.loanType ? <li>Loan Type</li> : null}
                  {errors.serialNumberVin ? <li>Serial #</li> : null}
                </ul>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </>
        )}
        {errorType === "MissingDLMCredentials" && (
          <>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
              {"Missing Credentials"}
            </DialogTitle>
            <DialogContent style={{ textAlign: "center", margin: 32 }}>
              You need to provide credentials in order to submit to this lender.
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center", marginBottom: 24 }}>
              <Button
                onClick={() => {
                  window.location.href = `/lenderadmin/${checkedLenderName[0].lenderProfileId}?openDealerCredentials=true`;
                }}
                color="primary"
                autoFocus
              >
                Update Credentials
              </Button>
            </DialogActions>
          </>
        )}
        {errorType === "MissingDLMLocation" && (
          <>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
              {"Unaccepted Location"}
            </DialogTitle>
            <DialogContent style={{ textAlign: "center", margin: 32 }}>
              You need to provide an accepted location in order to submit to this lender.
            </DialogContent>
            {/* <DialogActions style={{ display: "flex", justifyContent: "center", marginBottom: 24 }}>
              <Button
                onClick={() => {}}
                color="primary"
                autoFocus
              >
                Update Location
              </Button>
            </DialogActions> */}
          </>
        )}
      </Dialog>
      <Grid container direction="column">
        <Grid item xs={4}>
          <h4 className={classes.createSubHeader}>Create submission</h4>
        </Grid>
        {props.lp ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={[classes.selectFormControl, classes.formControlLenders].join(" ")} fullWidth>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!checkedLenderName.length && (
                  <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                    Select Lenders
                  </InputLabel>
                )}
                <Select
                  // multiline
                  classes={{ select: classes.select }}
                  className={classes.selectLender}
                  value={checkedLenderName[0] || ""}
                  renderValue={() =>
                    checkedLenderName.map(lender => {
                      return (
                        <Grid
                          key={`select_${lender.lenderProfileId}`}
                          container
                          direction="column"
                          className={classes.selectItem}
                        >
                          <Grid item>
                            <span className={classes.selectItemText}>{lender.lenderName}</span>
                          </Grid>
                          <Grid item>
                            <span className={classes.selectItemSubText}>{lender.submissionMethod}</span>
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                  onChange={handleChangeCheckedLenderName}
                  inputProps={{ name: "lenderSelect", id: "lender-select" }}
                  style={{ marginBottom: 20 }}
                >
                  {props.lp.map(item => {
                    const lenderTypeSetting = lenderTypesSettingsByValue[item.lenderTypeCode];
                    return (
                      <MenuItem
                        key={`item_${item.lenderProfileId}`}
                        value={item}
                        classes={{ root: classes.selectMenuItem }}
                        onClick={() => handleSelectLender(item.lenderProfileId)}
                      >
                        <Grid container alignItems="center">
                          {/* <Checkbox
                            checked={checkedLenderName.find(lender => lender.lenderProfileId === item.lenderProfileId)}
                            tabIndex={-1}
                            onChange={() => handleSelectLender(item.lenderProfileId)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{ checked: classes.checked }}
                          /> */}
                          <Grid item style={{ paddingLeft: 16 }}>
                            <Grid container direction="column">
                              <Grid item className={classes.optionText}>
                                {item.lenderName}
                              </Grid>
                              <Grid item className={classes.optionSubText}>
                                {item.submissionMethod}
                              </Grid>
                            </Grid>
                          </Grid>
                          {lenderTypeSetting && (
                            <Grid item className={classes.optionLenderType}>
                              <b style={{ color: lenderTypeSetting.color }}>{_.get(lenderTypeSetting, "letter", "")}</b>
                              <span> {_.get(lenderTypeSetting, "title", "")}</span>
                            </Grid>
                          )}
                        </Grid>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </FormControl>
            <Grid container justify="space-between" spacing={2} alignItems="center" style={{ minHeight: "50px" }}>
              <Grid item xs={8}>
                <span className={classes.transactionHeader}>Include an Opportunity Summary?</span>
                <CustomSwitch onText="yes" offText="no" onChange={setSummaryIncluded} />
              </Grid>

              {isSummaryIncluded && transactionSummaryList?.length > 0 && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="summary-select" style={{ textTransform: "uppercase" }}>
                      ADD Existing Summary?
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="summary-select"
                      onChange={e => {
                        setTransactionLender(e.target.value);
                        setTransactionSummary(e.target.value.transactionSummary);
                      }}
                      renderValue={e => (
                        <Grid
                          key={transactionLender.lenderName}
                          container
                          justify="space-between"
                          direction="column"
                          className={classes.selectItem}
                        >
                          <span className={classes.selectItemText}>{transactionLender.lenderName}</span>
                          <span className={classes.selectItemSubText}>{transactionLender.transactionSummary}</span>
                        </Grid>
                      )}
                    >
                      {transactionSummaryList.map(item => (
                        <MenuItem
                          key={item.lenderName + item.transactionSummary}
                          value={item}
                          classes={{ root: classes.selectMenuItem }}
                        >
                          <Grid container alignItems="center">
                            <Checkbox
                              checked={
                                transactionLender &&
                                transactionLender.transactionSummary === item.transactionSummary &&
                                transactionLender.lenderName === item.lenderName
                              }
                              tabIndex={-1}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{ checked: classes.checked }}
                            />
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item className={classes.optionText}>
                                  {item.lenderName}
                                </Grid>
                                <Grid item className={classes.optionSubText}>
                                  {item.transactionSummary}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center" className={classes.transactionContainer}>
              {isSummaryIncluded && (
                <TextField
                  className={classes.transactionInput}
                  value={transactionSummary}
                  label="OPPORTUNITY SUMMARY (TO BE SHARED WITH YOUR LENDER(S) UPON SUBMISSION)"
                  multiline
                  placeholder="Add summary here"
                  type="text"
                  variant="outlined"
                  fullWidth
                  rows={2}
                  rowsMax={4}
                  onChange={e => setTransactionSummary(e.currentTarget.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
            {/* {enabledForDraftApplications ? (
            <div className={classes.draftCheckbox}>
              Draft Apps Only
              <Checkbox
                size="small"
                color="default"
                checked={createDraftOnly}
                inputProps={{
                  "aria-label": "create draft",
                }}
                onChange={handleDisableDraftApplications}
              />
            </div>
          ) : null} */}
            <Grid container>
              <Grid item>
                <Button
                  className={classes.submitButton}
                  onClick={handleSubmit}
                  data-test-id="submit-to-lender-btn"
                  disabled={checkedLenderName.length === 0}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <span style={{ paddingLeft: "15px" }}>
                  {props.vp.lenderMatrixAccess && <LenderMatrix vendorOpportunityId={props.vo.vendorOpportunityId} />}
                </span>
              </Grid>
            </Grid>

            {props.vp.lenderMatrixAccess &&
              isSuperAdmin &&
              lenderMatrixTemplateData &&
              lenderMatrixTemplateData.getLenderMatrixTemplate &&
              lenderMatrixTemplateData.getLenderMatrixTemplate.lenderMatrixTemplate && (
                <div>
                  <Grid container>
                    <h3>For super admin only</h3>
                  </Grid>
                  <Grid container spacing={2}>
                    {Object.entries(lenderMatrixTemplateData.getLenderMatrixTemplate).map(([key, value]) => (
                      <React.Fragment key={key}>
                        <Grid item xs={2} style={{ fontWeight: "bold" }}>
                          {key}
                        </Grid>
                        <Grid item xs={10}>
                          <Box border={1} borderRadius={4} padding={1}>
                            {value}
                          </Box>
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={2} style={{ fontWeight: "bold" }}>
                      Prompt to copy
                    </Grid>
                    <Grid item xs={10}>
                      <Box border={1} borderRadius={4} padding={1}>
                        {lenderMatrixTemplateData.getLenderMatrixTemplate.lenderMatrixTemplate
                          .replace("{{FACTS}}", lenderMatrixTemplateData.getLenderMatrixTemplate.facts)
                          .replace("{{RULES}}", lenderMatrixTemplateData.getLenderMatrixTemplate.rules)}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              )}
          </Grid>
        ) : (
          "No lenders available. Please contact our team."
        )}
      </Grid>
    </div>
  );
}
