import React, { useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
};

const useStyles = makeStyles(styles);

const DateFieldComp = props => {
  const classes = useStyles();
  const { field, type, index, handleBlur, inputProps, setReference, setReferenceToRequiredFunction } = props;
  const [required, setRequired] = useState(field.details.required === "REQUIRED");
  const [readOnly, setReadOnly] = useState(field.details.readOnly);
  const [value, setValue] = useState(field.mappedValue ? field.mappedValue : null);

  const setRequiredValue = required => {
    setRequired(required);
  };

  setReference(field.lenderProperty, v => {
    setValue(v);
    handleBlur(v, index);
  });
  setReferenceToRequiredFunction(field.lenderProperty, setRequiredValue);

  useEffect(() => {
    if (field.mappedValue !== value) {
      setValue("");
    }
  }, [props]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} key={index}>
      <DatePicker
        disableToolbar
        InputLabelProps={{
          disableAnimation: true,
          shrink: true,
        }}
        inputVariant="outlined"
        format="MM/DD/yyyy"
        margin="normal"
        label={(required ? "* " : "") + field.details.displayName}
        error={!field.mappedValue && required}
        value={value}
        onChange={val => setValue(val.toISOString())}
        onBlur={() => handleBlur(value, index)}
        inputProps={(inputProps, { disabled: readOnly })}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        className={classes.textField}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateFieldComp;
