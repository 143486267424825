import React from "react";
import { Modal } from "@material-ui/core";
import { VendorOpportunity, Task, Document } from "@trnsact/trnsact-shared-types";
import TaskForm from "../../../TaskForm_V2";

interface Props {
  tasks: Task[];
  isOpen: boolean;
  creditSubId: any;
  refetchDocs: any;
  relatedUsers: any;
  conversations?: any;
  documents?: Document[];
  vo: VendorOpportunity;
  taskId: string | null;
  onClose: VoidFunction;
  lenderProfileDynamicsId?: any;
  fetchTasks: () => Promise<any>;
  documentsPortalConfiguration?: any;
  upsertTask: (payload: any) => Promise<any>;
  updateTask: (payload: any) => Promise<any>;
}

export const CreateAndUpdateTaskModal = ({
  vo,
  tasks,
  isOpen,
  taskId,
  onClose,
  documents,
  upsertTask,
  updateTask,
  fetchTasks,
  refetchDocs,
  creditSubId,
  relatedUsers,
  conversations,
  lenderProfileDynamicsId,
  documentsPortalConfiguration,
}: Props) => {
  const handleAddTask = async (payload: any) => {
    try {
      await upsertTask({ variables: { input: payload } });
      await fetchTasks();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveTask = async (payload: any) => {
    try {
      await updateTask({ variables: payload });
      await fetchTasks();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal open={isOpen} aria-describedby="Input" aria-labelledby="Task Form" onClose={onClose}>
      <TaskForm
        vo={vo}
        taskId={taskId}
        tasksData={tasks}
        refetch={fetchTasks}
        handleClose={onClose}
        documents={documents}
        creditSubId={creditSubId}
        refetchDocs={refetchDocs}
        relatedUsers={relatedUsers}
        handleAddTask={handleAddTask}
        handleSaveTask={handleSaveTask}
        lenderProfileDynamicsId={lenderProfileDynamicsId}
        documentsPortalConfiguration={documentsPortalConfiguration}
        conversations={(conversations || []).filter((conv: any) => conv.taskId === taskId)}
      />
    </Modal>
  );
};
