import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

// material-ui components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  Button as MuiButton,
  TextField,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// core components
import UpdateMessageModal from "../../components/UpdateMessageModal";
import Button from "../../components/CustomButtons/Button";
import EditIcon from "@material-ui/icons/Edit";
// import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CloseOutlined } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { connect } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import blue from "@material-ui/core/colors/blue";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { formatCurrency, parseCurrency } from "utils";

const useStyles = makeStyles(() => ({
  textField: {
    margin: "4px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  ...styles,
}));

const useStylesPaper = makeStyles(theme => ({
  clear: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  labelRoot: {
    fontWeight: 800,
    color: "black",
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  formControl: {
    minWidth: 500,
    marginTop: "20px",
    marginBottom: "20px",
  },
  margin: {
    padding: "5px 10px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  mainContainer: {
    maxWidth: "400px",
  },
  ...extendedFormsStyle,
}));

const closeStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="down" ref={ref} {...props} />;
// });
// const QUERY_SALES_REPS = gql`
// 	query($dynamicsId: ID) {
// 		publicAccount (dynamicsId: $dynamicsId) {
// 			salesReps {
// 				userProfileId
// 				dynamicsContactId
// 				email
// 				#dynamicsId
// 				firstName
// 				lastName
// 				fullName
// 				phoneNumber
// 				mugshot
// 				availability
// 				vendorContactRole
// 				title
// 			}
// 		}
// 	}
// `;

const UPDATE_INVOICE_MUTATION = gql`
  mutation($fieldsObj: UpdateInvoiceInput!, $entityId: ID!, $VOId: ID!) {
    updateInvoice(fieldsObj: $fieldsObj, entityId: $entityId, VOId: $VOId)
  }
`;

function InvoiceDetailModal({
  open,
  handleClose,
  vo,
  account,
  refetchVO,
  handleOpenUploadInvoiceModal,
  handleChangeDoc,
  handleOpenInvoiceDetailModal,
  handleCloseUploadInvoiceModal,
  uploadInvoiceModal,
  refetchDocs,
  startProcess,
  setStartProcess,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  const closeClass = useStyles(closeStyles);

  const classesPaper = useStylesPaper();
  // const [salesReps, setSalesReps] = useState();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [equipmentCost, setEquipmentCost] = useState(vo.equipmentCost || 0);
  const [otherCharges, setOtherCharges] = useState(vo.freightLaborInstallation || 0);
  const [salesTax, setSalesTax] = useState(vo.salesTaxOnInvoice || 0);
  const [downPayment, setDownPayment] = useState(vo.downPayment || 0);
  const [invoiceTotal, setInvoiceTotal] = useState(vo.invoiceTotal || 0);
  const [invoiceWarrantyAndInsuranceCharges, setInvoiceWarrantyAndInsuranceCharges] = useState(
    vo.invoiceWarrantyAndInsuranceCharges || 0
  );
  const [isInvoiceTotalDisabled, setIsInvoiceTotalDisabled] = useState(true);
  const [uploadDocAfterConfirm, setUploadDocAfterConfirm] = useState(true);
  const [showExpandedFields, setShowExpandedFields] = useState(false);
  const [manufacturerRebateAmount, setManufacturerRebateAmount] = useState(vo.manufacturerRebateAmount || 0);
  const [tradeAllowanceGrossTradeIn, setTradeAllowanceGrossTradeIn] = useState(vo.tradeAllowanceGrossTradeIn || 0);
  const [tradeOwedBalanceAmount, setTradeOwedBalanceAmount] = useState(vo.tradeOwedBalanceAmount || 0);
  const [saving, setSaving] = useState(false);

  const handleUploadDocAfterConfirm = () => setUploadDocAfterConfirm(!uploadDocAfterConfirm);

  const [updateInvoice] = useMutation(UPDATE_INVOICE_MUTATION, {
    context: { authRequired: true },
  });

  // const { loading, error: errorFetchSalesReps, data: salesRepsData } = useQuery(QUERY_SALES_REPS, {
  //   variables: {
  //     dynamicsId: !!account && !!account.dynamicsAccountId ? account.dynamicsAccountId : ''
  // 	},
  // });
  //
  // if (loading) console.log('Loading sales reps...');
  // if (errorFetchSalesReps) console.log(`Error! ${errorFetchSalesReps.message}`);
  //
  // useEffect(()=> {
  // 	if (!!salesRepsData && !!salesRepsData.publicAccount && !!salesRepsData.publicAccount.salesReps) {
  // 		setSalesReps(salesRepsData.publicAccount.salesReps)
  // 	}
  // },[salesRepsData]);
  //
  // const createSaleRepMenuItems = (salesReps) => {
  // 	return !!salesReps
  // 	? salesReps.map((salesRep)=>{
  // 		return (<MenuItem key={salesRep.userProfileId} value={formatCurrency(salesRep.userProfileId} salesrep={salesRep}>{`${salesRep.fullName} - ${salesRep.email}`}</MenuItem>) === "" ? "$0" : formatCurrency(salesRep.userProfileId} salesrep={salesRep}>{`${salesRep.fullName} - ${salesRep.email}`}</MenuItem>))
  // 	})
  // 	: null
  // };

  useEffect(() => {
    let equipmentCostFloat = typeof equipmentCost !== "number" ? parseFloat(equipmentCost) : equipmentCost;
    let newInvoiceTotal =
      parseFloat(equipmentCostFloat) +
      parseFloat(otherCharges || 0) -
      parseFloat(downPayment || 0) +
      parseFloat(salesTax || 0) +
      parseFloat(invoiceWarrantyAndInsuranceCharges || 0);

    if (isInvoiceTotalDisabled === true) {
      setInvoiceTotal(newInvoiceTotal);
    }
    if (showExpandedFields === true) {
      setInvoiceTotal(
        newInvoiceTotal -
          parseFloat(tradeAllowanceGrossTradeIn || 0) +
          parseFloat(tradeOwedBalanceAmount || 0) -
          parseFloat(manufacturerRebateAmount || 0)
      );
    }
  }, [
    isInvoiceTotalDisabled,
    equipmentCost,
    otherCharges,
    salesTax,
    downPayment,
    tradeAllowanceGrossTradeIn,
    tradeOwedBalanceAmount,
    manufacturerRebateAmount,
    invoiceWarrantyAndInsuranceCharges,
  ]);

  const handleSubmitInvoiceChange = async () => {
    setSaving(true);
    try {
      await updateInvoice({
        variables: {
          fieldsObj: {
            invoiceTotal: parseFloat(invoiceTotal),
            freightLaborInstallation: parseFloat(otherCharges),
            salesTaxOnInvoice: parseFloat(salesTax),
            downPayment: parseFloat(downPayment),
            equipmentCost: parseFloat(equipmentCost),
            invoiceWarrantyAndInsuranceCharges: parseFloat(invoiceWarrantyAndInsuranceCharges),
            manufacturerRebateAmount: parseFloat(manufacturerRebateAmount),
            tradeAllowanceGrossTradeIn: parseFloat(tradeAllowanceGrossTradeIn),
            tradeOwedBalanceAmount: parseFloat(tradeOwedBalanceAmount),
          },
          VOId: vo.vendorOpportunityId,
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });

      // await setResponseSuccess(true);
      // await handleOpenMessageModal();

      setTimeout(async () => {
        await refetchVO();
        setSaving(false);
        handleClose();
        // handleCloseMessageModal();
      }, 1500);
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };

  const handleOpenMessageModal = () => {
    setOpenMessageModal(true);
  };

  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(null);
    if (startProcess === "upload" || startProcess === "invoice") {
      setStartProcess(null);
    }
  };

  const handleChangeInvoiceEdit = () => {
    setIsInvoiceTotalDisabled(!isInvoiceTotalDisabled);
  };

  return (
    <div style={{ width: "300px" }}>
      <Dialog onClose={handleClose} open={open} scroll={"body"} maxWidth={"sm"} fullWidth>
        <DialogTitle
          disableTypography
          className={closeClass.root}
          style={{ display: "flex", width: "100%", alignItems: "flex-end", justifyContent: "space-between" }}
        >
          <h4>
            <b>Invoice Detail</b>
          </h4>
          <IconButton onClick={handleClose}>
            <CloseOutlined style={{ fontSize: 20 }} />
          </IconButton>
        </DialogTitle>
        {saving ? (
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "20rem",
              }}
            >
              <CircularProgress size={64} style={{ marginBottom: 16 }} />
              <h4>Updating Invoice...</h4>
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              <Grid className={classesPaper.margin}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Equipment Subtotal"
                    id="equipment-amount"
                    value={formatCurrency(equipmentCost) === "" ? "$0" : formatCurrency(equipmentCost)}
                    onChange={e => setEquipmentCost(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))}
                  />
                </FormControl>
              </Grid>
              <Grid className={classesPaper.margin}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Sales Tax (if applicable)"
                    id="sales-tax"
                    value={formatCurrency(salesTax) === "" ? "$0" : formatCurrency(salesTax)}
                    onChange={e => setSalesTax(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))}
                  />
                </FormControl>
              </Grid>
              <Grid className={classesPaper.margin}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Down Payment"
                    id="down-payment"
                    value={formatCurrency(downPayment) === "" ? "$0" : formatCurrency(downPayment)}
                    onChange={e => setDownPayment(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))}
                  />
                </FormControl>
              </Grid>
              <Grid className={classesPaper.margin}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Warranty and Insurance Charges"
                    id="invoiceWarrantyAndInsuranceCharges"
                    value={
                      formatCurrency(invoiceWarrantyAndInsuranceCharges) === ""
                        ? "$0"
                        : formatCurrency(invoiceWarrantyAndInsuranceCharges)
                    }
                    onChange={e =>
                      setInvoiceWarrantyAndInsuranceCharges(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))
                    }
                  />
                </FormControl>
              </Grid>
              <Grid className={classesPaper.margin}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Other Charges (Installation, shipping, etc):"
                    id="other-charges"
                    value={formatCurrency(otherCharges) === "" ? "$0" : formatCurrency(otherCharges)}
                    onChange={e => setOtherCharges(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))}
                  />
                </FormControl>
              </Grid>
              {showExpandedFields && (
                <>
                  <Grid className={classesPaper.margin}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Trade Value (Allowance)"
                        id="trade-allowance-gross-tradein"
                        value={
                          formatCurrency(tradeAllowanceGrossTradeIn) === ""
                            ? "$0"
                            : formatCurrency(tradeAllowanceGrossTradeIn)
                        }
                        onChange={e =>
                          setTradeAllowanceGrossTradeIn(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid className={classesPaper.margin}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Trade Owed"
                        id="trade-owed-balance-amount"
                        value={
                          formatCurrency(tradeOwedBalanceAmount) === "" ? "$0" : formatCurrency(tradeOwedBalanceAmount)
                        }
                        onChange={e => setTradeOwedBalanceAmount(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))}
                      />
                    </FormControl>
                  </Grid>
                  <Grid className={classesPaper.margin}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Net Trade"
                        id="net-trade"
                        value={
                          formatCurrency(tradeAllowanceGrossTradeIn - tradeOwedBalanceAmount) === ""
                            ? "$0"
                            : formatCurrency(tradeAllowanceGrossTradeIn - tradeOwedBalanceAmount)
                        }
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid className={classesPaper.margin}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Rebate"
                        id="rebate"
                        value={
                          formatCurrency(manufacturerRebateAmount) === ""
                            ? "$0"
                            : formatCurrency(manufacturerRebateAmount)
                        }
                        onChange={e =>
                          setManufacturerRebateAmount(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <MuiButton
                  color="primary"
                  style={{ fontSize: 12 }}
                  onClick={() => setShowExpandedFields(!showExpandedFields)}
                >
                  {!showExpandedFields ? <>Show More</> : <>Show Less</>}
                </MuiButton>
              </div>
              <div className={classesPaper.margin} style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <div style={{ width: "100%" }}>
                  <FormControl fullWidth>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Invoice Total:"
                      id="invoice-total"
                      value={formatCurrency(invoiceTotal) === "" ? "$0" : formatCurrency(invoiceTotal)}
                      disabled={isInvoiceTotalDisabled}
                      onChange={e => setInvoiceTotal(parseCurrency(e.target.value.replace(/[^.0-9]/g, "")))}
                    />
                  </FormControl>
                </div>
                <div>
                  <IconButton onClick={handleChangeInvoiceEdit} style={{ padding: 4 }} color="primary">
                    {isInvoiceTotalDisabled ? (
                      <EditIcon style={{ fontSize: 24 }} />
                    ) : (
                      <CloseOutlined style={{ fontSize: 20 }} />
                    )}
                  </IconButton>
                </div>
              </div>
              {/* {startProcess !== "upload" ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleUploadDocAfterConfirm()}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      checked={uploadDocAfterConfirm}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Upload a new invoice"
                />
              ) : (
                <></>
              )} */}
            </div>
          </DialogContent>
        )}
        {!saving && (
          <DialogActions style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button color="primary" onClick={handleSubmitInvoiceChange}>
              Confirm
            </Button>
          </DialogActions>
        )}
        <UpdateMessageModal
          open={openMessageModal}
          handleCloseMessageModal={handleCloseMessageModal}
          responseSuccess={responseSuccess}
        />
      </Dialog>
      {/* <InvoiceQuoteUploadModal
        equipmentUpload={true}
        handleChangeDoc={() => {
          handleClose();
          handleChangeDoc();
          handleSubmitInvoiceChange().then(res => {});
        }}
        handleOpenInvoiceModal={handleOpenInvoiceDetailModal}
        handleCloseUploadInvoiceModal={handleCloseUploadInvoiceModal}
        uploadZoneOpened={uploadInvoiceModal}
        refetchDoc={refetchDocs}
        uploadDocAfterConfirm={uploadDocAfterConfirm}
        hideButton={true}
      /> */}
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
});

export default connect(mapStateToProps, null)(InvoiceDetailModal);
