import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { AddOutlined, CloseOutlined } from "@material-ui/icons";
import NumberInputList from "./NumberInputList";
import { getDefaultFinanceProgram } from "../services/proposalsService";
import { areAllRatesSame, formatCurrency, formatNumberWithDelimiter, parseCurrency } from "../utils";
import { floatNumberRegexp } from "../pages/Prequal/constants";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { theme } from "../theme";
import _ from "lodash";
import DOMPurify from "dompurify";
import { Engine } from "json-rules-engine";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
  LENDER_WATERFALL_FACT_NAMES_STRING,
} from "@trnsact/types-lender-waterfall";

const styles = {
  card: {
    backgroundColor: "rgba(20, 115, 230, 0.3)",
    borderRadius: "8px",
    display: "inline-block",
    boxSizing: "border-box",
    margin: "6px 10px",
    padding: "12px",

    "& p": {
      margin: 0,
    },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
};

const useStyles = makeStyles(styles);

let requiredToQuoteEngine = new Engine();

const FeeInput = ({ index, onRemove, onFeeChange, fee, disabled }) => {
  const classes = useStyles();
  const [financedChecked, setFinancedChecked] = useState(fee.financed ? fee.financed : false);

  const handleDescriptionChange = event => {
    onFeeChange("identifier", event.target.value);
  };

  const handleFinancedChange = event => {
    setFinancedChecked(event.target.checked);
    onFeeChange("financed", event.target.checked);
  };

  return (
    <div key={index}>
      <p style={{ marginBottom: 8 }}>Fee: {index + 1}</p>
      <Grid container spacing={1} style={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={6}>
          <TextField
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
              style: { width: "100%" },
            }}
            label="Description"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={handleDescriptionChange}
            value={fee.identifier}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
              style: { width: "100%" },
            }}
            value={formatCurrency(fee.fee, true) || ""}
            label="Fee Amount"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={e => floatNumberRegexp.test(e.target.value) && onFeeChange("fee", e.target.value)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => onRemove(index)}>
            <CloseOutlined />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch checked={financedChecked} onChange={handleFinancedChange} color="primary" disabled={disabled} />
            }
            style={{ marginTop: "-16px", marginBottom: "24px" }}
            label="Financed?"
          />
        </Grid>
      </Grid>
    </div>
  );
};

function RequiredToQuoteInputs({
  financeProgram,
  amountFinanced,
  updateFinanceProgram,
  setShowProgramInputs,
  requiredToQuoteEngineResults,
  setRequiredToQuoteEngineResults, // { qualifies: boolean, overridden: boolean, engineNeedsToRun: boolean, engineHasRun: boolean }
}) {
  const classes = useStyles();

  const [facts, setFacts] = useState({});
  const [factsToCheck, setFactsToCheck] = useState([]);
  const [suggestedPrograms, setSuggestedPrograms] = useState([]);
  const [engineHasRun, setEngineHasRun] = useState(false);

  useEffect(() => {
    if (_.isString(amountFinanced)) {
      const dealSizeParsed = parseFloat(amountFinanced.replace(/[^0-9.]/g, ""));
      requiredToQuoteEngine.addFact("DEAL_SIZE", dealSizeParsed);
      setFacts({ ...facts, DEAL_SIZE: dealSizeParsed });
    } else if (_.isNumber(amountFinanced)) {
      requiredToQuoteEngine.addFact("DEAL_SIZE", amountFinanced);
      setFacts({ ...facts, DEAL_SIZE: amountFinanced });
    }
  }, [amountFinanced]);

  useEffect(() => {
    setRequiredToQuoteEngineResults({
      engineNeedsToRun: true,
      engineHasRun: false,
      qualifies: false,
      overridden: false,
    });
    setSuggestedPrograms([]);
    if (facts) {
      if (
        _.every(facts, (value, key) => {
          return facts[key] === null || facts[key] === undefined;
        })
      ) {
        console.log("No facts to check");
        return;
      }
      // const factDealSize = requiredToQuoteEngine.getFact("DEAL_SIZE");
      // console.log(`factDealSize: ${JSON.stringify(factDealSize)}`);

      // const factEquipmentHours = requiredToQuoteEngine.getFact("EQUIPMENT_HOURS");
      // console.log(`factEquipmentHours: ${JSON.stringify(factEquipmentHours)}`);

      // const factEquipmentAgeYears = requiredToQuoteEngine.getFact("EQUIPMENT_AGE_YEARS");
      // console.log(`factEquipmentAge: ${JSON.stringify(factEquipmentAgeYears)}`);

      // const factEquipmentModel = requiredToQuoteEngine.getFact("EQUIPMENT_MODEL");
      // console.log(`factEquipmentModel: ${JSON.stringify(factEquipmentModel)}`);

      requiredToQuoteEngine
        .run()
        .then(engineResults => {
          // console.log(`engineResults: ${JSON.stringify(engineResults)}`);
          let financeProgramHasBeenUpdated = false;
          const updatedFinanceProgramIds = [];
          let newFinanceProgram = {};

          const newRequiredToQuoteEngineResults = {
            qualifies: false,
            overridden: false,
            engineNeedsToRun: true,
            engineHasRun: true,
          };

          if (engineResults.events.length === 0) {
            setShowProgramInputs(false);
            setRequiredToQuoteEngineResults(newRequiredToQuoteEngineResults);
          }

          engineResults.events.map(event => {
            if (event.type === "SUGGESTED_PROGRAM_FOUND") {
              if (
                !_.has(financeProgram, "prescreenCriteria.jsonCriteria.programOverrides") ||
                !_.get(financeProgram, "prescreenCriteria.jsonCriteria.programOverrides", []).length === 0
              ) {
                setShowProgramInputs(true);
                newRequiredToQuoteEngineResults.qualifies = true;
              }
            } else if (event.type === "OVERRIDE_PROGRAM") {
              financeProgramHasBeenUpdated = true;
              setShowProgramInputs(true);

              newRequiredToQuoteEngineResults.qualifies = true;
              newRequiredToQuoteEngineResults.overridden = true;

              updatedFinanceProgramIds.push(event.params.financeProgramId);
              if (financeProgram.financeProgramId === event.params.financeProgramId) {
                console.log(
                  `TTT: Overriding program: ${JSON.stringify(financeProgram)} with ${JSON.stringify(event.params)}`
                );
                newFinanceProgram = {
                  ...financeProgram,
                  ...event.params.paymentOptionsConfiguration,
                  amountFinanced: amountFinanced,
                };
              }
            }
          });
          if (financeProgramHasBeenUpdated) {
            updateFinanceProgram(newFinanceProgram, updatedFinanceProgramIds);
          }
          setRequiredToQuoteEngineResults(newRequiredToQuoteEngineResults);
          setEngineHasRun(true);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [facts]);

  useEffect(() => {
    requiredToQuoteEngine = new Engine();
    setFacts([]);
    setFactsToCheck([]);
    if (financeProgram) {
      const newFactsToCheck = [];

      if (!_.get(financeProgram, "prescreenCriteria.jsonCriteria.formRules")) {
        return;
      }
      try {
        let ruleConditions = [];
        financeProgram.prescreenCriteria.jsonCriteria.formRules.forEach(rule => {
          if (rule.requiredForQuote) {
            setShowProgramInputs(false);
            setRequiredToQuoteEngineResults({
              ...requiredToQuoteEngineResults,
              engineNeedsToRun: true,
              qualifies: false,
            });
            //  || rule.requiredToQuote requiredForQuote is the correct name, requiredToQuote is here for backwards compatibility
            if (
              Object.keys(LENDER_WATERFALL_FACT_NAMES_BOOLEAN).includes(rule.fact) &&
              !_.find(newFactsToCheck, { factKey: rule.fact })
            ) {
              newFactsToCheck.push({ factKey: rule.fact, type: "boolean" });
            } else if (
              Object.keys(LENDER_WATERFALL_FACT_NAMES_NUMERIC).includes(rule.fact) &&
              !_.find(newFactsToCheck, { factKey: rule.fact })
            ) {
              newFactsToCheck.push({ factKey: rule.fact, type: "numeric" });
            } else if (Object.keys(LENDER_WATERFALL_FACT_NAMES_STRING).includes(rule.fact)) {
              if (_.find(newFactsToCheck, { factKey: rule.fact })) {
                const factToCheckIndex = newFactsToCheck.findIndex(fact => fact.factKey === rule.fact);
                newFactsToCheck[factToCheckIndex].options = _.uniq(
                  newFactsToCheck[factToCheckIndex].options.concat(
                    _.isArray(rule.exactMatch) ? rule.exactMatch : [rule.exactMatch]
                  )
                );
              } else {
                newFactsToCheck.push({
                  factKey: rule.fact,
                  type: "string",
                  options: Array.isArray(rule.exactMatch) ? rule.exactMatch : [],
                });
              }
            }
            ruleConditions.push(
              financeProgram.prescreenCriteria.jsonCriteria.jsonRules.conditions.all.find(
                condition => condition.fact === rule.fact
              )
            );
          }
        });
        console.log(`financeProgram: ${JSON.stringify(financeProgram)}`);
        if (ruleConditions.length > 0) {
          requiredToQuoteEngine.addRule({
            conditions: { all: ruleConditions },
            name: `Suggested Program Found: ${financeProgram.name}`,
            event: {
              type: "SUGGESTED_PROGRAM_FOUND",
              params: {
                financeProgram: financeProgram,
              },
            },
          });
        }

        if (
          financeProgram.prescreenCriteria.jsonCriteria.programOverrides &&
          financeProgram.prescreenCriteria.jsonCriteria.programOverrides.length > 0
        ) {
          financeProgram.prescreenCriteria.jsonCriteria.programOverrides.forEach(override => {
            // console.log("IN OVERRIDE");
            // console.log(`IN OVERRIDE: ${JSON.stringify(override)}`);
            if (_.get(override, "conditions.jsonRules.conditions", null)) {
              console.log(
                `Adding override rule: ${JSON.stringify({
                  conditions: { all: override.conditions.jsonRules.conditions.all },
                  name: `${financeProgram.name}: Override Triggered`,
                  event: {
                    type: "OVERRIDE_PROGRAM",
                    params: {
                      financeProgramId: financeProgram.financeProgramId,
                      financeProgram: override.program,
                    },
                  },
                })}`
              );
              requiredToQuoteEngine.addRule({
                conditions: { all: override.conditions.jsonRules.conditions.all },
                name: `${financeProgram.name}: Override Triggered`,
                event: {
                  type: "OVERRIDE_PROGRAM",
                  params: {
                    financeProgramId: financeProgram.financeProgramId,
                    paymentOptionsConfiguration: override.paymentOptionsConfiguration,
                  },
                },
              });
            }
          });
        }
      } catch (e) {
        console.log(e.message);
      }

      setFactsToCheck(newFactsToCheck);
    }
  }, [financeProgram]);

  if (factsToCheck.length === 0) {
    console.log("No facts to check");
    return <> </>;
  }

  return (
    <>
      {factsToCheck
        .filter(fact => fact.factKey !== "DEAL_SIZE")
        .map(fact => {
          let factInput;

          switch (fact.type) {
            case "numeric":
              factInput = (
                <Grid item xs={6} key={fact.factKey}>
                  <TextField
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                      style: { width: "100%" },
                      required: true,
                    }}
                    label={fact.factKey === "DEAL_SIZE" ? "AMOUNT ($)" : fact.factKey.replace(/_/g, " ")}
                    variant="outlined"
                    value={formatNumberWithDelimiter(facts[fact.factKey], true)}
                    onChange={e => {
                      if (floatNumberRegexp.test(e.target.value)) {
                        const parsedValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
                        console.log(`adding fact: ${parsedValue}`);
                        requiredToQuoteEngine.addFact(fact.factKey, parsedValue);
                        setFacts({ ...facts, [fact.factKey]: parsedValue });
                      }
                    }}
                    margin="normal"
                  />
                </Grid>
              );
              break;

            case "string":
              factInput = (
                <Grid item xs={6} key={fact.factKey}>
                  <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                    <InputLabel required={true} shrink={true} id={fact.factKey}>
                      {fact.factKey.replace(/_/g, " ")}
                    </InputLabel>
                    <Select
                      labelId={`${fact.factKey}-label`}
                      id={fact.factKey}
                      value={facts[fact.factKey]}
                      onChange={e => {
                        requiredToQuoteEngine.addFact(fact.factKey, e.target.value);
                        setFacts({ ...facts, [fact.factKey]: e.target.value });
                      }}
                      label={fact.factKey.replace(/_/g, " ")}
                      required={true}
                      style={{ height: 40 }}
                    >
                      <MenuItem key="none" value="None of These">
                        <ListItemText borderColor="black" primary="None of these / I don't Know" />
                      </MenuItem>
                      {_.orderBy(fact.options, null, "asc").map((option, index) => {
                        return (
                          <MenuItem key={index} value={option}>
                            <ListItemText primary={option} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              );
              break;

            default:
              break;
          }

          return factInput;
        })}
    </>
  );
}

const FinanceProgramForm = props => {
  const {
    isVOView,
    isCreditSubmissionView,
    onValuesChange,
    initFinanceProgram,
    isProgramOwner = true,
    financeProgram,
    quoteToolModeEnabled,
    requiredToQuoteEngineResults,
    setRequiredToQuoteEngineResults,
  } = props;

  const classes = useStyles();
  const [isRatePerTerm, setRatePerTerm] = useState(false);
  const [ratePerTermIsDisabled, setRatePerTermIsDisabled] = useState(false);
  const [selectedRatePerTermMethod, setSelectedRatePerTermMethod] = useState("markup");
  const [showCustomInputs, setShowCustomInputs] = useState(false);
  const [showProgramInputs, setShowProgramInputs] = useState(true);

  const defaultProgram = getDefaultFinanceProgram(isVOView, isCreditSubmissionView);

  const [program, setProgram] = useState(initFinanceProgram || defaultProgram);

  const [selectedRateType, setSelectedRateType] = useState("INTEREST_RATE");
  const [selectedFinanceType, setSelectedFinanceType] = useState("none");

  const updatePropsProgram = updatedProgram => {
    const parseFloatInput = input => {
      return parseFloat(parseCurrency(_.toString(input)));
    };

    let parsedProgram = {
      ...updatedProgram,
      // markups: [
      //   {
      //     identifier: "Dealer Markup",
      //     markupPercentage: updatedProgram.markups[0].markupPercentage,
      //   },
      //   {
      //     identifier: "OEM Subsidy",
      //     markupPercentage: updatedProgram.markups[1] ? updatedProgram.markups[1].markupPercentage : 0,
      //   },
      // ],
      flatFees: [..._.get(updatedProgram, "flatFees", [])],
    };

    parsedProgram.numberOfAdvancePayments = parsedProgram.numberOfAdvancePayments
      ? parseInt(parsedProgram.numberOfAdvancePayments)
      : 0;
    parsedProgram.residualPercentage = parsedProgram.residualPercentage
      ? parseInt(parsedProgram.residualPercentage)
      : 0;

    parsedProgram.terms = parsedProgram.terms
      ? parsedProgram.terms.map((term, i) => {
          return {
            term: parseInt(term),
            rate: parsedProgram.rates[i] ? parseFloat(parsedProgram.rates[i]) : 0,
          };
        })
      : [{ term: 0, rate: 0 }];

    if (parsedProgram.customerRate) {
      parsedProgram.customerRate = parseFloatInput(parsedProgram.customerRate);
    }

    if (parsedProgram.dealerMarkup) {
      parsedProgram.dealerMarkup = parseFloatInput(parsedProgram.dealerMarkup);
    }

    parsedProgram.flatFees =
      parsedProgram.flatFees.length > 0
        ? parsedProgram.flatFees.map(fee => ({
            ...fee,
            fee: parseFloatInput(fee.fee),
          }))
        : [];

    if (isVOView || isCreditSubmissionView) {
      parsedProgram.amountFinanced = parseFloatInput(parsedProgram.amountFinanced);
      parsedProgram.downPaymentAmount = parseFloatInput(parsedProgram.downPaymentAmount);
    }

    delete parsedProgram.rates; // important
    delete parsedProgram.buyRate; // important
    onValuesChange(parsedProgram);
  };

  const handleInputChange = (field, event) => {
    const updatedProgram = { ...program };
    if (field === "terms") {
      _.times(event.length - updatedProgram.rates.length, () => updatedProgram.rates.push(0));
    }
    if (event && event.target && (event.target.value || event.target.value === "")) {
      if (field === "buyRate") {
        updatedProgram.rates = [];
        const buyRate = event.target.value;
        _.times(_.get(updatedProgram, "terms.length", 1), () => updatedProgram.rates.push(buyRate));
        updatedProgram.buyRate = buyRate;
      } else if (field === "dealerMarkup") {
        updatedProgram.customerRate = undefined;
        updatedProgram[field] = parseFloat(event.target.value);
      } else if (field === "customerRate") {
        updatedProgram.dealerMarkup = undefined;
        updatedProgram[field] = parseFloat(event.target.value);
      } else if (field === "oemSubsidy") {
        if (!updatedProgram["markups"][1]) {
          updatedProgram["markups"][1] = {
            identifier: "OEM Subsidy",
            markupPercentage: "",
          };
        }
        updatedProgram["markups"][1].markupPercentage = event.target.value;
      } else {
        updatedProgram[field] = event.target.value;
      }
    } else {
      updatedProgram[field] = event;
    }

    setProgram(updatedProgram);
    updatePropsProgram(updatedProgram);
  };

  if (initFinanceProgram) {
    // console.log(`initFinanceProgram: ${JSON.stringify(initFinanceProgram)}`);
    initFinanceProgram.rates = initFinanceProgram.terms.map(term => {
      if (term && term.rate) {
        return term.rate;
      }
      return 0;
    });
    initFinanceProgram.terms = initFinanceProgram.terms.map(term => {
      if (term && term.term) {
        return term.term;
      }
      return term;
    });
    initFinanceProgram.buyRate = initFinanceProgram.rates[0];
    delete initFinanceProgram.rate; // Important

    try {
      if (initFinanceProgram.rateType) {
        setSelectedRateType(initFinanceProgram.rateType);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (initFinanceProgram.dealerMarkup) {
        setSelectedFinanceType("financeProfit");
      }
      if (initFinanceProgram.customerRate) {
        setSelectedFinanceType("subsidy");
      }
    } catch (e) {
      console.log(e);
    }

    // try {
    //   if (initFinanceProgram.type) {
    //     handleInputChange("type", { target: { value: initFinanceProgram.type } });
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  }

  const handleRatePerTermMethodChange = event => {
    setSelectedRatePerTermMethod(event.target.value);
    const newProgram = { ...program };
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleRateTypeChange = event => {
    console.log(`RATE TYPE CHANGED: ${event.target.value}`);
    setSelectedRateType(event.target.value);
    if (event.target.value === "RATE_FACTOR") {
      setRatePerTerm(true);
      setRatePerTermIsDisabled(true);
    } else {
      setRatePerTermIsDisabled(false);
    }
    const newProgram = { ...program };
    newProgram.rateType = event.target.value;
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleFinanceTypeChange = event => {
    setSelectedFinanceType(event.target.value);
    const newProgram = { ...program };
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  useEffect(() => {
    if (
      (initFinanceProgram && !areAllRatesSame(initFinanceProgram.rates)) ||
      (financeProgram && !areAllRatesSame(financeProgram.rates))
    ) {
      setRatePerTerm(true);
    }
  });

  useEffect(() => {
    if (financeProgram) {
      console.log(`FP CHANGED: financeProgram: ${JSON.stringify(financeProgram)}`);
      if (financeProgram.rateType) {
        setSelectedRateType(financeProgram.rateType);
        if (financeProgram.rateType === "RATE_FACTOR") {
          setRatePerTermIsDisabled(true);
        } else {
          setRatePerTermIsDisabled(false);
        }
      }
      if (financeProgram.dealerMarkup) {
        setSelectedFinanceType("financeProfit");
      }
      if (financeProgram.customerRate) {
        setSelectedFinanceType("subsidy");
      }
      const showCustomInputs = doesFinanceProgramHasCustomRequiredToQuote(financeProgram);
      setShowCustomInputs(showCustomInputs);
      setShowProgramInputs(showCustomInputs ? false : true);
      updatePropsProgram(financeProgram);
    }
  }, [financeProgram]);

  const handleRatePerTermToggle = () => {
    let newProgram = { ...program };
    if (isRatePerTerm) {
      // When this is TRUE, it means we are shutting OFF the toggle
      newProgram.rates = newProgram.terms.map(() => newProgram.buyRate);
    }
    setProgram(newProgram);
    updatePropsProgram(newProgram);
    setRatePerTerm(!isRatePerTerm);
  };

  const updateFinanceProgram = (newFinanceProgram, updatedFinanceProgramIds) => {
    newFinanceProgram.rates = newFinanceProgram.terms.map(term => {
      return term.rate;
    });
    newFinanceProgram.terms = newFinanceProgram.terms.map(term => {
      return term.term;
    });
    setProgram({ ...newFinanceProgram });
    updatePropsProgram({ ...newFinanceProgram });
  };

  const doesFinanceProgramHasCustomRequiredToQuote = fp => {
    try {
      const hasRequiredForQuoteRule = _.find(
        fp.prescreenCriteria.jsonCriteria.formRules,
        rule => rule.requiredForQuote
      );
      if (hasRequiredForQuoteRule) {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  };

  const handleAddFee = () => {
    let newProgram = { ...program };
    newProgram.flatFees.push({ identifier: "", fee: "", financed: false });
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleRemoveFee = feeIndex => {
    let newProgram = { ...program };
    newProgram.flatFees.splice(feeIndex, 1);
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleFeeChange = (feeIndex, field, value) => {
    let newProgram = { ...program };
    newProgram.flatFees[feeIndex][field] = field === "fee" ? formatCurrency(value) : value;
    setProgram(newProgram);
    updatePropsProgram(newProgram);
  };

  const handleRateInputChange = (rateIndex, event) => {
    const updatedProgram = { ...program };
    if (event && event.target && (event.target.value || event.target.value === "")) {
      updatedProgram.rates[rateIndex] = event.target.value;
    }
    setProgram(updatedProgram);
    updatePropsProgram(updatedProgram);
  };

  const handleValidityDateToggle = () => {
    const update = !program.validDateStart && !program.validDateEnd;

    const updatedProgram = {
      ...program,
      validDateStart: update ? new Date() : "",
      validDateEnd: update ? new Date(new Date().setMonth(new Date().getMonth() + 3)) : "",
    };

    setProgram(updatedProgram);
    updatePropsProgram(updatedProgram);
  };

  useEffect(() => {
    if (initFinanceProgram) {
      setProgram({
        ...initFinanceProgram,
        ...program,
      });
    }
  }, [initFinanceProgram]);

  useEffect(() => {
    setProgram({ ...program, ...props.financeProgram });
  }, [props.financeProgram]);

  return (
    <Grid container style={{ marginTop: 8 }}>
      <div>
        {program && program.prescreenCriteria && program.prescreenCriteria.guidelines && (
          <div>
            <h4>Program Guidelines</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(program.prescreenCriteria.guidelines, {
                  ALLOWED_TAGS: ["b", "i", "em", "u", "strong", "ol", "ul", "li", "p", "br"],
                }),
              }}
            />
          </div>
        )}
      </div>
      <Grid container spacing={1}>
        {(isVOView || isCreditSubmissionView) && (
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
                style: { width: "100%" },
                required: true,
              }}
              label="Amount Financed"
              variant="outlined"
              value={formatCurrency(program.amountFinanced, true) || ""}
              onChange={e => floatNumberRegexp.test(e.target.value) && handleInputChange("amountFinanced", e)}
              fullWidth
              margin="normal"
            />
          </Grid>
        )}
        {(isVOView || isCreditSubmissionView) && showCustomInputs && (
          <RequiredToQuoteInputs
            financeProgram={financeProgram}
            amountFinanced={program.amountFinanced}
            updateFinanceProgram={updateFinanceProgram}
            setShowProgramInputs={setShowProgramInputs}
            requiredToQuoteEngineResults={requiredToQuoteEngineResults}
            setRequiredToQuoteEngineResults={setRequiredToQuoteEngineResults}
          />
        )}
      </Grid>

      {showProgramInputs && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                label="Display Finance Program Name"
                variant="outlined"
                value={program.financeProgramName ? program.financeProgramName : ""}
                onChange={e => handleInputChange("financeProgramName", e)}
                fullWidth
                margin="normal"
                disabled={!isProgramOwner}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Rate Type</FormLabel>
                <RadioGroup
                  aria-label="rate-type"
                  name="rate-type"
                  value={program.rateType}
                  onChange={handleRateTypeChange}
                  row
                >
                  <FormControlLabel
                    value="INTEREST_RATE"
                    control={<Radio color="primary" disabled={!isProgramOwner} />}
                    label="Interest Rate"
                  />
                  <FormControlLabel
                    value="RATE_FACTOR"
                    control={<Radio color="primary" disabled={!isProgramOwner} />}
                    label="Rate Factor"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={isVOView || isCreditSubmissionView ? 6 : 12}>
              <NumberInputList
                className={classes.textField}
                label="Terms in Months"
                onNumbersChange={e => handleInputChange("terms", e)}
                onBlur={() => {}}
                value={program.terms}
                required={true}
                disabled={!isProgramOwner}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isRatePerTerm}
                    disabled={ratePerTermIsDisabled || !isProgramOwner}
                    onChange={handleRatePerTermToggle}
                    color="primary"
                  />
                }
                label="Specify rate per term"
              />
            </Grid>
            {isRatePerTerm ? (
              program.terms.map((term, i) => (
                <Grid item xs={6} key={i}>
                  <TextField
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                      style: { width: "100%" },
                      required: true,
                    }}
                    label={
                      selectedRateType === "INTEREST_RATE"
                        ? `Buy Rate at ${term} months (%)`
                        : `Rate Factor at ${term} months`
                    }
                    variant="outlined"
                    type="number"
                    value={program.rates[i]}
                    onChange={e => handleRateInputChange(i, e)}
                    fullWidth
                    margin="normal"
                    disabled={!isProgramOwner}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "100%" },
                    required: true,
                  }}
                  label="Buy Rate (%)"
                  variant="outlined"
                  type="number"
                  value={program.buyRate}
                  onChange={e => handleInputChange("buyRate", e)}
                  fullWidth
                  margin="normal"
                  disabled={!isProgramOwner}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Structure</FormLabel>
                <RadioGroup
                  aria-label="finance-type"
                  name="finance-type"
                  value={selectedFinanceType}
                  onChange={handleFinanceTypeChange}
                  row
                >
                  <FormControlLabel
                    value="financeProfit"
                    control={<Radio color="primary" disabled={!isProgramOwner} />}
                    label="Markup"
                  />
                  <FormControlLabel
                    value="subsidy"
                    control={<Radio color="primary" disabled={!isProgramOwner} />}
                    label="Subsidy"
                  />
                  <FormControlLabel
                    value="none"
                    control={<Radio color="primary" disabled={!isProgramOwner} />}
                    label="None"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          {selectedFinanceType === "financeProfit" && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Method</FormLabel>
                  <RadioGroup
                    aria-label="rate-per-term-method"
                    name="rate-per-term-method"
                    value={selectedRatePerTermMethod}
                    onChange={handleRatePerTermMethodChange}
                    row
                  >
                    <FormControlLabel
                      value="markup"
                      control={<Radio color="primary" disabled={!isProgramOwner} />}
                      label="Markup %"
                    />
                    <FormControlLabel
                      value="customerRate"
                      control={<Radio color="primary" disabled={!isProgramOwner} />}
                      label="Customer Rate"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {(selectedFinanceType === "subsidy" ||
            (selectedRatePerTermMethod === "customerRate" && selectedFinanceType === "financeProfit")) && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "75%" },
                    required: true,
                  }}
                  label="Customer Rate (%)"
                  variant="outlined"
                  type="number"
                  value={program.customerRate}
                  onChange={e => handleInputChange("customerRate", e)}
                  fullWidth
                  margin="normal"
                  disabled={!isProgramOwner}
                />
              </Grid>
            </Grid>
          )}
          {selectedFinanceType === "financeProfit" && selectedRatePerTermMethod === "markup" && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "75%" },
                    required: true,
                  }}
                  label="Dealer Markup (%)"
                  variant="outlined"
                  type="number"
                  value={program.dealerMarkup}
                  onChange={e => handleInputChange("dealerMarkup", e)}
                  fullWidth
                  margin="normal"
                  disabled={
                    !isProgramOwner && _.get(program, "financeProgramModificationSettings.markup", false) !== true
                  }
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                <InputLabel required={true} shrink={true} id="program-type-label">
                  Program Type
                </InputLabel>
                <Select
                  labelId="program-type-label"
                  id="program-type"
                  value={program.type}
                  onChange={e => handleInputChange("type", e)}
                  label="Program Type"
                  required={true}
                  style={{ height: 40 }}
                  disabled={!isProgramOwner}
                >
                  <MenuItem key={1} value={"LOAN"}>
                    <ListItemText primary={"Loan"} />
                  </MenuItem>
                  <MenuItem key={2} value={"LEASE"}>
                    <ListItemText primary={"Lease"} />
                  </MenuItem>
                  <MenuItem key={3} value={"EFA"}>
                    <ListItemText primary={"EFA"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {program.type === "LEASE" && (
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "100%" },
                    required: true,
                  }}
                  label="Residual (%)"
                  variant="outlined"
                  type="number"
                  value={program.residualPercentage}
                  onChange={e => handleInputChange("residualPercentage", e)}
                  fullWidth
                  margin="normal"
                  disabled={!isProgramOwner}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                label="Advance Payment (# of)"
                variant="outlined"
                type="number"
                value={program.numberOfAdvancePayments}
                onChange={e => handleInputChange("numberOfAdvancePayments", e)}
                fullWidth
                margin="normal"
                disabled={!isProgramOwner}
              />
            </Grid>
            {(isVOView || isCreditSubmissionView) && (
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                    style: { width: "100%" },
                    required: true,
                  }}
                  label="Down Payment"
                  variant="outlined"
                  value={formatCurrency(program.downPaymentAmount, true) || ""}
                  onChange={e => floatNumberRegexp.test(e.target.value) && handleInputChange("downPaymentAmount", e)}
                  fullWidth
                  margin="normal"
                  disabled={!isProgramOwner}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                <InputLabel required={true} shrink={true} id="payment-calculation-label">
                  Payment Calculation Method
                </InputLabel>
                <Select
                  labelId="payment-calculation-label"
                  id="payment-calculation"
                  value={program.paymentPeriodTiming}
                  onChange={e => handleInputChange("paymentPeriodTiming", e)}
                  label="Payment Calculation Method"
                  required={true}
                  style={{ height: 40 }}
                  disabled={!isProgramOwner}
                >
                  <MenuItem key={1} value={"BEGINNING_OF_PERIOD"}>
                    <ListItemText primary={"Advance"} />
                  </MenuItem>
                  <MenuItem key={2} value={"END_OF_PERIOD"}>
                    <ListItemText primary={"Arrears"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {!(isVOView || isCreditSubmissionView) && (
            <>
              <Grid container style={{ margin: "16px 0" }}>
                <FormControlLabel
                  style={{ color: "rgba(0,0,0,0.5)", fontWeight: "normal", marginLeft: 0 }}
                  value={program.validDateStart || program.validDateEnd}
                  control={
                    <Switch
                      color="primary"
                      size="small"
                      style={{
                        color: program.validDateStart || program.validDateEnd ? theme.palette.primary.main : undefined,
                      }}
                      checked={program.validDateStart || program.validDateEnd}
                      onChange={e => handleValidityDateToggle()}
                    />
                  }
                  label="Validity Dates"
                  labelPlacement="start"
                />
              </Grid>
              {(program.validDateStart || program.validDateEnd) && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        disableToolbar
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: true,
                        }}
                        inputVariant="outlined"
                        format="MM/DD/yyyy"
                        margin="normal"
                        label={"Start Date"}
                        required={true}
                        value={
                          program.validDateStart
                            ? new Date(
                                /^\d+$/.test(program.validDateStart)
                                  ? parseInt(program.validDateStart)
                                  : program.validDateStart
                              )
                            : null
                        }
                        onChange={e => handleInputChange("validDateStart", e.toISOString())}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        className={classes.textField}
                        disabled={!isProgramOwner}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        disableToolbar
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: true,
                        }}
                        inputVariant="outlined"
                        format="MM/DD/yyyy"
                        margin="normal"
                        label={"End Date"}
                        required={true}
                        value={
                          program.validDateEnd
                            ? new Date(
                                /^\d+$/.test(program.validDateEnd)
                                  ? parseInt(program.validDateEnd)
                                  : program.validDateEnd
                              )
                            : null
                        }
                        onChange={e => {
                          console.log(e);
                          handleInputChange("validDateEnd", e.toISOString());
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        className={classes.textField}
                        disabled={!isProgramOwner}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <div style={{ margin: "8px 0 24px" }}>
            {program.flatFees.map((fee, feeIndex) => (
              <FeeInput
                fee={fee}
                key={feeIndex}
                index={feeIndex}
                onRemove={() => handleRemoveFee(feeIndex)}
                onFeeChange={(field, value) => handleFeeChange(feeIndex, field, value)}
                disabled={!isProgramOwner}
              />
            ))}
            {isProgramOwner && (
              <Button onClick={() => handleAddFee()} style={{ marginTop: "4px", color: theme.palette.primary.main }}>
                <AddOutlined />
                Add Fee
              </Button>
            )}
          </div>
        </>
      )}
    </Grid>
  );
};

export default FinanceProgramForm;
