export const lenderTypeMap = new Map([
  [300, "ROUTE_ONE"],
  [301, "CLICK_LEASE"],
  [302, "FIN_PAC"],
  [303, "CIT"],
  [304, "STEARNS_BANK"],
  [305, "PAWNEE"],
  [306, "NAVITAS"],
  [307, "AMUR"],
  [308, "ENGS"],
  [309, "AFG"],
  [310, "DIESEL"],
  [311, "DTFS"],
  [401, "TOYOTA_BOT"],
  [402, "TOYOTA_BOT_FULL_SUBMIT"],
  [403, "ISUZU_BOT"],
  [405, "WELLSFARGO_BOT_FULL_SUBMIT"],
  [104, "WELLSFARGO_BOT"],
  [10404, "CNH_BOT_TEST"],
  [11404, "CNH_BOT_DRAFT"],
  [404, "CNH_BOT_FULL_SUBMIT"],
  [406, "SHEFFIELD"],
  [407, "DFS"],
  [408, "CNH"],
  [105, "PACCAR_BOT"],
]);
