import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import Tile from "components/Tile/Tile";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import TableSearch from "components/Table/TableSearch";
import Button from "components/CustomButtons/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ConfigurableItemActions } from "pages/PortalConfigurationWizard/CreditAppDetailModal/ConfigurableItemActions";
import { AddOutlined } from "@material-ui/icons";
import CreateEditChecklistItemModal from "./CreateEditChecklistItemModal";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const styles = {
  checklistTemplatesTable: {
    "& .actions-column": {
      display: "flex",
    },
  },
};

const useStyles = makeStyles(styles);

const ChecklistTemplate = ({ tileTitle, checklist, onChecklistSave, isPortalSetupPage }) => {
  const classes = useStyles();

  const userProfile = useSelector(state => state.userProfile);
  const account = useSelector(state => state.account);

  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });

  const [isCreateEditChecklistItemModalOpen, setIsCreateEditChecklistItemModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [checklistItemToEdit, setChecklistItemToEdit] = useState();

  const columns = [
    {
      Header: "Completed",
      Cell: props => (
        <Checkbox
          color="primary"
          onChange={() => handleChecklistItemToggle(props.original)}
          checked={props.original.isCompleted}
          disabled={isPortalSetupPage}
        />
      ),
      width: 100,
    },
    {
      Header: "Task Name",
      accessor: "name",
    },
    {
      Header: "Created On",
      accessor: "createdDateTime",
      Cell: ({ value }) => (value ? format(new Date(value), "M/dd/yyyy") : ""),
    },
    /*     {
      Header: "Modified",
      accessor: "modifiedDateTime",
      Cell: ({ value }) => (value ? format(new Date(value), "M/dd/yyyy") : ""),
    }, */
    {
      Header: "Completed On",
      accessor: "completedDateTime",
      Cell: ({ value }) => (value ? format(new Date(value), "h:mmaa MM/dd/yyyy") : ""),
    },
    {
      Header: "Completed By",
      accessor: "completedByUserProfileId",
      Cell: ({ value }) => (value ? _.get(_.find(account?.contacts, { id: value }), "fullName") : ""),
    },
    {
      Header: "Notes",
      accessor: "notes",
    },
    {
      Header: "Actions",
      Cell: props => (
        <ConfigurableItemActions
          itemType="Checklist Item"
          onEdit={e => handleEditChecklistItemClick(e, props.original)}
          onDelete={e => handleDeleteChecklistItemClick(e, props.original)}
        />
      ),
      width: 150,
      getProps: () => {
        return {
          className: "actions-column",
        };
      },
    },
  ];

  const handleCreateChecklistItemClick = () => {
    const emptyChecklistItem = {
      name: "",
      notes: "",
      isCompleted: false,
      completedDateTime: null,
      completedByUserProfileId: null,
      createdDateTime: new Date(),
      modifiedDateTime: new Date(),
    };
    setChecklistItemToEdit(emptyChecklistItem);
    setIsCreateEditChecklistItemModalOpen(true);
  };

  const handleEditChecklistItemClick = (e, checklistItemToEdit) => {
    const checklistItemToEditCopy = _.cloneDeep(checklistItemToEdit);
    setChecklistItemToEdit(checklistItemToEditCopy);
    setIsCreateEditChecklistItemModalOpen(true);
  };

  const handleDeleteChecklistItemClick = (e, checklistItemToRemove) => {
    setChecklistItemToEdit(checklistItemToRemove);
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      const checklistToSave = checklist.filter(c => c.checklistItemId !== checklistItemToEdit.checklistItemId);
      onChecklistSave(checklistToSave);
    }
  };

  const handleChecklistItemToggle = checklistItem => {
    const isCompleted = !checklistItem.isCompleted;
    const checklistToSave = checklist.map(c =>
      c.checklistItemId === checklistItem.checklistItemId
        ? {
            ...checklistItem,
            isCompleted,
            completedDateTime: isCompleted ? new Date() : null,
            completedByUserProfileId: isCompleted ? userProfile?.id : null,
          }
        : c
    );
    onChecklistSave(checklistToSave);
    //setChecklist(checklistToSet);
  };

  const handleSaveChecklistItem = () => {
    const isEditing = !!checklistItemToEdit?.checklistItemId;
    const checklistToSave = isEditing
      ? checklist.map(c =>
          c.checklistItemId == checklistItemToEdit.checklistItemId
            ? { ...checklistItemToEdit, modifiedDateTime: new Date() }
            : c
        )
      : [...checklist, { ...checklistItemToEdit, checklistItemId: uuidv4() }];
    onChecklistSave(checklistToSave);
    setIsCreateEditChecklistItemModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <Tile title={tileTitle}>
        <TableHeader
          filterComps={
            <TableSearch
              setPageNumber={setPageNumber}
              setSavedListSettings={setSavedListSettings}
              searchRef={searchRef}
              savedListSettings={savedListSettings}
            />
          }
        >
          <Button size="sm" color="primary" onClick={handleCreateChecklistItemClick}>
            <AddOutlined />
            Add Checklist Item
          </Button>
        </TableHeader>
        <Table
          color="primary"
          data={checklist}
          columns={columns}
          pageSize={pageSizeValue}
          pageNumber={pageNumber}
          defaultPageSize={savedListSettings.pageSize}
          showPaginationTop={false}
          showPaginationBottom={true}
          onPageChange={changePage => {
            setPageNumber(changePage);
          }}
          onPageSizeChange={changePageSize => {
            setPageSizeValue(changePageSize);
            setPageNumber(0);
          }}
          className={classes.checklistTemplatesTable + " -highlight"}
        />
      </Tile>

      <CreateEditChecklistItemModal
        isOpen={isCreateEditChecklistItemModalOpen}
        setIsOpen={setIsCreateEditChecklistItemModalOpen}
        checklistItem={checklistItemToEdit}
        setChecklistItem={setChecklistItemToEdit}
        handleSave={handleSaveChecklistItem}
      />

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove checklist item?"
        open={isConfirmationModalOpen}
        onClose={onConfiramtionModalClose}
      />
    </div>
  );
};

export default ChecklistTemplate;
