import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button";
import { Button as MUIButton } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import { convertToDate, formatMoney, setFieldValue, validateValue } from "../../utils";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { formatCurrency } from "../../utils";
import TableContainer from "@material-ui/core/TableContainer";
import { calculateRatesAndPayments } from "../../paymentSelectorMath";
import TableHead from "@material-ui/core/TableHead";
import { useHistory } from "react-router-dom";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { theme } from "../../theme";

const config = require("../../config.js");

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const M_SEND_PROPOSAL = gql`
  mutation($input: SendProposalInput!) {
    sendProposal(input: $input) {
      success
      errorMessage
    }
  }
`;

const Q_PROPOSAL = gql`
  query proposal($id: ID) {
    proposal(id: $id) {
      id
      productId
      rate
      riskTier
      amountRequested
      markupInternal
      markupDealer
      bizName
      proposalStage
      businessName
      creditProductIds
      vendorOpportunityId
      accountId
      status
      createdDateTime
      firstName
      lastName
      email
      vendorSalespersonId
      monthlyPayment
      termLength
      numOfAdvancePayments
      interestRate
      productSelected
      apr
      lender
      docFee
      amountDue
      gbbCalculatedOptions
      type
      gbbAcceptedTerms
      gbbAcceptedCalculatedOption
      gbbAcceptedMonthlyPayment
      gbbAcceptedTotalAmount
      monthlyPayment
      acceptedAftermarketOptions
      fullName
      paymentId
      category
      paymentCards {
        productId
        productName
        productHeader
        term
        rate
        payment
        rateFactor
        riskTier
        amountMin
        amountMax
        docFee
        advancePayment
        markupInternal
        markupDealer
        lender
        downPaymentAmount
      }
      lenderPaymentOptions {
        paymentId
        proposalId
        lenderId
        term
        residual
        lenderType
        payment
        paymentCriteria
        paymentCriteriaSelected {
          name
          description
          proposalMenuId
          menuOption {
            name
            description
            payment {
              paymentAmountPerPeriod
            }
            # proposalMenuOptionId ### not implemented
            titleColor
            titleBgColor
            packages {
              name
              products {
                title
                cost
                retailCost
                thirdPartyUrl
                proposalProductId
              }
            }
          }
        }
      }
      vendorOpportunity {
        equipmentDescription
      }
      contact {
        email
        firstName
        lastName
        fullName
        phoneNumber
        mugshot
        title
        availability
      }
      vendorProfile {
        logo
        styles
      }
    }
  }
`;

const DEACTIVATE_PROPOSAL = gql`
  mutation($id: ID!) {
    deactivateProposal(id: $id)
  }
`;

const UPDATE_FASTTRACK_STATUS = gql`
  mutation($VOId: String!) {
    updateVendorOpportunity(VOId: $VOId, fieldsObj: { fastTrackStatus: AWAITING_DEALER_APPROVAL })
  }
`;

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  formControlInputs: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControlRate: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 200,
  },
}));

function ProposalDetails({ userProfile, match, setRefetch, account, vo }) {
  const propId = match.params.propId;
  const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const classes = useStyles();
  const history = useHistory();

  const { data: proposalDetails } = useQuery(Q_PROPOSAL, {
    variables: {
      id: propId,
    },
    fetchPolicy: "no-cache",
  });

  const [sendProposal, { data, error, loading }] = useMutation(M_SEND_PROPOSAL, {
    context: { authRequired: true },
  });

  const [deactivateProposal] = useMutation(DEACTIVATE_PROPOSAL, {
    context: { authRequired: true },
  });

  const [updateFastTrackStatus] = useMutation(UPDATE_FASTTRACK_STATUS, {
    context: { authRequired: true },
  });

  const [salesReps, setSalesReps] = useState("");

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);
  const [renderOptions, setRenderOptions] = useState(false);
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    firstName: {
      value: "",
    },
    lastName: {
      value: "",
    },
    contactName: {
      value: "",
    },
    applicantName: {
      value: "",
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    businessName: {
      value: "",
    },
    phone: {
      value: "",
      validationStatus: "",
      format: formatPhone,
    },
    description: {
      value: "",
    },
    status: {
      value: "",
    },
    amountRequested: {
      value: "",
      format: formatMoney,
    },
    createdDateTime: {
      value: "",
    },
    vendorSalespersonId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    proposalLink: {
      value: "",
    },
  });
  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (proposalDetails) {
      setFields({
        id: {
          value: proposalDetails.proposal.id ? proposalDetails.proposal.id : "",
          validationStatus: "",
        },
        firstName: {
          value: proposalDetails.proposal.firstName ? proposalDetails.proposal.firstName : "",
          validationStatus: "",
        },
        lastName: {
          value: proposalDetails.proposal.lastName ? proposalDetails.proposal.lastName : "",
          validationStatus: "",
        },
        contactName: {
          value:
            proposalDetails.proposal.firstName && proposalDetails.proposal.lastName
              ? proposalDetails.proposal.firstName + " " + proposalDetails.proposal.lastName
              : "",
          validationStatus: "",
        },
        applicantName: {
          value: proposalDetails.proposal.fullName ? proposalDetails.proposal.fullName : "",
          validationStatus: "",
        },
        email: {
          value: proposalDetails.proposal.email ? proposalDetails.proposal.email : "",
          validationStatus: "",
        },
        amountRequested: {
          value: proposalDetails.proposal.amountRequested ? formatMoney(proposalDetails.proposal.amountRequested) : "",
          validationStatus: "",
          format: formatMoney,
        },
        businessName: {
          value: proposalDetails.proposal.businessName ? proposalDetails.proposal.businessName : "",
          validationStatus: "",
        },
        status: {
          value: proposalDetails.proposal.status ? proposalDetails.proposal.status : "",
          validationStatus: "",
        },
        phone: {
          value: proposalDetails.proposal.phone ? formatPhone(proposalDetails.proposal.phone) : "",
          validationStatus: "",
          format: formatPhone,
          // validations: [{ type: "required" }],
        },
        createdDateTime: {
          value: proposalDetails.proposal.createdDateTime
            ? convertToDate(proposalDetails.proposal.createdDateTime)
            : "",
        },
        vendorSalespersonId: {
          value: proposalDetails.proposal.vendorSalespersonId ? proposalDetails.proposal.vendorSalespersonId : "",
        },
        proposalLink: {
          value: proposalDetails.proposal.id
            ? `${config.REACT_APP_OCA_BASE_URL}/ps/?vendorGUID=${account.dynamicsAccountId}&proposalId=${proposalDetails.proposal.id}&viewOnly=true`
            : null,
        },
      });
    }
  }, [proposalDetails]);

  useEffect(() => {
    if (proposalDetails) {
      try {
        calculateMonthlyOptions(proposalDetails.proposal.paymentCards);
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  }, [proposalDetails]);

  const sendLink = async () => {
    try {
      handleOpenModal();
      handleSubmissionMessage({ title: "Sending", message: "Please wait. Sending proposal..." });
      await sendProposal({
        variables: {
          input: {
            id: proposalDetails.proposal.id,
            amountRequested: proposalDetails.proposal.amountRequested,
            creditProductIds: proposalDetails.proposal.creditProductIds,
            riskTier: proposalDetails.proposal.tier,
            vendorOpportunityId: vo.vendorOpportunityId,
            accountId: account.id,
            vendorSalespersonId: proposalDetails.proposal.vendorSalespersonId,
            firstName: proposalDetails.proposal.firstName,
            lastName: proposalDetails.proposal.lastName,
            email: fields.email.value,
            businessName: proposalDetails.proposal.businessName,
            status: "Proposal Sent",
          },
        },
      });
      handleSubmissionMessage({ title: "Success", message: "Sent Successfully" });
    } catch (err) {
      console.log(err);
      handleSubmissionMessage({ title: "Error", message: "There was something wrong with your request" });
    }
  };

  const handleSubmit = async event => {
    try {
      sendLink();
      setSnackBarMessage("Successfully sent your proposal!");
      setSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error sending proposal!");
      setErrorOpen(true);
    }
  };

  const handleDeleteProposal = async (id, VOId) => {
    console.log(id, VOId);

    try {
      await deactivateProposal({
        variables: {
          id: id,
        },
      }).then(async () => {
        await updateFastTrackStatus({
          variables: {
            VOId: VOId,
          },
        });
      });
      setSnackBarMessage("Successfully deleted your proposal!");
      setSnackbarOpen(true);
      setTimeout(() => {
        window.location.href = `/vo/${VOId}`;
      }, 3000);
    } catch (err) {
      console.log(err);
      setErrorMessage("Error deleting proposal!");
      setErrorOpen(true);
    }
  };

  const calculateMonthlyOptions = async obj => {
    if (!!obj && obj.length) {
      obj.forEach((item, i) => {
        item.amountRequested = parseFloat(proposalDetails.proposal.amountRequested);
        item.monthlyPayments = item.amountRequested * item.rateFactor;
        if (typeof item.docFee !== "number") {
          item.docFee = parseFloat(item.docFee.replace(/[^0-9.-]+/g, ""));
        }
        item.mpWithPoints = item.monthlyPayments * (1 + (item.markupInternal / 100 + item.markupDealer / 100));
        item.monthlyPayments = item.mpWithPoints;
        item.monthlyPayments = item.monthlyPayments.toFixed(2);
        const rateSandPay = calculateRatesAndPayments(
          item.amountRequested,
          item.term,
          item.rate,
          item.markupInternal / 100 + item.markupDealer / 100
        );
        if (!item.rateFactor) {
          item.monthlyPayments = rateSandPay.sellPayment;
        }
        item.interestRate = (rateSandPay.simpleInterest * 100).toFixed(2);
        if (!!item.advancePayment && typeof item.advancePayment !== "number") {
          item.advancePayment = parseFloat(item.advancePayment.replace(/[^0-9.-]+/g, ""));
        }
        if (item.advancePayment > 0) {
          item.amountDue = item.advancePayment * parseFloat(item.monthlyPayments) + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        } else {
          item.amountDue = item.monthlyPayments + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        }
        item.monthlyPayments = numberWithCommas(item.monthlyPayments);
        item.emailHeader = "Option " + parseInt(i + 1);
        delete item.interestRate;
        delete item.credittProduct;
        delete item.mpWithPoints;
        delete item.__typename;
      });
      setTimeout(() => setRenderOptions(true), 500);
    }
  };

  return (
    <>
      {proposalDetails ? (
        <Form
          onSubmit={values => {
            handleSubmit();
          }}
          validate={checkFormValidation}
          initialValues={!!proposalDetails.proposal}
          render={({ handleSubmit, pristine, valid, submitting, values }) => {
            function uploadButtonHandler(event) {
              const file = document.getElementById("file");
              file.click();
            }
            function uploadFileChanged(event) {
              fields.file.value = event.target.files[0];
              const reader = new FileReader();
              reader.onload = e => {
                const avatar = document.getElementById("avatar");
                setValue(e.target.result, "mugshot");
                avatar.src = e.target.result;
              };
              reader.readAsDataURL(event.target.files[0]);
              checkFormValidation();
            }
            function handleClose() {
              setSnackbarOpen(false);
              setErrorOpen(false);
            }

            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit().then(res => console.log(res));
                }}
              >
                <Grid container>
                  <Tile>
                    <Grid container alignItems="center">
                      <GridItem xs={6}>
                        <MUIButton
                          className="formBtn"
                          color="default"
                          onClick={() => history.goBack()}
                          style={{ padding: 0, color: "#666666" }}
                        >
                          <ArrowBackIosOutlined style={{ fontSize: 16, marginRight: 4 }} />
                          Back
                        </MUIButton>
                      </GridItem>
                      <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", fontSize: 14 }}>
                        {fields.proposalLink.value ? (
                          <MUIButton
                            className="formBtn"
                            color="primary"
                            style={{ color: theme.palette.primary.main }}
                            href={fields.proposalLink.value}
                            target="_blank"
                          >
                            {proposalDetails.proposal.category === "QUOTE" ? "View Quote" : "View Proposal"}
                          </MUIButton>
                        ) : (
                          <span>No Link Available</span>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 16 }}>
                      <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Created On"
                          id="createdDateTime"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          disabled
                          inputProps={{
                            type: "text",
                            name: "createdDateTime",
                            value: fields.createdDateTime.value,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Status"
                          id="status"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          disabled
                          inputProps={{
                            type: "text",
                            name: "status",
                            value: fields.status.value,
                          }}
                        />
                      </GridItem>
                    </Grid>
                    <Grid container>
                      <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Business Name"
                          id="businessName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            name: "businessName",
                            value: fields.businessName.value,
                            onChange: e => setValue(e.target.value, "businessName"),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Amount Requested"
                          id="amountRequested"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            name: "amountRequested",
                            value: formatCurrency(fields.amountRequested.value),
                            onChange: e => setValue(e.target.value, "amountRequested"),
                          }}
                        />
                      </GridItem>
                    </Grid>
                    <Grid container>
                      <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Contact Name"
                          id="contactName"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            disabled: true,
                            type: "text",
                            name: "contactName",
                            value: fields.contactName.value ? fields.contactName.value : fields.applicantName.value,
                            onChange: e =>
                              fields.contactName.value
                                ? setValue(e.target.value, "contactName")
                                : setValue(e.target.value, "applicantName"),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput
                          labelText="Email"
                          id="email"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            type: "text",
                            name: "email",
                            value: fields.email.value,
                            onChange: e => setValue(e.target.value, "email"),
                          }}
                        />
                      </GridItem>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} style={{ display: "flex", gap: 24, justifyContent: "flex-end" }}>
                        <MUIButton
                          className="formBtn"
                          color="default"
                          style={{ color: "#f44336" }}
                          onClick={() => handleDeleteProposal(proposalDetails.proposal.id, vo.vendorOpportunityId)}
                        >
                          Delete
                        </MUIButton>
                        {(proposalDetails.proposal.status !== "Accepted" ||
                          proposalDetails.proposal.status !== "accepted - pending bank") && (
                          <Button
                            className="formBtn"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={submitting || !formValid}
                          >
                            {submitting ? "Sending" : "Send"}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Tile>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={12}>
                    {renderOptions &&
                      proposalDetails.proposal.status !== "Accepted" &&
                      proposalDetails.proposal.type === "base" && (
                        <Paper>
                          <CardHeader title="Generated Proposal" />
                          <Divider />
                          <CardContent style={{ padding: 0 }}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow className={classes.row}>
                                    <TableCell>Credit Product</TableCell>
                                    <TableCell>Term </TableCell>
                                    <TableCell>Monthly Payment</TableCell>
                                    <TableCell>Amount Due</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {proposalDetails.proposal.paymentCards.map(
                                    ({ term, monthlyPayments, productHeader, amountDue }, i) => (
                                      <>
                                        {term === 24 && (
                                          <TableRow key={i}>
                                            <TableCell colSpan={3} align="left">
                                              Credit Product {productHeader}
                                            </TableCell>
                                          </TableRow>
                                        )}
                                        <TableRow key={i}>
                                          <TableCell></TableCell>
                                          <TableCell>{term} Months</TableCell>
                                          <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                          <TableCell>{formatCurrency(amountDue)}</TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Paper>
                      )}
                    {!!proposalDetails &&
                      proposalDetails.proposal.type === "gbb" &&
                      proposalDetails.proposal.status !== "Accepted" && (
                        <Paper>
                          <CardHeader title="Generated Proposal" />
                          <Divider />
                          <CardContent style={{ padding: 0 }}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow className={classes.row}>
                                    <TableCell>Credit Product</TableCell>
                                    <TableCell>Term </TableCell>
                                    <TableCell>Monthly Payment</TableCell>
                                    <TableCell>Rate</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>Trac Option</TableCell>
                                  </TableRow>
                                  {proposalDetails.proposal.gbbCalculatedOptions[0].trac.map(
                                    ({ term, monthlyPayments, rate, simpleInterest, emailHeader, amountDue }, i) => (
                                      <>
                                        <TableRow key={i}>
                                          <TableCell>{emailHeader}</TableCell>
                                          <TableCell>60 Months</TableCell>
                                          <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                          <TableCell>{rate}%</TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  )}
                                  <TableRow>
                                    <TableCell>Finance Option</TableCell>
                                  </TableRow>
                                  {proposalDetails.proposal.gbbCalculatedOptions[0].finance.map(
                                    ({ term, monthlyPayments, rate, simpleInterest, emailHeader, amountDue }, i) => (
                                      <>
                                        <TableRow key={i}>
                                          <TableCell>{emailHeader}</TableCell>
                                          <TableCell>60 Months</TableCell>
                                          <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                          <TableCell>{rate}%</TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Paper>
                      )}
                    {!!proposalDetails.proposal &&
                      proposalDetails.proposal.status == "Accepted" &&
                      proposalDetails.proposal.type === "base" && (
                        <Paper>
                          <CardHeader title="Accepted Proposal" />
                          <Divider />
                          <CardContent style={{ padding: 0 }}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow className={classes.row}>
                                    <TableCell>Credit Product</TableCell>
                                    <TableCell>Term </TableCell>
                                    <TableCell>Monthly Payment</TableCell>
                                    <TableCell>Interest</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{proposalDetails.proposal.productSelected}</TableCell>
                                    <TableCell>{proposalDetails.proposal.termLength} Months</TableCell>
                                    <TableCell>{formatCurrency(proposalDetails.proposal.monthlyPayment)}</TableCell>
                                    <TableCell>{proposalDetails.proposal.interestRate.toFixed(2)}%</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Paper>
                      )}
                    {!!proposalDetails.proposal &&
                      proposalDetails.proposal.status == "Accepted" &&
                      proposalDetails.proposal.type === "gbb" && (
                        <Paper>
                          <CardHeader title="Accepted Proposal" />
                          <Divider />
                          <CardContent style={{ padding: 0 }}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow className={classes.row}>
                                    <TableCell>Accepted Option</TableCell>
                                    <TableCell>Term </TableCell>
                                    <TableCell>Monthly Payment</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      {proposalDetails.proposal.gbbAcceptedCalculatedOption.type} -{" "}
                                      {proposalDetails.proposal.gbbAcceptedCalculatedOption.emailHeader} -{" "}
                                      {proposalDetails.proposal.acceptedAftermarketOptions}
                                    </TableCell>
                                    <TableCell>{proposalDetails.proposal.gbbAcceptedTerms} Months</TableCell>
                                    <TableCell>{formatCurrency(proposalDetails.proposal.monthlyPayment)}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Paper>
                      )}
                    {!!proposalDetails && proposalDetails.proposal.type === "payments" && (
                      <Paper>
                        <CardHeader title="Payment Options" />
                        <Divider />
                        <CardContent style={{ padding: 0 }}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow className={classes.row}>
                                  <TableCell>Term </TableCell>
                                  <TableCell>Monthly Payment</TableCell>
                                  <TableCell>Selected by Applicant</TableCell>
                                  <TableCell>Tier Option</TableCell>
                                  <TableCell>Rate</TableCell>
                                  {/* <TableCell>Proposal Template</TableCell> */}
                                  <TableCell>Finance Program</TableCell>
                                  <TableCell>F&I Menu Selected by Applicant</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {proposalDetails.proposal.lenderPaymentOptions.map(
                                  ({ term, payment, paymentId, paymentCriteria, paymentCriteriaSelected }, i) => (
                                    <>
                                      <TableRow key={i}>
                                        <TableCell>{term}</TableCell>
                                        <TableCell>
                                          {formatCurrency(
                                            paymentCriteriaSelected?.menuOption?.payment
                                              ? paymentCriteriaSelected.menuOption.payment.paymentAmountPerPeriod
                                              : payment
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {paymentId === proposalDetails.proposal.paymentId ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell>N.A.</TableCell>
                                        <TableCell>
                                          {(paymentCriteria?.rate || paymentCriteria?.rate === 0) &&
                                            `${paymentCriteria?.rate}%`}
                                        </TableCell>
                                        {/* <TableCell></TableCell> */}
                                        <TableCell>{paymentCriteria?.financeProgramName}</TableCell>
                                        <TableCell>
                                          {paymentCriteriaSelected ? (
                                            <div>
                                              <p style={{ fontWeight: "bold" }}>
                                                {paymentCriteriaSelected.menuOption?.name}
                                              </p>
                                              <p style={{ paddingLeft: "3px" }}>Packages:</p>
                                              <ul style={{ paddingLeft: "8px" }}>
                                                {paymentCriteriaSelected.menuOption?.packages?.map(p => (
                                                  <li>{p.name}</li>
                                                ))}
                                              </ul>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        />
      ) : (
        "Loading Proposal"
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    account: state.account,
    vo: state.vo,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDetails);
