import React from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import avatar from "assets/img/default-avatar.png";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import CompanyProfileSidebarBlock from "../CompanyProfileSidebarBlock";
import _ from "lodash";
import { adminRoles } from "../../../../pages/Prequal/constants";

const logoDCR = require("../../assets/img/trnsact-logo-dark.png");
let ps;
const config = require("config.js");
const GET_USER_PROFILE = gql`
  query {
    userProfile {
      id
      firstName
      lastName
      mugshot
      phoneNumber
    }
  }
`;

const GET_ACCOUNT_PROFILE = gql`
  query {
    lenderProfile {
      id
      name
      logo
    }
  }
`;

const lenderOcaAccessDependentPages = ["vo", "sendoca", "leads", "locationMgmt", "locationDetailLocationId"];
const nonVPOnlyRoutes = ["addDealer"];

class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links, userInfoBlock } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
        {userInfoBlock}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates([...props.appRoutes, ...props.userRoutes]),
    };
  }
  mainPanel = React.createRef();

  // this creates the initial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => (window.location.href.indexOf(routeName) > -1 ? "active" : "");
  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  getDynamicPath = ({ entity, key, path }) => {
    return path.replace(`:${key}`, `${this.props.accountData[entity] ? this.props.accountData[entity][key] : ""}`);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color } = this.props;
    return routes.map((prop, key) => {
      if (prop.showInSidebarNav) {
        if (!this.props?.accountData?.vendorProfile && lenderOcaAccessDependentPages.includes(prop.id)) {
          return null;
        }
        if (this.props?.accountData?.vendorProfile && nonVPOnlyRoutes.includes(prop.id)) {
          return null;
        }
        if (prop.redirect) {
          return null;
        }

        const path = prop.dynamic
          ? this.getDynamicPath({ path: prop.path, key: prop.dynamicKey, entity: prop.dynamicEntity })
          : prop.path;

        if (prop.collapse) {
          const st = {};
          st[prop["state"]] = !this.state[prop.state];
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes.collapseActive]: this.getCollapseInitialState(prop.views),
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
            });
          const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
              [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
            });
          const itemIcon = classes.itemIcon;
          const caret = classes.caret;
          const collapseItemMini = classes.collapseItemMini;
          return (
            <ListItem
              key={key}
              className={cx(
                { [classes.item]: prop.icon !== undefined },
                { [classes.collapseItem]: prop.icon === undefined }
              )}
            >
              <NavLink
                to={"#"}
                className={navLinkClasses}
                onClick={e => {
                  e.preventDefault();
                  this.setState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>{prop.mini}</span>
                )}
                <ListItemText
                  primary={prop.name}
                  secondary={<b className={caret + " " + (this.state[prop.state] ? classes.caretActive : "")} />}
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </NavLink>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={classes.list + " " + classes.collapseList}>{this.createLinks(prop.views)}</List>
              </Collapse>
            </ListItem>
          );
        }
        const innerNavLinkClasses =
          classes.collapseItemLink + " " + cx({ [" " + classes[color]]: this.activeRoute(path) });
        const collapseItemMini = classes.collapseItemMini;
        const navLinkClasses = classes.itemLink + " " + cx({ [" " + classes[color]]: this.activeRoute(path) });

        const itemText = `${classes.itemText} ${cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        })}`;
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
          });
        const itemIcon = classes.itemIcon;
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            {prop.action ? (
              <div
                className={classes.itemLink}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  prop.action();
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>{prop.mini}</span>
                )}
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                ></ListItemText>
              </div>
            ) : (
              <NavLink
                to={path}
                className={cx(
                  { [navLinkClasses]: prop.icon !== undefined },
                  { [innerNavLinkClasses]: prop.icon === undefined }
                )}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>{prop.mini}</span>
                )}
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </NavLink>
            )}
          </ListItem>
        );
      }
    });
  };
  render() {
    const { classes, logo, image, logoText, bgColor, appRoutes, userRoutes, accountData } = this.props;
    const vendorProfile = _.get(accountData, "vendorProfile");
    const userAdminRole = _.get(accountData, "userProfile.adminRole", "");
    let isSuperAdmin = false;
    if (userAdminRole) {
      isSuperAdmin = [adminRoles.super, adminRoles.singleAccountOnly].includes(userAdminRole);
    }
    const lenderAdminRoute = _.find(userRoutes, { id: "lenderadmin" });
    lenderAdminRoute.showInSidebarNav = vendorProfile?.allowSyndication;

    const lenderWaterfallRoute = _.find(userRoutes, { id: "lenderWaterfall" });

    lenderWaterfallRoute.showInSidebarNav = vendorProfile?.allowSyndication;
    const pqtRoute = _.find(appRoutes, { id: "pqt" });
    pqtRoute.showInSidebarNav = vendorProfile?.showPqt === "default" || vendorProfile?.showPqt === "options";

    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      });
    const userWrapperClass = classes.user + " " + cx({ [classes.whiteAfter]: bgColor === "white" });
    const caret = classes.caret;
    const photo = classes.photo;

    const queryContext = { authRequired: true };

    const brand = (
      <Query context={queryContext} query={GET_ACCOUNT_PROFILE}>
        {({ loading, error, data }) => {
          const lenderLogo = _.get(data, "lenderProfile.logo", false);
          if (loading) return false;
          return (
            <div className={logoClasses}>
              <a href="#" className={logoNormal}>
                <img
                  id="avatarSidebar"
                  alt="Logo"
                  className={classes.img}
                  style={{
                    /* maxWidth: "100%", */
                    maxHeight: "15vh",
                    backgroundColor: "#FFFFFF",
                  }}
                  src={
                    !lenderLogo
                      ? logoDCR
                      : typeof lenderLogo === "string"
                      ? lenderLogo.indexOf("data:image") === -1
                        ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${lenderLogo}`
                        : lenderLogo
                      : lenderLogo
                  }
                />
              </a>
            </div>
          );
        }}
      </Query>
    );

    const user = (
      <Query context={queryContext} query={GET_USER_PROFILE}>
        {({ loading, error, data }) => {
          if (loading) {
            return "Loading";
          }
          const userProfile = data && data.userProfile;

          return (
            <div className={userWrapperClass}>
              {userProfile ? (
                <React.Fragment>
                  <div className={photo}>
                    {userProfile.mugshot ? (
                      <img
                        src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${userProfile.mugshot}`}
                        className={classes.avatarImg}
                        alt="..."
                      />
                    ) : (
                      <img src={avatar} className={classes.avatarImg} alt="..." />
                    )}
                  </div>
                  <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                      <NavLink
                        to={"#"}
                        className={classes.itemLink + " " + classes.userCollapseButton}
                        onClick={() => this.openCollapse("openAvatar")}
                      >
                        <ListItemText
                          primary={`${userProfile.firstName} ${userProfile.lastName}`}
                          secondary={
                            <b
                              className={
                                caret +
                                " " +
                                classes.userCaret +
                                " " +
                                (this.state.openAvatar ? classes.caretActive : "")
                              }
                            />
                          }
                          disableTypography={true}
                          className={itemText + " " + classes.userItemText}
                        />
                      </NavLink>
                      <Collapse in={this.state.openAvatar} unmountOnExit>
                        <List className={classes.list + " " + classes.collapseList}>
                          {this.createLinks(userRoutes)}
                        </List>
                      </Collapse>
                    </ListItem>
                  </List>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          );
        }}
      </Query>
    );
    const links = <List className={classes.list}>{this.createLinks(appRoutes)}</List>;

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
      });
    const logoMini = classes.logoMini;
    const logoClasses = classes.logo + " " + cx({ [classes.whiteAfter]: bgColor === "white" });

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1,
      });

    const isNeedToShowLocationManagement = !!_.get(vendorProfile, "multiLocationManagement");
    const locationManagementRoute = _.find(userRoutes, { id: "locationMgmt" });
    _.set(locationManagementRoute, "showInSidebarNav", isNeedToShowLocationManagement);

    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks />}
              links={links}
              userInfoBlock={<CompanyProfileSidebarBlock />}
            />
            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={"right"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
              userInfoBlock={<CompanyProfileSidebarBlock />}
            />
            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default withStyles(sidebarStyle)(Sidebar);
