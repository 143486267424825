import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Button as MUIButton,
  MenuItem,
  Snackbar,
  Select,
  Tab,
  TextField,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import Close from "@material-ui/icons/Close";
import FinanceProgramForm from "../../../components/FinanceProgramForm";
import CriteriaForm from "./CriteriaForm";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import { parseCurrency } from "utils";
import InfoIcon from "@material-ui/icons/Info";

interface Account {
  id: string;
}

interface AppState {
  account: Account;
}

const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

const styles = makeStyles(theme => ({
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },

  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
  locationTree: {
    padding: "10px 0",
    "& .tag-item": {
      position: "inherit !important",
    },
    "& .tag-item span": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "#f4f4f4",
      border: "1px solid #e9e9e9",
      padding: "2px 0 2px 8px",
      borderRadius: "4px",
      display: "inline-block",
    },
    "& .tag-item button": {
      display: "inline-block",
      fontSize: "100%",
    },
    "& .tag-item span.placeholder": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "transparent",
      border: "none",
      padding: "0",
      borderRadius: "0",
      display: "inline-block",
    },
  },
}));

const CREATE_FINANCE_PROGRAM = gql`
  mutation CreateFinanceProgram($input: CreateFinanceProgramInput!) {
    createFinanceProgram(input: $input) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      createdDateTime
      modifiedDateTime
      financeProgramToLenderProfiles {
        lenderProfileId
        financeProgramToLenderProfileId
        createdDateTime
        modifiedDateTime
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

const UPDATE_FINANCE_PROGRAM = gql`
  mutation UpdateFinanceProgram($input: UpdateFinanceProgramInput!) {
    updateFinanceProgram(input: $input) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      createdDateTime
      modifiedDateTime
      financeProgramToLenderProfiles {
        lenderProfileId
        financeProgramToLenderProfileId
        createdDateTime
        modifiedDateTime
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

// Define the types for each part of the state

interface PaymentOptionsConfiguration {
  financeProgramName: string;
  flatFees: number[];
  markups: number[];
  dealerMarkup: number | null;
  customerRate: number | null;
  numberOfAdvancePayments: number;
  paymentPeriodTiming: string;
  rate: number;
  terms: string[];
  type: "LOAN" | "LEASE";
  rateType: "INTEREST_RATE" | "RATE_FACTOR";
  rates: any[];
  residualPercentage: number;
}

interface ProgramModificationSettings {
  markup: boolean;
}

interface Program {
  accountId: string;
  lenderProfileIds: string[];
  nameInternal: string;
  validDateStart: string;
  validDateEnd: string;
  prescreenCriteriaIdToLink?: string;
  paymentOptionsConfiguration: PaymentOptionsConfiguration;
  financeProgramId: undefined | string;
  financeProgramModificationSettings: ProgramModificationSettings;
}

const ProgramsPreScreen = ({
  open,
  onClose,
  lenderProfiles,
  create,
  refetchPrograms,
  financeProgram,
  isLenderUser,
  dealerVendorProfiles,
  targetLenderProfileId,
}: {
  open: boolean;
  onClose: any;
  lenderProfiles: any;
  create: any;
  refetchPrograms: any;
  financeProgram: any;
  isLenderUser: any;
  dealerVendorProfiles: any;
  targetLenderProfileId: any;
}) => {
  const classes = styles();
  const [tabSelected, setTabSelected] = useState("1");

  // console.log(`WIP: lenderProfiles: ${JSON.stringify(lenderProfiles)}`);
  // console.log(`WIP: dealerVendorProfiles: ${JSON.stringify(dealerVendorProfiles)}`);

  // console.log(`WIP: isLenderUser: ${isLenderUser}`);

  const account = useTypedSelector(state => state.account);
  const [loading, setLoading] = useState(false);
  const [createMode, setCreateMode] = useState(create);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [initFinanceProgram, setInitFinanceProgram] = useState<Program | null>(null);
  const [prescreenCriteriaId, setPrescreenCriteriaId] = useState<string>(
    _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", null)
  );
  const [removingFP, setRemovingFP] = useState(false);

  const [selectedLenderProfilesForLender, setSelectedLenderProfilesForLender] = useState<string[]>([]);
  const [selectedLenderProfilesForDealer, setSelectedLenderProfilesForDealer] = useState<string[]>([]);

  const [program, setProgram] = useState<Program>({
    accountId: account.id,
    lenderProfileIds: targetLenderProfileId ? [targetLenderProfileId] : [],
    nameInternal: _.get(financeProgram, "nameInternal", ""),
    validDateStart: "",
    validDateEnd: "",
    prescreenCriteriaIdToLink: _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""),
    financeProgramId: undefined,
    paymentOptionsConfiguration: {
      financeProgramName: "",
      flatFees: [],
      markups: [],
      dealerMarkup: null,
      customerRate: null,
      numberOfAdvancePayments: 0,
      paymentPeriodTiming: "",
      rate: 0,
      terms: [],
      type: "LOAN",
      rateType: "INTEREST_RATE",
      rates: [],
      residualPercentage: 0,
    },
    financeProgramModificationSettings: {
      markup: _.get(financeProgram, "financeProgramModificationSettings.markup", false),
    },
  });

  const handleTabChange = (event: any, newValue: string) => {
    setTabSelected(newValue);
    //refetch program selected (if any)
    if (_.get(program, "financeProgramId")) {
      setInitFinanceProgram(program);
    }
  };
  useEffect(() => {
    setCreateMode(create);
    if (create) {
      setInitFinanceProgram(null);
      setProgram({
        accountId: account.id,
        lenderProfileIds: targetLenderProfileId ? [targetLenderProfileId] : [],
        nameInternal: _.get(financeProgram, "nameInternal", ""),
        validDateStart: "",
        validDateEnd: "",
        prescreenCriteriaIdToLink: _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""),
        financeProgramId: undefined,
        paymentOptionsConfiguration: {
          financeProgramName: "",
          flatFees: [],
          markups: [],
          dealerMarkup: null,
          customerRate: null,
          numberOfAdvancePayments: 0,
          paymentPeriodTiming: "",
          rate: 0,
          terms: [],
          type: "LOAN",
          rateType: "INTEREST_RATE",
          rates: [],
          residualPercentage: 0,
        },
        financeProgramModificationSettings: {
          markup: _.get(financeProgram, "financeProgramModificationSettings.markup", false),
        },
      });
    }
  }, [create]);

  const parseFloatInput = (input: any) => {
    return parseFloat(parseCurrency(_.toString(input)));
  };

  const [createLenderProgram] = useMutation(CREATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
    onCompleted: () => {
      refetchPrograms();
    },
  });
  const [updateLenderProgram] = useMutation(UPDATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
  });

  const handleSubmit = async () => {
    setLoading(true);

    if (createMode) {
      const parsedProgram = { ...program };

      const result = await createLenderProgram({ variables: { input: { ...parsedProgram } } });

      const programSaved = _.get(result, "data.createFinanceProgram", null);
      if (programSaved) {
        setProgram(programSaved);
        setCreateMode(false);
      }
    } else {
      try {
        // @ts-expect-error __typename exists
        delete program.financeProgramModificationSettings.__typename;
      } catch (e) {}

      await updateLenderProgram({
        variables: {
          input: {
            ...program,
            accountId: undefined,
            lenderProfileIds: undefined,
            lenderProfileIdsToDisassociate: _.isEqual(
              _.get(initFinanceProgram, "lenderProfileIds", []),
              program.lenderProfileIds
            )
              ? undefined
              : _.get(initFinanceProgram, "lenderProfileIds", []).filter(
                  id => !program.lenderProfileIds.includes(id)
                ) || [],
            lenderProfileIdsToAssociate: program.lenderProfileIds,
            prescreenCriteriaIdToLink: _.get(program, "prescreenCriteriaIdToLink"),
            //@todo: cleanup payload
            financeProgramToLenderProfiles: undefined,
            rate: undefined,
            prescreenCriteria: undefined,
            createdDateTime: undefined,
            modifiedDateTime: undefined,
            __typename: undefined,
          },
        },
      });
    }

    setLoading(false);
  };

  const handleClose = () => {
    console.log("WIP: handleClose");
    setPrescreenCriteriaId("");
    setInitFinanceProgram(null);
    setProgram({
      accountId: account.id,
      lenderProfileIds: targetLenderProfileId ? [targetLenderProfileId] : [],
      nameInternal: _.get(financeProgram, "nameInternal", ""),
      validDateStart: "",
      validDateEnd: "",
      prescreenCriteriaIdToLink: _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""),
      financeProgramId: undefined,
      paymentOptionsConfiguration: {
        financeProgramName: "",
        flatFees: [],
        markups: [],
        dealerMarkup: null,
        customerRate: null,
        numberOfAdvancePayments: 0,
        paymentPeriodTiming: "",
        rate: 0,
        terms: [],
        type: "LOAN",
        rateType: "INTEREST_RATE",
        rates: [],
        residualPercentage: 0,
      },
      financeProgramModificationSettings: {
        markup: _.get(financeProgram, "financeProgramModificationSettings.markup", false),
      },
    });
    refetchPrograms();
    onClose();
  };

  useEffect(() => {
    if (_.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", "")) {
      setPrescreenCriteriaId(_.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""));
    }

    let programToSet = {
      ...program,
      ...financeProgram,
      prescreenCriteriaIdToLink: _.get(financeProgram, "prescreenCriteria.prescreenCriteriaId", ""),
      nameInternal: _.get(financeProgram, "nameInternal", ""),
    };

    if (targetLenderProfileId) {
      programToSet = { ...programToSet, lenderProfileIds: [targetLenderProfileId] };
    }

    setSelectedLenderProfilesForLender(
      _.get(financeProgram, "lenderProfileIds", []).filter((lpId: string) =>
        (lenderProfiles ?? []).find((lp: any) => lp.id === lpId)
      )
    );
    setSelectedLenderProfilesForDealer(
      _.get(financeProgram, "lenderProfileIds", []).filter((lpId: string) =>
        (dealerVendorProfiles ?? []).find((vp: any) => vp.lenderProfiles[0]?.lenderProfileId === lpId)
      )
    );

    if (financeProgram && financeProgram.paymentOptionsConfiguration) {
      setInitFinanceProgram(programToSet);
    }

    setProgram(programToSet);
  }, [financeProgram, targetLenderProfileId]);
  useEffect(() => {
    console.log("re mounted");
  }, []);

  const getLenderProfileLenderOptions = () => {
    let lenderProfileOptions: any[] = [];
    if (_.get(lenderProfiles, "length", 0) > 0) {
      lenderProfileOptions.push(
        <MenuItem value={"selectAll"}>
          <ListItemText style={{ fontWeight: "bold" }} primary="Select All" />
        </MenuItem>
      );
      lenderProfileOptions = lenderProfileOptions.concat(
        lenderProfiles
          .sort((a: any, b: any) => _.get(a, "lenderName", "").localeCompare(b.lenderName))
          .map((lp: any, i: any) => (
            <MenuItem key={`lender-profile-${i}`} value={lp.id}>
              <Checkbox checked={selectedLenderProfilesForLender.includes(lp.id)} />
              <ListItemText primary={lp.lenderName} />
            </MenuItem>
          ))
      );
    }

    return [...lenderProfileOptions];
  };

  const getLenderProfileDealerOptions = () => {
    let dealerVendorProfileOptions: any[] = [];
    if (dealerVendorProfiles && _.get(dealerVendorProfiles, "length", 0) > 0) {
      dealerVendorProfileOptions.push(
        <MenuItem value={"selectAll"}>
          <ListItemText style={{ fontWeight: "bold" }} primary="Select All" />
        </MenuItem>
      );
      dealerVendorProfileOptions = dealerVendorProfileOptions.concat(
        dealerVendorProfiles
          .map((vp: any) => ({ ...vp, name: vp.name.replace("VP - ", "") }))
          .sort((a: any, b: any) => _.get(a, "name", "").localeCompare(b.name))
          .map((vp: any, i: any) => (
            <MenuItem key={`dealer-vendor-profile-${i}`} value={vp.lenderProfiles[0]?.lenderProfileId}>
              <Checkbox checked={selectedLenderProfilesForDealer.includes(vp.lenderProfiles[0]?.lenderProfileId)} />
              <ListItemText primary={vp.name} />
            </MenuItem>
          ))
      );
    }

    return [...dealerVendorProfileOptions];
  };

  const updatePrescreenCriteriaId = (newPrescreenCriteriaId: string) => {
    if (newPrescreenCriteriaId) {
      setPrescreenCriteriaId(newPrescreenCriteriaId);
      setProgram({ ...program, prescreenCriteriaIdToLink: newPrescreenCriteriaId });

      updateLenderProgram({
        variables: {
          input: {
            financeProgramId: program.financeProgramId,
            prescreenCriteriaIdToLink: newPrescreenCriteriaId,
          },
        },
      }).then(() => {
        setSnackbarOpen(true);
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="fp-pc-title"
      aria-describedby="fp-pc-description"
      fullWidth
      maxWidth="lg"
      transitionDuration={0}
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      <DialogTitle id="fp-pc-title" style={{ paddingBottom: 0 }}>
        <h4>
          <b>Program</b>
        </h4>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <TabContext value={tabSelected}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="Program" variant="fullWidth">
            <Tab label="Pricing" value="1" style={{ textTransform: "none" }} />
            <Tab
              label="Criteria"
              value="2"
              style={{ textTransform: "none", display: program.financeProgramId ? "block" : "none" }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <DialogContent>
            {loading ?? <LinearProgress />}
            {lenderProfiles && _.get(lenderProfiles, "length", 0) > 0 && _.isEmpty(targetLenderProfileId) && (
              <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                <InputLabel shrink={true} id="lender-profile-label">
                  Lenders this program is available to
                </InputLabel>
                <Select
                  labelId="lender-profile-label"
                  id="lender-profile"
                  defaultValue={[]}
                  value={selectedLenderProfilesForLender}
                  onChange={(e: any) => {
                    console.log(
                      `WIP: selected: ${JSON.stringify([...Object.keys(e.target.value).map(k => e.target.value[k])])}`
                    );
                    if (e.target.value.includes("selectAll")) {
                      setSelectedLenderProfilesForLender(lenderProfiles.map((lp: any) => lp.id));
                      return setProgram({
                        ...program,
                        lenderProfileIds: lenderProfiles
                          .map((lp: any) => lp.id)
                          .concat(selectedLenderProfilesForDealer),
                      });
                    }
                    setSelectedLenderProfilesForLender([...Object.keys(e.target.value).map(k => e.target.value[k])]);
                    return setProgram({
                      ...program,
                      lenderProfileIds: [...Object.keys(e.target.value).map(k => e.target.value[k])].concat(
                        selectedLenderProfilesForDealer
                      ),
                    });
                  }}
                  label={"Lenders this program is available to"}
                  style={{ height: 40 }}
                  multiple={true}
                  renderValue={(selected: any) => {
                    // console.log(`WIP: lenderProfiles: ${JSON.stringify(lenderProfiles)}`);

                    const lenderProfilesSelected = (lenderProfiles ? lenderProfiles : [])
                      .filter((lp: any) => selected.includes(lp.id))
                      .map((lp: any) => lp.lenderName);

                    return [...lenderProfilesSelected].join(", ");
                  }}
                  disabled={program.accountId !== account.id}
                >
                  {getLenderProfileLenderOptions()}
                </Select>
              </FormControl>
            )}

            {dealerVendorProfiles && _.get(dealerVendorProfiles, "length", 0) > 0 && _.isEmpty(targetLenderProfileId) && (
              <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                <InputLabel shrink={true} id="lender-profile-label">
                  Dealers this program is available to
                </InputLabel>
                <Select
                  labelId="lender-profile-label"
                  id="lender-profile"
                  defaultValue={[]}
                  value={selectedLenderProfilesForDealer}
                  onChange={(e: any) => {
                    console.log(
                      `WIP: selected: ${JSON.stringify([...Object.keys(e.target.value).map(k => e.target.value[k])])}`
                    );
                    if (e.target.value.includes("selectAll")) {
                      setSelectedLenderProfilesForDealer(
                        dealerVendorProfiles.map((vp: any) => vp.lenderProfiles[0]?.lenderProfileId)
                      );
                      return setProgram({
                        ...program,
                        lenderProfileIds: dealerVendorProfiles.map((vp: any) => vp.lenderProfiles[0]?.lenderProfileId),
                      });
                    }
                    setSelectedLenderProfilesForDealer([...Object.keys(e.target.value).map(k => e.target.value[k])]);
                    return setProgram({
                      ...program,
                      lenderProfileIds: [...Object.keys(e.target.value).map(k => e.target.value[k])].concat(
                        selectedLenderProfilesForLender
                      ),
                    });
                  }}
                  label={"Dealers this program is available to"}
                  style={{ height: 40 }}
                  multiple={true}
                  renderValue={(selected: any) => {
                    // console.log(`WIP: lenderProfiles: ${JSON.stringify(lenderProfiles)}`);

                    const dealerVendorProfilesSelected = (dealerVendorProfiles ? dealerVendorProfiles : [])
                      .filter((vp: any) => selected.includes(vp.lenderProfiles[0]?.lenderProfileId))
                      .map((vp: any) => vp.name.replace("VP - ", ""));

                    return [...dealerVendorProfilesSelected].join(", ");
                  }}
                  disabled={program.accountId !== account.id}
                >
                  {getLenderProfileDealerOptions()}
                </Select>
              </FormControl>
            )}
            <TextField
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
                style: { width: "100%" },
                required: true,
              }}
              label="Finance Program Name"
              variant="outlined"
              value={_.get(program, "nameInternal", "")}
              onChange={e => {
                return setProgram({ ...program, nameInternal: e.target.value });
              }}
              fullWidth
              margin="normal"
              disabled={isLenderUser ? false : program.accountId !== account.id}
            />

            <InputLabel shrink={true} style={{ marginTop: 10, fontSize: "22px" }}>
              Allow partners to set markup?
              <Checkbox
                checked={_.get(program, "financeProgramModificationSettings.markup", false)}
                onChange={e =>
                  setProgram({
                    ...program,
                    financeProgramModificationSettings: {
                      ...program.financeProgramModificationSettings,
                      markup: e.target.checked,
                    },
                  })
                }
              />
              <Tooltip
                title="When this is checked your partners will be able to set and adjust markup, otherwise they will have to use the exact settings you set below."
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputLabel>

            <FinanceProgramForm
              initFinanceProgram={
                !_.isEmpty(initFinanceProgram)
                  ? {
                      ...initFinanceProgram?.paymentOptionsConfiguration,
                      validDateEnd: initFinanceProgram?.validDateEnd,
                      validDateStart: initFinanceProgram?.validDateStart,
                    }
                  : null
              }
              onValuesChange={(values: any) =>
                setProgram({
                  ...program,
                  paymentOptionsConfiguration: {
                    ...values,
                    validDateStart: undefined,
                    validDateEnd: undefined,
                  },
                  validDateEnd: values.validDateEnd,
                  validDateStart: values.validDateStart,
                })
              }
              isProgramOwner={program.accountId === account.id}
            />
          </DialogContent>
          <DialogActions>
            {!createMode && !removingFP && (isLenderUser || program.accountId === account.id) && (
              <MUIButton color="default" style={{ color: "#f44336" }} onClick={() => setRemovingFP(true)}>
                Remove
              </MUIButton>
            )}
            {(isLenderUser || program.accountId === account.id) && (
              <MUIButton color="primary" onClick={() => handleSubmit()}>
                {createMode ? "Create" : "Update"}
              </MUIButton>
            )}
          </DialogActions>
        </TabPanel>
        <TabPanel value="2">
          <DialogContent style={{ overflowY: "hidden" }}>
            <CriteriaForm
              account={account}
              updatePrescreenCriteriaId={updatePrescreenCriteriaId}
              selectedPrescreenCriteriaId={prescreenCriteriaId}
              targetLenderProfileId={targetLenderProfileId}
              triggerSetSnackbarOpen={setSnackbarOpen}
            />
          </DialogContent>
        </TabPanel>
      </TabContext>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert severity="success">Successfully updated pricing configuration</Alert>
      </Snackbar>
      {/* <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar> */}
    </Dialog>
  );
};

export default ProgramsPreScreen;
