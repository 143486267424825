import gql from "graphql-tag";

const QUERY_USER_DOCS = gql`
  query($creditSubId: ID, $VOId: ID) {
    creditSubmissionDocuments(creditSubId: $creditSubId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      createdDateTime
      source
      docType
      docDescription
      createdBy
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
    lenderDecisionLetters(creditSubId: $creditSubId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
  }
`;

const GET_USER_PROFILE = gql`
  query {
    userProfile {
      fullName
      id
    }
  }
`;

const QUERY_VENDOR_PROFILE = gql`
  query($id: ID, $dynamicsId: ID, $userProfileId: ID, $VOId: ID) {
    vendorProfile(id: $id, dynamicsId: $dynamicsId, userProfileId: $userProfileId, VOId: $VOId) {
      id
      name
      dynamicsId
      showDocMgt
      showLenderDocsAccessMgmt
      showCreditApp
      showPsOptions
      showMarginTool
      showLenderSubmission
      industryGroupType
      role
      logo
    }
  }
`;

const DEACTIVATE_DOC = gql`
  mutation($id: ID!) {
    deactivateDocument(id: $id)
  }
`;

const UPDATE_DOC = gql`
  mutation($id: ID!, $input: UpdateDocumentInput) {
    updateDocument(id: $id, input: $input) {
      id
      source
    }
  }
`;

export { QUERY_USER_DOCS, GET_USER_PROFILE, QUERY_VENDOR_PROFILE, DEACTIVATE_DOC, UPDATE_DOC };
