import React from "react";
import { Modal } from "@material-ui/core";
import CADMv2 from "../../../pages/VendorOpportunity/CADMv2";
import { BaseModalProps } from "../../../global";

interface Props extends BaseModalProps {
  account: any;
  CADMData: any;
  setIsPDFDownloaded: any;
  isCADMv2ForPDFOnly: boolean;
}

export const CadMv2DocumentModal = ({
  isOpen,
  account,
  onClose,
  CADMData,
  isCADMv2ForPDFOnly,
  setIsPDFDownloaded,
}: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-describedby="Summary"
      aria-labelledby="Credit Application"
      style={{ display: isCADMv2ForPDFOnly ? "none" : "block" }}
    >
      <>
        {CADMData && (
          <CADMv2
            vo={CADMData.vo}
            account={account}
            isEditingDisabled={true}
            isForPDFOnly={isCADMv2ForPDFOnly}
            vendorProfile={CADMData.vendorProfile}
            equipmentData={CADMData.equipmentData}
            setIsPDFDownloaded={setIsPDFDownloaded}
            creditAppModalConfig={CADMData.CADMPortalConfiguration.jsonDefinition}
          />
        )}
      </>
    </Modal>
  );
};
