import { documentsByKey } from "../../../pages/Prequal/constants";
import { DocumentsTableFilters } from "../types";

const creditSubmissionDocumentsTypesFiltersOptions = [
  { label: "Show all", value: "all" },
  ...Object.values(documentsByKey).map(({ value, text }) => ({ value, label: text })),
];

const creditSubmissionDocumentsFiltersInitialValue: DocumentsTableFilters = { search: "", type: ["all"] };

export { creditSubmissionDocumentsTypesFiltersOptions, creditSubmissionDocumentsFiltersInitialValue };
