import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InsertionContainer } from "../PortalConfigurationWizard/CreditAppDetailModal/InsertionContainer";
import CreateEditProposalProductModal from "./CreateEditProposalProductModal";
import DraggableProductItem from "./DraggableProductItem";
import DraggableProductPackageItem from "./DraggableProductPackageItem";
import _ from "lodash";

const styles = {
  root: {},
  list: {
    width: "100%",
  },
  addNewListItem: {
    flexDirection: "column",
    "& .MuiListItemText-root": {
      marginTop: 10,
    },
  },
};

const useStyles = makeStyles(styles);

const ProposalMenuSidebar = ({
  productPackages,
  products,
  isProductPackageEditMode,
  setIsProductPackageEditMode,
  setProductPackageToEdit,
  refetchProducts,
  refetchProductPackages,
  refetchProposalMenu,
}) => {
  const classes = useStyles();

  const [expandedStates, setExpandedStates] = useState({ productPackages: true, products: false });

  const [proposalProductToEdit, setProposalProductToEdit] = useState();
  const [isCreateEditProposalProductModalOpen, setIsCreateEditProposalProductModalOpen] = useState(false);

  useEffect(() => {
    setExpandedStates({ ...expandedStates, products: isProductPackageEditMode });
  }, [isProductPackageEditMode]);

  const handleCreateProductPackageClick = () => {
    const emptyProductPackage = {
      name: "",
      products: [],
    };
    setProductPackageToEdit(emptyProductPackage);
    setIsProductPackageEditMode(true);
  };

  const handleEditProductPackageClick = productPackageToEdit => {
    const productPackageToEditCopy = _.cloneDeep(productPackageToEdit);
    setProductPackageToEdit(productPackageToEditCopy);
    setIsProductPackageEditMode(true);
  };

  const handleCreateProductClick = () => {
    const emptyProduct = {
      title: "",
      cost: null,
      retailCost: null,
      thirdPartyUrl: "",
    };
    setProposalProductToEdit(emptyProduct);
    setIsCreateEditProposalProductModalOpen(true);
  };

  const handleEditProductClick = productToEdit => {
    const productToEditCopy = _.cloneDeep(productToEdit);
    setProposalProductToEdit(productToEditCopy);
    setIsCreateEditProposalProductModalOpen(true);
  };

  return (
    <div className={classes.root}>
      {!isProductPackageEditMode && (
        <Accordion
          expanded={expandedStates.productPackages}
          onChange={() => setExpandedStates({ ...expandedStates, productPackages: !expandedStates.productPackages })}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="product-packages-content"
            id="product-packages-header"
          >
            <Typography variant="h5">Product Packages</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List component="nav" className={classes.list}>
              <ListItem button className={classes.addNewListItem} onClick={handleCreateProductPackageClick}>
                <InsertionContainer />
                <ListItemText primary="Add new Product package" />
              </ListItem>
              {productPackages?.map(productPackage => (
                <DraggableProductPackageItem
                  key={productPackage.proposalPackageId}
                  productPackage={productPackage}
                  handleEditProductPackageClick={handleEditProductPackageClick}
                  refetchProductPackages={refetchProductPackages}
                  refetchProposalMenu={refetchProposalMenu}
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        expanded={expandedStates.products}
        onChange={() => setExpandedStates({ ...expandedStates, products: !expandedStates.products })}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="products-content" id="products-header">
          <Typography variant="h5">Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav" className={classes.list}>
            <ListItem button className={classes.addNewListItem} onClick={handleCreateProductClick}>
              <InsertionContainer />
              <ListItemText primary="Add new Product" />
            </ListItem>
            {products?.map(product => (
              <DraggableProductItem
                key={product.proposalProductId}
                product={product}
                handleEditProductClick={handleEditProductClick}
                isProductPackageEditMode={isProductPackageEditMode}
                refetchProducts={refetchProducts}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <CreateEditProposalProductModal
        isOpen={isCreateEditProposalProductModalOpen}
        setIsOpen={setIsCreateEditProposalProductModalOpen}
        proposalProduct={proposalProductToEdit}
        setProposalProduct={setProposalProductToEdit}
        refetchProducts={refetchProducts}
      />
    </div>
  );
};

export default ProposalMenuSidebar;
