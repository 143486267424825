import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProposalMenuSidebar from "./ProposalMenuSidebar";
import ProposalProductPackageEditor from "./ProposalProductPackageEditor";
import ProposalMenu from "./ProposalMenu";
import { checkIfUserAbleToSeeProposalMenuConstructor } from "../../../src/services/proposalsService";
import _ from "lodash";

const QUERY_PROPOSAL_MENUS = gql`
  query($input: ProposalMenusInput) {
    proposalMenus(input: $input) {
      proposalMenuId
      name
      description
      createdDateTime
      modifiedDateTime
      menuOptions {
        proposalMenuOptionId
        name
        description
        titleColor
        titleBgColor
        ordinal
        packages {
          name
          accountIdCreatedBy
          proposalPackageId
          products {
            proposalProductId
            title
            cost
            retailCost
            thirdPartyUrl
          }
        }
      }
    }
  }
`;

const QUERY_PRODUCT_PACKAGES = gql`
  query {
    proposalProductPackages {
      name
      accountIdCreatedBy
      proposalPackageId
      createdDateTime
      modifiedDateTime
      products {
        proposalProductId
        title
        cost
        retailCost
        thirdPartyUrl
      }
    }
  }
`;

const QUERY_PRODUCTS = gql`
  query {
    proposalProducts {
      proposalProductId
      title
      cost
      retailCost
      thirdPartyUrl
      createdDateTime
      modifiedDateTime
    }
  }
`;

const styles = theme => ({
  root: {
    margin: "40px 15px 0 15px",
  },
  breadcrumbs: {
    marginBottom: 30,
  },
  breadcrumbsLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const useStyles = makeStyles(styles);

const ProposalMenuDetails = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();

  const userProfile = useSelector(state => state.userProfile);
  const vendorProfile = useSelector(state => state.vp);

  const proposalMenuId = match.params.proposalMenuId;

  const { data: proposalMenusData, loading, refetch: refetchProposalMenu } = useQuery(QUERY_PROPOSAL_MENUS, {
    variables: { input: { proposalMenuId } },
    fetchPolicy: "no-cache",
  });

  const { data: productPackagesData, loading: productPackagesDataLoading, refetch: refetchProductPackages } = useQuery(
    QUERY_PRODUCT_PACKAGES,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { data: productsData, loading: productsDataLoading, refetch: refetchProducts } = useQuery(QUERY_PRODUCTS, {
    fetchPolicy: "no-cache",
  });

  const [isUserAbleToSeeProposalMenuConstructor, setIsUserAbleToSeeProposalMenuConstructor] = useState(
    checkIfUserAbleToSeeProposalMenuConstructor(userProfile, vendorProfile)
  );
  const [proposalMenu, setProposalMenu] = useState();
  const [isProductPackageEditMode, setIsProductPackageEditMode] = useState(false);
  const [productPackageToEdit, setProductPackageToEdit] = useState();
  const [productPackages, setProductPackages] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    if (_.some([proposalMenusData, productPackagesData, productsData], _.isEmpty)) {
      return;
    }
    setProposalMenu(_.first(proposalMenusData.proposalMenus));
    setProductPackages(_.sortBy(productPackagesData.proposalProductPackages, "createdDateTime"));
    setProducts(_.sortBy(productsData.proposalProducts, "createdDateTime"));
  }, [proposalMenusData, productPackagesData, productsData]);

  return (
    <div className={classes.root}>
      {!loading && !isUserAbleToSeeProposalMenuConstructor && <h3>Access Denied</h3>}

      {!loading && isUserAbleToSeeProposalMenuConstructor && proposalMenu && (
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Typography
              onClick={() => {
                history.push("/proposal-menu-constructor");
              }}
              className={classes.breadcrumbsLink}
            >
              Proposal Menu Constructor
            </Typography>
            <Typography>Menu Details</Typography>
          </Breadcrumbs>
          <Grid container spacing={4}>
            <Grid item xs={9}>
              {!isProductPackageEditMode && (
                <ProposalMenu
                  proposalMenu={proposalMenu}
                  setProposalMenu={setProposalMenu}
                  refetchProposalMenu={refetchProposalMenu}
                />
              )}
              {isProductPackageEditMode && (
                <ProposalProductPackageEditor
                  productPackage={productPackageToEdit}
                  setProductPackageToEdit={setProductPackageToEdit}
                  setIsProductPackageEditMode={setIsProductPackageEditMode}
                  refetchProductPackages={refetchProductPackages}
                  refetchProposalMenu={refetchProposalMenu}
                />
              )}
            </Grid>

            <Grid item xs={3}>
              <ProposalMenuSidebar
                productPackages={productPackages}
                products={products}
                isProductPackageEditMode={isProductPackageEditMode}
                setIsProductPackageEditMode={setIsProductPackageEditMode}
                setProductPackageToEdit={setProductPackageToEdit}
                refetchProducts={refetchProducts}
                refetchProductPackages={refetchProductPackages}
                refetchProposalMenu={refetchProposalMenu}
              />
            </Grid>
          </Grid>
        </DndProvider>
      )}
    </div>
  );
};

export default ProposalMenuDetails;
