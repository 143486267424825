import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ChecklistTemplate from "../../components/ChecklistTemplate/ChecklistTemplate";
import _ from "lodash";

const UPDATE_VO = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const useStyles = makeStyles({});

export default function VOChecklistCard({ vo, refetchVO }) {
  const classes = useStyles();

  const [updateVO, { loading }] = useMutation(UPDATE_VO, {
    context: { authRequired: true },
    onCompleted() {
      setSaveVOSuccess(true);
    },
    onError() {
      setSaveVOError(true);
    },
  });

  const [checklist, setChecklist] = useState();
  const [saveVOSuccess, setSaveVOSuccess] = useState(false);
  const [saveVOError, setSaveVOError] = useState(false);

  const updateAndRefetchVO = async checklist => {
    await updateVO({
      variables: {
        VOId: vo.vendorOpportunityId,
        fieldsObj: {
          checklist,
        },
      },
    });
    //await refetchVO();
  };

  useEffect(() => {
    const checklistToSet = _.isEmpty(vo.checklist) ? [] : vo.checklist;
    setChecklist(checklistToSet);
  }, [vo.checklist]);

  const updateAndRefetchVODebounced = useRef(_.debounce(updateAndRefetchVO, 500));

  const onChecklistSave = async checklistToSave => {
    setChecklist(checklistToSave); // to reflect on UI first
    updateAndRefetchVODebounced.current(checklistToSave);
  };

  return (
    <>
      <ChecklistTemplate tileTitle="Checklist" checklist={checklist} onChecklistSave={onChecklistSave} />

      <Snackbar open={saveVOSuccess} autoHideDuration={3000} onClose={() => setSaveVOSuccess(state => !state)}>
        <Alert severity="success">
          <AlertTitle>Changes Saved!</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar open={saveVOError} autoHideDuration={3000} onClose={() => setSaveVOError(state => !state)}>
        <Alert severity="error">
          <AlertTitle>Error on saving</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
}
