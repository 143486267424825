import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloProviderHooks } from "@apollo/react-hooks";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import { RetryLink } from "apollo-link-retry";
import { AWS_CONFIG } from "./config";
import Amplify, { Analytics, Auth } from "aws-amplify";
import App from "./App";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/scss/custom.scss";
import { compose, createStore } from "redux";
import { rootReducer } from "./redux/rootReducer";
import { Provider } from "react-redux";
import axios from "axios";
import { getHeadersFromAuth } from "./utils";
import localforage from "localforage";
import { persistCache } from "apollo-cache-persist";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
const config = require("./config");

console.log("%c ".concat("", " "), "color: grey; font-size:12px");
// Sentry.init({
//   environment: config.environmentName,
//   dsn: "https://93d7a96799404a4099c568d655e5dc75@o1281243.ingest.sentry.io/6487092",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const store = createStore(
  rootReducer,
  compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
);

Analytics.disable();

Amplify.configure(AWS_CONFIG);

// Add a request interceptor
axios.interceptors.request.use(
  async request => {
    try {
      const authHeaders = await getHeadersFromAuth(Auth);
      request.headers = { ...request.headers, ...authHeaders };
    } catch (err) {}

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

const authLink = setContext(async (_, { headers, ...context }) => {
  try {
    const authHeaders = await getHeadersFromAuth(Auth);

    const settings = {
      headers: {
        ...headers,
        accept: "application/json",
        ...authHeaders,
      },
      ...context,
    };

    return settings;
  } catch (error) {}

  if (context.authRequired === false) {
    return;
  }

  //If no user token could be capture, throw error for ErrorLink to handle
  throw new Error("No current user");
});

const httpLink = createUploadLink({
  uri: config.DCR_GRAPHQL_ENDPOINT,
});

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(function({ message, locations, path, extensions }) {
      if (window.NREUM) {
        window.NREUM.noticeError(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }

      if (!extensions) {
        return null;
      }

      if (extensions.code === "UNAUTHENTICATED") {
        console.log("The session was ended.");
      }

      return null;
    });

  if (networkError) {
    if (!!networkError.message && networkError.message != "No current user") {
      console.log(networkError.message);
    }
  }
});

const cache = new InMemoryCache({
  AccountRep: {
    keyFields: ["email"],
  },
  Location: { keyFields: ["locationId"] },
});
persistCache({
  cache,
  storage: localforage,
});

const client = new ApolloClient({
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
  },
  connectToDevTools: true,
  link: errorLink.concat(ApolloLink.from([authLink, retryLink, httpLink])),
});

const ApolloApp = () => {
  return (
    <Provider store={store}>
      <Helmet>
        <script
          type="text/javascript"
          defer="true"
          src="//cdn.mouseflow.com/projects/c13bcfe7-bdc3-4737-b9a3-992157baf90f.js"
        />
      </Helmet>
      <ApolloProvider client={client}>
        <ApolloProviderHooks client={client}>
          <App />
        </ApolloProviderHooks>
      </ApolloProvider>
    </Provider>
  );
};

ReactDOM.render(<ApolloApp />, document.getElementById("root"));
