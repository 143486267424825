import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { formatCurrency } from "../utils";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import _ from "lodash";

const styles = {
  card: {
    backgroundColor: "rgba(20, 115, 230, 0.3)",
    borderRadius: "8px",
    display: "inline-block",
    boxSizing: "border-box",
    margin: "6px 10px",
    padding: "12px",

    "& p": {
      margin: 0,
    },
  },

  root: {
    border: "1px solid #e6e6e6",
    borderRadius: "8px",
    minHeight: 350,
    width: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  optionHeader: {
    padding: "16px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    position: "relative",
    minHeight: 70,
  },
  productPackagesContainer: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  productPackageListContainer: {
    listStyle: "none",
    padding: "0 12px",
    "& > li:not(:last-child)": {
      marginBottom: 15,
    },
  },
  productPackageListItemText: {
    marginLeft: 10,
  },
  productListContainer: {
    paddingLeft: 51,
  },
  menuOptionConfigurableItemActions: {
    position: "absolute",
    top: 5,
    right: 7,
  },
  propductPackageConfigurableItemActions: {
    marginLeft: "auto",
  },
  financeProfitText: {
    color: "green",
    fontWeight: "bold",
  },
};

const useStyles = makeStyles(styles);

const ProposalOptionCard = ({
  term,
  paymentAmountPerPeriod,
  menuName,
  menuOptionName,
  titleColor,
  titleBgColor,
  packages,
}) => {
  const classes = useStyles();
  if (menuOptionName) {
    return (
      <span className={classes.root}>
        <div className={classes.optionHeader} style={{ backgroundColor: titleBgColor }}>
          <Typography variant="h5" align="center" style={{ color: titleColor, fontWeight: 400 }}>
            {menuOptionName}
          </Typography>
        </div>

        <div className={classes.productPackagesContainer}>
          <ul className={classes.productPackageListContainer}>
            {_.some(packages) ? (
              packages.map(pp => (
                <li key={pp.proposalPackageId} className={classes.productPackageListItem}>
                  <Grid container xs={12} style={{ flexWrap: "nowrap" }}>
                    <CheckCircleIcon style={{ color: titleBgColor }} />
                    <Typography className={classes.productPackageListItemText}> {pp.name} </Typography>
                  </Grid>

                  {_.some(pp.products) && (
                    <Grid container xs={12}>
                      <ul className={classes.productListContainer}>
                        {_.map(pp.products, p => (
                          <li key={p.proposalProductId}>
                            <Typography variant="body2">
                              {p.title} {formatCurrency(p.retailCost)}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  )}
                </li>
              ))
            ) : (
              <li>
                <Typography align="center">No Product Packages</Typography>
              </li>
            )}
          </ul>
        </div>
        <Typography align="center" paragraph={true}>
          <p style={{ display: "flex", flexDirection: "column", margin: 0 }}>
            <span style={{ fontWeight: "bold", color: "rgba(0,0,0,0.85)", fontSize: 20 }}>
              {formatCurrency(paymentAmountPerPeriod)}
            </span>
            <span className={styles.greyText} style={{ fontSize: 12 }}>
              per month
            </span>
          </p>
        </Typography>
      </span>
    );
  } else {
    return (
      <div className={classes.card}>
        <p style={{ fontSize: "24px", fontWeight: 400 }}>{formatCurrency(paymentAmountPerPeriod)}/mo.</p>
        <p className={styles.greyText}>for {term} months</p>
      </div>
    );
  }
};

export default ProposalOptionCard;
