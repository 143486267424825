import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Redirect, Route, Switch } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { appRoutes, userRoutes } from "routes.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import logo from "assets/img/trnsact-logo-dark.png";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { SETUP_WIZARD, SET_CHAT_UNREAD_MESSAGES } from "../redux/types";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { Constructor } from "../pages/DynamicOCA/Constructor";
import { ErrorHandler } from "ErrorHandler";
import { MobileContext } from "contexts/MobileContext";
import { portalConfigurationTypes } from "pages/Prequal/constants";
import { getDefaultPortalConfigurationByType } from "../services/pcwService";
import _ from "lodash";

//named query
const Q_UNREAD_MESSAGES = gql`
  query UnreadMessagesPolling($userProfileId: ID) {
    unreadMessages(userProfileId: $userProfileId) {
      count
      vendorOpportunityId
      relatedTo
      topic
      feedId
    }
  }
`;

const QUERY_PORTAL_CONFIGURATION = gql`
  query($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

let ps;

const useStyles = makeStyles(styles);

function constructRoutePath(r) {
  return r.path;
}

export default function Dashboard(props) {
  const { accountData, adminRoles = { super: null, singleAccountOnly: null }, dashboards } = props;

  const dashboardPortalConfigurationTypes = [
    portalConfigurationTypes.userManagement,
    portalConfigurationTypes.portalSetup,
    portalConfigurationTypes.voManagement,
  ];

  const [getPortalConfigurations, { data: portalConfigurationData, loading, error }] = useLazyQuery(
    QUERY_PORTAL_CONFIGURATION,
    {
      context: { authRequired: true },
      fetchPolicy: "no-cache",
    }
  );

  // states and functions
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [mobActive, setMobActive] = React.useState(false);
  const [promptMobilePhone, setPromptMobilePhone] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [portalConfigurations, setPortalConfigurations] = useState();
  const mobileContextVal = { isMobile, setIsMobile };
  const isLoadConstructor = window.location.pathname === "/constructor";
  const adminRolesToViewConstructor = [adminRoles.super, adminRoles.singleAccountOnly];
  const image = "";
  const color = "blue";
  const bgColor = "black";
  const classes = useStyles();
  const mainPanelClasses = `${classes.mainPanel} ${cx({
    [classes.mainPanelSidebarMini]: miniActive,
  })}`;
  const mainPanel = React.createRef();

  React.useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    if (window.innerWidth <= 600) {
      setIsMobile(true);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  });

  React.useEffect(() => {
    // const script = document.createElement("script");
    // const root = document.getElementById("root");
    // function jiraHelpdesk() {
    //   const DOMContentLoaded_event = document.createEvent("Event");
    //   DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
    //   window.document.dispatchEvent(DOMContentLoaded_event);
    // }
    // script.setAttribute("data-jsd-embedded", "");
    // script.setAttribute("data-key", "dcd8e115-3828-48d9-8e89-da2ab58614c7");
    // script.setAttribute("data-base-url", "https://jsd-widget.atlassian.com");
    // script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
    // script.onload = jiraHelpdesk;
    // Auth.currentAuthenticatedUser().then(user => {
    //   if (user) {
    //     root.before(script);
    //   }
    // });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const body = document.getElementById("body");
      const jiraHelp = document.querySelector("[name='JSD widget']");

      if (body && jiraHelp) {
        if (isLoadConstructor) {
          body.style.backgroundColor = "#fff";
          jiraHelp.style.display = "none";
        } else {
          body.style.backgroundColor = "#eee";
          jiraHelp.style.display = "block";
        }
      }
    }, 500);
  }, [isLoadConstructor]);

  const vendorProfile = accountData && accountData.vendorProfile;
  //Interval 10 seconds for polling messages, only for those who have Chat enabled
  const [getUnreadMessagesData, { data: unreadMessagesData }] = useLazyQuery(Q_UNREAD_MESSAGES, {
    context: { authRequired: true },
    fetchPolicy: "cache-first",
  });
  React.useEffect(() => {
    if (accountData) {
      const authorizedToAccessConstructor = [
        "e70a587e-0f22-11eb-b15e-572bc2a6ae0c",
        "24779b7a-1804-eb11-8125-065d4ad39109",
        "cf19c55f-5336-ea11-8114-005056b05a0f",
      ];

      const routeIndex = userRoutes.findIndex(({ path }) => path === "/constructor");
      if (_.get(accountData, "vendorProfile.ocaForm", "") === "Dynamic v4") {
        if (authorizedToAccessConstructor.includes(accountData.userProfile.dynamicsContactId)) {
          userRoutes[routeIndex] = { ...userRoutes[routeIndex], showInSidebarNav: true };
        }
      }

      if (accountData.userProfile.vendorContactRole !== "sales_rep") {
        const wizard = userRoutes.find(route => route.name === "Setup Wizard");

        if (wizard) {
          wizard.showInSidebarNav = true;
          wizard.action = () => {
            dispatch({
              type: SETUP_WIZARD,
              payload: { opened: true },
            });
          };
        }
      }
    }

    try {
      getUnreadMessagesData({
        variables: {
          userProfileId: accountData.userProfile.id,
        },
      });
    } catch (error) {
      console.log(error);
    }

    try {
      if (unreadMessagesData) {
        dispatch({
          type: SET_CHAT_UNREAD_MESSAGES,
          payload: { unread: unreadMessagesData.unreadMessages },
        });
      }
    } catch (error) {
      console.error(error);
    }

    if (accountData.userProfile) {
      //Once accountData arrive, prompt user for its mobile phone.
      if (accountData.userProfile.mobilePhoneNumber == null) {
        setPromptMobilePhone(true);
      } else {
        setPromptMobilePhone(false);
      }
      // setIsAvailableToViewConstructor(_.includes(adminRolesToViewConstructor, accountData.userProfile.adminRole));
    }
  }, [accountData, unreadMessagesData, vendorProfile, getUnreadMessagesData]);

  useEffect(() => {
    const vendorProfileId = _.get(accountData, "vendorProfile.id");
    if (!vendorProfileId) {
      return;
    }

    getPortalConfigurations({
      variables: {
        vendorProfileId,
        types: dashboardPortalConfigurationTypes,
      },
    });
  }, [accountData]);

  useEffect(() => {
    if (!portalConfigurationData) {
      return;
    }

    const pcwConfigurationsToSet = _.map(dashboardPortalConfigurationTypes, portalConfigurationType => {
      const savedConfiguration = _.find(portalConfigurationData.portalConfigurations, {
        type: portalConfigurationType,
      });
      const configurationToSet = _.some(savedConfiguration)
        ? savedConfiguration
        : getDefaultPortalConfigurationByType(portalConfigurationType, _.get(accountData, "vendorProfile.id", ""));
      delete configurationToSet.__typename;
      return configurationToSet;
    });

    setPortalConfigurations(pcwConfigurationsToSet);
  }, [portalConfigurationData]);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const getRoute = () => window.location.pathname !== "/admin/full-screen-maps";

  const getActiveRoute = routes => {
    let selectedRoute = { name: "" };
    let activeRoute = selectedRoute.name;
    let exactSelected = false;
    const uri = window.location.pathname + window.location.search;
    routes.forEach(route => {
      if (route.collapse) {
        let collapseActiveRoute = getActiveRoute(route.views);
        if (collapseActiveRoute !== activeRoute) {
          return (selectedRoute = collapseActiveRoute);
        }
      } else {
        let constructedRoute = constructRoutePath(route);
        if (route.exact) {
          if (uri === constructedRoute) {
            exactSelected = true;
            selectedRoute = route;
          }
        } else {
          if (!exactSelected) {
            if (
              uri.indexOf(constructedRoute) > -1 ||
              uri.indexOf(constructedRoute.substr(0, constructedRoute.indexOf(":"))) > -1
            ) {
              selectedRoute = route;
            }
          }
        }
      }
    });
    return selectedRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/dashboard") {
        let path = constructRoutePath(prop);
        return prop.exact ? (
          <Route path={path} component={prop.component} key={key} exact />
        ) : (
          <Route path={path} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const sidebarMobActive = () => {
    setMobActive(!mobActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    } else if (window.innerWidth > 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <MobileContext.Provider value={mobileContextVal}>
      <div
        className={classes.wrapper}
        style={isMobile ? { paddingBottom: "env(safe-area-inset-bottom)", height: "100svh" } : {}}
      >
        {/* <AlertChats /> */}

        {isLoadConstructor ? (
          <Constructor accountData={accountData} />
        ) : (
          <>
            <Sidebar
              appRoutes={appRoutes}
              userRoutes={userRoutes}
              logoText={"Trnsact Portal"}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              bgColor={bgColor}
              miniActive={miniActive}
              mobActive={mobActive}
              sidebarMobActive={sidebarMobActive.bind(this)}
              accountData={accountData}
              dashboards={dashboards}
              portalConfigurations={portalConfigurations}
              {...props}
            />
            <ErrorHandler>
              <div className={mainPanelClasses} ref={mainPanel} style={isMobile ? { overflow: "hidden" } : {}}>
                <AdminNavbar
                  sidebarMinimize={sidebarMinimize.bind(this)}
                  sidebarMobActive={sidebarMobActive.bind(this)}
                  miniActive={miniActive}
                  mobActive={mobActive}
                  brandText={getActiveRoute([...appRoutes, ...userRoutes]).name}
                  routeType={
                    getActiveRoute([...appRoutes, ...userRoutes])?.type
                      ? getActiveRoute([...appRoutes, ...userRoutes])?.type
                      : null
                  }
                  handleDrawerToggle={handleDrawerToggle}
                  fastTrackOption={getActiveRoute([...appRoutes, ...userRoutes]).fastTrackOption}
                  isMobile={isMobile}
                  portalConfigurations={portalConfigurations}
                  {...props}
                />
                {getRoute() ? (
                  <div className={classes.content} style={isMobile ? { padding: 0 } : {}}>
                    <div
                      className={classes.container}
                      style={isMobile && window ? { padding: 0, paddingTop: 32, height: "calc(100svh-40px)" } : {}}
                    >
                      <Switch>
                        <Redirect exact from="/" to="/vo" />
                        <Redirect exact from="/welcome" to="/vo" />{" "}
                        {/* if authorized user clicks welcome link from email */}
                        {getRoutes([...appRoutes, ...userRoutes])}
                      </Switch>
                    </div>
                  </div>
                ) : (
                  <div className={classes.map}>
                    <Switch>
                      <Redirect exact from="/" to="/vo" />
                      {getRoutes([...appRoutes, ...userRoutes])}
                    </Switch>
                  </div>
                )}
                {getRoute() ? <Footer fluid /> : null}
              </div>
            </ErrorHandler>
          </>
        )}
      </div>
    </MobileContext.Provider>
  );
}
