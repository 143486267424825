import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-pro-react/modalStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CreditAppModifierConfirmModal({
  open,
  close,
  handleSubmit,
  creditAppUpdateIsSubmitting,
  additionalMessage,
}) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={open}
        transition={Transition}
        keepMounted
        onClose={null}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          {!creditAppUpdateIsSubmitting && (
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={close}
            >
              <Close className={classes.modalClose} />
            </Button>
          )}
          <h4 className={classes.modalTitle}>
            <b>CONFIRM CHANGE</b>
          </h4>
        </DialogTitle>
        <DialogContent id="modal-slide-description" className={classes.modalBody}>
          <h5>
            <b>Did you want to make these changes?</b>
          </h5>
          <p>Note: Changes to credit application are permanent and recorded into server log!</p>
          {additionalMessage && <p>{additionalMessage}</p>}
        </DialogContent>
        <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
          {!creditAppUpdateIsSubmitting && (
            <div>
              <Button onClick={close}>Cancel</Button>
              <Button
                onClick={async () => {
                  await handleSubmit();
                  await close();
                  return true;
                }}
                color="success"
              >
                Yes
              </Button>
            </div>
          )}
          {creditAppUpdateIsSubmitting && <CircularProgress size={20} />}
        </DialogActions>
      </Dialog>
    </>
  );
}
