import React from "react";
import { FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, OutlinedInput } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { SelectOption } from "../../global";

interface MultiSelectProps extends Omit<SelectProps, "onChange" | "value"> {
  label: string;
  value: string[];
  options: SelectOption[];
  onChange: (selected: string[]) => void;
}

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
});

export const MultiSelect: React.FC<MultiSelectProps> = ({ options, value, label, onChange, ...props }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = event.target.value as string[];
    onChange(selectedValues);
  };

  const renderSelectedValues = (selected: string[]) => {
    const selectedLabels = options.filter(option => selected.includes(option.value)).map(option => option.label);
    return selectedLabels.join(", ");
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={selected => renderSelectedValues(selected as string[])}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
