import React, { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Query } from "react-apollo";
import { useHistory, useParams } from "react-router-dom";
import { DCR_API_BASE_SERVER_URL } from "config";
import MessageModal from "components/MessageModal";
import Tile from "components/Tile/Tile";
import _ from "lodash";
import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { io } from "socket.io-client";
// @ts-ignore
import mixpanel from "../../mixpanel";
import {
  GET_CREDIT_SUBMISSION,
  Q_CREDIT_APP,
  Q_EQUIPMENT,
  QUERY_ACCOUNT_BY_VP_ID,
  QUERY_PORTAL_CONFIGURATION,
  C_GET_CONVERSATIONS_BY_VO,
  C_CREATE_CONVERSATION,
} from "../../components/SubmissionDetailsTabs_V2/queries/queries";
import {
  CHAT_V2_CONVERSATIONS,
  CHAT_V2_OPEN,
  CHAT_V2_RESET,
  SET_CHAT_TARGET_RECIPIENT,
  SET_VO,
} from "../../redux/types";
import { CHAT_V2_SET_CREDIT_SUBMISSION, CHAT_V2_SET_TARGET_CREDIT_SUBMISSION_ID } from "../../copiedLP/src/redux/types";
import { Q_VO } from "../../queries/common";
import { portalConfigurationTypes } from "../Prequal/constants";
import { checkIfIndividualCreditApp } from "../../utils";
import {
  assignFieldsOCAConfigFromActiveTemplate,
  isItemHasRestrictionForRole,
} from "../../services/creditAppModalService";
import { getDefaultPortalConfigurationByType, rolesSettingsKeys } from "../../services/pcwService";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageSkeleton } from "./PageSkeleton";
import TransactionSummary from "../../copiedLP/src/pages/CreditSubmissions/TransactionSummaryCard";
import ApplicationSummary from "../../copiedLP/src/pages/CreditSubmissions/ApplicationSummary";
import CreditSubmissionsCard from "../../copiedLP/src/pages/CreditSubmissions/CreditSubmissionsCard";
import { SpecificTasks_V2 } from "../../components/SpecificTasks_V2";
import ManageSyndications from "../../copiedLP/src/pages/CreditSubmissions/ManageSyndications";
import LenderWaterfallResults from "../VendorOpportunity/LenderWaterfallResults";
import { invoiceDetailAPIKey } from "../../components/SubmissionDetailsTabs_V2/configuration";
import { parseTableData } from "../../components/SubmissionDetailsTabs_V2/helpers";
import Paper from "@material-ui/core/Paper";
import { CardBackground } from "../../components/CardBackground/CardBackground";
import HealthCheckCard from "../VendorOpportunity/HealthCheckCard";
import CSProposalList from "../../copiedLP/src/pages/CreditSubmissions/CSProposalList";
import FDRList from "../../copiedLP/src/pages/CreditSubmissions/FDRCard";
import ActivityList from "../../copiedLP/src/pages/CreditSubmissions/ActivityCard";
import TabPanel from "../../components/TabPanel";
import ScoreCard from "../../copiedLP/src/components/ScoreCard";
import { ConversationsHistory } from "../../copiedLP/src/pages/CreditSubmissions/ConversationsHistory";
import { InvoiceEquipmentDetailsCard } from "copiedLP/src/pages/CreditSubmissions/InvoiceEquipmentDetailCard";
import { CreditSubmission } from "@trnsact/trnsact-shared-types";
import { CreditSubmissionTabs } from "./types";
import { CreditSubmissionResponse, CreditSubmissionVariables } from "../../components/SubmissionDetailsTabs_V2/types";
import { Documents_V2 } from "../../components/Documents_V2";
// ?????
import { DocumentContext } from "../../copiedLP/src/contexts/DocumentContext";

export const CreditSubmissionDetailsPageV2 = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const [lastDocument, setLastDocument] = useState<any>();
  const dispatch = useDispatch();

  const relatedUsers = useSelector((state: any) => state.relatedUsers);
  const userProfile = useSelector((state: any) => state.userProfile);
  const account = useSelector((state: any) => state.account);
  const chat = useSelector((state: any) => state.chat);
  const refetchCS = useSelector((state: any) => state.cs.refetch);
  const vp = useSelector((state: any) => state.vp);

  const classes = useStyles();
  const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
  const [submissionMessage] = useState<any>({});
  const [openCreditAppModal, setOpenCreditAppModal] = useState<boolean>(false);
  const [handleSubmitToLenderByProfileId, setHandleSubmitToLenderByProfileId] = useState<boolean>(false);

  const [appSummary, setAppSummary] = useState<any>();
  const [creating] = useState<boolean>(false);
  const [showScoreCard, setShowScoreCard] = useState<boolean>(false);

  const [vendorProfile, setVendorProfile] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [CADMData, setCADMData] = useState<any>(null);
  const [portalConfigurations, setPortalConfigurations] = useState<any>(null);
  const [CADMPortalConfiguration, setCADMPortalConfiguration] = useState<any>(null);
  const [isCreditReportsHiddenForUserRole, setIsCreditReportsHiddenForUserRole] = useState<boolean>(true);

  // This docs use only for 'handleOpenCreditReportDocument' handler
  const [documents, setDocuments] = useState<any>([]);

  const [showHealthCheck, setShowHealthCheck] = useState<boolean>(false);
  const [isHealthCheckHiddenForUserRole, setIsHealthCheckHiddenForUserRole] = useState<boolean>(true);

  const [triggerRefetchDocs, setTriggerRefetchDocs] = useState<any>("");

  const ioRef = useRef<any>(null);

  const vo = useSelector((state: any) => state.vo);
  const reduxConversations = useSelector((state: any) => state.chatV2.conversations);

  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };
  const handleClose = () => {
    setOpenCreditAppModal(false);
  };

  const [getCreditSubmission, { data: creditSubmissionData, refetch }] = useLazyQuery<
    CreditSubmissionResponse,
    CreditSubmissionVariables
  >(GET_CREDIT_SUBMISSION, {
    fetchPolicy: "no-cache",
    variables: { csId: id },
    context: { authRequired: true },
  });

  const [
    getConversationsByVOId,
    { data: conversations, refetch: refetchConversations, loading: conversationsLoading },
  ] = useLazyQuery(C_GET_CONVERSATIONS_BY_VO);

  const [createConversation] = useMutation(C_CREATE_CONVERSATION, {
    context: { authRequired: true },
  });

  const [getPortalConfigurations, { data: portalConfigurationData }] = useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
    fetchPolicy: "no-cache",
  });

  const [getCADMPortalConfigurations, { data: CADMPortalConfigurationData }] = useLazyQuery(
    QUERY_PORTAL_CONFIGURATION,
    {
      context: { authRequired: true },
      fetchPolicy: "no-cache",
    }
  );

  const [getOcaTemplateAccount, { data: ocaTemplateAccountData }] = useLazyQuery(QUERY_ACCOUNT_BY_VP_ID, {
    context: { authRequired: true },
  });

  const [getCreditAppData, { data: creditAppData }] = useLazyQuery(Q_CREDIT_APP, { fetchPolicy: "no-cache" });

  const [getEquipmentData, { data: equipmentData }] = useLazyQuery(Q_EQUIPMENT);

  const [getVoData, { data: voData }] = useLazyQuery(Q_VO, { fetchPolicy: "no-cache" });

  const handleOpenCreditReportDocument = (_: any, cbr: any) => {
    const reportDoc = documents.find((d: any) => d.key === cbr.humanReadableReportDocumentId);
    if (reportDoc) {
      window.open(reportDoc.link, "_newtab");
    }
  };

  const handleCreateConversation = useCallback(() => {
    console.log("CONVERSATIONS ADD CONVERSATION CLICKED EVENT FIRED");
    // @ts-ignore
    mixpanel.track("CONVERSATIONS: ADD CONVERSATION CLICKED");

    dispatch({ type: CHAT_V2_RESET });
    dispatch({ type: CHAT_V2_OPEN, payload: true });
  }, [creditSubmissionData, vo, createConversation, userProfile, dispatch, conversations]);

  useEffect(() => {
    if (vo && vo.vendorOpportunityId) {
      getConversationsByVOId({
        variables: {
          vendorOpportunityId: vo.vendorOpportunityId,
        },
      });
    }
  }, [vo, getConversationsByVOId]);

  useEffect(() => {
    if (conversations && conversations.getConversationsForVendorOpportunity) {
      dispatch({
        type: CHAT_V2_CONVERSATIONS,
        payload: conversations.getConversationsForVendorOpportunity,
      });
    }
  }, [conversations, dispatch]);

  useEffect(() => {
    if (!userProfile.id) {
      return;
    }

    ioRef.current = io(DCR_API_BASE_SERVER_URL, {
      path: "/notifications",
      transports: ["websocket"],
      secure: true,
      withCredentials: true,
      auth: {
        userId: userProfile.id,
      },
    });

    try {
      ioRef.current.connect();
      ioRef.current.on("onMessageAdded", refetchConversations);
    } catch (error) {}

    ioRef.current.on("onParticipantAdded", (data: any) => {
      try {
        if (data) refetchConversations();
      } catch (error) {
        console.log(error);
      }
    });

    ioRef.current.on("onParticipantRemoved", refetchConversations);

    return () => {
      ioRef.current && ioRef.current.disconnect();
    };
  }, [userProfile, refetchConversations]);

  useEffect(() => {
    if (creditSubmissionData) refetch();
  }, [refetchCS]);

  useEffect(() => {
    dispatch({ type: CHAT_V2_RESET });
    dispatch({ type: CHAT_V2_CONVERSATIONS, payload: [] });
  }, [dispatch]);

  useEffect(() => {
    if (creditSubmissionData && creditSubmissionData.creditSubmission) {
      dispatch({
        type: CHAT_V2_SET_CREDIT_SUBMISSION,
        payload: creditSubmissionData.creditSubmission,
      });
    }
  }, [dispatch, creditSubmissionData]);

  useEffect(() => {
    if (
      conversationsLoading ||
      !vo ||
      !vo.vendorOpportunityId ||
      !creditSubmissionData ||
      !creditSubmissionData.creditSubmission ||
      !userProfile ||
      !conversations
    ) {
      return;
    }

    if (conversations.getConversationsForVendorOpportunity?.length) return;

    const input = {
      vendorOpportunityId: vo.vendorOpportunityId,
      topic: "RE: " + creditSubmissionData.creditSubmission.applicant,
      initialParticipant: {
        participantUserProfileId: userProfile.id,
        identity: `${userProfile.fullName}`,
        phoneNumber: null,
        email: userProfile.email,
        notifyByEmail: Boolean(userProfile.email),
        notifyBySMS: false,
        notifyByWhatsapp: false,
      },
    };

    createConversation({ variables: { input } });
  }, [conversationsLoading, vo, creditSubmissionData, userProfile, conversations, createConversation]);

  useEffect(() => {
    if (creditSubmissionData) {
      const cs = creditSubmissionData.creditSubmission;

      const voId = _.get(cs, "voId", "");
      const creditSubId = _.get(cs, "creditSubId", "");
      const contactUserProfileId = _.get(cs, "contactUserProfileId", null);

      const targetRecipient = _.find(relatedUsers, {
        id: contactUserProfileId,
      });

      if (targetRecipient) {
        dispatch({
          type: SET_CHAT_TARGET_RECIPIENT,
          payload: {
            targetRecipient: [
              {
                id: targetRecipient.id,
                accountId: targetRecipient.account.id,
                mughshot: targetRecipient.mugshot,
                vendorContactRole: targetRecipient.vendorContactRole,
                email: targetRecipient.email,
                firstName: targetRecipient.firstName,
                lastName: targetRecipient.lastName,
                fullName: targetRecipient.fullName,
                mobilePhoneNumber: targetRecipient.mobilePhoneNumber,
                company: targetRecipient.account.name,
              },
            ],
          },
        });
      }

      dispatch({
        type: CHAT_V2_SET_TARGET_CREDIT_SUBMISSION_ID,
        payload: creditSubId,
      });

      getVoData({ variables: { voId } });
      getCreditAppData({ variables: { VOId: voId } });
      getEquipmentData({ variables: { VOId: voId } });
    } else {
      getCreditSubmission();
    }
  }, [creditSubmissionData, relatedUsers, getCreditSubmission, dispatch]);

  const refetchDocs = () => {
    setTriggerRefetchDocs(new Date().getTime());
  };

  useEffect(() => {
    const whiteListIdAccount = [
      "f366505b-3b92-eb11-812f-066ef92796c9",
      "81f5c112-4e7f-ea11-8119-005056b05a0f",
      "c62ab074-9e7e-ea11-8119-005056b05a0f",
      "790be67b-09c9-ed11-816c-066ef92796c9",
    ];
    if (account && account.dynamicsAccountId) {
      setShowScoreCard(whiteListIdAccount.includes(account.dynamicsAccountId));
    }
  }, [account]);

  useEffect(() => {
    if (_.every([equipmentData, vo?.vendorOpportunityId, CADMPortalConfiguration, vendorProfile])) {
      setCADMData({
        equipmentData,
        vo,
        CADMPortalConfiguration,
        vendorProfile: _.get(vendorProfile, "vendorProfile"),
      });
    }
  }, [equipmentData, vo, CADMPortalConfiguration, vendorProfile]);

  useEffect(() => {
    if (_.get(vendorProfile, "vendorProfile.id")) {
      getPortalConfigurations({
        variables: {
          vendorProfileId: _.get(vendorProfile, "vendorProfile.id"),
          types: [portalConfigurationTypes.documents],
        },
      });
    }

    if (_.get(vendorProfile, "vendorProfile.ofacRedFlagComplianceAccess", false)) {
      setShowHealthCheck(true);
    }
  }, [vendorProfile]);

  useEffect(() => {
    if (!creditAppData) return;

    if (_.get(creditAppData, "creditApplication.ocaTemplate.vendorProfileId")) {
      getOcaTemplateAccount({
        variables: {
          vendorProfileId: creditAppData.creditApplication.ocaTemplate?.vendorProfileId,
        },
      });
    }
    setLocation(_.get(creditAppData, "creditApplication.location"));
  }, [creditAppData]);

  useEffect(() => {
    // We need to take a vp from oca related account since credit app could be submitted from both the lender's and dealer's templates
    const ocaTemplateAccount = _.get(ocaTemplateAccountData, "accountByVPId");
    if (_.isEmpty(ocaTemplateAccount)) return;

    const vpId = ocaTemplateAccount.vendorProfileId || account.vendorProfileId;
    getCADMPortalConfigurations({
      variables: {
        vendorProfileId: vpId,
        types: [portalConfigurationTypes.CADMIndividual, portalConfigurationTypes.CADMCommercial],
      },
    });
  }, [ocaTemplateAccountData]);

  useEffect(() => {
    if (!CADMPortalConfigurationData || !creditAppData) return;

    const applicationType = _.get(creditAppData, "creditApplication.applicationType");
    const portalConfigurations = _.get(CADMPortalConfigurationData, "portalConfigurations") || [];
    const ocaTemplateJsonDefinition = _.get(creditAppData, "creditApplication.ocaTemplate.jsonDefinition");
    const isIndividualCreditApp = checkIfIndividualCreditApp(applicationType);

    const CADMConfigurationTypeToUse = isIndividualCreditApp
      ? portalConfigurationTypes.CADMIndividual
      : portalConfigurationTypes.CADMCommercial;

    const CADMConfigurationToUse = _.find(portalConfigurations, { type: CADMConfigurationTypeToUse });

    if (_.some(CADMConfigurationToUse)) {
      assignFieldsOCAConfigFromActiveTemplate(CADMConfigurationToUse.jsonDefinition, ocaTemplateJsonDefinition);
    }

    setCADMPortalConfiguration(CADMConfigurationToUse);
  }, [CADMPortalConfigurationData, creditAppData]);

  useEffect(() => {
    if (!portalConfigurationData) return;

    const portalConfigurations = _.get(portalConfigurationData, "portalConfigurations") || [];

    const portalConfigurationsToSetByDefault = [portalConfigurationTypes.documents];
    _.forEach(portalConfigurationsToSetByDefault, portalConfigurationType => {
      if (!_.find(portalConfigurations, { type: portalConfigurationType })) {
        portalConfigurations.push(
          getDefaultPortalConfigurationByType(portalConfigurationType, account.vendorProfileId)
        );
      }
    });

    setPortalConfigurations(portalConfigurations);

    const isCreditReportsHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurations, `${portalConfigurationTypes.creditReports}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );

    setIsCreditReportsHiddenForUserRole(isCreditReportsHiddenForUserRole);

    const isHealthCheckHiddenForUserRole = isItemHasRestrictionForRole(
      _.get(portalConfigurations, `${portalConfigurationTypes.healthCheck}.jsonDefinition`),
      rolesSettingsKeys.hidden,
      userProfile.vendorContactRole
    );
    setIsHealthCheckHiddenForUserRole(isHealthCheckHiddenForUserRole);
  }, [portalConfigurationData]);

  useEffect(() => {
    if (!voData?.vendorOpportunity) return;

    dispatch({ type: SET_VO, payload: voData.vendorOpportunity });
  }, [voData]);

  const callbackSubmissionFinished = (dynamicsCreditSubmissionId: any) => {
    console.log(`Finished submission with ID ${dynamicsCreditSubmissionId}`);
    //Send mutation to update Lender Waterfall
    setHandleSubmitToLenderByProfileId(false);
  };

  const tabs: Record<CreditSubmissionTabs, (cs: CreditSubmission, invoiceDetailTableBody: any) => ReactNode> = {
    [CreditSubmissionTabs.General]: (cs, invoiceDetailTableBody) => (
      <Box className={classes.generalContainer}>
        <Box className={classes.row}>
          <ApplicationSummary
            cs={cs}
            vo={vo}
            lendersVendorProfile={vp}
            creditApp={creditAppData}
            isCreditReportsHiddenForUserRole={isCreditReportsHiddenForUserRole}
            refetchCreditApp={() => {
              getCreditAppData({
                variables: {
                  VOId: _.get(creditSubmissionData, "creditSubmission.voId", null),
                },
              });
              refetchDocs();
            }}
            handleOpenCreditReportDocument={handleOpenCreditReportDocument}
            openCreditAppModal={openCreditAppModal}
            handleClose={handleClose}
            appSummary={appSummary}
            setOpenCreditAppModal={setOpenCreditAppModal}
            setAppSummary={setAppSummary}
            vendorProfile={vendorProfile}
            setVendorProfile={setVendorProfile}
            CADMData={CADMData}
            location={location}
          />

          <Grid container direction="row" spacing={2}>
            <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
              <InvoiceEquipmentDetailsCard invoiceDetailTableBody={invoiceDetailTableBody} cs={cs} />
              {cs.transactionSummary && <TransactionSummary cs={cs} />}
            </Grid>
          </Grid>
        </Box>
        <>
          {showHealthCheck &&
            creditAppData?.creditApplication?.creditApplicationOwner?.length > 0 &&
            !isHealthCheckHiddenForUserRole && (
              <Tile title="Health Check" useCollapsing>
                <HealthCheckCard
                  owners={creditAppData.creditApplication.creditApplicationOwner}
                  refetchCreditApp={() =>
                    getCreditAppData({
                      variables: {
                        VOId: _.get(creditSubmissionData, "creditSubmission.voId", null),
                      },
                    })
                  }
                  vo={vo}
                  vp={vendorProfile}
                  creditApp={creditAppData.creditApplication}
                  full={true}
                />
              </Tile>
            )}

          <CreditSubmissionsCard cs={cs} />
          <MessageModal
            link={null}
            isOpen={openMessageModal}
            handleCloseModal={handleCloseModal}
            title={submissionMessage.title}
            message={submissionMessage.message}
          />

          <Grid container spacing={1}>
            <Grid item lg={12} xl={8} md={12} sm={12} xs={12}></Grid>
            {chat.enabled && (
              <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
                <ConversationsHistory
                  loading={conversationsLoading || creating}
                  conversations={reduxConversations}
                  onConversationAdd={handleCreateConversation}
                />
              </Grid>
            )}
            {account.dynamicsAccountId === "5e7f2efe-5c02-eb11-8125-065d4ad39109" && (
              <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
                <ActivityList vo={vo} tileTitle="" account={account} userProfile={null} creditSubId={cs.creditSubId} />
              </Grid>
            )}
            {account.dynamicsAccountId === "5e7f2efe-5c02-eb11-8125-065d4ad39109" && (
              <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
                <FDRList
                  vo={vo}
                  cs={cs}
                  tileTitle=""
                  account={account}
                  userProfile={null}
                  dynamicsTransactionId={cs.transactionId}
                />
              </Grid>
            )}
          </Grid>
        </>
      </Box>
    ),
    [CreditSubmissionTabs.Tasks]: cs => (
      <SpecificTasks_V2 vo={vo} cs={cs} account={account} userProfile={userProfile} refetchDocs={refetchDocs} />
    ),
    [CreditSubmissionTabs.Documents]: cs => (
      <Documents_V2
        cs={cs}
        CADMData={CADMData}
        handleSetDocuments={setDocuments}
        triggerRefetchDocs={triggerRefetchDocs}
        portalConfigurations={portalConfigurations}
      />
    ),
    [CreditSubmissionTabs.Syndication]: (cs, invoiceDetailTableBody) => (
      <>
        {vp?.allowSyndication && (
          <ManageSyndications
            cs={cs}
            equipmentData={invoiceDetailTableBody}
            callbackSubmissionFinished={callbackSubmissionFinished}
            handleSubmitToLenderByProfileId={handleSubmitToLenderByProfileId}
          />
        )}
      </>
    ),
    [CreditSubmissionTabs.LenderWaterfall]: cs => (
      <>
        {vo.vendorOpportunityId && vp?.allowSyndication && (
          <Grid item xs={12}>
            <LenderWaterfallResults
              creditSubmissions={cs}
              accountId={account.id}
              vendorOpportunityId={vo.vendorOpportunityId}
              handleSubmitToLenderByProfileId={setHandleSubmitToLenderByProfileId}
              lenderProfiles={vp.lenderProfiles?.filter((profile: any) => profile.id)}
            />
          </Grid>
        )}
      </>
    ),
    [CreditSubmissionTabs.Scorecard]: cs => <>{showScoreCard && <ScoreCard cs={cs} />}</>,
    [CreditSubmissionTabs.Proposals]: cs => (
      <CSProposalList
        cs={cs}
        applicant={cs.applicant}
        voId={cs.voId}
        showOnlyLenderPrograms={
          account.dynamicsAccountId === "10106e7c-d667-eb11-812d-066ef92796c9" ||
          account.dynamicsAccountId === "f970df4b-81f6-ed11-816e-066ef92796c9" ||
          account.dynamicsAccountId === "f8c9b561-d4fd-e011-9cc1-005056a20000"
        }
      />
    ),
  };

  const [currentTab, setCurrentTab] = useState<CreditSubmissionTabs>(CreditSubmissionTabs.General);

  const handleChangeTab = (_: ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue as CreditSubmissionTabs);
  };

  return (
    <Query<CreditSubmissionResponse, CreditSubmissionVariables>
      query={GET_CREDIT_SUBMISSION}
      context={{ authRequired: true }}
      variables={{ csId: id }}
    >
      {({ loading, data }) => {
        const submissionDataLoaded = !!data && !!data?.creditSubmission && Object.keys(data.creditSubmission).length;

        if (loading) return <PageSkeleton />;

        if (!submissionDataLoaded) return null;

        const cs = data.creditSubmission;

        const invoiceDetailTableBody = invoiceDetailAPIKey.map(key => parseTableData(cs, key));
        invoiceDetailTableBody.forEach(item => (item[0] = <b>{item[0]}</b>));

        return (
          <DocumentContext.Provider
            value={{
              lastDoc: lastDocument,
              documents: [],
              setLastDocument,
            }}
          >
            <Box className={classes.pageContainer}>
              <Paper className={classes.head} elevation={0}>
                <Box className={classes.headContent}>
                  <Typography variant="h5">Submission Details: {data.creditSubmission.applicant}</Typography>

                  <Breadcrumbs
                    currentValue="Detail"
                    config={[
                      { label: "List View", value: "list", onClick: () => push("/cs") },
                      { label: "Detail", value: "Detail", onClick: () => {} },
                    ]}
                  />
                </Box>
              </Paper>

              <Box className={classes.container}>
                <CardBackground>
                  <Tabs indicatorColor="primary" textColor="primary" value={currentTab} onChange={handleChangeTab}>
                    {Object.keys(tabs).map(tabName => (
                      <Tab key={tabName} label={tabName} value={tabName} />
                    ))}
                  </Tabs>
                </CardBackground>

                {Object.entries(tabs).map(([label, renderFunction]) => (
                  <TabPanel key={label} value={currentTab} index={label}>
                    {renderFunction(cs, invoiceDetailTableBody)}
                  </TabPanel>
                ))}
              </Box>
            </Box>
          </DocumentContext.Provider>
        );
      }}
    </Query>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  defaultTextWeight: {
    fontWeight: 300,
  },
  a: {
    color: primaryColor[0],
    "&:hover": {
      color: primaryColor[1],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pageContainer: {
    minHeight: "100vh",
    gap: "0.3rem",
    display: "flex",
    flexDirection: "column",
  },
  head: {
    padding: "1.5rem 1.5rem 0.5rem 1.5rem",
  },
  headContent: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    gap: "0.3rem",
    display: "flex",
    flexDirection: "column",
  },
  generalContainer: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    gap: "0.5rem",

    "& > *": {
      flexBasis: "50%",
    },
  },
}));
