import React from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import avatar from "assets/img/default-avatar.png";
import CompanyProfileSidebarBlock from "../CompanyProfileSidebarBlock";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Badge } from "@material-ui/core";

import { vendorContactRole as vendorContactRoles } from "../../utils";
import { vendorProfileDemoLogoPath, adminRoles, portalConfigurationTypes } from "../../pages/Prequal/constants";
import { checkIfUserAbleToSeeProposalMenuConstructor } from "../../../src/services/proposalsService";

import _ from "lodash";

let ps;
const config = require("../../config.js");

class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links, userInfoBlock } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
        {userInfoBlock}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    const { accountData, dashboards } = props;
    this.state = {
      openAvatar: false,
      miniActive: true,
      mobActive: false,
      ...this.getCollapseStates([...props.appRoutes, ...props.userRoutes]),
      open: true,
      close: false,
      mugshotFlag: !!accountData && !!accountData.userProfile && !!accountData.userProfile.mugshot,
    };

    this.isSuperAdmin = accountData.userProfile.adminRole === adminRoles.super;

    this.isSuperAdminAllRoles = [adminRoles.super, adminRoles.singleAccountOnly].includes(
      accountData.userProfile.adminRole
    );
  }

  mainPanel = React.createRef();
  // this creates the initial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms

  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => (window.location.href.indexOf(routeName) > -1 ? "active" : "");
  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  getDynamicPath = ({ entity, key, path }) => {
    return path.replace(`:${key}`, `${this.props.accountData[entity] ? this.props.accountData[entity][key] : ""}`);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color } = this.props;
    return routes.map((prop, key) => {
      if (prop.showInSidebarNav) {
        if (prop.redirect) {
          return null;
        }

        const path = prop.dynamic
          ? this.getDynamicPath({ path: prop.path, key: prop.dynamicKey, entity: prop.dynamicEntity })
          : prop.path;

        if (prop.collapse) {
          const st = {};
          st[prop["state"]] = !this.state[prop.state];
          const navLinkClasses = `${classes.itemLink}  ${cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(prop.views),
          })}`;
          const itemText = `${classes.itemText} ${cx({
            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
          })}`;
          const collapseItemText = `${classes.collapseItemText} ${cx({
            [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
          })}`;
          const itemIcon = classes.itemIcon;
          const caret = classes.caret;
          const collapseItemMini = classes.collapseItemMini;
          return (
            <ListItem
              key={key}
              style={{ overflowX: "hidden" }}
              className={cx(
                { [classes.item]: prop.icon !== undefined },
                { [classes.collapseItem]: prop.icon === undefined }
              )}
            >
              <NavLink
                to={"#"}
                className={navLinkClasses}
                style={{ overflowX: "hidden" }}
                onClick={e => {
                  e.preventDefault();
                  this.setState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>{prop.mini}</span>
                )}
                <ListItemText
                  primary={prop.name}
                  id={prop.name}
                  secondary={<b className={caret + " " + (this.state[prop.state] ? classes.caretActive : "")} />}
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                />
              </NavLink>
              <Collapse in={this.state[prop.state]} unmountOnExit>
                <List className={`${classes.list} ${classes.collapseList}`}>{this.createLinks(prop.views)}</List>
              </Collapse>
            </ListItem>
          );
        }
        const innerNavLinkClasses = `${classes.collapseItemLink} ${cx({
          [" " + classes[color]]: this.activeRoute(path),
        })}`;
        const collapseItemMini = classes.collapseItemMini;
        const navLinkClasses = `${classes.itemLink} ${cx({
          [" " + classes[color]]: this.activeRoute(path),
        })}`;
        const itemText = `${classes.itemText} ${cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        })}`;
        const collapseItemText = `${classes.collapseItemText} ${cx({
          [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
        })}`;
        const itemIcon = classes.itemIcon;
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            {!!prop.action ? (
              <div
                className={classes.itemLink}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  prop.action();
                }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>{prop.mini}</span>
                )}
                <ListItemText
                  primary={prop.name}
                  id={prop.name}
                  disableTypography={true}
                  className={cx(
                    { [itemText]: prop.icon !== undefined },
                    { [collapseItemText]: prop.icon === undefined }
                  )}
                ></ListItemText>
              </div>
            ) : (
              <NavLink
                to={path}
                className={cx(
                  { [navLinkClasses]: prop.icon !== undefined },
                  { [innerNavLinkClasses]: prop.icon === undefined }
                )}
                style={{ overflowX: "hidden" }}
              >
                {prop.icon !== undefined ? (
                  typeof prop.icon === "string" ? (
                    <Icon className={itemIcon}>{prop.icon}</Icon>
                  ) : (
                    <prop.icon className={itemIcon} />
                  )
                ) : (
                  <span className={collapseItemMini}>{prop.mini}</span>
                )}
                {prop.isNewFeature ? (
                  <Badge badgeContent={"NEW"} color="secondary">
                    <ListItemText
                      primary={prop.name}
                      id={prop.name}
                      disableTypography={true}
                      className={cx(
                        { [itemText]: prop.icon !== undefined },
                        { [collapseItemText]: prop.icon === undefined }
                      )}
                      style={{ paddingLeft: 0 }}
                    />
                  </Badge>
                ) : (
                  <ListItemText
                    primary={prop.name}
                    id={prop.name}
                    disableTypography={true}
                    className={cx(
                      { [itemText]: prop.icon !== undefined },
                      { [collapseItemText]: prop.icon === undefined }
                    )}
                  />
                )}
              </NavLink>
            )}
          </ListItem>
        );
      }
      return null;
    });
  };

  render() {
    const {
      classes,
      logo,
      image,
      bgColor,
      appRoutes,
      userRoutes,
      accountData,
      portalConfigurations,
      dashboards,
    } = this.props;

    const miniActive = this.props.miniActive && this.state.miniActive;
    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
    })}`;
    const userWrapperClass = `${classes.user} ${cx({
      [classes.whiteAfter]: bgColor === "white",
    })}`;
    const caret = classes.caret;
    const handleClick = () => {
      return (window.location.href = "/user-profile");
    };
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      this.setState({ close: true, open: false });
    };
    const userProfile = !!accountData && accountData.userProfile;
    const upMugshotSrc = this.state.mugshotFlag
      ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${userProfile.mugshot}`
      : null;
    const message = (
      <a href="/user-profile" style={{ color: "white" }}>
        Your profile is missing a user image. Please upload one now.
      </a>
    );
    const vendorProfile = accountData && accountData.vendorProfile;

    if (_.get(accountData, "account.accountType", null) === "Partner") {
      const appRouteIds = ["sendoca", "leads", "dealerMgmt", "addDealer"];
      const userRouteIds = ["companyProfile", "acctadmin", "creditPrograms", "setupWizard"];
      const routeIds = [...appRouteIds, ...userRouteIds];

      const appRoutesShowList = {
        sendoca: false,
        dealerMgmt: true,
        addDealer: true,
        leads: true,
      };

      const userRouteShowList = {
        companyProfile: false,
        acctadmin: true,
        creditPrograms: false,
        setupWizard: false,
      };

      routeIds.forEach(id => {
        const routeIndex = appRoutes.findIndex(route => route.id === id);

        if (routeIndex >= 0) {
          return (appRoutes[routeIndex].showInSidebarNav = appRoutesShowList[id]);
        }

        const userRouteIndex = userRoutes.findIndex(route => route.id === id);
        if (userRouteIndex >= 0) {
          userRoutes[userRouteIndex].showInSidebarNav = userRouteShowList[id];
        }
      });
    } else {
      if (!!userProfile && userProfile.vendorContactRole !== "sales_rep") {
        if (!!vendorProfile && vendorProfile.showTasks === "show") {
          const consolidatedTasksIndex = appRoutes.findIndex(route => route.id === "consolidatedTasks");
          appRoutes[consolidatedTasksIndex].showInSidebarNav = true;
        }

        if (!!vendorProfile && vendorProfile.showLenderSubmission) {
          //default all roles visibility
          const lenderadminIndex = userRoutes.findIndex(route => route.id === "lenderadmin");
          userRoutes[lenderadminIndex].showInSidebarNav = true;

          switch (vendorProfile.showLenderSubmission) {
            case "no_access":
              userRoutes[lenderadminIndex].showInSidebarNav = false;
              break;
            case "credit_manager_only":
              if (!!userProfile && userProfile.vendorContactRole !== "credit_mgr") {
                userRoutes[lenderadminIndex].showInSidebarNav = false;
              }
              break;
            case "all_managers":
              if (!!userProfile && userProfile.vendorContactRole === "sales_rep") {
                userRoutes[lenderadminIndex].showInSidebarNav = false;
              }
              break;
          }
        }
        //Website Integration tool
        const websiteIntegrationIndex = userRoutes.findIndex(route => route.id === "websiteIntegration");
        userRoutes[websiteIntegrationIndex].showInSidebarNav = true;
      }

      if (!!vendorProfile && vendorProfile.showPortalCreditApp) {
        const creditappIndex = appRoutes.findIndex(route => route.id === "creditapp");
        appRoutes[creditappIndex].showInSidebarNav = true;
      }
      if (!!vendorProfile && vendorProfile.showPrequal) {
        const prequalIndex = appRoutes.findIndex(route => route.id === "prequal");
        appRoutes[prequalIndex].showInSidebarNav = true;
      }
      if (
        (!!userProfile && userProfile.email === "rossstites@aol.com") ||
        (!!userProfile && userProfile.email === "ivan@dealercreditresources.com") ||
        (!!userProfile && userProfile.email === "beckhamt@wharton.upenn.edu")
      ) {
        const adminvoIndex = appRoutes.findIndex(route => route.id === "adminvo");
        appRoutes[adminvoIndex].showInSidebarNav = true;
        const docsIndex = appRoutes.findIndex(route => route.id === "docs");
        appRoutes[docsIndex].showInSidebarNav = true;
      }

      //Always show Chat
      if (!!vendorProfile && vendorProfile.showChat === "show") {
        const chatIndex = appRoutes.findIndex(route => route.id === "chat");
        appRoutes[chatIndex].showInSidebarNav = true;
      }

      if (!!vendorProfile && vendorProfile.showTasks === "show") {
        const consolidatedTasksIndex = appRoutes.findIndex(route => route.id === "consolidatedTasks");
        appRoutes[consolidatedTasksIndex].showInSidebarNav = true;
      }
      if (
        (!!vendorProfile && vendorProfile.showProposal === "show") ||
        (!!vendorProfile && vendorProfile.showProposal === "show_base_gbb")
      ) {
        const proposalsIndex = appRoutes.findIndex(route => route.id === "proposals");
        appRoutes[proposalsIndex].showInSidebarNav = true;
        const financeProgramsIndex = userRoutes.findIndex(route => route.id === "financePrograms");
        userRoutes[financeProgramsIndex].showInSidebarNav = true;
      }
      if (!!vendorProfile && (vendorProfile.showPqt === "default" || vendorProfile.showPqt === "options")) {
        const pqtIndex = appRoutes.findIndex(route => route.id === "pqt");
        appRoutes[pqtIndex].showInSidebarNav = true;
      }
      if (!!vendorProfile && vendorProfile.docusignDocMgmt === "show") {
        const templatesIndex = appRoutes.findIndex(route => route.id === "templates");
        appRoutes[templatesIndex].showInSidebarNav = true;
      }

      const isNeedToShowProposalMenuConstructor = checkIfUserAbleToSeeProposalMenuConstructor(
        userProfile,
        vendorProfile
      );
      if (isNeedToShowProposalMenuConstructor) {
        const proposalMenuConstructorRoute = _.find(userRoutes, { id: "proposalMenuConstructor" });
        _.set(proposalMenuConstructorRoute, "showInSidebarNav", true);
      }

      const isNeedToShowLocationManagement =
        _.get(vendorProfile, "multiLocationManagement", false) &&
        _.get(userProfile, "vendorContactRole", null) !== vendorContactRoles.sales_rep;
      if (isNeedToShowLocationManagement) {
        const locationManagementRoute = _.find(userRoutes, { id: "locationMgmt" });
        _.set(locationManagementRoute, "showInSidebarNav", true);
      }

      const isNeedToShowDealerDashboard =
        this.isSuperAdmin ||
        (_.get(dashboards, "length", 0) > 0 && !!userProfile && userProfile.vendorContactRole === "credit_mgr");
      if (isNeedToShowDealerDashboard) {
        const dealerDashboardRoute = _.find(appRoutes, { id: "dealerDashboard" });
        _.set(dealerDashboardRoute, "showInSidebarNav", true);
      }

      _.set(_.find(userRoutes, { id: "lenderWaterfall" }), "showInSidebarNav", this.isSuperAdminAllRoles);

      const isNeedToHideFinancePrograms =
        _.get(userProfile, "vendorContactRole", null) === vendorContactRoles.sales_rep;
      if (isNeedToHideFinancePrograms) {
        const financeProgramsRoute = _.find(userRoutes, { id: "financePrograms" });
        _.set(financeProgramsRoute, "showInSidebarNav", false);
      }
    }

    const PETE_DYNAMICS_ACCOUNT_ID = "a3fd7f13-46df-eb11-8139-066ef92796c9";
    const TEST_VENDOR_V2_DYNAMICS_ACCOUNT_ID = "38e27536-7ccd-e811-80fa-005056b05a0f";

    if (
      accountData.account.dynamicsAccountId === PETE_DYNAMICS_ACCOUNT_ID ||
      accountData.account.dynamicsAccountId === TEST_VENDOR_V2_DYNAMICS_ACCOUNT_ID
    ) {
      // Allow access to the customer list page
      const customersIndex = appRoutes.findIndex(route => route.id === "customers");
      appRoutes[customersIndex].showInSidebarNav = true;
    }

    const determineIfNeedToShowUserManagement = () => {
      const userManagementPortalConfiguration = _.find(portalConfigurations, {
        type: portalConfigurationTypes.userManagement,
      });
      if (userManagementPortalConfiguration) {
        const userManagementHiddenForRoles = _.get(
          userManagementPortalConfiguration,
          "jsonDefinition.config.rolesSettings.hidden"
        );
        return !_.includes(userManagementHiddenForRoles, userProfile.vendorContactRole);
      } else {
        return true;
      }
    };

    const determineIfNeedToShowPortalSetup = () => {
      const portalSetupPortalConfiguration = _.find(portalConfigurations, {
        type: portalConfigurationTypes.portalSetup,
      });
      if (portalSetupPortalConfiguration) {
        const portalSetupHiddenForRoles = _.get(
          portalSetupPortalConfiguration,
          "jsonDefinition.config.rolesSettings.hidden"
        );
        return !_.includes(portalSetupHiddenForRoles, userProfile.vendorContactRole);
      } else {
        return true;
      }
    };

    if (!!userProfile) {
      const acctadminIndex = userRoutes.findIndex(route => route.id === "acctadmin");
      const portalSetupIndex = userRoutes.findIndex(route => route.id === "portalSetup");
      userRoutes[acctadminIndex].showInSidebarNav = determineIfNeedToShowUserManagement();
      userRoutes[portalSetupIndex].showInSidebarNav = determineIfNeedToShowPortalSetup();
    }

    const user = (
      <React.Fragment>
        <div className={userWrapperClass}>
          {!this.state.mugshotFlag && (
            <div>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                open={this.state.open}
                autoHideDuration={10000}
                onClose={handleClose}
                message={message}
                action={
                  <React.Fragment>
                    <Button color="primary" size="small" onClick={handleClick}>
                      Upload Photo
                    </Button>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
            </div>
          )}
          {!!userProfile && (
            <React.Fragment>
              <List className={classes.list}>
                <ListItem className={`${classes.item} ${classes.userItem}`}>
                  <div
                    style={{
                      float: "left",
                      width: "34px",
                      height: "100%",
                      margin: "11px 11px 0 23px",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    >
                      {!!this.state.mugshotFlag ? (
                        <img
                          id="profile-image"
                          src={upMugshotSrc}
                          className={classes.avatarImg}
                          onError={() => this.setState({ mugshotFlag: false })}
                          alt="..."
                        />
                      ) : (
                        <img id="profile-image" src={avatar} className={classes.avatarImg} alt="..." />
                      )}
                    </div>
                  </div>
                  <NavLink
                    to={"#"}
                    className={classes.itemLink + " " + classes.userCollapseButton}
                    onClick={() => this.openCollapse("openAvatar")}
                  >
                    <ListItemText
                      secondary={
                        <b
                          className={
                            caret + " " + classes.userCaret + " " + (this.state.openAvatar ? classes.caretActive : "")
                          }
                        />
                      }
                      disableTypography={true}
                      style={{ overflowX: "hidden" }}
                      className={itemText + " " + classes.userItemText}
                    >
                      {!this.props.miniActive && (
                        <div
                          style={{
                            display: "inline-block",
                            marginLeft: "-16px",
                          }}
                          id="sideBarMainDisplay"
                        >
                          Welcome
                          <br />
                          {userProfile.fullName}
                        </div>
                      )}
                    </ListItemText>
                  </NavLink>
                  <Collapse in={this.state.openAvatar} unmountOnExit>
                    <List className={classes.list + " " + classes.collapseList}>
                      {this.createLinks(userRoutes, userProfile)}
                    </List>
                  </Collapse>
                </ListItem>
              </List>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );

    const links = <List className={classes.list}>{this.createLinks(appRoutes)}</List>;

    const logoNormal = `${classes.logoNormal} ${cx({
      [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
    })}`;
    // const logoMini = classes.logoMini;
    const logoClasses = `${classes.logo} ${cx({
      [classes.whiteAfter]: bgColor === "white",
    })}`;

    const vendorLogo = userProfile.isDemoMode ? vendorProfileDemoLogoPath : _.get(vendorProfile, "logo", false);

    const brand = (
      <div className={logoClasses}>
        <Hidden mdUp>
          <Button
            style={{
              position: "absolute",
              left: "15px",
              top: "50%",
              transform: "translateY(-50%)",
              color: "#fff",
              minWidth: "0",
            }}
            onClick={this.props.sidebarMobActive}
          >
            <KeyboardArrowLeftIcon />
          </Button>
        </Hidden>
        <NavLink
          to="/vo"
          className={logoNormal}
          style={{ backgroundColor: _.get(vendorProfile, "logoColors.logoComplementaryColor", "#FFFFFF") }}
        >
          <img
            onError={ev => {
              ev.target.src = logo;
            }}
            src={vendorLogo ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${vendorLogo}` : logo}
            alt="logo"
            className={classes.img}
          />
        </NavLink>
      </div>
    );
    let drawerPaper = `${classes.drawerPaper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
    })}`;
    const sidebarWrapper = `${classes.sidebarWrapper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1,
    })}`;
    if (this.props.mobActive) {
      drawerPaper += " " + classes.showSideBar;
    }
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            anchor="right"
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes.mobSideBar + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
              mobActive={this.props.mobActive}
              userInfoBlock={<CompanyProfileSidebarBlock miniActive={miniActive} />}
            />
            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            // onMouseOver={() => this.setState({ miniActive: false })}
            // onMouseOut={() => this.setState({ miniActive: true })}
            anchor="right"
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
              userInfoBlock={<CompanyProfileSidebarBlock miniActive={miniActive} />}
            />
            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  mobActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default withStyles(sidebarStyle)(Sidebar);
