import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "0px 0px 0px 16px",
    minWidth: 120,
  },
}));

const UPDATE_DYNAMICS_VO_STAGE_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const UPDATE_VO_STAGE_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!, $emailInput: UpdateVendorOpportunityEmailInput) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId, emailInput: $emailInput)
  }
`;

const stageCode = {
  "100": "Lead",
  "105": "Application Processing",
  "107": "Application Complete",
  "110": "Credit Processing",
  "115": "Doc Generation",
  "120": "Doc Outstanding",
  "125": "Funding Review",
  "130": "Booked",
  "150": "Declined",
  "155": "Lost Sale",
  "160": "Deferred ",
  "170": "Dealer Cancelled",
  "175": "Customer Cancelled",
};

const stageCodeOptions = [
  { value: "100", label: "Lead" },
  { value: "105", label: "Application Processing" },
  { value: "107", label: "Application Complete" },
  // { value: '110', label: 'Partnership' },
  // { value: '115', label: 'Municipality' },
  // { value: '120', label: 'Doc Outstanding'},
  // { value: '125', label: 'Funding Review'},
  // { value: '130', label: 'Booked'},
  // { value: '150', label: 'Declined'},
  // { value: '155', label: 'Lost Sale'},
  // { value: '160', label: 'Deferred'},
  { value: "170", label: "Dealer Cancelled" },
  { value: "175", label: "Customer Cancelled" },
];

export default function VOStageModifier({
  vo,
  refetchVO,
  customerName,
  salesRep,
  cancelledMenuOption,
  open,
  handleOpen,
  handleClose,
  setOpen,
}) {
  const classes = useStyles();
  const [stage, setStage] = React.useState(vo.dynamicsVOStage || "");

  const [updateDynamicsVOStage] = useMutation(UPDATE_DYNAMICS_VO_STAGE_MUTATION, {
    context: { authRequired: true },
  });

  const [updateVOStage] = useMutation(UPDATE_VO_STAGE_MUTATION, {
    context: { authRequired: true },
  });

  const handleChange = async event => {
    try {
      await setStage(event.target.value);
      await handleClose();
      await updateDynamicsVOStage({
        variables: {
          fieldsObj: {
            dynamicsVOStage: parseFloat(event.target.value),
          },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await updateVOStage({
        variables: {
          fieldsObj: {
            transactionStage: stageCode[event.target.value],
          },
          emailInput: {
            customerName: customerName,
            salesRep: salesRep,
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      await refetchVO();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div>SET STATUS</div>
      <FormControl className={classes.formControl} required>
        {cancelledMenuOption ? (
          <Select
            value={stage}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            required
            inputProps={{ "aria-label": "Without label" }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <MenuItem value={170}>Dealer Cancelled</MenuItem>
            <MenuItem value={175}>Customer Cancelled</MenuItem>
          </Select>
        ) : (
          <Select
            value={stage}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}
            required
            inputProps={{ "aria-label": "Without label" }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {!!stageCodeOptions &&
              stageCodeOptions.map(({ value, label }, i) => (
                <MenuItem key={i} value={value}>
                  {label}
                </MenuItem>
              ))}
            {/* <MenuItem value={105}>Application Processing</MenuItem>
            <MenuItem value={107}>Application Complete</MenuItem>
            <MenuItem value={170}>Dealer Cancelled</MenuItem>
            <MenuItem value={175}>Customer Cancelled</MenuItem> */}
          </Select>
        )}
      </FormControl>
    </div>
  );
}
