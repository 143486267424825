import React, { useEffect, useState } from "react";
import Tile from "components/Tile/Tile";
import Button from "../../components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../components/Table/Table";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import DeliveryTimelineModal from "./DeliveryTimelineModal";

const UPDATE_DYNAMICS_DELIVERY_TIMELINE = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;
const UPDATE_DELIVERY_TIMELINE_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const useStyles = makeStyles({
  cardLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px 0",
    "& h4": {
      width: "100%",
    },
    "& > div": {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  clearBlock: {
    width: 0,
    height: 0,
    overflow: "hidden",
  },
  alignRight: {
    display: "block",
    textAlign: "right",
  },
  uploadedInvoice: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  uploadedInvoiceData: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& div": {
      marginRight: "50px",
    },
  },
  uploadedInvoiceButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    "& button": {
      marginRight: "5px",
      "&::last-child": {
        marginRight: 0,
      },
    },
  },
  uploadedInvoiceTable: {
    "& th": {
      textAlign: "left",
      padding: "0 20px 0 0",
    },
    "& td": {
      textAlign: "left",
      padding: "0 20px 0 0",
    },
  },
  redBorder: {
    border: "2px solid #FF0000",
  },
  transparentBorder: {
    border: "2px solid transparent",
  },
  greenText: {
    color: "#3ec179",
  },
  modalContainer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.5)",
  },
  modalViewInvoiceImage: {
    position: "absolute",
    top: "10%",
    bottom: "10%",
    left: "10%",
    right: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  viewInvoiceImage: {
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  greenButton: {
    backgroundColor: "#3ec179",
  },
  right: {
    textAlign: "right",
  },
  disappearingUploadText: {
    marginLeft: 8,
    "@media (max-width: 1530px) and (min-width: 1280px)": {
      display: "none",
    },
    "@media (max-width: 1150px) and (min-width: 960px)": {
      display: "none",
    },
    "@media (max-width: 860px)": {
      display: "none",
    },
  },
  disappearingDownloadButton: {
    "@media (max-width: 780px)": {
      display: "none",
    },
  },
  appearingInvoiceData: {
    display: "none",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  disappearingInvoiceData: {
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export default function DeliveryTimelineCard({ deliveryTimeLineTableBody, vo, refetchVO }) {
  const classes = useStyles();

  const [openDeliveryTimelineModal, setOpenDeliveryTimelineModal] = useState(false);

  const [updateDynamicsDeliveryTimeline] = useMutation(UPDATE_DYNAMICS_DELIVERY_TIMELINE, {
    context: { authRequired: true },
  });
  const [updateDeliveryTimeline] = useMutation(UPDATE_DELIVERY_TIMELINE_MUTATION, {
    context: { authRequired: true },
  });

  const handleOpenDeliveryTimelineModal = () => {
    setOpenDeliveryTimelineModal(true);
  };
  const handleCloseDeliveryTimelineModal = () => {
    setOpenDeliveryTimelineModal(false);
  };

  const [redBorder, setRedBorder] = useState(false);

  useEffect(() => {
    if (!!vo.expectedDeliveryDate && !!vo.deliveryDate && vo.expectedDeliveryDate < vo.deliveryDate) {
      setRedBorder(true);
    } else {
      setRedBorder(false);
    }
  }, []);

  return (
    <Tile
      title="Delivery Timeline"
      titleButtonText="Edit"
      titleButtonFunc={handleOpenDeliveryTimelineModal}
      className={redBorder ? classes.redBorder : classes.transparentBorder}
      useCollapsing={true}
    >
      <div className={classes.cardLine}>
        {deliveryTimeLineTableBody ? (
          <>
            <div className={classes.fullWidth}>
              <Table
                tableHeaderColor="primary"
                tableData={deliveryTimeLineTableBody}
                colorsColls={["primary"]}
                customCellClasses={[classes.right]}
                customClassesForCells={[1]}
              />
            </div>
          </>
        ) : (
          <div className={classes.cardLine}>
            <span className={classes.greenText}>Please add delivery timeline details via "Edit" button</span>
            <Button color="primary" size="sm" onClick={handleOpenDeliveryTimelineModal} className={classes.greenButton}>
              EDIT
            </Button>
          </div>
        )}
      </div>
      <DeliveryTimelineModal
        open={openDeliveryTimelineModal}
        handleClose={() => handleCloseDeliveryTimelineModal()}
        refetchVO={refetchVO}
        handleOpenInvoiceModal={handleOpenDeliveryTimelineModal}
        deliveryTimeLineTableBody={deliveryTimeLineTableBody}
      />
    </Tile>
  );
}
