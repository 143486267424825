import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, makeStyles } from "@material-ui/core";

const styles = {
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
};

const useStyles = makeStyles(styles);

const SelectFieldComp = props => {
  const classes = useStyles();
  const {
    field,
    type,
    index,
    handleBlur,
    inputProps,
    setReference,
    setReferenceToRequiredFunction,
    setReferenceToSetOptionSetsFunction,
  } = props;

  const valueIsValidSelectOption = v => {
    const foundSelectValue = field.details?.optionSets?.find(item => {
      return item.lenderValue === String(v);
    });
    if (foundSelectValue) {
      return true;
    }
    return false;
  };

  const [value, setValue] = useState(valueIsValidSelectOption(field.mappedValue) ? field.mappedValue : "");
  const [required, setRequired] = useState(field.details.required === "REQUIRED");
  const [readOnly, setReadOnly] = useState(field.details.readOnly);
  const [optionSets, setOptionSets] = useState(field.details.optionSets);

  const setRequiredValue = required => {
    setRequired(required);
  };

  setReference(field.lenderProperty, v => {
    const foundSelectValue = valueIsValidSelectOption(v);
    if (foundSelectValue) {
      setValue(v);
      handleBlur(v, index);
    }
  });
  setReferenceToRequiredFunction(field.lenderProperty, setRequiredValue);
  setReferenceToSetOptionSetsFunction(field.lenderProperty, setOptionSets);

  return (
    <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }} key={index}>
      <InputLabel
        id={`${field.lenderProperty}-select-label`}
        className={classes.selectLabel}
        error={(!field.mappedValue || !valueIsValidSelectOption(field.mappedValue)) && required}
      >
        {(required ? "* " : "") + field.details?.displayName}
      </InputLabel>
      <Select
        labelId={`${field.lenderProperty}-select-label`}
        id={`${field.lenderProperty}-select`}
        value={value}
        onChange={e => setValue(e.target.value)}
        onBlur={() => handleBlur(value, index)}
        inputProps={(inputProps, { disabled: readOnly })}
        label={(required ? "* " : "") + field.details?.displayName}
        className={classes.selectStyles}
        error={(!field.mappedValue || !valueIsValidSelectOption(field.mappedValue)) && required}
      >
        {optionSets?.map((item, index) => (
          <MenuItem className={classes.selectOption} key={field.lenderProperty + "-" + index} value={item.lenderValue}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFieldComp;
