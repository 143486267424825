import React from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useDrop } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, LinearProgress, TextField, Typography } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import { ConfigurableItemActions } from "../PortalConfigurationWizard/CreditAppDetailModal/ConfigurableItemActions";
import { proposalMenuDraggableTypes } from "../../../src/pages/Prequal/constants";
import { formatCurrency } from "../../utils";
import _ from "lodash";

const CREATE_PRODUCT_PACKAGE = gql`
  mutation($input: CreateProposalProductPackageInput!) {
    createProposalProductPackage(input: $input) {
      proposalPackageId
    }
  }
`;

const UPDATE_PRODUCT_PACKAGE = gql`
  mutation($input: UpdateProposalProductPackageInput!) {
    updateProposalProductPackage(input: $input) {
      proposalPackageId
    }
  }
`;

const styles = {
  root: {
    padding: 20,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: "6px",
    background: "white",
  },
  addNewProductContainer: {
    minHeight: 300,
    border: "2px dashed gray",
    width: "100%",
    backgroundColor: "#0000000a",
    padding: 24,
    margin: "0 20px",
  },
  productCard: {
    position: "relative",
    border: "1px solid gray",
    borderRadius: 8,
    padding: "30px 12px 12px",
    background: "white",
  },
  productCardTitle: {
    fontWeight: 600,
    marginBottom: 12,
  },
  productCardInputName: {
    fontWeight: 500,
    marginRight: 6,
    fontSize: 14,
  },
  configurableItemActions: {
    position: "absolute",
    top: 7,
    right: 10,
  },
};

const useStyles = makeStyles(styles);

const ProposalProductPackageEditor = ({
  productPackage,
  setProductPackageToEdit,
  setIsProductPackageEditMode,
  refetchProductPackages,
  refetchProposalMenu,
}) => {
  const classes = useStyles();

  const isEditing = productPackage?.proposalPackageId;

  const [createProductPackage, { loading: createLoading }] = useMutation(CREATE_PRODUCT_PACKAGE, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const [updateProductPackage, { loading: updateLoading }] = useMutation(UPDATE_PRODUCT_PACKAGE, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const onMutationCompleted = () => {
    setIsProductPackageEditMode(false);
    refetchProductPackages();
    refetchProposalMenu();
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: proposalMenuDraggableTypes.proposalProduct,
      drop: product => setProductPackageToEdit({ ...productPackage, products: [...productPackage.products, product] }),
      canDrop: item => _.findIndex(productPackage.products, { proposalProductId: item.proposalProductId }) === -1,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(), //cannot drop 2 same items
      }),
    }),
    [productPackage]
  );

  const handleOnRemoveClick = product => {
    setProductPackageToEdit({
      ...productPackage,
      products: productPackage.products.filter(p => p.proposalProductId !== product.proposalProductId),
    });
  };

  const handleSave = () => {
    const proposalProductIdsToLink = _.map(productPackage.products, "proposalProductId");
    isEditing
      ? updateProductPackage({
          variables: {
            input: {
              proposalProductPackageId: productPackage.proposalPackageId,
              name: productPackage.name,
              proposalProductIdsToLink,
            },
          },
        })
      : createProductPackage({
          variables: {
            input: {
              name: productPackage.name,
              proposalProductIdsToLink,
            },
          },
        });
  };

  const loading = createLoading || updateLoading;
  return (
    <div className={classes.root}>
      <Typography variant="h5" style={{ marginBottom: 25 }}>
        Product Package Editor
      </Typography>
      {!loading && (
        <Grid container spacing={5}>
          <Grid item>
            <TextField
              required
              label="Name"
              fullWidth
              size="small"
              value={productPackage.name}
              onChange={e => setProductPackageToEdit({ ...productPackage, name: e.target.value })}
            />
          </Grid>
          <div ref={drop} className={classes.addNewProductContainer}>
            <Typography variant="h6" align="center" style={{ marginBottom: 25 }}>
              Drop Products Here
            </Typography>
            <Grid item container xs={12} spacing={3}>
              {productPackage.products?.map(product => {
                return (
                  <Grid item xs={3} key={product.proposalProductId}>
                    <div className={classes.productCard}>
                      <Typography align="center" className={classes.productCardTitle}>
                        {product.title}
                      </Typography>
                      {product.cost && (
                        <Typography>
                          <Typography variant="overline" className={classes.productCardInputName}>
                            Cost:
                          </Typography>{" "}
                          {formatCurrency(product.cost)}
                        </Typography>
                      )}
                      {product.retailCost && (
                        <Typography>
                          <Typography variant="overline" className={classes.productCardInputName}>
                            Retail Cost:
                          </Typography>{" "}
                          {formatCurrency(product.retailCost)}
                        </Typography>
                      )}
                      {product.thirdPartyUrl && (
                        <Typography style={{ overflowWrap: "break-word" }}>
                          <Typography variant="overline" className={classes.productCardInputName}>
                            3rd Party Url:
                          </Typography>{" "}
                          {product.thirdPartyUrl}
                        </Typography>
                      )}
                      <div className={classes.configurableItemActions}>
                        <ConfigurableItemActions onRemove={() => handleOnRemoveClick(product)} itemType="Product" />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <Grid item container xs={12} justifyContent="flex-end">
            <Button onClick={() => setIsProductPackageEditMode(false)}>Cancel</Button>
            <Button
              onClick={handleSave}
              color="primary"
              disabled={!(productPackage.name && _.some(productPackage.products))}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
      {loading && <LinearProgress style={{ margin: 10 }} />}
    </div>
  );
};

export default ProposalProductPackageEditor;
