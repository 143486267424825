import React, { useEffect, useState } from "react";
import Tile from "../../Tile/Tile";
import { Box } from "@material-ui/core";
import { TaskTable } from "./table/TaskTable";
import { makeStyles } from "@material-ui/core/styles";
import { filterTableDataByFiltersValues } from "../lib";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { TasksTableFilters } from "../types";
import { TableFiltersPanel } from "../../Table/TableFiltersPanel";
import { TaskListSettingsPanel } from "./TaskListSettingsPanel";
import { QUERY_TASKS_BY_CS, UPDATE_TASK, UPSERT_TASK } from "../queries";
import { priorityFiltersOptions, statusFiltersOptions, tasksTableFilters } from "../config/configuration";
import { CreateAndUpdateTaskModal } from "./modals";
import { QueryHookOptions } from "@apollo/react-hooks/lib/types";
import {
  Account,
  CreditSubmission,
  Task,
  UserProfile,
  VendorOpportunity,
  Document,
} from "@trnsact/trnsact-shared-types";

interface Props {
  creditSubId: any;
  account: Account;
  relatedUsers: any;
  tileTitle?: string;
  addTaskFlag: boolean;
  cs: CreditSubmission;
  vo: VendorOpportunity;
  tasksQueryKey: string;
  userProfile: UserProfile;
  refetchDocs: VoidFunction;
  taskOnClickAction: string;
  queryTasksOptions?: QueryHookOptions;

  lenderProfile?: any;
  conversations?: any;
  documents?: Document[];
  documentsPortalConfiguration?: any;
}

export const TaskList = (props: Props) => {
  const {
    vo,
    account,
    tileTitle,
    addTaskFlag,
    userProfile,
    creditSubId,
    refetchDocs,
    relatedUsers,
    tasksQueryKey,
    queryTasksOptions,
    lenderProfile,
    documents,
    documentsPortalConfiguration,
    conversations,
  } = props;

  const classes = useStyles();

  // Modals
  const [isCreateAndUpdateModalOpen, setIsCreateAndUpdateModalOpen] = useState(false);

  // Others
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [tableFilters, setTableFilters] = useState<TasksTableFilters>(tasksTableFilters);

  const { loading: isTasksLoading, refetch: fetchTasks } = useQuery(QUERY_TASKS_BY_CS, {
    ...queryTasksOptions,
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      if (!userProfile && !response?.[tasksQueryKey]) return;

      const preparedTasks = response[tasksQueryKey].map((task: any) => ({
        ...task,
        createdDateMilliseconds: new Date(task.createdDateTime).getTime(),
      }));

      setTasks(preparedTasks);
    },
  });

  useEffect(() => {
    const filteredTasks = filterTableDataByFiltersValues(tasks, tableFilters);
    setFilteredTasks(filteredTasks);
  }, [tasks, tableFilters]);

  const [upsertTask] = useMutation(UPSERT_TASK, { context: { authRequired: true } });
  const [updateTask] = useMutation(UPDATE_TASK, { context: { authRequired: true } });

  return (
    <>
      {!!account && (
        <Tile useCollapsing title=" ">
          <Box className={classes.container}>
            <TaskListSettingsPanel
              tileTitle={tileTitle}
              addTaskFlag={addTaskFlag}
              onOpenCreateTaskModel={() => {
                setTaskId(null);
                setIsCreateAndUpdateModalOpen(true);
              }}
            />

            <TableFiltersPanel
              options={{
                status: { label: "Status", values: statusFiltersOptions },
                priority: { label: "Priority", values: priorityFiltersOptions },
              }}
              onSearchChange={(filter, nextValue) => {
                setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
              }}
              onOptionsChange={(filter, nextValue) => {
                setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
              }}
            />

            {tasks && (
              <TaskTable
                fetchTasks={fetchTasks}
                userProfile={userProfile}
                tableFilters={tableFilters}
                tasksQueryKey={tasksQueryKey}
                filteredTasks={filteredTasks}
                isTableDataLoading={isTasksLoading}
                onOpenCreateTaskModel={(taskId: string) => {
                  setTaskId(taskId);
                  setIsCreateAndUpdateModalOpen(true);
                }}
              />
            )}
          </Box>
        </Tile>
      )}

      <CreateAndUpdateTaskModal
        vo={vo}
        tasks={tasks}
        taskId={taskId}
        documents={documents}
        updateTask={updateTask}
        upsertTask={upsertTask}
        fetchTasks={fetchTasks}
        refetchDocs={refetchDocs}
        creditSubId={creditSubId}
        relatedUsers={relatedUsers}
        conversations={conversations}
        isOpen={isCreateAndUpdateModalOpen}
        documentsPortalConfiguration={documentsPortalConfiguration}
        lenderProfileDynamicsId={lenderProfile ? lenderProfile.lenderProfileId : null}
        onClose={() => {
          setIsCreateAndUpdateModalOpen(false);
          setTaskId(null);
        }}
      />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    padding: "1rem",
    flexDirection: "column",
  },
});
