import React, { useState } from "react";
import Table from "../../components/Table/Table";
import ContactDetailModal from "./ContactDetailModal";
import Tile from "../../components/Tile/Tile";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  statusTableCell: {
    width: "50%",
  },
  cardItem: {
    padding: "8px 8px",
    borderTop: "1px solid #DDD",
  },
  cardItemHeader: {
    fontSize: "14px",
    fontWeight: "700",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  cardItemText: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

export default function ContactCard({
  contactsTableBody,
  refetchVO,
  vo,
  refetchCreditApp,
  creditApp,
  multiLocationManagement,
  setContactsTableBody,
}) {
  const classes = useStyles();
  const { push } = useHistory();

  const [openContactDetail, setOpenContactDetail] = useState(false);

  const handleOpenContactDetailModal = () => setOpenContactDetail(true);
  const handleCloseContactDetailModal = () => setOpenContactDetail(false);
  const redirectToLocation = () => push(`/locationDetail/${vo.location.locationId}`);

  return (
    <div>
      <Tile title="Details" useCollapsing titleButtonText="Edit" titleButtonFunc={handleOpenContactDetailModal}>
        <Grid item xs={12} className={classes.cardItem}>
          {multiLocationManagement && (
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h5" component="h5" className={classes.cardItemHeader}>
                  Location
                </Typography>
              </Grid>
              {!_.get(vo, "location") && (
                <Grid item xs={6} style={{ paddingLeft: "8px" }}>
                  <span>Branch: </span>
                  <span style={{ color: "red", fontWeight: "bold" }}>Missing</span>
                </Grid>
              )}

              {_.get(vo, "location.category") === "Branch" && (
                <Grid item xs={6} style={{ paddingLeft: "8px" }}>
                  {_.get(vo, "location.parentLocation.category") === "Region" && (
                    <>
                      <span>Region: </span>
                      <span className={classes.cardItemText} onClick={redirectToLocation}>
                        {vo.location.parentLocation.locationName}
                      </span>
                      <span> | </span>
                    </>
                  )}
                  <span>Branch: </span>
                  <span className={classes.cardItemText} onClick={redirectToLocation}>
                    {vo.location.locationName}
                  </span>
                </Grid>
              )}

              {_.get(vo, "location.category") === "Region" && (
                <Grid item xs={6} style={{ paddingLeft: "8px" }}>
                  <span>Region: </span>
                  <span className={classes.cardItemText} onClick={redirectToLocation}>
                    {vo.location.locationName}
                  </span>
                  <span> | </span>
                  <span>Branch: </span>
                  <span style={{ color: "red", fontWeight: "bold" }}>Missing</span>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Table
          tableHeaderColor="primary"
          tableCellStyles={classes.statusTableCell}
          tableData={contactsTableBody}
          colorsColls={["primary"]}
        />
        <Table
          tableHeaderColor="primary"
          tableData={[
            [<span style={{ fontWeight: "bold" }}>Status Details</span>, vo.statusDetail ? vo.statusDetail : "N/A"],
          ]}
          colorsColls={["primary"]}
          tableCellStyles={classes.statusTableCell}
        />
        <ContactDetailModal
          open={openContactDetail}
          handleCloseContactDetailModal={handleCloseContactDetailModal}
          handleClose={handleCloseContactDetailModal}
          refetchVO={refetchVO}
          setContactsTableBody={setContactsTableBody}
          creditApp={creditApp}
        />
      </Tile>
    </div>
  );
}
