import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { DCR_API_BASE_SERVER_URL } from "config";
import gql from "graphql-tag";
import axios from "axios";
import { GuarantorListWithCreditPull } from "components/GuarantorListWithCreditPull";
import { useMutation } from "@apollo/react-hooks";
import { formatPhoneNumber } from "utils";
import { formatCurrency } from "utils";

import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
  Select,
  Modal,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Table from "components/Table/Table";
import Tile from "../../components/Tile/Tile";
import ApplicationSummaryModal from "./ApplicationSummaryModal";
import { useDispatch, useSelector } from "react-redux";
import { SET_CS_REFETCH } from "redux/types";
import _ from "lodash";

import CADMv2 from "../../../../../src/pages/VendorOpportunity/CADMv2";

const applicationStageOptions = [
  { code: 804790003, label: "Lender Approved" },
  { code: 804790007, label: "Docs Out" },
  { code: 804790008, label: "Docs In" },
  { code: 804790009, label: "Funding Review" },
  { code: 804790010, label: "Funding Items Requested" },
  { code: 804790020, label: "Funded" },
];

const QUERY_VENDOR_PROFILE = gql`
  query($id: ID, $dynamicsId: ID, $userProfileId: ID, $VOId: ID) {
    vendorProfile(id: $id, dynamicsId: $dynamicsId, userProfileId: $userProfileId, VOId: $VOId) {
      id
      name
      dynamicsId
      showDocMgt
      showLenderDocsAccessMgmt
      softPullInDealerPortal
      ofacRedFlagComplianceAccess
      showCreditApp
      showPsOptions
      showMarginTool
      showLenderSubmission
      industryGroupType
      role
      logo
      dcrDisclosureLanguage
      dcrDisclosureTerms
    }
  }
`;

const GET_USER_PROFILE = gql`
  query {
    userProfile {
      fullName
      id
    }
  }
`;

export default function ApplicationSummary({
  cs,
  vo,
  creditApp,
  lendersVendorProfile,
  handleClose,
  openCreditAppModal,
  appSummary,
  setOpenCreditAppModal,
  setAppSummary,
  vendorProfile,
  setVendorProfile,
  CADMData,
  location,
  isCreditReportsHiddenForUserRole,
  refetchCreditApp,
  handleOpenCreditReportDocument,
}) {
  const creditId = cs.creditSubId.toString();
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const refetchCS = useSelector(state => state.cs.refetch);
  const [userProfile, setUserProfile] = useState(null);
  const [approvedAmount, setApprovedAmount] = useState(cs.approvedAmmount || 0);
  const [notes, setNotes] = useState(cs.notes || "");

  const decisionObj = _.find(applicationStageOptions, { label: cs.stage });

  const [appStage, setAppStage] = useState(decisionObj ? decisionObj.code : 804790003);
  const [applicationSummaryTileData, setApplicationSummaryTileData] = useState({
    title: "Application Summary",
  });

  const POST_APPLICATION_STAGE = gql`
    mutation($entityId: String!, $stage: Int) {
      updateLenderApplicationStage(entityId: $entityId, stage: $stage)
    }
  `;

  const [updateLenderApplicationStage] = useMutation(POST_APPLICATION_STAGE);

  const { data: dataVendorProfile, loading: loadingVendorProfile, error: errorVendorProfile } = useQuery(
    QUERY_VENDOR_PROFILE,
    {
      variables: {
        VOId: cs.voId,
      },
      context: { authRequired: true },
    }
  );

  if (loadingVendorProfile) console.log("Loading vendorProfile...");
  if (errorVendorProfile) console.log(`Error! ${errorVendorProfile.message}`);

  useEffect(() => {
    if (dataVendorProfile !== null && dataVendorProfile !== undefined) {
      setVendorProfile(dataVendorProfile);
    }
  }, [dataVendorProfile]);

  const {
    loading: loadingUserProfile,
    error: errorUserProfile,
    data: dataUserProfile,
    refetch: refetchUserProfile,
  } = useQuery(GET_USER_PROFILE, {
    variables: {
      VOId: cs.voId,
      docId: null,
    },
    context: { authRequired: true },
  });

  // if (loadingUserProfile) console.log('Loading userProfile...');
  if (errorUserProfile) console.log(`Error! ${errorUserProfile.message}`);

  //Fetch UserProfile for the very first time:
  useEffect(() => {
    if (dataUserProfile !== null && dataUserProfile !== undefined && dataUserProfile.userProfile.fullName !== null) {
      setUserProfile(dataUserProfile);
    }
  }, [dataUserProfile]);

  const handleCreditAppRequest = () => {
    setOpenCreditAppModal(true);

    return axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-lender-pdf?transaction_id=${cs.pgTransactionId}`,
        {
          dealerName: cs.dealer,
          userProfile,
          vendorProfile,
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setAppSummary(response.data);
      })
      .catch(error => console.log(error));
  };

  const handleSetStage = async stageCode => {
    try {
      const result = await updateLenderApplicationStage({
        variables: {
          entityId: creditId,
          stage: stageCode,
        },
      });

      dispatch({
        type: SET_CS_REFETCH,
        payload: { refetch: !refetchCS },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (vendorProfile && userProfile) {
      setApplicationSummaryTileData({
        ...applicationSummaryTileData,
        titleButtonIcon: <VisibilityIcon size="small" style={{ margin: "0px 8px 0px 0px" }} />,
        titleButtonText: "View Details",
        titleButtonFunc: handleCreditAppRequest,
      });
    }
  }, [vendorProfile, userProfile]);

  let lookupStage = cs.stage;

  if (cs.decision === "Approved") {
    //No changes here allowed, we only show a fixed label
    lookupStage = (
      <Select
        size="small"
        value={appStage}
        onChange={e => {
          setAppStage(e.target.value);
          handleSetStage(e.target.value);
        }}
        inputProps={{
          name: "stage",
          id: "stage",
        }}
      >
        {applicationStageOptions.map(({ code, label }, i) => {
          return (
            <MenuItem value={code} key={i}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  const getApplicationSummaryTableData = () => {
    const getVOUsersRows = () => {
      if (_.isEmpty(vo)) {
        return;
      }

      const dealerUsersToShow = _([vo.salesRepresentative, vo.salesManager, vo.financeManager])
        .compact()
        .uniqBy("email")
        .value();

      const rows = dealerUsersToShow.map((user, idx) => {
        return [
          [
            <div style={{ fontFamily: "Roboto" }}>Dealer Contact {idx + 1}</div>,
            `${user.fullName}${user.title ? `, ${user.title}` : ``} `,
          ],
          user.phoneNumber ? [<div style={{ paddingLeft: 20 }}>Phone</div>, user.phoneNumber] : null,
          user.email ? [<div style={{ paddingLeft: 20 }}>Email</div>, user.email] : null,
        ];
      });
      return _.flatten(rows);
    };

    const getSubmittedByRows = () => {
      if (_.isEmpty(cs)) {
        return;
      }

      return [
        ["Submitted By", cs.contact],
        cs.contactPhone ? [<div style={{ paddingLeft: 20 }}>Phone</div>, cs.contactPhone] : null,
        cs.contactEmail ? [<div style={{ paddingLeft: 20 }}>Email</div>, cs.contactEmail] : null,
      ];
    };

    let columns = [
      ["Applicant", cs.applicant],
      ["Request Amount", cs.amountRequested ? formatCurrency(cs.amountRequested.toFixed(2)) : ""],
      [
        "Guarantor(s)",
        <GuarantorListWithCreditPull
          vo={vo}
          creditApp={creditApp?.creditApplication}
          creditSubmission={cs}
          vp={lendersVendorProfile}
          refetchCreditApp={refetchCreditApp}
          handleOpenCreditReportDocument={handleOpenCreditReportDocument}
          isCreditReportsHiddenForUserRole={isCreditReportsHiddenForUserRole}
          key={"guarantor-list-with-credit-pull"}
        />,
      ],
      ["Submitted On", cs.submissionDate],
      ["Dealer", cs.dealer],
      location && location.locationName ? ["Dealer Location", location.locationName] : null,
    ];

    // DR-6115: If Syndication, then show all Dealer Contacts; Show "Submitted By" unless was Syndicated
    if (cs.syndicatedBy) {
      columns = columns.concat(getVOUsersRows());
    } else {
      columns = columns.concat(getSubmittedByRows());
    }

    columns = columns.concat([
      ["Stage", lookupStage],
      cs.masterLenderName ? ["Syndicated From", cs.masterLenderName] : null,
      cs.syndicatedBy ? ["Syndicated By", cs.syndicatedBy.fullName] : null,
    ]);
    return _.compact(columns);
  };

  const applicationSummaryTableData = getApplicationSummaryTableData();

  return (
    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
      <Tile style={{ display: "flex", alignItems: "center" }} {...applicationSummaryTileData}>
        <Table tableHeaderColor="primary" tableData={applicationSummaryTableData} colorsColls={["primary"]} />
        <Modal
          open={openCreditAppModal}
          onClose={handleClose}
          aria-labelledby="Credit Application"
          aria-describedby="Summary"
        >
          {CADMData ? (
            <CADMv2
              vo={CADMData.vo}
              account={account}
              vendorProfile={CADMData.vendorProfile}
              equipmentData={CADMData.equipmentData}
              creditAppModalConfig={CADMData.CADMPortalConfiguration.jsonDefinition}
            />
          ) : (
            <ApplicationSummaryModal {...appSummary} />
          )}
        </Modal>
      </Tile>
    </Grid>
  );
}
