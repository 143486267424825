import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import { FORM_ERROR } from "final-form";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import Tile from "components/Tile/Tile";
import { setRefetch } from "../../../../redux/actions";
import { getHeadersFromAuth, setFieldValue, validateValue } from "../../../../utils";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import TableSearch from "components/Table/TableSearch";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { CircularProgress, IconButton } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { DUPLICATE_OPPORTUNITY } from "services/opportunities";

const config = require("../../../../config.js");

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Q_ACCOUNT = gql`
  query($id: ID) {
    account(id: $id) {
      id
      dynamicsAccountId
      vendorProfileId
      primaryContactId
      name
      portalType
      address
      phone
      primaryContactName
      primaryContactPhone
      primaryContactEmail
      accountManagerDynamicsId
    }
  }
`;

const CUSTOMER_VOS = gql`
  query($accountId: ID!) {
    getCustomerVendorOpportunitiesByAccountId(accountId: $accountId) {
      vendorOpportunityId
      dynamicsVendorOpportunityId
      createdOn
      potentialCustomer {
        name
      }
      salesRepresentative {
        fullName
      }
      transactionStage
      equipmentCost
    }
  }
`;

function AccountDetails({ userProfile, match, setRefetch }) {
  const accountId = match.params.accountId;
  const history = useHistory();
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 10 });
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const [isShowSuccessSnackbar, setIsShowSuccessSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: accountDetails } = useQuery(Q_ACCOUNT, {
    variables: {
      id: accountId,
    },
  });
  const { data: vendorOpportunities, refetch: voRefetch } = useQuery(
    CUSTOMER_VOS,
    {
      variables: {
        accountId: accountId,
      },
    },
    { fetchPolicy: "no-cache" }
  );
  const [duplicateOpportunity] = useMutation(DUPLICATE_OPPORTUNITY, {
    context: { authRequired: true },
  });

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsShowSuccessSnackbar(false);
  };

  const handleCreateNewOpportunity = async voID => {
    setIsLoading(true);
    try {
      const res = await duplicateOpportunity({
        variables: { vendorOpportunityId: voID },
      });
      const newVoId = res?.data?.duplicateTransaction?.vendorOpportunityId;

      if (newVoId) {
        setIsShowSuccessSnackbar(true);
        voRefetch();
      }
      setIsLoading(false);
    } catch (error) {
      window.NREUM.noticeError(error);
      setIsLoading(false);
    }
  };

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    businessName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    address: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    phone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    primaryContactName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    primaryContactPhone: {
      value: "",
      validationStatus: "",
      format: formatPhone,
      validations: [{ type: "required" }],
    },
    primaryContactEmail: {
      value: "",
    },
  });
  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (accountDetails) {
      setFields({
        id: {
          value: accountDetails.account.id ? accountDetails.account.id : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        businessName: {
          value: accountDetails.account.name ? accountDetails.account.name : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        address: {
          value: accountDetails.account.address ? accountDetails.account.address : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        phone: {
          value: accountDetails.account.phone ? formatPhone(accountDetails.account.phone) : "",
          validationStatus: "",
          format: formatPhone,
          validations: [{ type: "required" }],
        },
        primaryContactName: {
          value: accountDetails.account.primaryContactName ? accountDetails.account.primaryContactName : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        primaryContactPhone: {
          value: accountDetails.account.primaryContactPhone ? accountDetails.account.primaryContactPhone : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        primaryContactEmail: {
          value: accountDetails.account.primaryContactEmail ? accountDetails.account.primaryContactEmail : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
      });
    }
  }, [accountDetails]);

  return (
    <>
      {accountDetails ? (
        <>
          <Form
            onSubmit={values => {
              const formData = new FormData();
              formData.append(
                "operations"
                // JSON.stringify({
                //   query: M_USER_PROFILE(!!fields.file.value),
                //   variables: {
                //     entityId: dynamicsContactId,
                //     fieldsObj: {
                //       id: repDetails.contact.userProfileId,
                //       firstName: fields.firstName.value,
                //       lastName: fields.lastName.value,
                //       fullName: `${fields.firstName.value} ${fields.lastName.value}`,
                //       mugshot: fields.mugshot.value == avatar ? null : fields.mugshot.value,
                //       phoneNumber: fields.phoneNumber.value,
                //       vendorContactRole: fields.vendorContactRole.value,
                //       accountId: repDetails.contact.accountId,
                //       title: fields.title.value,
                //       status: fields.status.value,
                //     },
                //   },
                // })
              );
              formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
              if (fields.file.value) {
                formData.append("0", fields.file.value);
              }
              return new Promise(async (resolve, reject) => {
                const authHeaders = await getHeadersFromAuth(Auth);
                fetch(config.DCR_GRAPHQL_ENDPOINT, {
                  method: "POST",
                  headers: {
                    ...authHeaders,
                  },
                  body: formData,
                })
                  .then(response => response.json())
                  .then(response => {
                    response = response || {};
                    if (response.errors && response.errors.length) {
                      const errors = response.errors.map(e => e.message).join("; ");
                      console.error("Error saving account:", errors);
                      setErrorMessage("Error saving account!");
                      setErrorOpen(true);
                      resolve();
                    } else {
                      setSnackbarOpen(true);
                      resolve();
                      setRefetch("account", true);
                    }
                  })
                  .catch(err => {
                    console.error("Error saving account:", err);
                    const ret = {};
                    ret[FORM_ERROR] = "Error saving account!";
                    resolve(ret);
                  });
              });
            }}
            validate={checkFormValidation}
            render={({ handleSubmit, pristine, valid, submitting, values }) => {
              function uploadButtonHandler(event) {
                const file = document.getElementById("file");
                file.click();
              }
              function uploadFileChanged(event) {
                fields.file.value = event.target.files[0];
                const reader = new FileReader();
                reader.onload = e => {
                  const avatar = document.getElementById("avatar");
                  setValue(e.target.result, "mugshot");
                  avatar.src = e.target.result;
                };
                reader.readAsDataURL(event.target.files[0]);
                checkFormValidation();
              }
              function handleClose() {
                setSnackbarOpen(false);
                setErrorOpen(false);
              }

              return (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleSubmit().then(res => console.log(res));
                  }}
                >
                  <Grid container>
                    <GridItem lg={12}>
                      <Tile>
                        <Grid container>
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="outlined"
                              onClick={() => history.goBack()}
                            >
                              Back
                            </Button>
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CustomInput
                              labelText="Business Name"
                              id="businessName"
                              formControlProps={{ fullWidth: true }}
                              disabled
                              error={fields.businessName.validationStatus === "error"}
                              inputProps={{
                                type: "text",
                                name: "businessName",
                                value: fields.businessName.value,
                                onChange: e => setValue(e.target.value, "businessName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CustomInput
                              labelText="Address"
                              id="address"
                              formControlProps={{ fullWidth: true }}
                              disabled
                              error={fields.address.validationStatus === "error"}
                              inputProps={{
                                type: "text",
                                name: "address",
                                value: fields.address.value,
                                onChange: e => setValue(e.target.value, "address"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3} lg={4}>
                            <CustomInput
                              labelText="Phone"
                              id="phone"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: "text",
                                name: "phone",
                                value: fields.phone.value,
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CustomInput
                              labelText="Primary Contact"
                              id="primaryContactName"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              disabled
                              inputProps={{
                                type: "text",
                                name: "primaryContactName",
                                value: fields.primaryContactName.value,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CustomInput
                              labelText="Primary Contact Phone"
                              id="primaryContactPhone"
                              formControlProps={{ fullWidth: true }}
                              disabled
                              inputProps={{
                                type: "text",
                                name: "primaryContactPhone",
                                value: fields.primaryContactPhone.value,
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CustomInput
                              labelText="Primary Contact Email"
                              id="primaryContactPhone"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              disabled
                              inputProps={{
                                type: "text",
                                name: "primaryContactEmail",
                                value: fields.primaryContactEmail.value,
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <br />
                        {/* <Grid container justify="center" alignItems="center">
                          <GridItem xs={8} sm={3} md={3} lg={2}>
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="contained"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={submitting || !formValid}
                            >
                              {submitting ? "Updating" : "Update"}
                            </Button>
                          </GridItem>
                        </Grid> */}
                      </Tile>
                    </GridItem>
                  </Grid>
                  <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                      Successfully updated the account!
                    </Alert>
                  </Snackbar>
                  <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                </form>
              );
            }}
          />
        </>
      ) : (
        "Loading Account Details"
      )}
      {vendorOpportunities && (
        <Grid container style={{ marginTop: 32 }}>
          <GridItem lg={12}>
            <Tile>
              <h4 style={{ margin: "0 0 16px", fontWeight: 400 }}>Opportunities</h4>
              <TableHeader
                filterComps={
                  <>
                    <TableSearch
                      setPageNumber={setPageNumber}
                      setSavedListSettings={setSavedListSettings}
                      searchRef={searchRef}
                      savedListSettings={savedListSettings}
                    />
                  </>
                }
              >
                {isLoading && <CircularProgress size={32} />}
              </TableHeader>
              <Table
                color="primary"
                noDataText={<h3>No results</h3>}
                className="-highlight"
                getTrProps={(state, rowInfo, column, instance) => ({
                  style: { cursor: "pointer" },
                })}
                data={vendorOpportunities.getCustomerVendorOpportunitiesByAccountId}
                columns={[
                  {
                    Header: "Created",
                    accessor: "createdOn",
                    sortMethod: (a, b) => {
                      const dateA = new Date(a).getTime();
                      const dateB = new Date(b).getTime();

                      return dateA - dateB;
                    },
                    Cell: props => (
                      <>{props.value ? moment(new Date(parseInt(props.value))).format("ll hh:mma") : ""}</>
                    ),
                    getProps: (state, cellInfo, column, instance) => ({
                      style: { cursor: "pointer" },
                      onClick: e => {
                        window.location.href = `/vo/${cellInfo.original.vendorOpportunityId}`;
                      },
                    }),
                  },
                  {
                    Header: "Customer",
                    accessor: "potentialCustomer.name",
                    getProps: (state, cellInfo, column, instance) => ({
                      style: { cursor: "pointer" },
                      onClick: e => {
                        window.location.href = `/vo/${cellInfo.original.vendorOpportunityId}`;
                      },
                    }),
                  },
                  {
                    Header: "Owner",
                    accessor: "salesRepresentative.fullName",
                    getProps: (state, cellInfo, column, instance) => ({
                      style: { cursor: "pointer" },
                      onClick: e => {
                        window.location.href = `/vo/${cellInfo.original.vendorOpportunityId}`;
                      },
                    }),
                  },
                  {
                    Header: "Amount",
                    accessor: "equipmentCost",
                    getProps: (state, cellInfo, column, instance) => ({
                      style: { cursor: "pointer" },
                      onClick: e => {
                        window.location.href = `/vo/${cellInfo.original.vendorOpportunityId}`;
                      },
                    }),
                  },
                  {
                    Header: "Status",
                    accessor: "transactionStage",
                    getProps: (state, cellInfo, column, instance) => ({
                      style: { cursor: "pointer" },
                      onClick: e => {
                        window.location.href = `/vo/${cellInfo.original.vendorOpportunityId}`;
                      },
                    }),
                  },
                  {
                    Header: "Duplicate",
                    Cell: props => (
                      <IconButton onClick={() => handleCreateNewOpportunity(props.original.vendorOpportunityId)}>
                        <FileCopy style={{ fontSize: 20 }} />
                      </IconButton>
                    ),
                    maxWidth: 75,
                  },
                ]}
                pageSize={pageSizeValue}
                pageNumber={pageNumber}
                defaultPageSize={savedListSettings.pageSize}
                showPaginationTop={false}
                showPaginationBottom={true}
                onPageChange={changePage => {
                  setPageNumber(changePage);
                }}
                onPageSizeChange={changePageSize => {
                  setPageSizeValue(changePageSize);
                  setPageNumber(0);
                }}
              />
            </Tile>
          </GridItem>
        </Grid>
      )}
      <Snackbar
        open={isShowSuccessSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleCloseSuccessSnackbar} severity="success" variant="filled">
          Success! The opportunity has been duplicated!.
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetails);
