import React, { useState } from "react";
import { Box, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import { FormInput, MultiSelect } from "../form";
import { SelectOption } from "../../global";

type BaseOption = { [key: string]: { label: string; values: SelectOption[] } };

interface Props<Options> {
  options: Options;
  onSearchChange: (filter: "search", nextValue: string) => void;
  onOptionsChange: (filter: keyof Options, nextValue: string[]) => void;
}

export const TableFiltersPanel = <Options extends BaseOption>({
  onSearchChange,
  onOptionsChange,
  options,
}: Props<Options>) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const [optionsValue, setOptionsValue] = useState<Partial<Record<keyof Options, string[]>>>(() => {
    return Object.keys(options).reduce<Partial<Record<keyof Options, string[]>>>((acc, key) => {
      const typedKey = key as keyof Options;

      acc[typedKey] = ["all"];
      return acc;
    }, {});
  });

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <FormInput
        label="Search"
        placeholder="Enter value..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
        value={searchValue}
        onChange={value => {
          setSearchValue(value);
          onSearchChange("search", value);
        }}
      />

      {!!options &&
        Object.entries(options).map(([key, { values, label }]) => (
          <MultiSelect
            key={key}
            label={label}
            options={values}
            value={optionsValue?.[key] ?? []}
            onChange={selected => {
              setOptionsValue(prev => ({ ...prev, [key]: selected }));
              onOptionsChange(key, selected);
            }}
          />
        ))}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
