import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import avatar from "../assets/img/default-avatar.png";
import { makeStyles } from "@material-ui/core/styles";
import { formatPhoneNumber } from "../utils";
import { primaryColor } from "../assets/jss/material-dashboard-pro-react";

const config = require("config");

const GET_COMPANY_PROFILE = gql`
  query {
    vendorProfile {
      showPrequal
      showPqt
      id
    }
    accountRep {
      fullName
      email
      phoneNumber
      mugshot
    }
  }
`;

const useStyles = makeStyles({
  accountRepBlock: {
    padding: "20px 0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    height: "99%",
  },
  accountRepImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  accountRepFullName: {},
  accountRepPhone: {
    fontSize: "18px",
    fontWeight: "600",
  },
  avatarImg: {
    width: "100%",
    verticalAlign: "middle",
    border: "0",
  },
  accountRepEmail: {
    fontSize: "14px",
    fontWeight: "600",
    "& a": {
      color: primaryColor[0],
    },
  },
});

const queryContext = { authRequired: true };

export default function CompanyProfileSidebarBlock() {
  const classes = useStyles();
  return (
    <Query context={queryContext} query={GET_COMPANY_PROFILE}>
      {({ loading, error, data }) => {
        if (loading) {
          return "Loading";
        }
        const accountRep = data && data.accountRep;
        return (
          <div className={classes.accountRepBlock}>
            {accountRep ? (
              <React.Fragment>
                <div className={classes.accountRepImage}>
                  {accountRep.mugshot ? (
                    <img
                      src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${accountRep.mugshot}`}
                      className={classes.avatarImg}
                      alt="..."
                    />
                  ) : (
                    <img src={avatar} className={classes.avatarImg} alt="..." />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "-10px",
                  }}
                >
                  Need Help?
                </div>
                <div className={classes.accountRepFullName}>
                  <h3>{accountRep.fullName}</h3>
                </div>
                <div className={classes.accountRepPhone}>{formatPhoneNumber(accountRep.phoneNumber)}</div>
                <div className={classes.accountRepEmail}>
                  <a href={"mailto:" + accountRep.email} target="_blank" rel="noopener noreferrer">
                    {accountRep.email}
                  </a>
                </div>
              </React.Fragment>
            ) : (
              <div />
            )}
          </div>
        );
      }}
    </Query>
  );
}
