import { SET_ACCOUNT } from "./types";

const initialState = {};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
