import React, { SyntheticEvent } from "react";
import ReactTable, { RowInfo } from "react-table";
import { TasksTableFilters } from "../../types";
import { createTableColumns } from "./tableConfig";
import { Task, UserProfile } from "@trnsact/trnsact-shared-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props {
  filteredTasks: Task[];
  tasksQueryKey: string;
  userProfile: UserProfile;
  isTableDataLoading: boolean;
  fetchTasks: () => Promise<any>;
  tableFilters: TasksTableFilters;
  onOpenCreateTaskModel: (taskId: string) => void;
}

export const TaskTable = ({
  fetchTasks,
  userProfile,
  filteredTasks,
  tasksQueryKey,
  isTableDataLoading,
  onOpenCreateTaskModel,
}: Props) => {
  const classes = useStyles();

  const columns = createTableColumns({
    fetchTasks,
    userProfile,
    tasksQueryKey,
    onOpenCreateTaskModel,
  });

  const handleRowClick = (event: SyntheticEvent, taskId: string) => {
    event.preventDefault();
    event.stopPropagation();
    onOpenCreateTaskModel(taskId);
  };

  return (
    <ReactTable<Task>
      getTrProps={(_: any, rowInfo: RowInfo | undefined) => ({
        style: { cursor: "pointer" },
        onClick: (event: SyntheticEvent) => {
          if (rowInfo) handleRowClick(event, rowInfo.original.taskId!);
        },
      })}
      columns={columns}
      defaultPageSize={5}
      data={filteredTasks}
      showPaginationBottom
      loading={isTableDataLoading}
      loadingText={"Loading tasks"}
      className={clsx("-highlight", classes.table)}
      noDataText={isTableDataLoading ? "" : "There are no tasks"}
    />
  );
};

const useStyles = makeStyles({
  table: {
    "& .rt-thead": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },

    "& .rt-tr": {
      margin: 0,
      minHeight: "4rem",
    },

    "& .rt-td": {
      margin: 0,
    },
  },
});
