import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@material-ui/core";
import Button from "./CustomButtons/Button";

const styles = {
  actionButton: {
    width: "100px",
  },
};

const useStyles = makeStyles(styles);

export default function ConfirmationDialog({
  title,
  message,
  messageLayout,
  onClose,
  open,
  loading,
  okButtonText = "Ok",
  cancelButtonText = "Cancel",
  titleCentered,
  buttonsCentered,
}) {
  const classes = useStyles();

  const handleCancel = () => {
    onClose({ result: "cancel" });
  };

  const handleOk = () => {
    onClose({ result: "ok" });
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="sm">
      {title && (
        <DialogTitle onClose={onClose} style={titleCentered ? { textAlign: "center" } : {}}>
          {title}
        </DialogTitle>
      )}

      <DialogContent>
        {message && <Typography>{message}</Typography>}
        {messageLayout && React.createElement(messageLayout, {})}
      </DialogContent>

      <DialogActions style={buttonsCentered ? { justifyContent: "center" } : {}}>
        {!loading && (
          <>
            <Button onClick={handleCancel} className={classes.actionButton}>
              {cancelButtonText}
            </Button>
            <Button onClick={handleOk} color="primary" className={classes.actionButton}>
              {okButtonText}
            </Button>
          </>
        )}
        {loading && <LinearProgress style={{ width: "100%" }} />}
      </DialogActions>
    </Dialog>
  );
}
