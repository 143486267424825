import { gql } from "apollo-boost";

// Requests:
// - Get submissions request
export const GET_CS_LIST = gql`
  query CreditSubmissions {
    creditSubmissions {
      id
      applicant
      amountRequested
      creditSubId
      stage
      submissionDate
      decision
      dealer
      contact
      approvedAmount
      dealerName
      contactName
      applicantName
      archiveStatusId
      lenderDisplayNotify
    }
  }
`;

export const UPDATE_CS = gql`
  mutation($input: UpsertCreditSubmissionInput!) {
    upsertCreditSubmission(input: $input) {
      id
    }
  }
`;

export const SUBMISSION_DOCS = gql`
  query($creditSubId: ID, $VOId: ID) {
    creditSubmissionDocuments(creditSubId: $creditSubId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
    }
  }
`;

export const STATUS_TYPES = {
  ARCHIVED_DUPLICATE_201: "ARCHIVED_DUPLICATE_201",
  ARCHIVED_TESTING_200: "ARCHIVED_TESTING_200",
  ARCHIVED_OTHER_202: "ARCHIVED_OTHER_202",
  ACTIVE_100: "ACTIVE_100",
  UNARCHIVED: "UNARCHIVED",
};

// Helper
export const getStatus = status => {
  switch (status) {
    case STATUS_TYPES.ACTIVE_100:
      return { label: "Active", menuLabel: "Active" };
    case STATUS_TYPES.ARCHIVED_TESTING_200:
      return { label: "Archive, Testing", menuLabel: "Testing" };
    case STATUS_TYPES.ARCHIVED_DUPLICATE_201:
      return { label: "Archive, Duplicate", menuLabel: "Duplicate" };
    case STATUS_TYPES.ARCHIVED_OTHER_202:
      return { label: "Archive, Other", menuLabel: "Other" };
    case STATUS_TYPES.UNARCHIVED:
      return { label: "Unarchive", menuLabel: "Unarchive" };
    default:
      return "";
  }
};

// Options
const status = [
  {
    title: "Duplicate",
    value: STATUS_TYPES.ARCHIVED_DUPLICATE_201,
  },
  {
    title: "Testing",
    value: STATUS_TYPES.ARCHIVED_TESTING_200,
  },
  {
    title: "Other",
    value: STATUS_TYPES.ARCHIVED_OTHER_202,
  },
  {
    title: "Active",
    value: STATUS_TYPES.ACTIVE_100,
  },
];

const decision = ["Awaiting Review", "Requesting Additional Information", "Declined", "Pre-Approved", "Approved"];
const stage = [
  "Awaiting for transmission",
  "Draft Application Created",
  "Submitted to Lender",
  "Lender Review",
  "Lender - Additional Info Requested",
  "Lender Approved",
  "Lender Declined",
  "Dealer Accepted",
  "Dealer Cancelled",
  "Docs Out",
  "Docs In",
  "Funding Review",
  "Funding Items Requested",
  "Customer Cancelled",
  " Lender Pre-Approved",
];

export const OPTIONS = {
  status,
  decision,
  stage,
};
