import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import CreditAppDetailModalContainer from "./CreditAppDetailModal/CreditAppDetailModalContainer";
import AccessControlContainer from "./AccessControl/AccessControlContainer";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import { vendorContactRole as vendorContactRoles } from "../../utils";
import { adminRoles } from "../../pages/Prequal/constants";
import _ from "lodash";

const styles = {
  toggleButtonGroup: {
    width: "100%",
    marginBottom: "20px",
  },
  toggleButton: {
    width: "50%",
    "&.Mui-selected": {
      backgroundColor: "#5a78e1",
      color: "white",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#4360c7",
    },
  },
};

let tabsSettings = [
  {
    key: "cadm",
    label: "CADM",
    component: CreditAppDetailModalContainer,
  },
  {
    key: "accessControl",
    label: "Access Control Settings",
    component: AccessControlContainer,
  },
];

const useStyles = makeStyles(styles);

const PortalConfigurationWizard = props => {
  const classes = useStyles();
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);
  tabsSettings = isLenderUser ? _.reject(tabsSettings, { key: "accessControl" }) : tabsSettings;
  const userProfile = useSelector(state => state.userProfile);
  const [activeTab, setAсtiveTab] = useState(_.first(tabsSettings));

  const adminRolesToSeePCW = [adminRoles.super];
  const userRolesToSeePCW = [vendorContactRoles.admin];
  const isUserAbleToSeePCW =
    isLenderUser ||
    _.includes(adminRolesToSeePCW, userProfile.adminRole) ||
    _.includes(userRolesToSeePCW, userProfile.vendorContactRole);

  const handleActiveTabToggle = (event, newValue) => {
    if (newValue !== null) {
      setAсtiveTab(newValue);
    }
  };

  return (
    <>
      {isUserAbleToSeePCW ? (
        <>
          <ToggleButtonGroup
            value={activeTab}
            exclusive
            onChange={handleActiveTabToggle}
            className={classes.toggleButtonGroup}
          >
            {tabsSettings.map(tabSetting => (
              <ToggleButton
                key={tabSetting.key}
                value={tabSetting}
                aria-label={tabSetting.key}
                className={classes.toggleButton}
              >
                {tabSetting.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {React.createElement(activeTab.component, {})}
        </>
      ) : (
        <h3>Access Denied</h3>
      )}
    </>
  );
};

export default PortalConfigurationWizard;
