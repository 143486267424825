import React from "react";
import { MenuItem, Select, SelectProps } from "@material-ui/core";

interface Props extends SelectProps {
  onValueChange?: (nextValue: any) => void;
  options: { label: string; value: string }[];
}

export const FormSelect = ({ label, onValueChange, options, ...other }: Props) => {
  return (
    <Select
      label={label}
      variant="outlined"
      labelId="formSelect"
      onChange={(event, child) => {
        if (onValueChange) onValueChange(event.target.value as string);
        other?.onChange?.(event, child);
      }}
      {...other}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
