import { TasksTableFilters } from "../types";

const priorityColors: Record<string, string> = {
  urgent: "#bb3354",
  high: "#bb3354",
  medium: "#71589a",
  low: "#69a1bd",
  in_progress: "#808080",
  closed: "#808080",
  open: "#ffd180",
  specify: "#c4c4c4",
  add: "#3bc173",
  complete: "#3bc173",
  requested: "#7a7a7a",
  cancel: "#dd5040",
};

const prioritySelectOptions = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

const statusSelectOptions = [
  { label: "Open", value: "open" },
  { label: "Complete", value: "complete" },
  { label: "In Progress", value: "in_progress" },
  { label: "Cancel", value: "cancel" },
  { label: "Requested", value: "requested" },
];

const priorityFiltersOptions = [{ label: "Show all", value: "all" }, ...prioritySelectOptions];
const statusFiltersOptions = [{ label: "Show all", value: "all" }, ...statusSelectOptions];

const tasksTableFilters: TasksTableFilters = {
  search: "",
  status: ["all"],
  priority: ["all"],
};

export {
  priorityColors,
  prioritySelectOptions,
  statusSelectOptions,
  tasksTableFilters,
  priorityFiltersOptions,
  statusFiltersOptions,
};
