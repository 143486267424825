import { DocumentsTableFilters } from "../types";

export function filterTableDataByFiltersValues(data: any[], filters: DocumentsTableFilters) {
  const isFiltersEmpty = !Object.values(filters).filter(Boolean).length;

  if (isFiltersEmpty) return data;

  const filterAction: Record<keyof DocumentsTableFilters, (task: any) => boolean> = {
    type: doc => filters.type.includes("all") || filters.type.includes(doc?.docType),
    search: doc => doc?.name?.toLowerCase()?.includes(filters.search.toLowerCase()),
  };

  return data.filter(row => Object.values(filterAction).every(fn => fn(row)));
}
