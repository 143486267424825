import { gql } from "apollo-boost";

export const GET_CREDIT_SUBMISSION = gql`
  query($csId: ID!) {
    creditSubmission(dynamicsId: $csId) {
      creditSubmissionId
      applicant
      amountRequested
      businessName
      creditSubId
      stage
      submissionDate
      decision
      dealer
      contactUserProfileId
      contact
      transactionId
      contactName
      contactPhone
      pgTransactionId
      voId
      dynamicsVOId
      pgTransactionId
      dealerVendorProfile
      contactEmail
      invoiceTotal
      equipmentDescription
      equipmentApprovalStatus
      equipmentCondition
      equipmentCost
      freightLaborInstallation
      downPayment
      salesTaxOnInvoice
      dealerId
      lenderAccountId
      transactionSummary
      transactionSummaryBy
      dynamicsCreditApplicationId
      dynamicsApplicantId
      applicantAddress
      lenderDisplayNotify
      dynamicsLenderProfileId
      proposalV2Options
      lenderProfiles {
        lenderName
        showCustomApp
        draftApplicationsEnabled
        pdfTemplate
        submissionMethod
        lenderProfileId
        lenderAccountId
        lenderContactId
        lenderChannelCode
      }
      masterCreditSubmissionId
      syndicatedBy {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      masterLenderName
      approvedRate
      notes
      contingencies
      approvedAmount
      lenderName
      fastTrackStatus
      fastTrackResults {
        creditSubmissionId
        fastTrackId
        applicationStatus
        rate
        approvedBy
        outputs {
          key
          value
        }
        inputs {
          key
          value
        }
      }
    }
  }
`;

export const Q_CREDIT_APP = gql`
  query($VOId: ID!) {
    creditApplication(VOId: $VOId) {
      id
      dynamicsId
      amountRequested
      firstName
      lastName
      email
      businessName
      title
      phone
      bizPhone
      businessAddressCounty
      address
      city
      state
      stateOfIncorp
      postalCode
      equipmentDescription
      dba
      applicationType
      applyingFor
      creditLineAmountRequested
      creditLineStatus
      creditLineNotes
      industryType
      taxId
      createdDateTime
      ocaTemplateId
      ocaTemplate {
        vendorProfileId
        jsonDefinition
        name
      }
      location {
        locationId
        locationName
        category
      }
      creditApplicationOwner {
        ownerPgId
        email
        creditApplicationId
        firstName
        middleName
        lastName
        nameSuffix
        ownerPercentage
        prevCounty
        ssn
        title
        dateOfBirth
        address
        address2
        city
        state
        postalCode
        driversLicenseNum
        dlStateIssued
        ownerNumber
        homeType
        homePhone
        phone
        timeAtAddressYears
        timeAtAddressMonths
        netWorth
        grossMonthlyIncome
        monthlyHousingPayment
        employerName
        employerAddress
        employerAddress2
        employerCity
        employerState
        employerZip
        employerEmail
        employmentStatus
        workPhone
        timeAtJobYears
        timeAtJobMonths
        mobilePhone
        signature
        signatureTitle
        signatureDate
        dlUploaded
        usCitizen
        commercialDriverMonths
        commercialDriverYears
        residentStatus
        providingGuaranty
        maritalStatus
        companyDriverYears
        ownerOperatorYears
        countryOfResidence
        ownershipType
        firstTimeFinancing
        nearestRelativeFirstName
        nearestRelativeLastName
        nearestRelativePhone
        nearestRelativeCity
        nearestRelativeZip
        prevAddress
        prevAddress2
        prevCity
        prevState
        prevCounty
        prevPostalCode
        reasonForDeclining
        yearsWithCdl
        personalGuaranteeSignature
        personalGuaranteeSignatureDate
        addendum
        ipAddress
        personalGuarantor {
          personalGuarantorId
          personalGuarantorDynamicsId
          personalGuarantorName
          mostRecentCbrs {
            bureauType
            creditBureauType
            creditReportConsumerId
            auditLog
            humanReadableReportDocumentId
            ownerPgId
            scoreType
            ficoScore
            fields {
              key
              value
            }
            publicRecordCount
            pastAmountDue
            totalInquiryCount
            satisfactoryAccountsCount
            installmentBalance
            monthlyPayment
            inquiryCountLastSixMonths
            derogAccountsCount
            realEstateBalance
            realEstatePayment
            tradelineCount
            pastDerogAccountsCount
            collectionRecordsCount
            revolvingAvailPercent
            paidAccountsCount
            oldestTradeline
            bankruptcyRecordCount
            revolvingBalance
            revolvingTotalCredit
            errorDescription
            createdDateTime
            modifiedDateTime
          }
        }
      }
    }
  }
`;

export const QUERY_PORTAL_CONFIGURATION = gql`
  query($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

export const QUERY_ACCOUNT_BY_VP_ID = gql`
  query ocaTemplateAccount($vendorProfileId: ID!) {
    accountByVPId(vendorProfileId: $vendorProfileId) {
      name
      vendorProfileId
      accountType
    }
  }
`;

export const Q_EQUIPMENT = gql`
  query Equipments($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
      truckClass
      attachments
      wholesaleCost
      totalWholesaleCost
      retailPrice
      mileage
      assetTypeStr
      classType
      cabType
      term
      lenderProduct
      loanType
      hours
      type
      tradeInValue
      amountOwedOnTradeIn
      equipmentIdx
    }
  }
`;

export const C_GET_CONVERSATIONS_BY_VO = gql`
  query getConversationsForVendorOpportunity($vendorOpportunityId: ID!, $creditSubmissionId: ID) {
    getConversationsForVendorOpportunity(
      vendorOpportunityId: $vendorOpportunityId
      creditSubmissionId: $creditSubmissionId
    ) {
      sid
      vendorOpportunityId
      creditSubmissionId
      participants {
        sid
        userProfileId
        notifyByEmail
        notifyBySMS
        notifyByWhatsapp
        identity
        mobilePhoneNumber
        mugshot
        email
        isDealer
        isApplicant
        isLender
        conversationCreator
      }
      messages {
        participantSid
        userProfileId
        body
        author
        delivery {
          total
          read
          sent
          delivered
          failed
          undelivered
        }
      }
      task {
        regarding
      }
      topic
      createdAt
      taskId
    }
  }
`;

export const C_CREATE_CONVERSATION = gql`
  mutation CreateConversation($input: CreateConversationInput!) {
    createConversation(input: $input) {
      sid
      vendorOpportunityId
      creditSubmissionId
      taskId
      topic
      createdBy
      createdAt
      messages {
        body
      }
      participants {
        sid
        identity
        userProfileId
      }
    }
  }
`;
