import React from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  boxContainer: {
    maxHeight: "22px",
  },
  actionsContainer: {
    flexWrap: "nowrap",
  },
  icon: {
    cursor: "pointer",
    //marginLeft: "8px",
  },
  reportProblemIcon: {
    color: "#ec9c12",
  },
  importExportIcon: {
    color: "#5a78e1",
  },
  mergeTypeIcon: {
    color: "#5a78e1",
  },
  settingsIcon: {
    color: "#5a78e1",
  },
  deleteIcon: {
    color: "#c62626",
  },
  removeIcon: {
    color: "#ff0000cf",
  },
}));

export function ConfigurableItemActions({
  itemType,
  warningMessage,
  onEdit,
  onMove,
  onMerge,
  onDelete,
  onRemove,
  onRemoveCustomIcon,
}) {
  const classes = useStyles();

  const tooltipValues = {
    settings: _.startCase(itemType + " settings"),
    move: _.startCase("Move " + itemType),
    merge: _.startCase("Merge " + itemType),
    delete: _.startCase("Delete " + itemType),
    remove: _.startCase("Remove " + itemType),
  };

  return (
    <Box className={classes.boxContainer}>
      <Grid container spacing={1} className={classes.actionsContainer}>
        {warningMessage && (
          <Tooltip title={warningMessage} aria-label={warningMessage} placement="top">
            <Grid item xs>
              <ReportProblemOutlinedIcon className={`${classes.icon} ${classes.reportProblemIcon}`} />
            </Grid>
          </Tooltip>
        )}
        {onEdit && (
          <Tooltip title={tooltipValues.settings} aria-label={tooltipValues.settings} placement="top">
            <Grid item xs>
              <SettingsIcon className={`${classes.icon} ${classes.settingsIcon}`} onClick={onEdit} />
            </Grid>
          </Tooltip>
        )}
        {onMove && (
          <Tooltip title={tooltipValues.move} aria-label={tooltipValues.move} placement="top">
            <Grid item xs>
              <ImportExportIcon className={`${classes.icon} ${classes.importExportIcon}`} onClick={onMove} />
            </Grid>
          </Tooltip>
        )}
        {onMerge && (
          <Tooltip title={tooltipValues.merge} aria-label={tooltipValues.merge} placement="top">
            <Grid item xs>
              <MergeTypeIcon className={`${classes.icon} ${classes.mergeTypeIcon}`} onClick={onMerge} />
            </Grid>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title={tooltipValues.delete} aria-label={tooltipValues.delete} placement="top">
            <Grid item xs>
              <DeleteIcon className={`${classes.icon} ${classes.deleteIcon}`} onClick={onDelete} />
            </Grid>
          </Tooltip>
        )}
        {onRemove && (
          <Tooltip title={tooltipValues.remove} aria-label={tooltipValues.remove} placement="top">
            <Grid item xs>
              {onRemoveCustomIcon ? (
                React.createElement(onRemoveCustomIcon, {
                  className: `${classes.icon} ${classes.removeIcon}`,
                  onClick: onRemove,
                })
              ) : (
                <RemoveCircleOutlineIcon className={`${classes.icon} ${classes.removeIcon}`} onClick={onRemove} />
              )}
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </Box>
  );
}
