import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import TaskList from "components/TaskList";

const QUERY_TASKS = gql`
  query VendorOpportunityTask($VOId: ID!, $limit: Int) {
    vendorOpportunityTask(VOId: $VOId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      completedDateTime
      dueDateTime
      createdDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notifyByEmail
      notifyByText
      notifyAssignorOnReminder
      notificationContactIds
      lastReminderSent
      nextReminder
      assignedToApplicant
      assignedBySystem
      ownerPgId
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
    }
  }
`;

const QUERY_TASKS_BY_CS = gql`
  query VendorOpportunityTask($csId: ID!, $limit: Int) {
    vendorOpportunityTask(csId: $csId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      createdDateTime
      completedDateTime
      dueDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notificationContactIds
      notifyAssignorOnReminder
      notifyByEmail
      notifyByText
      lastReminderSent
      nextReminder
      assignedToApplicant
      assignedBySystem
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
    }
  }
`;

const QUERY_RELATED_USERS = gql`
  query($accountId: ID) {
    relatedUsers(accountId: $accountId) {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mobilePhoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
    }
  }
`;

export default function SpecificTasks({
  vo,
  account,
  userProfile,
  creditSubId,
  lenderContact,
  lenderProfile,
  refetchDocs,
  submissionsTable,
  conversations,
  refetchConversations,
  conversationsLoading,
  voId,
  onTasksLoaded,
  initCollapsed,
  classNameHeader,
  tileTitle,
  classNameButton,
  handleConversationCreation,
  documentsPortalConfiguration,
  voTasks,
  taskId,
  documents,
}) {
  const [relatedUsers, setRelatedUsers] = useState([]);

  const [
    getRelatedUsers,
    { loading: relatedUsersLoading, error: relatedUsersError, data: relatedUsersData },
  ] = useLazyQuery(QUERY_RELATED_USERS, {
    variables: {
      accountId: account.id,
    },
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  useEffect(() => {
    if (relatedUsersData !== null && relatedUsersData !== undefined && relatedUsersData.relatedUsers !== null) {
      setRelatedUsers(relatedUsersData.relatedUsers);
    } else {
      getRelatedUsers();
    }
  }, [getRelatedUsers, relatedUsersData]);

  if (relatedUsersLoading) console.log("Loading related users profiles...");
  if (relatedUsersError) console.log(`Error! ${relatedUsersError.message}`);
  if ((!!vo && !!vo.vendorOpportunityId) || creditSubId) {
    return (
      <TaskList
        vo={vo}
        taskId={taskId}
        account={account}
        voTasks={voTasks}
        queryTasksOptions={
          !creditSubId
            ? {
                variables: {
                  VOId: vo.vendorOpportunityId,
                  csId: null,
                  limit: 100,
                },
                context: { authRequired: true },
                fetchPolicy: "no-cache",
              }
            : {
                variables: {
                  VOId: null,
                  csId: creditSubId,
                  limit: 100,
                },
                context: { authRequired: true },
                fetchPolicy: "no-cache",
              }
        }
        queryTasks={!creditSubId ? QUERY_TASKS : QUERY_TASKS_BY_CS}
        userProfile={userProfile}
        creditSubId={creditSubId ? creditSubId : null}
        tasksQueryKey="vendorOpportunityTask"
        relatedUsers={!lenderContact ? relatedUsers : lenderContact}
        taskOnClickAction="editTask"
        addTaskFlag={true}
        refetchDocs={refetchDocs}
        onTasksLoaded={onTasksLoaded}
        conversations={conversations}
        refetchConversations={refetchConversations}
        conversationsLoading={conversationsLoading}
        submissionsTable={submissionsTable}
        voId={voId}
        initCollapsed={initCollapsed}
        classNameHeader={classNameHeader}
        tileTitle={tileTitle}
        classNameButton={classNameButton}
        handleConversationCreation={handleConversationCreation}
        documentsPortalConfiguration={documentsPortalConfiguration}
        lenderProfileDynamicsId={lenderProfile ? lenderProfile.lenderProfileId : null}
        documents={documents}
      ></TaskList>
    );
  } else return null;
}
