import React, { useEffect, useState, useContext, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ImageIcon from "@material-ui/icons/Image";
import SendIcon from "@material-ui/icons/Send";
import Grid from "@material-ui/core/Grid";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import _ from "lodash";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
  LENDER_WATERFALL_FACT_NAMES_STRING,
} from "@trnsact/types-lender-waterfall";
import Tile from "components/Tile/Tile";
import LaunchIcon from "@material-ui/icons/Launch";
import Link from "@material-ui/core/Link";
import FinanceProgramModal from "pages/FinanceProgramMgmt/FinaceProgramModal";

import { CommonDataContext } from "contexts/CommonDataContext";
import { useSelector } from "react-redux";
import { ProgramsPreScreen } from "pages/LenderWaterfall/components";
import { formatCurrency } from "../../utils";
import { LEGAL_STRUCTURE, FINANCING_TYPE } from "@trnsact/trnsact-shared-types";

const config = require("../../config");

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
    "& th": {
      fontWeight: "bold",
      backgroundColor: "#f5f5f5",
      textAlign: "left",
      borderBottom: "2px solid #ddd",
      fontSize: "14px",
    },
    "& td": {
      borderBottom: "1px solid #ddd",
      fontSize: "12px",
    },
  },
  chipPASS: {
    color: "#388E3C",
    backgroundColor: "#DCEDC8",
  },
  chipFAIL: {
    color: "#FF1744",
    backgroundColor: "#FEEBEE",
  },
  stepCell: {
    backgroundColor: "#E8EAF6",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    fontSize: "12px",
  },
  tableCell: {
    fontSize: "12px",
  },
  headerCell: {
    fontSize: "14px",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "12px",
  },
  button: {
    textTransform: "none",
    fontSize: "12px",
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "small",
  },
  expandButton: {
    padding: 0,
  },
  expandedRow: {
    backgroundColor: "#f9f9f9",
  },
  lenderName: {
    top: "1px",
    display: "inline-block",
  },
  conditionTrue: {
    color: "#388E3C",
  },
  conditionFalse: {
    color: "#FF1744",
  },
  conditionText: {
    marginLeft: theme.spacing(1),
    fontSize: "13px",
  },
  autoSubmittedLbl: {
    fontSize: "12px",
    color: "grey",
  },
  decisionLbl: {
    fontSize: "13px",
  },
  listStyleNone: {
    listStyleType: "none",
  },
}));

const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!) {
    financePrograms(accountId: $accountId) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

const GET_LENDER_WATERFALL_RESULTS = gql`
  query GetLenderWaterfallResults($vendorOpportunityId: ID!) {
    lenderWaterfallPrescreenResults(vendorOpportunityId: $vendorOpportunityId) {
      lenderWaterfallPrescreenCriteriaResultId
      result
      step
      financeProgramId
      lenderProfileId
      runtimeInput
      runtimeOutput
      vendorOpportunityId
      creditSubmissionId
      creditSubmissionAutoSubmitted
      createdDateTime
      modifiedDateTime
    }
  }
`;

const formatNames = (name: string) => {
  return name
    .replace(/([A-Z])/g, " $1")
    .replace(/And/g, "and")
    .trim();
};

const capitalize = (s: string) => {
  if (s) {
    if (s.length <= 4) return s.toUpperCase();
    return s
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase());
  } else return s;
};

const friendlyFactName = (fact: string): string => {
  return capitalize(fact);
};

const formatValue = (type: string, value: any): string => {
  try {
    if (value === null || value === undefined || value === "") {
      return "N/A";
    }

    if (typeof value === "boolean") {
      return value ? "true" : "false";
    }

    if (typeof value === "number") {
      if (value === 0) {
        return "0";
      }
      return value.toString();
    }

    if (type === "DEAL_SIZE") {
      return formatCurrency(value);
    }

    //This on is an array of strings with numbers
    if (type === "LEGAL_STRUCTURE") {
      const LEGAL_STRUCTURE_MAP: { [key: string]: string } = {};
      Object.keys(LEGAL_STRUCTURE).forEach(key => {
        const value = LEGAL_STRUCTURE[key as keyof typeof LEGAL_STRUCTURE];
        LEGAL_STRUCTURE_MAP[value] = key;
      });
      if (type === "LEGAL_STRUCTURE") {
        if (typeof value === "string") {
          return value
            .split(",")
            .map((v: any) => capitalize(LEGAL_STRUCTURE_MAP[v]))
            .join(", ");
        } else {
          return value.map((v: any) => LEGAL_STRUCTURE_MAP[v]);
        }
      }
    }

    if (type === "FINANCING_TYPE") {
      return FINANCING_TYPE[value as keyof typeof FINANCING_TYPE];
    }
  } catch (error) {
    console.log(error);
  }

  return value.toString();
};
const FormattedText: React.FC<{ condition: any }> = ({ condition }) => {
  try {
    const fact = friendlyFactName(condition.fact);
    let factResult = "N/A";
    if (condition.factResult) {
      factResult = formatValue(condition.fact, condition.factResult.toString());
    }
    const value = formatValue(condition.fact, condition.value.toString());

    switch (condition.operator) {
      case "greaterThanInclusive":
        return condition.result ? (
          <>
            <u>{fact}</u> is at least <b>{value}</b> (actual: <b>{factResult}</b>)
          </>
        ) : (
          <>
            Expected <u>{fact}</u> to be at least <b>{value}</b>, but it was <b>{factResult}</b>
          </>
        );
      case "lessThanInclusive":
        return condition.result ? (
          <>
            <u>{fact}</u> is at most <b>{value}</b> (actual: <b>{factResult}</b>)
          </>
        ) : (
          <>
            Expected <u>{fact}</u> to be at most <b>{value}</b>, but it was <b>{factResult}</b>
          </>
        );
      case "equal":
        return condition.result ? (
          <>
            <u>{fact}</u> is <b>{value}</b>
          </>
        ) : (
          <>
            It was not <u>{fact}</u>; expected <b>{value}</b> but got <b>{factResult}</b>
          </>
        );
      case "in":
        return condition.result ? (
          <>
            <u>{fact}</u> is included on <b>{value}</b>
          </>
        ) : (
          <>
            <u>{fact}</u> expected to be <b>{value}</b>, but got <b>{factResult}</b>
          </>
        );
      default:
        return (
          <>
            <u>{fact}</u> <b>{factResult}</b> {condition.result ? "meets" : "does not meet"} the condition{" "}
            <i>{condition.operator}</i> [<b>{value}</b>]
          </>
        );
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const LenderWaterfallResults = ({
  accountId,
  vendorOpportunityId,
  creditSubmissions,
  handleSubmitToLenderByProfileId,
  lenderProfiles,
}: {
  accountId: string;
  vendorOpportunityId: string;
  creditSubmissions: any;
  handleSubmitToLenderByProfileId: any;
  lenderProfiles: any;
}) => {
  const classes = useStyles();
  const { isLenderUser } = useContext(CommonDataContext);
  //@ts-ignore
  const userProfile = useSelector(state => state.userProfile);
  //@ts-ignore
  const account = useSelector(state => state.account);

  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [openModal, setOpenModal] = useState(false);
  const [createProgram, setCreateProgram] = useState(false);
  const [financeProgram, setFinanceProgram] = useState(null);
  const [targetLenderProfileId, setTargetLenderProfileId] = useState(null);
  const [dealerVendorProfiles, setDealerVendorProfiles] = useState(null);
  const [financePrograms, setFinancePrograms] = useState<any>([]);
  const [waterfallResults, setWaterfallResults] = useState<any>([]);
  const [openRowKey, setOpenRowKey] = useState<string | null>(null);
  const [openRowMap, setOpenRowMap] = useState<Array<any>>([]);

  const {
    data: fpData,
    loading: loadingFinancePrograms,
    refetch: fetchFinancePrograms,
    error: errorFinancePrograms,
  } = useQuery(GET_FINANCE_PROGRAMS, {
    context: { authRequired: true },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: { accountId: accountId },
    onCompleted: data => {
      if (data.financePrograms) {
        setFinancePrograms(data.financePrograms);
      }
    },
  });

  const {
    data: waterfallData,
    loading: loadingLenderWaterfallResults,
    refetch: fetchWaterfall,
    error: errorLenderWaterfallResults,
  } = useQuery(GET_LENDER_WATERFALL_RESULTS, {
    context: { authRequired: true },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: { vendorOpportunityId },
    onCompleted: data => {
      if (data.lenderWaterfallPrescreenResults) {
        //filtering only the lender profiles that are available for submission
        let aggregatedResults = [];
        if (!_.isEmpty(data.lenderWaterfallPrescreenResults)) {
          aggregatedResults = data.lenderWaterfallPrescreenResults.filter(
            (result: any) => !result.lenderProfileId || _.find(lenderProfiles, { id: result.lenderProfileId })
          );
        }
        // Remove duplicates based on lenderProfileId and financeProgramId for each step

        aggregatedResults = aggregatedResults.reduce((acc: { [key: number]: any[] }, item: any) => {
          if (!acc[item.step]) {
            acc[item.step] = [];
          }
          acc[item.step].push(item);
          acc[item.step] = _.uniqBy(
            acc[item.step],
            (result: any) => `${result.lenderProfileId}-${result.financeProgramId}`
          );
          return acc;
        }, {});
        setWaterfallResults(aggregatedResults);
        let rowMap: any = [];
        _.forEach(aggregatedResults, (rows, step) => {
          _.forEach(rows, (row, rowIndex) => {
            const calcIndex = parseInt(step) - 1;
            const hasDetails = !_.isEmpty(_.get(row, "runtimeOutput.conditions.all", []));
            _.set(rowMap, `[${calcIndex}][${rowIndex}].opened`, false);
            _.set(rowMap, `[${calcIndex}][${rowIndex}].hasDetails`, hasDetails);
          });
        });
        setOpenRowMap(rowMap);
      }
    },
  });

  const handleRowClick = (stepIndex: number, rowIndex: number, rowKey: string) => {
    setOpenRowMap(prev => {
      const key = `[${stepIndex}][${rowIndex}].opened`;
      let rowMap: any[] = prev;
      _.set(rowMap, key, !_.get(prev, key));
      return rowMap;
    });
    setOpenRowKey(openRowKey === rowKey ? null : rowKey);
  };

  if (loadingFinancePrograms || loadingLenderWaterfallResults) {
    return <div>Loading...</div>;
  }

  if (errorFinancePrograms || errorLenderWaterfallResults) {
    return <div>Error loading data</div>;
  }

  const getRateSpecs = (row: any, key: "term" | "rate"): string => {
    const objecPath = "runtimeOutput.event.params.financeProgram.paymentOptionsConfiguration.terms";
    const value: string = _.get(_.last(_.get(row, objecPath, [])), key) || "";
    if (!value) {
      if (Array.isArray(_.get(row, objecPath))) {
        switch (key) {
          case "rate":
            return "N/A";
          case "term":
            return `${_.last(_.get(row, objecPath))}`;
        }
      }
    }

    return key == "rate" ? `${value}%` : value;
  };

  const countOpenedItems = (array: any) => {
    return _.map(array, subArray => _.filter(subArray, { opened: true }).length);
  };

  const friendlyCondition = (condition: any) => {
    return <FormattedText condition={condition} />;
  };

  const renderConditions = (conditions: []) =>
    conditions.map((condition: any, index: number) => (
      <ul>
        <li className={classes.listStyleNone}>
          <Grid container alignItems="center" key={index}>
            <Grid item>
              {condition.result ? (
                <CheckCircleIcon className={classes.conditionTrue} />
              ) : (
                <CancelIcon className={classes.conditionFalse} />
              )}
            </Grid>
            <Grid item className={classes.conditionText}>
              {friendlyCondition(condition)}
            </Grid>
          </Grid>
        </li>
      </ul>
    ));

  const getAction = (row: any) => {
    if (!row.creditSubmissionId) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {
                handleSubmitToLenderByProfileId(row.lenderProfileId);
              }}
            >
              <SendIcon className={classes.icon} />
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      const decisionLbl = _.get(
        _.find(creditSubmissions, { creditSubmissionId: row.creditSubmissionId }),
        "decision",
        ""
      );
      const wasAutoSubmitted = _.get(row, "creditSubmissionAutoSubmitted");
      return (
        <Grid container>
          {wasAutoSubmitted && (
            <Grid item xs={12} className={classes.autoSubmittedLbl}>
              Auto submitted
            </Grid>
          )}
          <Grid item xs={12} className={classes.decisionLbl}>
            {decisionLbl}
          </Grid>
        </Grid>
      );
    }
  };
  const waterfallResultsEntries: [string, any[]][] = Object.entries(waterfallResults);
  return (
    <Tile title={"Lender Waterfall Results"}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="lender waterfall results table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} style={{ backgroundColor: "#E8EAF6" }}>
                Step
              </TableCell>
              <TableCell className={classes.headerCell}>Result</TableCell>
              <TableCell className={classes.headerCell}>Actions</TableCell>
              <TableCell className={classes.headerCell}>Lender</TableCell>
              <TableCell className={classes.headerCell}>Program</TableCell>
              <TableCell className={classes.headerCell}>Max term (mos.)</TableCell>
              <TableCell className={classes.headerCell}>Interest Rate</TableCell>
              <TableCell className={classes.headerCell}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waterfallResultsEntries.map(([step, rows], stepIndex) => {
              return (
                <React.Fragment key={stepIndex}>
                  {rows.map((row: any, rowIndex: number) => {
                    const rowKey = `${stepIndex}-${rowIndex}`;
                    const isRowOpen = _.get(openRowMap, `[${stepIndex}][${rowIndex}].opened`, false);
                    const hasDetails = _.get(openRowMap, `[${stepIndex}][${rowIndex}].hasDetails`, false);
                    const rowSpan = countOpenedItems(openRowMap);

                    return (
                      <React.Fragment key={rowIndex}>
                        <TableRow>
                          {rowIndex === 0 && (
                            <TableCell className={classes.stepCell} rowSpan={_.size(rows) + rowSpan[stepIndex]}>
                              {row.step}
                            </TableCell>
                          )}
                          <TableCell className={classes.tableCell}>
                            <Grid container>
                              <Grid item xs={8}>
                                <Chip
                                  size="small"
                                  icon={
                                    row.result === "PASS" ? (
                                      <CheckCircleIcon style={{ color: "#388E3C" }} />
                                    ) : (
                                      <CancelIcon style={{ color: "#FF1744" }} />
                                    )
                                  }
                                  label={row.result}
                                  className={row.result === "PASS" ? classes.chipPASS : classes.chipFAIL}
                                />
                              </Grid>
                              {hasDetails && (
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => handleRowClick(stepIndex, rowIndex, rowKey)}
                                    className={classes.expandButton}
                                  >
                                    {isRowOpen ? (
                                      <RemoveCircleOutlineIcon color="primary" />
                                    ) : (
                                      <ControlPointIcon color="primary" />
                                    )}
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell className={classes.tableCell}>{getAction(row)}</TableCell>
                          <TableCell className={classes.tableCell}>
                            <Avatar
                              style={{ display: "inline-flex" }}
                              alt="img"
                              src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${_.get(
                                row,
                                "runtimeOutput.event.params.lenderProfile.logo"
                              )}`}
                            >
                              <ImageIcon textRendering={row.lender} />
                            </Avatar>
                            <span className={classes.lenderName}>
                              {_.get(row, "runtimeOutput.event.params.lenderProfile.name")}
                            </span>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {_.get(row, "runtimeOutput.event.params.financeProgram.nameInternal")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>{getRateSpecs(row, "term")} </TableCell>
                          <TableCell className={classes.tableCell}>
                            <Grid container>
                              <Grid item xs={12}>
                                {getRateSpecs(row, "rate")}
                              </Grid>
                              <Grid item xs={12}>
                                <Link
                                  component="button"
                                  style={{ fontSize: "12px" }}
                                  variant="button"
                                  onClick={() => {
                                    setTargetLenderProfileId(row.lenderProfileId);
                                    const targetFinanceProgram = _.find(financePrograms, {
                                      financeProgramId: row.financeProgramId,
                                    });
                                    if (targetFinanceProgram) {
                                      targetFinanceProgram.__typename = undefined;

                                      if (targetFinanceProgram.prescreenCriteria === null) {
                                        //no criteria linked, so remove this field to avoid error
                                        targetFinanceProgram.prescreenCriteria = undefined;
                                      } else {
                                        //set criteria ID to the prescreenCriteriaIdToLink field
                                        targetFinanceProgram.prescreenCriteriaIdToLink =
                                          targetFinanceProgram.prescreenCriteria.prescreenCriteriaId;
                                      }
                                      setFinanceProgram({
                                        ...targetFinanceProgram,
                                        lenderProfileIds: [row.lenderProfileId],
                                      });
                                      setOpenModal(true);
                                    }
                                  }}
                                >
                                  {_.find(financePrograms, {
                                    financeProgramId: row.financeProgramId,
                                  }) && (
                                    <>
                                      <LaunchIcon color="primary" fontSize="small" /> Program Price
                                    </>
                                  )}
                                </Link>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {_.get(row, "runtimeOutput.event.params.financeProgram.paymentOptionsConfiguration.type")}
                          </TableCell>
                        </TableRow>
                        {isRowOpen && hasDetails && (
                          <TableRow className={classes.expandedRow}>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                              <Collapse in={isRowOpen} appear={true} timeout="auto" unmountOnExit>
                                <div style={{ margin: 16 }}>
                                  {renderConditions(_.get(row, "runtimeOutput.conditions.all", []))}
                                </div>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ProgramsPreScreen
        open={openModal}
        onClose={() => {
          console.log("Fired onClose");
          setCreateProgram(false);
          setOpenModal(false);
        }}
        lenderProfiles={lenderProfiles}
        dealerVendorProfiles={dealerVendorProfiles}
        create={createProgram}
        refetchPrograms={() => {
          fetchFinancePrograms();
        }}
        financeProgram={financeProgram}
        isLenderUser={isLenderUser}
        targetLenderProfileId={targetLenderProfileId}
      />
    </Tile>
  );
};

export default LenderWaterfallResults;
