import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Snackbar,
} from "@material-ui/core";
import gql from "graphql-tag";
import { Grid, ListItemText, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  checkProposalRequiredFields,
  getDefaultFinanceProgram,
  isValidProgram,
  parseProposal,
} from "../../src/services/proposalsService";
import { formatCurrency, formatNumberWithDelimiter } from "../utils";
import { makeStyles } from "@material-ui/core/styles";
import Tile from "./Tile/Tile";
import FinanceProgramForm from "./FinanceProgramForm";
import ProposalOptionCard from "./ProposalOptionCard";
import { checkIfUserIsAbleToSeeProposalMenus } from "../../src/services/proposalsService";
import { theme } from "../theme";
import _ from "lodash";
import { Engine } from "json-rules-engine";
import {
  LENDER_WATERFALL_FACT_NAMES_BOOLEAN,
  LENDER_WATERFALL_FACT_NAMES_NUMERIC,
  LENDER_WATERFALL_FACT_NAMES_STRING,
} from "@trnsact/types-lender-waterfall";
import Alert from "@material-ui/lab/Alert";

let requiredToQuoteEngine = new Engine();

export const floatNumberRegexp = /^\$?([\d,.]*)?$/;

const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!) {
    financePrograms(accountId: $accountId) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

const GET_PROPOSAL_MENUS = gql`
  query {
    proposalMenus(input: {}) {
      proposalMenuId
      name
      description
      menuOptions {
        name
        ordinal
        description
        proposalMenuOptionId
        titleBgColor
        titleColor
        packages {
          name
          ordinal
          proposalPackageId
          products {
            cost
            retailCost
            title
            proposalProductId
            thirdPartyUrl
            accountIdCreatedBy
          }
        }
      }
    }
  }
`;

const CALCULATE_MULTIPLE_PAYMENTS = gql`
  query CalculateMultiplePayments($input: [CalculateMultiplePaymentsInput]) {
    calculateMultiplePayments(input: $input) {
      criteria {
        amountFinanced
        paymentPeriodTiming
        # rate
        residualPercentage
        salesTaxPercentage
        numberOfAdvancePayments
        downPaymentAmount
        flatFees {
          fee
          identifier
        }
        markups {
          identifier
          markupPercentage
        }
        ##
        menu {
          name
          description
          menuOptions {
            name
            payment {
              paymentAmountPerPeriod
              financeProfit
            }
            description
            titleColor
            titleBgColor
            packages {
              name
              # products
              ordinal
            }
            ordinal
          }
        }
      }
      paymentOptions {
        paymentAmountPerPeriod
        dealerMarkup
        financeProfit
        paymentAmountPerPeriodWithoutDealerMarkup
        rate
        term
        customerRate
        subsidyAmount
        subsidyPercentage
        menu {
          name
          proposalMenuId
          description
          menuOptions {
            name
            description
            payment {
              paymentAmountPerPeriod
              financeProfit
              dealerMarkup
              paymentAmountPerPeriodWithoutDealerMarkup
              rate
              term
              customerRate
              subsidyAmount
              subsidyPercentage
            }
            titleColor
            titleBgColor
            packages {
              name
              ordinal
              products {
                proposalProductId
                title
                retailCost
              }
            }
          }
        }
        term
      }
    }
  }
`;

const CREATE_PROPOSAL_V2 = gql`
  mutation CreateProposalV2($input: CreateProposalV2Input!) {
    createProposalV2(input: $input) {
      errorMessage
      id
      success
      vendorOpportunityId
    }
  }
`;

const useStyles = makeStyles(() => ({
  selectFinanceProgramFormControl: {
    width: "250px",
    margin: "0 auto 0 40px",
  },
  selectProposalMenuFormControl: {
    width: "250px",
    margin: "0 auto 0 40px",
  },
  card: {
    margin: "180px",
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
}));

function RequiredToQuoteInputs({ financePrograms, updateFinancePrograms }) {
  console.log("version bump 7/9/24");
  const classes = useStyles();

  const [facts, setFacts] = useState({});
  const [factsToCheck, setFactsToCheck] = useState([]);
  const [suggestedPrograms, setSuggestedPrograms] = useState([]);
  const [engineHasRun, setEngineHasRun] = useState(false);
  const [initialSetupOfEngineHasRun, setInitialSetupOfEngineHasRun] = useState(false);

  useEffect(() => {
    setSuggestedPrograms([]);
    if (facts) {
      if (
        _.every(facts, (value, key) => {
          return facts[key] === null || facts[key] === undefined;
        })
      ) {
        console.log("No facts to check");
        return;
      }
      const factEquipmentHours = requiredToQuoteEngine.getFact("EQUIPMENT_HOURS");
      console.log(`factEquipmentHours: ${JSON.stringify(factEquipmentHours)}`);

      const factEquipmentAgeYears = requiredToQuoteEngine.getFact("EQUIPMENT_AGE_YEARS");
      console.log(`factEquipmentAge: ${JSON.stringify(factEquipmentAgeYears)}`);

      const factEquipmentModel = requiredToQuoteEngine.getFact("EQUIPMENT_MODEL");
      console.log(`factEquipmentModel: ${JSON.stringify(factEquipmentModel)}`);

      requiredToQuoteEngine
        .run()
        .then(engineResults => {
          console.log(`engineResults: ${JSON.stringify(engineResults)}`);
          const engineSuggestedPrograms = [];
          let financeProgramsHaveBeenUpdated = false;
          const updatedFinanceProgramIds = [];
          let newFinancePrograms = [];
          engineResults.events.map(event => {
            console.log(`Event Type: ${event.type}`);
            console.log(`Event: ${JSON.stringify(event)}`);
            if (event.type === "SUGGESTED_PROGRAM_FOUND") {
              engineSuggestedPrograms.push(event.params.financeProgram);
            } else if (event.type === "OVERRIDE_PROGRAM") {
              financeProgramsHaveBeenUpdated = true;
              updatedFinanceProgramIds.push(event.params.financeProgramId);
              newFinancePrograms = financePrograms.map(fp => {
                if (fp.financeProgramId === event.params.financeProgramId) {
                  console.log("TTT +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-");
                  console.log(
                    `TTT: Overriding program: ${JSON.stringify(fp)} with ${JSON.stringify(event.params.financeProgram)}`
                  );
                  return { ...fp, paymentOptionsConfiguration: event.params.paymentOptionsConfiguration };
                }
                return fp;
              });
            }
          });
          if (financeProgramsHaveBeenUpdated) {
            updateFinancePrograms(newFinancePrograms, updatedFinanceProgramIds);
          }
          setSuggestedPrograms(engineSuggestedPrograms);
          setEngineHasRun(true);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [facts]);

  useEffect(() => {
    if (initialSetupOfEngineHasRun) {
      return;
    }
    requiredToQuoteEngine = new Engine();
    if (financePrograms) {
      const newFactsToCheck = [];
      financePrograms.forEach(fp => {
        if (!_.get(fp, "prescreenCriteria.jsonCriteria.formRules")) {
          return;
        }
        try {
          let ruleConditions = [];
          fp.prescreenCriteria.jsonCriteria.formRules.forEach(rule => {
            if (rule.requiredForQuote) {
              //  || rule.requiredToQuote requiredForQuote is the correct name, requiredToQuote is here for backwards compatibility
              if (
                Object.keys(LENDER_WATERFALL_FACT_NAMES_BOOLEAN).includes(rule.fact) &&
                !_.find(newFactsToCheck, { factKey: rule.fact })
              ) {
                newFactsToCheck.push({ factKey: rule.fact, type: "boolean" });
              } else if (
                Object.keys(LENDER_WATERFALL_FACT_NAMES_NUMERIC).includes(rule.fact) &&
                !_.find(newFactsToCheck, { factKey: rule.fact })
              ) {
                newFactsToCheck.push({ factKey: rule.fact, type: "numeric" });
              } else if (Object.keys(LENDER_WATERFALL_FACT_NAMES_STRING).includes(rule.fact)) {
                if (_.find(newFactsToCheck, { factKey: rule.fact })) {
                  const factToCheckIndex = newFactsToCheck.findIndex(fact => fact.factKey === rule.fact);
                  newFactsToCheck[factToCheckIndex].options = _.uniq(
                    newFactsToCheck[factToCheckIndex].options.concat(
                      _.isArray(rule.exactMatch) ? rule.exactMatch : [rule.exactMatch]
                    )
                  );
                } else {
                  newFactsToCheck.push({
                    factKey: rule.fact,
                    type: "string",
                    options: Array.isArray(rule.exactMatch) ? rule.exactMatch : [],
                  });
                }
              }
              ruleConditions.push(
                fp.prescreenCriteria.jsonCriteria.jsonRules.conditions.all.find(
                  condition => condition.fact === rule.fact
                )
              );
            }
          });
          console.log(`fp: ${JSON.stringify(fp)}`);
          if (ruleConditions.length > 0) {
            requiredToQuoteEngine.addRule({
              conditions: { all: ruleConditions },
              name: `Suggested Program Found: ${fp.name}`,
              event: {
                type: "SUGGESTED_PROGRAM_FOUND",
                params: {
                  financeProgram: fp,
                },
              },
            });
          }

          if (
            fp.prescreenCriteria.jsonCriteria.programOverrides &&
            fp.prescreenCriteria.jsonCriteria.programOverrides.length > 0
          ) {
            fp.prescreenCriteria.jsonCriteria.programOverrides.forEach(override => {
              console.log("IN OVERRIDE");
              console.log(`IN OVERRIDE: ${JSON.stringify(override)}`);
              if (_.get(override, "conditions.jsonRules.conditions", null)) {
                console.log(
                  `Adding override rule: ${JSON.stringify({
                    conditions: { all: override.conditions.jsonRules.conditions.all },
                    name: `${fp.name}: Override Triggered`,
                    event: {
                      type: "OVERRIDE_PROGRAM",
                      params: {
                        financeProgramId: fp.financeProgramId,
                        financeProgram: override.program,
                      },
                    },
                  })}`
                );
                requiredToQuoteEngine.addRule({
                  conditions: { all: override.conditions.jsonRules.conditions.all },
                  name: `${fp.name}: Override Triggered`,
                  event: {
                    type: "OVERRIDE_PROGRAM",
                    params: {
                      financeProgramId: fp.financeProgramId,
                      paymentOptionsConfiguration: override.paymentOptionsConfiguration,
                    },
                  },
                });
              }
            });
          }
        } catch (e) {
          console.log(e.message);
        }
      });

      setFactsToCheck(newFactsToCheck.concat([...factsToCheck]));
      setInitialSetupOfEngineHasRun(true);
    }
  }, [financePrograms]);

  if (factsToCheck.length === 0) {
    console.log("No facts to check");
    return <> </>;
  }

  return (
    <div style={{ paddingBottom: "16px", color: "black" }}>
      <Tile
        title={"Find Qualifying Programs"}
        subtitle={
          <div style={{ color: "black" }}>
            Answer the follow questions to find programs your applicant qualifies for
          </div>
        }
      >
        <div style={{ color: "black" }}>
          <Grid container spacing={1}>
            {factsToCheck.map(fact => {
              let factInput;

              switch (fact.type) {
                case "numeric":
                  factInput = (
                    <Grid item xs={2} key={fact.factKey}>
                      <TextField
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                          style: { width: "100%" },
                          required: true,
                        }}
                        label={fact.factKey === "DEAL_SIZE" ? "AMOUNT ($)" : fact.factKey.replace(/_/g, " ")}
                        variant="outlined"
                        value={formatNumberWithDelimiter(facts[fact.factKey])}
                        onChange={e => {
                          if (floatNumberRegexp.test(e.target.value)) {
                            const parsedValue = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
                            console.log(`adding fact: ${parsedValue}`);
                            requiredToQuoteEngine.addFact(fact.factKey, parsedValue);
                            setFacts({ ...facts, [fact.factKey]: parsedValue });
                          }
                        }}
                        margin="normal"
                      />
                    </Grid>
                  );
                  break;

                case "string":
                  factInput = (
                    <Grid item xs={2} key={fact.factKey}>
                      <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                        <InputLabel required={true} shrink={true} id={fact.factKey}>
                          {fact.factKey.replace(/_/g, " ")}
                        </InputLabel>
                        <Select
                          labelId={`${fact.factKey}-label`}
                          id={fact.factKey}
                          value={facts[fact.factKey]}
                          onChange={e => {
                            requiredToQuoteEngine.addFact(fact.factKey, e.target.value);
                            setFacts({ ...facts, [fact.factKey]: e.target.value });
                          }}
                          label={fact.factKey.replace(/_/g, " ")}
                          required={true}
                          style={{ height: 40 }}
                        >
                          <MenuItem key="none" value="None of These">
                            <ListItemText borderColor="black" primary="None of these / I don't Know" />
                          </MenuItem>
                          {_.orderBy(fact.options, null, "asc").map((option, index) => {
                            return (
                              <MenuItem key={index} value={option}>
                                <ListItemText primary={option} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  );
                  break;

                default:
                  break;
              }

              return factInput;
            })}
          </Grid>
        </div>

        {suggestedPrograms && suggestedPrograms.length > 0 && (
          <div>
            <h4>Qualifying Programs</h4>
            <ul>
              {suggestedPrograms.map(program => {
                return <li key={program.financeProgramId}>{program.nameInternal}</li>;
              })}
            </ul>
          </div>
        )}
        {engineHasRun && suggestedPrograms.length === 0 && (
          <div>
            <h4>No qualifying programs found</h4>
            <p>Based on the information provided, no programs were found, contact your lenders for more options</p>
          </div>
        )}
      </Tile>
    </div>
  );
}

export default function FinanceProgramCard({
  account,
  vp,
  initProgram,
  cardTitle,
  isVOView,
  vo,
  isCreditSubmissionView,
  cs,
  onProgramChange,
  quoteToolModeEnabled,
  quoteInfoComponent,
  submitCallback,
  externalLoadingState,
}) {
  const { loading: isFinanceProgramsDataLoading, data: financeProgramsData } = useQuery(GET_FINANCE_PROGRAMS, {
    fetchPolicy: "no-cache",
    variables: { accountId: account.id },
  });

  const { loading: isProposalMenusDataLoading, data: proposalMenusData } = useQuery(GET_PROPOSAL_MENUS, {
    fetchPolicy: "no-cache",
    variables: {},
  });

  const [
    calculateMultiplePayments,
    { loading: calculatedPaymentsLoading, data: calculatedPaymentsData },
  ] = useLazyQuery(CALCULATE_MULTIPLE_PAYMENTS, {
    context: { authRequired: true },
  });

  const [createProposalV2, { loading: createProposalLoading, data: propsalCreationResponse }] = useMutation(
    CREATE_PROPOSAL_V2,
    {
      context: { authRequired: true },
      onCompleted: data => {
        if (data.createProposalV2.success) {
          window.location.href = `/proposalDetail/${data.createProposalV2.id}`;
        }
      },
    }
  );

  const classes = useStyles();
  const [calculatedPaymentOptions, setCalculatedPaymentOptions] = useState();
  const [selectedFinanceProgram, setSelectedFinanceProgram] = useState("");
  const [selectedProposalMenu, setSelectedProposalMenu] = useState("");
  const [initialSelectedFinanceProgram, setInitialSelectedFinanceProgram] = useState("");
  const [initialSelectedProposalMenu, setInitialSelectedProposalMenu] = useState("");
  const [financePrograms, setFinancePrograms] = useState();
  const [proposalMenus, setProposalMenus] = useState();
  const [isNeedToSendEmailOnProposalCreation, setIsNeedToSendEmailOnProposalCreation] = useState(false);
  const [program, setProgram] = useState();
  const calculateMultiplePaymentsDebounced = useCallback(_.debounce(calculateMultiplePayments, 500), []);
  const [financeProgramsHaveBeenUpdated, setFinanceProgramsHaveBeenUpdated] = useState(false);
  const [isProgramOwner, setIsProgramOwner] = useState(true);

  const [requiredToQuoteEngineResults, setRequiredToQuoteEngineResults] = useState({
    qualifies: true,
    overridden: false,
    engineNeedsToRun: false,
    engineHasRun: false,
  }); // { qualifies: boolean, overridden: boolean, engineHasRun:  }

  const updateFinancePrograms = (newFinancePrograms, updatedFinanceProgramIds) => {
    setFinancePrograms(newFinancePrograms);
    // console.log(`||| selectedFinanceProgram: ${JSON.stringify(selectedFinanceProgram)}`)
    // console.log(`||| Updated programs: ${JSON.stringify(newFinancePrograms)}`);
    if (selectedFinanceProgram && updatedFinanceProgramIds.includes(selectedFinanceProgram.financeProgramId)) {
      setFinanceProgramsHaveBeenUpdated(true);
      setSelectedFinanceProgram(
        newFinancePrograms.find(fp => fp.financeProgramId === selectedFinanceProgram.financeProgramId)
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(financeProgramsData)) {
      setFinancePrograms(financeProgramsData.financePrograms);
    }
  }, [financeProgramsData]);

  useEffect(() => {
    if (!_.isEmpty(proposalMenusData)) {
      setProposalMenus(proposalMenusData.proposalMenus);
    }
  }, [proposalMenusData]);

  useEffect(() => {
    const getAmountFinancedValue = () => {
      if (program?.amountFinanced > 0) {
        return program.amountFinanced;
      }
      if (!_.isEmpty(initProgram)) {
        return initProgram.amountFinanced;
      }
      if (isVOView && vo) {
        return vo.invoiceTotal;
      }
      /*       if (isCreditSubmissionView && cs) {
              return cs.amountRequested;
            } */
      return null;
    };

    const amountFinanced = getAmountFinancedValue();

    let programToSet = {};

    if (selectedFinanceProgram !== "")  {
      programToSet = { amountFinanced, ...selectedFinanceProgram, ..._.cloneDeep(selectedFinanceProgram.paymentOptionsConfiguration) };
    } else if (initProgram) {
      programToSet = { amountFinanced,..._.cloneDeep(initProgram) };
    } else {
      programToSet = getDefaultFinanceProgram(isVOView, isCreditSubmissionView);
    }

    // const baseProgramToSet =
    //   _.get(selectedFinanceProgram, "paymentOptionsConfiguration") ||
    //   initProgram ||
    //   getDefaultFinanceProgram(isVOView, isCreditSubmissionView);

    // let programToSet = {
    //   ..._.cloneDeep(baseProgramToSet), // to not modify original nested objects (markups and fee)
    //   financeProgramId: selectedFinanceProgram.financeProgramId,
    //   amountFinanced,
    //   prescreenCriteria: { ..._.cloneDeep(selectedFinanceProgram.prescreenCriteria) },
    //   financeProgramModificationSettings: { ..._.cloneDeep(selectedFinanceProgram.financeProgramModificationSettings) },
    //   dealerMarkup: selectedFinanceProgram.dealerMarkup,
    //   customerRate: selectedFinanceProgram.customerRate,
    // };

    delete programToSet.paymentOptionsConfiguration;

    if (programToSet) {
      if (!programToSet.dealerMarkup) {
        programToSet.dealerMarkup = "";
      }
      if (!programToSet.customerRate) {
        programToSet.customerRate = "";
      }
      programToSet.rates = programToSet.terms.map(term => {
        return term.rate;
      });
      programToSet.terms = programToSet.terms.map(term => {
        return term.term;
      });
      delete programToSet.rate; // Important
      programToSet.buyRate = programToSet.rates[0];
    }

    if (selectedProposalMenu) {
      programToSet = {
        ..._.cloneDeep(programToSet),
        menu: selectedProposalMenu,
      };
    }

    setProgram(programToSet);
    setInitialSelectedFinanceProgram(programToSet);
  }, [selectedFinanceProgram, selectedProposalMenu]);

  useEffect(() => {
    if (_.isEmpty(program)) {
      return;
    }
    const parsedProposal = parseProposal(program);
    if (!checkProposalRequiredFields(parsedProposal)) {
      setCalculatedPaymentOptions([]);
      return;
    }
    calculateMultiplePaymentsDebounced({ variables: { input: parsedProposal } });
  }, [program, requiredToQuoteEngineResults]);

  useEffect(() => {
    if (calculatedPaymentsData) {
      try {
        if (calculatedPaymentsData.calculateMultiplePayments[0].criteria.menu) {
          let paymentOptions = [];
          calculatedPaymentsData.calculateMultiplePayments[0].paymentOptions.forEach(po => {
            const menuPaymentOptions = po.menu.menuOptions.map(mo => {
              return {
                paymentAmountPerPeriod: mo.payment.paymentAmountPerPeriod,
                financeProfit: mo.payment.financeProfit,
                term: po.term,
                rate: po.rate,
                menuName: calculatedPaymentsData.calculateMultiplePayments[0].criteria.menu.name,
                menuOptionName: mo.name,
                titleColor: mo.titleColor,
                titleBgColor: mo.titleBgColor,
                packages: mo.packages,
              };
            });
            paymentOptions = paymentOptions.concat(menuPaymentOptions);
          });
          setCalculatedPaymentOptions(paymentOptions);
        } else {
          setCalculatedPaymentOptions(calculatedPaymentsData.calculateMultiplePayments[0].paymentOptions);
        }
      } catch (e) {
        console.log(`Error calculating payments: ${e.message}`);
      }
    }
  }, [calculatedPaymentsData]);

  const onFinanceProgramValuesChange = values => {
    setProgram(values);
  };

  useEffect(() => {
    if (onProgramChange) {
      onProgramChange(program);
    }
  }, [program]);

  useEffect(() => {
    console.log(`requiredToQuoteEngineResults: ${JSON.stringify(requiredToQuoteEngineResults)}`);
  }, [requiredToQuoteEngineResults]);

  const handleCreateProposal = () => {
    const parsedProposal = parseProposal(program);
    createProposalV2({
      variables: {
        input: {
          paymentOptions: parsedProposal,
          vendorOpportunityId: isVOView && vo.vendorOpportunityId,
          sendProposal: isNeedToSendEmailOnProposalCreation,
          financeProgramId: selectedFinanceProgram.financeProgramId,
        },
      },
    });
  };

  return (
    <div>
      {/* <RequiredToQuoteInputs financePrograms={financePrograms} updateFinancePrograms={updateFinancePrograms} /> */}

      <Tile
        title={cardTitle || "Finance Program"}
        titleButtonComponent={
          <div style={{ margin: "0 auto 0 0" }}>
            <FormControl className={classes.selectFinanceProgramFormControl}>
              <InputLabel shrink id="select-finance-program-label">
                {quoteToolModeEnabled ? "Select finance program" : "Select existing or create new"}
              </InputLabel>
              <Select
                value={selectedFinanceProgram}
                displayEmpty
                labelId="select-finance-program-label"
                fullWidth
                onChange={e => {
                  setRequiredToQuoteEngineResults({
                    qualifies: true,
                    overridden: false,
                    engineNeedsToRun: false,
                    engineHasRun: false,
                  });
                  setSelectedFinanceProgram(e.target.value);
                  setIsProgramOwner(e.target.value.accountId === account.id);
                }}
              >
                <MenuItem key="none" value="">
                  {quoteToolModeEnabled ? "None" : "Create New"}
                </MenuItem>
                {_.some(financePrograms) &&
                  financePrograms
                    .filter(program => isValidProgram(program))
                    .map(financeProgram => (
                      <MenuItem key={financeProgram.financeProgramId} value={financeProgram}>
                        {financeProgram.nameInternal}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            {checkIfUserIsAbleToSeeProposalMenus(vp) && (
              <span>
                <FormControl className={classes.selectProposalMenuFormControl}>
                  <InputLabel shrink id="select-proposal-menu-label">
                    Select F&I Menu
                  </InputLabel>
                  <Select
                    value={selectedProposalMenu}
                    displayEmpty
                    labelId="select-proposal-menu-label"
                    fullWidth
                    onChange={e => {
                      setSelectedProposalMenu(e.target.value);
                    }}
                  >
                    <MenuItem key="none" value="">
                      None
                    </MenuItem>
                    {_.some(proposalMenus) &&
                      proposalMenus.map(proposalMenu => (
                        <MenuItem key={proposalMenu.proposalMenuId} value={proposalMenu}>
                          {proposalMenu.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </span>
            )}
          </div>
        }
      >
        {isFinanceProgramsDataLoading || isProposalMenusDataLoading ? (
          <CircularProgress />
        ) : (
          <div>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FinanceProgramForm
                  isVOView={isVOView}
                  isCreditSubmissionView={isCreditSubmissionView}
                  financeProgram={initialSelectedFinanceProgram}
                  onValuesChange={onFinanceProgramValuesChange}
                  quoteToolModeEnabled
                  requiredToQuoteEngineResults={requiredToQuoteEngineResults}
                  setRequiredToQuoteEngineResults={setRequiredToQuoteEngineResults}
                  isProgramOwner={isProgramOwner}
                />
              </Grid>
              {requiredToQuoteEngineResults.engineNeedsToRun && !requiredToQuoteEngineResults.engineHasRun && (
                <Grid item xs={8}>
                  <h3>Please fill out the inputs on the left</h3>
                </Grid>
              )}
              {requiredToQuoteEngineResults.engineHasRun && !requiredToQuoteEngineResults.qualifies && (
                <Grid item xs={8}>
                  <Alert severity="error">
                    <div style={{ fontSize: "22px" }}>Does not qualify</div>
                    <ul>
                      <li>Make sure the values you entered are correct.</li>
                      <li>Please contact your lender for more program options.</li>
                    </ul>
                  </Alert>
                </Grid>
              )}
              {requiredToQuoteEngineResults.qualifies && (
                <Grid item xs={8}>
                  {requiredToQuoteEngineResults.engineHasRun && (
                    <Alert severity="success" style={{ marginBottom: "12px" }}>
                      <div style={{ fontSize: "22px" }}>Qualifies</div>
                    </Alert>
                  )}
                  {_.some(calculatedPaymentOptions) && !calculatedPaymentsLoading && (
                    <div style={{ paddingLeft: 16 }}>
                      <div>
                        {calculatedPaymentOptions[0].financeProfit > 0 && (
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              Finance Profit: {formatCurrency(calculatedPaymentOptions[0].financeProfit)}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Dealer Markup: {calculatedPaymentOptions[0].dealerMarkup}%
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Customer Rate: {calculatedPaymentOptions[0].customerRate}%
                            </div>
                          </div>
                        )}
                        {calculatedPaymentOptions[0].subsidyAmount > 0 && (
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              Subsidy Amount: {formatCurrency(calculatedPaymentOptions[0].subsidyAmount)}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Subsidy Percentage: {calculatedPaymentOptions[0].subsidyPercentage}%
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Customer Rate: {calculatedPaymentOptions[0].customerRate}%
                            </div>
                          </div>
                        )}
                      </div>
                      <>
                        {calculatedPaymentOptions[0].menuName ? (
                          <>
                            {Object.entries(Object.groupBy(calculatedPaymentOptions, ({ term }) => term)).map(group => {
                              return (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginBottom: 16,
                                  }}
                                  key={group[0]} // Add key prop here
                                >
                                  <h5>
                                    <b>{group[0]} months</b>
                                  </h5>
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div
                                      style={{
                                        gap: 8,
                                        width: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      {group[1].map((po, i) => {
                                        return (
                                          <ProposalOptionCard
                                            key={`${po.term}-${po.paymentAmountPerPeriod}-${i}`}
                                            term={po.term}
                                            paymentAmountPerPeriod={po.paymentAmountPerPeriod}
                                            menuName={po.menuName}
                                            menuOptionName={po.menuOptionName}
                                            titleColor={po.titleColor}
                                            titleBgColor={po.titleBgColor}
                                            packages={po.packages}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div
                            style={{
                              marginLeft: 24,
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Grid container spacing={1}>
                              {calculatedPaymentOptions.map(po => {
                                return (
                                  <ProposalOptionCard
                                    key={`${po.term}-${po.paymentAmountPerPeriod}`}
                                    term={po.term}
                                    paymentAmountPerPeriod={po.paymentAmountPerPeriod}
                                    menuName={po.menuName}
                                    menuOptionName={po.menuOptionName}
                                    titleColor={po.titleColor}
                                    titleBgColor={po.titleBgColor}
                                    packages={po.packages}
                                  />
                                );
                              })}
                            </Grid>
                          </div>
                        )}
                      </>
                    </div>
                  )}
                </Grid>
              )}
              {quoteInfoComponent}
              {!(createProposalLoading || externalLoadingState) && isVOView && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: theme.palette.primary.main, color: "white", marginRight: "30px" }}
                    onClick={
                      quoteToolModeEnabled
                        ? () => {
                            submitCallback(program);
                          }
                        : handleCreateProposal
                    }
                    disabled={createProposalLoading || externalLoadingState}
                  >
                    {quoteToolModeEnabled ? "Create & Send Quote" : "Create Proposal"}
                  </Button>
                  {quoteToolModeEnabled ? null : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isNeedToSendEmailOnProposalCreation}
                          onChange={() => setIsNeedToSendEmailOnProposalCreation(!isNeedToSendEmailOnProposalCreation)}
                          color="primary"
                          disabled={createProposalLoading || externalLoadingState}
                        />
                      }
                      label={"Send proposal email"}
                      style={{ color: "rgba(0,0,0,0.85)", fontSize: 14 }}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                {(createProposalLoading || externalLoadingState) && <LinearProgress />}
              </Grid>
            </Grid>
          </div>
        )}

        <Snackbar
          open={financeProgramsHaveBeenUpdated}
          autoHideDuration={10000}
          onClose={(event, reason) => {
            setFinanceProgramsHaveBeenUpdated(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="info">
            <div style={{ fontSize: "22px" }}>
              Based on the qualifying data you entered, the program you selected has changed.
              <p>
                <b>Please review the new program details.</b>
              </p>
            </div>
          </Alert>
        </Snackbar>
      </Tile>
    </div>
  );
}
