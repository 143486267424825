import moment from "moment";
import React from "react";
import { Column } from "react-table";
import { Task, UserProfile } from "@trnsact/trnsact-shared-types";
import { Typography } from "@material-ui/core";
import { TableCell } from "../../../Table/TableCell";
import { PriorityCell, StatusCell, OptionCell } from "./cells";

interface Options {
  tasksQueryKey: string;
  userProfile: UserProfile;
  fetchTasks: () => Promise<any>;
  onOpenCreateTaskModel: (taskId: string) => void;
}

export function createTableColumns(options: Options): Array<Column<Task>> {
  const { fetchTasks, userProfile, tasksQueryKey, onOpenCreateTaskModel } = options;

  return [
    {
      filterable: false,
      Header: "Created on",
      accessor: "createdDateMilliseconds",
      headerStyle: { fontWeight: "500", padding: "0.5rem" },
      Cell: ({ value }) => (
        <TableCell>
          <Typography variant="body2">{value ? moment(value).format("ll") : ""}</Typography>
        </TableCell>
      ),
    },
    {
      filterable: false,
      Header: "Assigned to",
      accessor: "assignedToUser",
      headerStyle: { fontWeight: "500", padding: "0.5rem" },
      Cell: ({ value }) => (
        <TableCell>
          <Typography variant="body2">{value ?? "Trnsact"}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Regarding",
      accessor: "regarding",
      headerStyle: { fontWeight: "500", padding: "0.5rem" },
      Cell: ({ value }) => (
        <TableCell>
          <Typography variant="body2">{value ?? "Trnsact"}</Typography>
        </TableCell>
      ),
    },
    {
      resizable: false,
      Header: "Completed On",
      accessor: "completedDateTime",
      headerStyle: { fontWeight: "500", padding: "0.5rem" },
      Cell: ({ value }) => (
        <TableCell>
          <Typography variant="body2">{value ? moment(value).format("ll") : "Not completed"}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Status",
      filterable: false,
      accessor: "status",
      headerStyle: { fontWeight: "500", padding: "0.5rem" },
      Cell: ({ original: task }) => <StatusCell task={task} />,
    },
    {
      Header: "Priority",
      filterable: false,
      accessor: "priority",
      headerStyle: { fontWeight: "500", padding: "0.5rem" },
      Cell: ({ original: task }) => <PriorityCell task={task} />,
    },
    {
      sortable: false,
      Header: "Option",
      filterable: false,
      headerStyle: { fontWeight: "500", padding: "0.5rem" },
      show: tasksQueryKey === "userRelatedTasks",
      Cell: ({ original: task }) => (
        <OptionCell
          task={task}
          fetchTasks={fetchTasks}
          onOpenCreateTaskModel={onOpenCreateTaskModel}
          isMyTask={userProfile.id === task.assignedToUserProfileId}
        />
      ),
    },
  ];
}
