import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Grid from "@material-ui/core/Grid";
import StepLabel from "@material-ui/core/StepLabel";
import CheckIcon from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DoneIcon from "@material-ui/icons/Done";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import VOStatusModifier from "./VOStatusModifier";
import { connect } from "react-redux";
import _ from "lodash";
import { setRefetch } from "../../redux/actions";

const statusCode = {
  "100": "Prospect",
  "150": "Pre-Qualified",
  "200": "Approved",
  "300": "Funded",
  "400": "Cancelled",
  "410": "Dealer Cancelled",
  "420": "Customer Cancelled",
  "500": "Declined",
};
const statusStepWithoutCancel = ["Prospect", "Pre-Qualified", "Approved", "Funded"];
const statusStepWithCancel = ["Cancelled"];
const statusStepWithDecline = ["Declined"];
const useQontoStepIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      {completed ? <CheckIcon className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorLibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
    },
  },
  completed: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
    },
  },
  line: {
    height: 5,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}))(StepConnector);

const useColorLibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#eaeaf0",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.main} 100%)`,
  },
}));

const useColorLibStepIconWithCancelStyles = makeStyles({
  root: {
    backgroundColor: "#7f7f7f",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "#7f7f7f",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "#7f7f7f",
  },
});

const useColorLibStepIconWithDeclineStyles = makeStyles({
  root: {
    backgroundColor: "#7f7f7f",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: "#7f7f7f",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: "#7f7f7f",
  },
});

function ColorLibStepIcon(props) {
  const classes = useColorLibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AssignmentIndIcon />,
    2: <DoneIcon />,
    3: <ThumbUpIcon />,
    4: <LocalAtmIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
function ColorLibStepIconWithCancel(props) {
  const classes = useColorLibStepIconWithCancelStyles();
  const { active, completed } = props;

  const icons = {
    1: <ErrorOutlineIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorLibStepIconWithDecline(props) {
  const classes = useColorLibStepIconWithDeclineStyles();
  const { active, completed } = props;

  const icons = {
    1: <CancelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorLibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getSteps(isCancelled, isDeclined) {
  if (isCancelled) return statusStepWithCancel;
  if (isDeclined) return statusStepWithDecline;
  return statusStepWithoutCancel;
}

function ProgressStepper({ vo, userProfile, setRefetch }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [isCancelled, setIsCancelled] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [salesRep, setSalesRep] = useState("");
  const useStyles = makeStyles(theme => ({
    stepper: {
      border: 0,
      borderTopLeftRadius: "6px",
      borderBottomLeftRadius: matches ? "6px" : 0,
      borderTopRightRadius: matches ? 0 : "6px",
      borderBottomRightRadius: 0,
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    },
    modifier: {
      backgroundColor: "rgba(128, 128, 128, 0.12)",
      paddingTop: "auto",
      border: 0,
      borderTopRightRadius: matches ? "6px" : 0,
      borderBottomRightRadius: "6px",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: matches ? 0 : "6px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    },
  }));
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const steps = getSteps(isCancelled, isDeclined);

  const [customerName, setCustomerName] = useState("");

  const refetchVO = () => {
    setRefetch("vo", true);
  };

  useEffect(() => {
    if (!!vo && !!userProfile && Object.keys(userProfile).length && !!vo.dynamicsVOStatus) {
      setCustomerName(_.get(vo, "potentialCustomer.name", ""));
      const isCancelledValue =
        vo.dynamicsVOStatus === 400 || vo.dynamicsVOStatus === 410 || vo.dynamicsVOStatus === 420;
      setIsCancelled(isCancelledValue);
      const isDeclinedValue = vo.dynamicsVOStatus === 500;
      setIsDeclined(isDeclinedValue);
      setSalesRep(userProfile.fullName);
      if (isCancelledValue) {
        setActiveStep(statusStepWithCancel.indexOf(statusCode[vo.dynamicsVOStatus.toString()]) + 1);
      } else if (isDeclinedValue) {
        setActiveStep(statusStepWithDecline.indexOf(statusCode[vo.dynamicsVOStatus.toString()]) + 1);
      } else {
        setActiveStep(statusStepWithoutCancel.indexOf(statusCode[vo.dynamicsVOStatus.toString()]));
      }
    }
  }, [vo, userProfile]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <Stepper
            className={classes.stepper}
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorLibConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={
                    !isCancelled && !isDeclined
                      ? ColorLibStepIcon
                      : isDeclined
                      ? ColorLibStepIconWithDecline
                      : ColorLibStepIconWithCancel
                  }
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} className={classes.modifier} style={{ position: "relative" }}>
          <VOStatusModifier vo={vo} refetchVO={refetchVO} customerName={customerName} salesRep={salesRep} />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  userProfile: state.userProfile,
});

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressStepper);
