import React, { useEffect } from "react";
import cx from "classnames";
import { Redirect, Route, Switch } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { appRoutes, userRoutes } from "../routes.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { SET_CHAT_ENABLED } from "../redux/types";
import AlertChats from "../components/Chat/AlertChats";
import { useLocation } from "react-router-dom";
import { Constructor } from "../../../pages/DynamicOCA/Constructor";
import _ from "lodash";

let ps;

const useStyles = makeStyles(styles);

function constructRoutePath(r) {
  return r.path;
}

export default function Dashboard(props) {
  const { accountData, lenderProfile } = props;
  const { ...rest } = props;
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.userProfile);

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [promptMobilePhone, setPromptMobilePhone] = React.useState(false);
  const [image, setImage] = React.useState();
  const [color, setColor] = React.useState("blue");
  const [applicationRoutes, setApplicationRoutes] = React.useState(appRoutes);
  const [bgColor, setBgColor] = React.useState("black");
  const location = useLocation();

  const isLoadConstructor = window.location.pathname === "/constructor";

  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("../assets/img/trnsact-logo-dark.png"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
    });

  const mainPanel = React.createRef();
  useEffect(() => {
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  });

  React.useEffect(() => {
    if (!!lenderProfile && !!userProfile) {
      const chatEnabled =
        userProfile.dynamicsContactId == _.get(lenderProfile, "lenderProfile.dynamicsContactSubmission", false);

      dispatch({
        type: SET_CHAT_ENABLED,
        payload: {
          enabled: chatEnabled,
        },
      });

      const chatRoute = _.find(appRoutes, { id: "chat" });
      chatRoute.showInSidebarNav = chatEnabled;

      setApplicationRoutes(appRoutes);
    }
  }, [lenderProfile, userProfile]);

  React.useEffect(() => {
    if (userProfile) {
      //Once accountData arrive, prompt user for its mobile phone.
      if (userProfile.phoneNumber) {
        if (userProfile.mobilePhoneNumber == null) {
          setPromptMobilePhone(true);
        } else {
          setPromptMobilePhone(false);
        } //Once accountData arrive, prompt user for its mobile phone.
      }
    }
  }, [userProfile]);

  // functions for changing the states from components
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        setLogo(require("../assets/img/trnsact-logo-light.png"));
        break;
      default:
        setLogo(require("../assets/img/trnsact-logo-dark.png"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let selectedRoute = { name: "" };
    let activeRoute = selectedRoute.name;
    let exactSelected = false;
    const uri = window.location.pathname + window.location.search;
    routes.forEach(route => {
      if (route.collapse) {
        let collapseActiveRoute = getActiveRoute(route.views);
        if (collapseActiveRoute !== activeRoute) {
          return (selectedRoute = collapseActiveRoute);
        }
      } else {
        let constructedRoute = constructRoutePath(route);
        if (route.exact) {
          if (uri === constructedRoute) {
            exactSelected = true;
            selectedRoute = route;
          }
        } else {
          if (!exactSelected) {
            if (
              uri.indexOf(constructedRoute) > -1 ||
              uri.indexOf(constructedRoute.substr(0, constructedRoute.indexOf(":"))) > -1
            ) {
              selectedRoute = route;
            }
          }
        }
      }
    });
    return selectedRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/dashboard") {
        let path = constructRoutePath(prop);
        return prop.exact ? (
          <Route path={path} component={prop.component} key={key} exact />
        ) : (
          <Route path={path} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <AlertChats />

      {isLoadConstructor ? (
        <Constructor accountData={accountData} />
      ) : (
        <>
          <Sidebar
            appRoutes={applicationRoutes}
            userRoutes={userRoutes}
            logoText={"Trnsact Portal"}
            logo={logo}
            image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={color}
            bgColor={bgColor}
            miniActive={miniActive}
            {...rest}
          />
          <div className={mainPanelClasses} ref={mainPanel}>
            <AdminNavbar
              sidebarMinimize={sidebarMinimize.bind(this)}
              miniActive={miniActive}
              brandText={getActiveRoute([...appRoutes, ...userRoutes]).name}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
            {getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>{getRoutes([...appRoutes, ...userRoutes])}</Switch>
                </div>
              </div>
            ) : (
              <div className={classes.map}>
                <Switch>{getRoutes([...appRoutes, ...userRoutes])}</Switch>
              </div>
            )}
            {location.pathname === "/" ? <Redirect exact from="/" to="/cs" /> : null}

            {getRoute() ? <Footer fluid /> : null}
          </div>
        </>
      )}
    </div>
  );
}
