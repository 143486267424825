import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useDrag } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemText } from "@material-ui/core";
import { ConfigurableItemActions } from "../PortalConfigurationWizard/CreditAppDetailModal/ConfigurableItemActions";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "components/ConfirmationDialog";
import { proposalMenuDraggableTypes } from "../../../src/pages/Prequal/constants";
import _ from "lodash";

const UPDATE_PRODUCT_PACKAGE = gql`
  mutation($input: UpdateProposalProductPackageInput!) {
    updateProposalProductPackage(input: $input) {
      proposalPackageId
    }
  }
`;

const styles = {
  listItem: {
    cursor: "move",
  },
  listItemText: {
    marginLeft: 6,
  },
};

const useStyles = makeStyles(styles);

const DraggableProductPackageItem = ({
  productPackage,
  handleEditProductPackageClick,
  refetchProductPackages,
  refetchProposalMenu,
}) => {
  const classes = useStyles();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [updateProductPackage, { loading: updateLoading }] = useMutation(UPDATE_PRODUCT_PACKAGE, {
    context: { authRequired: true },
    onCompleted() {
      refetchProductPackages();
      refetchProposalMenu(); //to update packages on menu option (remove archived)
    },
  });

  const handleRemoveProductPackageClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      updateProductPackage({
        variables: {
          input: {
            proposalProductPackageId: productPackage.proposalPackageId,
            archived: true,
          },
        },
      });
    }
  };

  const [{ isDragging, handlerId, canDrag }, drag] = useDrag(() => ({
    type: proposalMenuDraggableTypes.proposalProductPackage,
    item: productPackage,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
      canDrag: monitor.canDrag(),
    }),
  }));

  return (
    <>
      <ListItem button ref={drag} data-handler-id={handlerId} className={classes.listItem}>
        <DragHandleIcon />
        <ListItemText primary={productPackage.name} className={classes.listItemText} />
        <ConfigurableItemActions
          itemType="Product package"
          onEdit={() => handleEditProductPackageClick(productPackage)}
          onRemove={handleRemoveProductPackageClick}
          onRemoveCustomIcon={DeleteIcon}
        />
      </ListItem>

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove Product Package?"
        open={isConfirmationModalOpen}
        onClose={onConfiramtionModalClose}
      />
    </>
  );
};

export default DraggableProductPackageItem;
