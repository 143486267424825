import React from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";

const CREATE_PROPOSAL_MENU = gql`
  mutation($input: CreateProposalMenuInput) {
    createProposalMenu(input: $input) {
      proposalMenuId
    }
  }
`;

const UPDATE_PROPOSAL_MENU = gql`
  mutation($input: UpdateProposalMenuInput) {
    updateProposalMenu(input: $input) {
      proposalMenuId
    }
  }
`;

const styles = {};
const useStyles = makeStyles(styles);

const defaultOptions = [
  { name: "GOLD", titleBgColor: "#ffc900", titleColor: "#ffffff" },
  { name: "SILVER", titleBgColor: "#c0c0c0", titleColor: "#ffffff" },
  { name: "BRONZE", titleBgColor: "#cd7f32", titleColor: "#ffffff" },
  { name: "STANDARD", titleBgColor: "#333333", titleColor: "#ffffff" },
];

const CreateEditProposalMenuModal = ({ isOpen, setIsOpen, proposalMenu, setProposalMenu, refetchProposalMenus }) => {
  const classes = useStyles();

  const isEditing = proposalMenu?.proposalMenuId;

  const [createProposalMenu, { loading: createLoading }] = useMutation(CREATE_PROPOSAL_MENU, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const [updateProposalMenu, { loading: updateLoading }] = useMutation(UPDATE_PROPOSAL_MENU, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const onMutationCompleted = () => {
    handleClose();
    refetchProposalMenus();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    isEditing
      ? updateProposalMenu({
          variables: {
            input: {
              proposalMenuId: proposalMenu.proposalMenuId,
              name: proposalMenu.name,
              description: proposalMenu.description,
            },
          },
        })
      : createProposalMenu({
          variables: {
            input: {
              ...proposalMenu,
              newMenuOptions: defaultOptions,
            },
          },
        });
  };

  if (!proposalMenu) {
    return null;
  }
  const loading = createLoading || updateLoading;
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <DialogTitle id="form-dialog-title">{isEditing ? "Edit Menu" : "Create Menu"}</DialogTitle>
      {!loading && (
        <>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Name"
                  fullWidth
                  size="small"
                  value={proposalMenu.name}
                  onChange={e => setProposalMenu({ ...proposalMenu, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  fullWidth
                  size="small"
                  value={proposalMenu.description}
                  onChange={e => setProposalMenu({ ...proposalMenu, description: e.target.value })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} color="primary" disabled={!proposalMenu.name}>
              Save
            </Button>
          </DialogActions>
        </>
      )}
      {loading && <LinearProgress style={{ margin: 10 }} />}
    </Dialog>
  );
};

export default CreateEditProposalMenuModal;
