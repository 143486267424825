import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import VOStageModifier from "./VOTransactionStage";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "0px 0px 0px 16px",
    minWidth: 120,
  },
  mainContainer: {
    padding: 24,
    "@media (min-width: 1280px)": {
      position: "absolute",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      padding: "0 24px",
    },
  },
}));

const UPDATE_DYNAMICS_VO_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const UPDATE_VO_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const statusCode = {
  "100": "prospect",
  "150": "pre_qualified",
  "200": "approved",
  "300": "funded",
  "400": "cancelled",
  "500": "declined",
};

// const stageCode = {
//   '100': 'Lead',
//   '105': 'Application Processing',
//   '107': 'Application Complete',
//   '110': 'Credit Processing',
//   '115': 'Doc Generation',
//   '120': 'Doc Outstanding',
//   '125': 'Funding Review',
//   '130': 'Booked',
//   '150': 'Declined',
//   '155': 'Lost Sale',
//   '160': 'Deferred '
// }

export default function VOStatusModifier({ vo, refetchVO, customerName, salesRep }) {
  const classes = useStyles();
  const [status, setStatus] = React.useState(vo.dynamicsVOStatus || "");
  const [transactionStage, setTransactionStage] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [cancelledMenuOption, setCancelledMenuOption] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [updateDynamicsVOStatus] = useMutation(UPDATE_DYNAMICS_VO_STATUS_MUTATION, {
    context: { authRequired: true },
  });

  const [updateVOStatus] = useMutation(UPDATE_VO_STATUS_MUTATION, {
    context: { authRequired: true },
  });

  const handleChange = async event => {
    try {
      await setStatus(event.target.value);
      if (event.target.value === 400) {
        await handleOpen();
        setCancelledMenuOption(true);
      } else {
        await handleOpen();
        setCancelledMenuOption(false);
      }
      await updateDynamicsVOStatus({
        variables: {
          fieldsObj: {
            dynamicsVOStatus: event.target.value,
          },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await updateVOStatus({
        variables: {
          fieldsObj: {
            applicationStage: statusCode[event.target.value],
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      await refetchVO();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div>SET STAGE</div>
      <FormControl className={classes.formControl}>
        <Select
          value={status}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={100}>Prospect</MenuItem>
          <MenuItem value={150}>Pre-Qualified</MenuItem>
          <MenuItem value={200}>Approved</MenuItem>
          <MenuItem value={300}>Funded</MenuItem>
          <MenuItem value={400}>Cancelled</MenuItem>
          <MenuItem value={500}>Declined</MenuItem>
        </Select>
      </FormControl>
      <div style={{ marginTop: "10px" }}>
        <VOStageModifier
          vo={vo}
          refetchVO={refetchVO}
          customerName={customerName}
          salesRep={salesRep}
          cancelledMenuOption={cancelledMenuOption}
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={open}
          setOpen={setOpen}
        />
      </div>
    </div>
  );
}
