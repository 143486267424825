import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircle } from "@material-ui/icons";

interface Props {
  addTaskFlag: boolean;
  tileTitle: string | undefined;
  onOpenCreateTaskModel: VoidFunction;
}

export const TaskListSettingsPanel = ({ tileTitle, addTaskFlag, onOpenCreateTaskModel }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="h6">
        {tileTitle ?? "Tasks"}
      </Typography>

      {addTaskFlag && (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddCircle />}
          className={classes.addBtn}
          onClick={onOpenCreateTaskModel}
        >
          Add task
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addBtn: {
    textTransform: "uppercase",
  },
  title: {
    textTransform: "capitalize",
  },
});
