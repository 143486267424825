import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MessageModal from "../../components/MessageModal";
import TextField from "@material-ui/core/TextField";
import { useLazyQuery } from "@apollo/react-hooks";
import { calculateRatesAndPayments, residualPMT } from "../../paymentSelectorMath";
import TableHead from "@material-ui/core/TableHead";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { formatCurrency } from "../../utils";
import { connect } from "react-redux";
import CustomInput from "../../components/CustomInput/CustomInput";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import NumberFormat from "react-number-format";
import TableContainer from "@material-ui/core/TableContainer";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  formControlInputs: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControlRate: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 200,
  },
}));

const useRadioStyles = makeStyles(customCheckboxRadioSwitch);

const GENERATE_QUOTE = gql`
  query(
    $id: ID
    $tier: String
    $amount: Float
    $creditProducts: String
    $lenderProgramIds: [String]
    $hours: Int
    $equipmentType: String
  ) {
    quotePaymentCards(
      id: $id
      tier: $tier
      amount: $amount
      creditProducts: $creditProducts
      hours: $hours
      equipmentType: $equipmentType
      lenderProgramIds: $lenderProgramIds
    ) {
      rateFactor
      markupDealer
      markupInternal
      term
      productHeader
      advancePayment
      docFee
      downPaymentAmount
      rate
      paymentCardId
    }
  }
`;

const CREATE_PROPOSAL = gql`
  mutation($input: CreateProposalInput!) {
    createProposal(input: $input) {
      id
    }
  }
`;

const GET_AFTERMARKET_PRODUCTS = gql`
  query($accountId: ID) {
    aftermarketProducts(accountId: $accountId) {
      productId
      cost
      name
      active
      accountId
      associatedCredit_productIds
    }
  }
`;

function ResidualNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={"##"}
    />
  );
}
ResidualNumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function SalesTaxNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={"#.###"}
    />
  );
}

SalesTaxNumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatHours(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      decimalScale={0}
      fixedDecimalScale={true}
      allowLeadingZeros={false}
    />
  );
}

const CreateProposalModal = ({ userProfile, account, vp, vo, open, handleClose, refetchProposals }) => {
  console.log("Change bump");
  const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const classes = useStyles();
  const classesRadio = useRadioStyles();
  const [email, setEmail] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [amount, setAmount] = useState(null);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [amountError, setAmountError] = useState(false);
  const [amountErrorMessage, setAmountErrorMessage] = useState("Required");
  const [emailError, setEmailError] = useState(false);
  const [applicantNameError, setApplicantNameError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("Required");
  const [renderOptions, setRenderOptions] = useState(false);
  const tier = "A";
  const [quoteGenerated, setQuoteGenerated] = useState(false);
  const [generateQuote, { data }] = useLazyQuery(GENERATE_QUOTE);
  const [rate, setRate] = useState();
  const [creditProducts, setCreditProducts] = useState(
    !!vp.creditProducts && vp.creditProducts.length > 0 ? [vp.creditProducts] : null
  );
  const [hours, setHours] = useState("");
  const [equipmentType, setEquipmentType] = useState("");
  const [hoursInputRequired, setHoursInputRequired] = useState(false);
  const [equipmentTypeInputRequired, setEquipmentTypeInputRequired] = useState(false);
  const [selectedCreditProducts, setSelectedCreditProducts] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [saveOrSend, setSaveOrSend] = useState("save");
  const [selectedOption, setSelectedOption] = useState("base");
  const [term, setTerm] = useState(60);
  const [aftermarket, setAftermarket] = useState("");
  const [apr, setApr] = useState("");
  const [goodAftermarket, setGoodAftermarket] = useState({
    name: "Extended Warranty",
    cost: 0,
  });
  const [betterAftermarket, setBetterAftermarket] = useState({
    name: "Maintenance - 5yrs./150,000 miles",
    cost: 0,
  });
  const [bestAftermarket, setBestAftermarket] = useState({
    name: "GAP",
    cost: 0,
  });
  const [gbbOptions, setGbbOptions] = useState([{ trac: [{}, {}, {}, {}], finance: [{}, {}, {}, {}] }]);
  const [gbbCalculatedOptions, setGbbCalculatedOptions] = useState([]);
  const [residual, setResidual] = useState("");
  const [salesTax, setSalesTax] = useState("");
  const [loadingProposal, setLoadingProposal] = useState("");

  const handleChangedEmail = event => setEmail(event.target.value);
  const handleChangedBusinessName = event => setBusinessName(event.target.value);
  const handleChangedApplicantName = event => setApplicantName(event.target.value);
  const handleSaveOrSend = value => {
    if (saveOrSend === "save") {
      setSaveOrSend("send");
    } else {
      setSaveOrSend("save");
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    validateAmount(amount);
  };

  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);

  const handleHoursChange = e => {
    setHours(e.target.value);
  };

  const handleEquipmentTypeChange = e => {
    setEquipmentType(e.target.value);
  };

  useEffect(() => {
    let hoursRequired = false;
    let equipmentTypeRequired = false;
    selectedCreditProducts.forEach(scp => {
      console.log(JSON.stringify(scp));
      if (scp.inputRequirements && scp.inputRequirements.includes("hours")) {
        hoursRequired = true;
      }
      if (scp.inputRequirements && scp.inputRequirements.includes("equipmentType")) {
        equipmentTypeRequired = true;
      }
    });
    setHoursInputRequired(hoursRequired);
    setEquipmentTypeInputRequired(equipmentTypeRequired);
  });

  useEffect(() => {
    if (!!vp && !!vp.creditProducts && Object.keys(vp).length !== 0 && !!vp.creditProducts.length > 0) {
      const creditProds = vp.creditProducts;

      setCreditProducts(creditProds);
    }
  }, [vp]);

  const { data: aftermarketProducts } = useQuery(GET_AFTERMARKET_PRODUCTS, {
    variables: {
      accountId: account.id,
    },
  });

  const [createProposal] = useMutation(CREATE_PROPOSAL, {
    context: { authRequired: true },
  });

  const reset = async () => {
    setEmail("");
    setSelectedCreditProducts([]);
    setRenderOptions(false);
    setQuoteGenerated(false);
    setSelectedOption("base");
    setRate();
    setSaveOrSend("save");
    setApr("");
    setGoodAftermarket({ name: "Extended Warranty", cost: 0 });
    setBetterAftermarket({
      name: "Maintenance - 5yrs./150,000 miles",
      cost: 0,
    });
    setBestAftermarket({ name: "GAP", cost: 0 });
    setGbbOptions([{ trac: [{}, {}, {}, {}], finance: [{}, {}, {}, {}] }]);
    setGbbCalculatedOptions([]);
    setResidual("");
    setSalesTax("");
  };

  const validateAmount = amountRequested => {
    if (!amountRequested) {
      setAmountError(true);
      setAmountErrorMessage("Please enter an amount greater than $5,000.00");
    }
    if (parseFloat(amountRequested) < 4999) {
      setAmountError(true);
      setAmountErrorMessage("Please enter an amount greater than $5,000.00");
    } else {
      setAmountError(false);
      setAmountErrorMessage("");
      if (selectedOption === "select") {
        console.log("IN SELECT");
        setQuoteGenerated(true);
        setLoadingProposal(true);
        const lenderProgramIds = selectedCreditProducts.map(scp => scp.lenderProgramId);
        const reduceCreditProducts = selectedCreditProducts.reduce((acc, curr) => (acc.push(curr.productId), acc), []);
        const creditProductIds = reduceCreditProducts.join(",");
        console.log("WOW look at me variables:");
        console.log(
          JSON.stringify({
            variables: {
              id: account.id,
              amount: parseFloat(amountRequested),
              creditProducts: creditProductIds.toString(),
              hours: parseInt(hours, 10),
              equipmentType: equipmentType,
              lenderProgramIds: lenderProgramIds,
            },
          })
        );
        generateQuote({
          variables: {
            id: account.id,
            amount: parseFloat(amountRequested),
            creditProducts: creditProductIds.toString(),
            hours: parseInt(hours, 10),
            equipmentType: equipmentType,
            lenderProgramIds: lenderProgramIds,
          },
        });
      } else if (selectedOption === "base") {
        setQuoteGenerated(true);
        setLoadingProposal(true);
        const reduceCreditProducts = selectedCreditProducts.reduce((acc, curr) => (acc.push(curr.productId), acc), []);
        const creditProductIds = reduceCreditProducts.join(",");
        generateQuote({
          variables: {
            id: account.id,
            tier: tier,
            amount: parseFloat(amountRequested),
            creditProducts: creditProductIds.toString(),
          },
        });
      } else {
        setQuoteGenerated(true);
        setRenderOptions(true);
        calculateOptions(rate, apr);
      }
    }
  };
  const validate = (email, name) => {
    if (!email && !name) {
      setEmailError(true);
      setApplicantNameError(true);
      return false;
    }
    if (!email) {
      setEmailError(true);
      return false;
    }
    if (!name) {
      setApplicantNameError(true);
      return false;
    } else {
      setApplicantNameError(false);
    }
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setEmailError(false);
      setEmailErrorMessage("");
      return true;
    } else {
      setEmailError(true);
      setEmailErrorMessage("Please ensure email has been entered correctly");
      return false;
    }
  };

  const sendLink = async () => {
    if (validate(email, applicantName)) {
      try {
        handleOpenModal();
        handleSubmissionMessage({
          title: "Sending",
          message: "Please wait. Sending proposal...",
        });
        const paymentCardIds = [];
        data.quotePaymentCards.forEach(qpc => {
          paymentCardIds.push(qpc.paymentCardId);
          console.log(qpc);
        });
        const reduceCreditProducts = selectedCreditProducts.reduce((acc, curr) => (acc.push(curr.productId), acc), []);
        const creditProductIds = reduceCreditProducts.join(",");
        if (selectedOption === "base" || selectedOption === "select") {
          await createProposal({
            variables: {
              input: {
                amountRequested: parseFloat(amount),
                creditProductIds: creditProductIds,
                riskTier: tier,
                vendorOpportunityId: vo.vendorOpportunityId,
                accountId: account.id,
                vendorSalespersonId: userProfile.id,
                fullName: applicantName,
                email: email,
                businessName: businessName,
                status: "Proposal Sent",
                type: selectedOption,
                paymentCardIds: paymentCardIds,
              },
            },
          });
          handleSubmissionMessage({
            title: "Success",
            message: "Sent Successfully",
          });
          await refetchProposals();
          await reset();
          handleClose();
        } else {
          await createProposal({
            variables: {
              input: {
                amountRequested: parseFloat(amount),
                gbbAftermarketOptions:
                  goodAftermarket.productId + ", " + betterAftermarket.productId + ", " + bestAftermarket.productId,
                gbbProposedTerms: "60",
                gbbProposedApr: parseFloat(apr),
                gbbProposedRate: parseFloat(rate),
                vendorOpportunityId: vo.vendorOpportunityId,
                accountId: account.id,
                vendorSalespersonId: userProfile.id,
                fullName: applicantName,
                email: email,
                businessName: businessName,
                status: "Proposal Sent",
                type: "gbb",
                gbbCalculatedOptions: JSON.stringify(gbbOptions),
                residual: parseFloat(residual),
              },
            },
          });
          handleSubmissionMessage({
            title: "Success",
            message: "Sent Successfully",
          });
          await refetchProposals();
          await reset();
          handleClose();
        }
      } catch (err) {
        console.log(err);
        handleSubmissionMessage({
          title: "Error",
          message: "There was something wrong with your request",
        });
      }
    }
  };
  const saveAsDraft = async () => {
    try {
      handleOpenModal();
      handleSubmissionMessage({
        title: "Saving",
        message: "Please wait. Saving proposal as a draft...",
      });
      const reduceCreditProducts = selectedCreditProducts.reduce((acc, curr) => (acc.push(curr.productId), acc), []);
      const creditProductIds = reduceCreditProducts.join(",");
      if (selectedOption === "base") {
        await createProposal({
          variables: {
            input: {
              amountRequested: parseFloat(amount),
              creditProductIds: creditProductIds,
              riskTier: tier,
              vendorOpportunityId: vo.vendorOpportunityId,
              accountId: account.id,
              vendorSalespersonId: userProfile.id,
              fullName: applicantName,
              email: email,
              businessName: businessName,
              status: "Draft",
              type: "base",
            },
          },
        });
        handleSubmissionMessage({
          title: "Success",
          message: "Saved Successfully",
        });
        await refetchProposals();
      } else {
        await createProposal({
          variables: {
            input: {
              amountRequested: parseFloat(amount),
              gbbAftermarketOptions:
                goodAftermarket.productId + ", " + betterAftermarket.productId + ", " + bestAftermarket.productId,
              gbbProposedTerms: "60",
              gbbProposedApr: parseFloat(apr),
              gbbProposedRate: parseFloat(rate),
              vendorOpportunityId: vo.vendorOpportunityId,
              accountId: account.id,
              vendorSalespersonId: userProfile.id,
              fullName: applicantName,
              email: email,
              businessName: businessName,
              status: "Draft",
              type: "gbb",
              gbbCalculatedOptions: JSON.stringify(gbbOptions),
              residual: parseFloat(residual),
            },
          },
        });
        handleSubmissionMessage({
          title: "Success",
          message: "Saved Successfully",
        });
        await refetchProposals();
      }
    } catch (err) {
      console.log(err);
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };
  const calculateMonthlyOptions = async obj => {
    if (!!obj && obj.length) {
      obj.forEach((item, i) => {
        item.amountRequested = parseFloat(amount);
        item.monthlyPayments = item.amountRequested * item.rateFactor;
        if (typeof item.docFee !== "number") {
          item.docFee = parseFloat(item.docFee.replace(/[^0-9.-]+/g, ""));
        }
        item.mpWithPoints = item.monthlyPayments * (1 + (item.markupInternal / 100 + item.markupDealer / 100));
        item.monthlyPayments = item.mpWithPoints;
        item.monthlyPayments = item.monthlyPayments.toFixed(2);
        const rateSandPay = calculateRatesAndPayments(
          item.amountRequested,
          item.term,
          item.rate,
          item.markupInternal / 100 + item.markupDealer / 100
        );
        if (!item.rateFactor) {
          item.monthlyPayments = rateSandPay.sellPayment;
        }
        item.interestRate = (rateSandPay.simpleInterest * 100).toFixed(2);
        if (!!item.advancePayment && typeof item.advancePayment !== "number") {
          item.advancePayment = parseFloat(item.advancePayment.replace(/[^0-9.-]+/g, ""));
        }
        if (item.advancePayment > 0) {
          item.amountDue = item.advancePayment * parseFloat(item.monthlyPayments) + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        } else {
          item.amountDue = item.downPaymentAmount + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        }
        item.monthlyPayments = numberWithCommas(item.monthlyPayments);
        item.emailHeader = "Option " + parseInt(i + 1);
        delete item.interestRate;
        delete item.credittProduct;
        delete item.mpWithPoints;
        delete item.__typename;
      });
      setTimeout(() => (setRenderOptions(true), setLoadingProposal(false)), 500);
    }
  };

  const totalAmount = (option1, option2, option3, option4) => {
    return option1 + option2 + option3 + option4;
  };

  if (data && data.quotePaymentCards) calculateMonthlyOptions(data.quotePaymentCards);

  const calculateOptions = async (rate, apr) => {
    const parsedRate = parseFloat(rate);
    const parsedAPR = parseFloat(apr);
    const goodOptionCost = goodAftermarket.cost ? goodAftermarket.cost : 0;
    const goodOptionName = goodAftermarket.name ? goodAftermarket.name : null;
    const betterOptionCost = betterAftermarket.cost ? betterAftermarket.cost : 0;
    const betterOptionName = betterAftermarket.name ? betterAftermarket.name : null;
    const bestOptionCost = bestAftermarket.cost ? bestAftermarket.cost : 0;
    const bestOptionName = bestAftermarket.name ? bestAftermarket.name : null;
    var optionsState = [...gbbOptions];
    const trac1rateSandPay = calculateRatesAndPayments(amount, 60, parsedRate * 0.01, 0);
    const trac1Interest = parseFloat((trac1rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].trac[0].rate = parsedRate;
    optionsState[0].trac[0].simpleInterest = trac1Interest;
    optionsState[0].trac[0].basePayments = trac1rateSandPay.buyRatePayment.toFixed(2);
    const trac1PaymentsWithNoTax = parseFloat(
      residualPMT(parsedRate / 100 / 12, 60, -amount, amount * (residual / 100), 1)
    );
    const trac1PaymentsWithTax = trac1PaymentsWithNoTax + trac1PaymentsWithNoTax * (salesTax / 100000);
    optionsState[0].trac[0].monthlyPayments = (
      Math.round((trac1PaymentsWithTax + Number.EPSILON) * 100) / 100
    ).toString();
    optionsState[0].trac[0].emailHeader = "Standard";
    optionsState[0].trac[0].type = "TRAC Lease";
    optionsState[0].trac[0].totalAmount = amount;

    const trac2TotalAmount = amount + goodOptionCost;
    const trac2rateSandPay = calculateRatesAndPayments(amount + goodOptionCost, 60, parsedRate * 0.01, 0);
    const trac2Interest = parseFloat((trac2rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].trac[1].rate = parsedRate;
    optionsState[0].trac[1].simpleInterest = trac2Interest;
    optionsState[0].trac[1].basePayments = trac2rateSandPay.buyRatePayment.toFixed(2).toString();
    const trac2PaymentsWithNoTax = parseFloat(
      residualPMT(parsedRate / 100 / 12, 60, -trac2TotalAmount, trac2TotalAmount * (residual / 100), 1)
    );
    const trac2PaymentsWithTax = trac2PaymentsWithNoTax + trac2PaymentsWithNoTax * (salesTax / 100000);
    optionsState[0].trac[1].monthlyPayments = (
      Math.round((trac2PaymentsWithTax + Number.EPSILON) * 100) / 100
    ).toString();
    optionsState[0].trac[1].emailHeader = "Good";
    optionsState[0].trac[1].aftermarketName = goodOptionName;
    optionsState[0].trac[1].type = "TRAC Lease";
    optionsState[0].trac[1].totalAmount = trac2TotalAmount;

    const trac3TotalAmount = amount + goodOptionCost + betterOptionCost;
    const trac3rateSandPay = calculateRatesAndPayments(
      amount + betterOptionCost + goodOptionCost,
      60,
      parsedRate * 0.01,
      0
    );
    const trac3Interest = parseFloat((trac3rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].trac[2].rate = parsedRate;
    optionsState[0].trac[2].simpleInterest = trac3Interest;
    optionsState[0].trac[2].basePayments = trac3rateSandPay.buyRatePayment.toFixed(2).toString();
    const trac3PaymentsWithNoTax = parseFloat(
      residualPMT(parsedRate / 100 / 12, 60, -trac3TotalAmount, trac3TotalAmount * (residual / 100), 1)
    );
    const trac3PaymentsWithTax = trac3PaymentsWithNoTax + trac3PaymentsWithNoTax * (salesTax / 100000);
    optionsState[0].trac[2].monthlyPayments = (
      Math.round((trac3PaymentsWithTax + Number.EPSILON) * 100) / 100
    ).toString();
    optionsState[0].trac[2].emailHeader = "Better";
    optionsState[0].trac[2].aftermarketName = goodOptionName ? goodOptionName + ", " + betterOptionName : null;
    optionsState[0].trac[2].type = "TRAC Lease";
    optionsState[0].trac[2].totalAmount = trac3TotalAmount;

    const trac4TotalAmount = amount + goodOptionCost + betterOptionCost + bestOptionCost;
    const trac4rateSandPay = calculateRatesAndPayments(
      amount + bestOptionCost + betterOptionCost + goodOptionCost,
      60,
      parsedRate * 0.01,
      0
    );
    const trac4Interest = parseFloat((trac4rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].trac[3].rate = parsedRate;
    optionsState[0].trac[3].simpleInterest = trac4Interest;
    optionsState[0].trac[3].basePayments = trac4rateSandPay.buyRatePayment.toFixed(2).toString();
    const trac4PaymentsWithNoTax = parseFloat(
      residualPMT(parsedRate / 100 / 12, 60, -trac4TotalAmount, trac4TotalAmount * (residual / 100), 1)
    );
    const trac4PaymentsWithTax = trac4PaymentsWithNoTax + trac4PaymentsWithNoTax * (salesTax / 100000);
    optionsState[0].trac[3].monthlyPayments = (
      Math.round((trac4PaymentsWithTax + Number.EPSILON) * 100) / 100
    ).toString();
    optionsState[0].trac[3].emailHeader = "Best";
    optionsState[0].trac[3].aftermarketName = goodOptionName
      ? goodOptionName + ", " + betterOptionName + ", " + bestOptionName
      : null;
    optionsState[0].trac[3].type = "TRAC Lease";
    optionsState[0].trac[3].totalAmount = trac4TotalAmount;

    const amountFinanced = amount + amount * (salesTax / 100000);

    const finance1rateSandPay = calculateRatesAndPayments(amountFinanced, 60, parsedAPR * 0.01, 0);
    const finance1Interest = parseFloat((finance1rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].finance[0].rate = parsedAPR;
    optionsState[0].finance[0].simpleInterest = finance1Interest;
    optionsState[0].finance[0].monthlyPayments = finance1rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[0].finance[0].emailHeader = "Standard";
    optionsState[0].finance[0].type = "Finance";
    optionsState[0].finance[0].totalAmount = amountFinanced;

    const finance2TotalAmount = amountFinanced + goodOptionCost;
    const finance2rateSandPay = calculateRatesAndPayments(amountFinanced + goodOptionCost, 60, parsedAPR * 0.01, 0);
    const finance2Interest = parseFloat((finance2rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].finance[1].rate = parsedAPR;
    optionsState[0].finance[1].simpleInterest = finance2Interest;
    optionsState[0].finance[1].monthlyPayments = finance2rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[0].finance[1].emailHeader = "Good";
    optionsState[0].finance[1].aftermarketName = goodOptionName ? goodOptionName : null;
    optionsState[0].finance[1].type = "Finance";
    optionsState[0].finance[1].totalAmount = finance2TotalAmount;

    const finance3TotalAmount = amountFinanced + goodOptionCost + betterOptionCost;
    const finance3rateSandPay = calculateRatesAndPayments(
      amountFinanced + betterOptionCost + goodOptionCost,
      60,
      parsedAPR * 0.01,
      0
    );
    const finance3Interest = parseFloat((finance3rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].finance[2].rate = parsedAPR;
    optionsState[0].finance[2].simpleInterest = finance3Interest;
    optionsState[0].finance[2].monthlyPayments = finance3rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[0].finance[2].emailHeader = "Better";
    optionsState[0].finance[2].aftermarketName = goodOptionName ? goodOptionName + ", " + betterOptionName : null;
    optionsState[0].finance[2].type = "Finance";
    optionsState[0].finance[2].totalAmount = finance3TotalAmount;

    const finance4TotalAmount = amountFinanced + goodOptionCost + betterOptionCost + bestOptionCost;
    const finance4rateSandPay = calculateRatesAndPayments(
      amountFinanced + bestOptionCost + betterOptionCost + goodOptionCost,
      60,
      parsedAPR * 0.01,
      0
    );
    const finance4Interest = parseFloat((finance4rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].finance[3].rate = parsedAPR;
    optionsState[0].finance[3].simpleInterest = finance4Interest;
    optionsState[0].finance[3].monthlyPayments = finance4rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[0].finance[3].emailHeader = "Best";
    optionsState[0].finance[3].aftermarketName = goodOptionName
      ? goodOptionName + ", " + betterOptionName + ", " + bestOptionName
      : null;
    optionsState[0].finance[3].type = "Finance";
    optionsState[0].finance[3].totalAmount = finance4TotalAmount;
    setGbbOptions(optionsState);
    setGbbCalculatedOptions([...optionsState[0].trac, ...optionsState[0].finance]);
    setRenderOptions(true);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"xl"} scroll={"body"}>
      <Grid container>
        <Grid item xs={12}>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Paper>
            <CardHeader title="Proposal Inputs" />
            <Divider />
            <CardContent>
              <Grid container direction="column">
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                    <CurrencyTextField
                      label="Financed Amount"
                      value={amount}
                      onChange={(event, value) => setAmount(value)}
                      required={true}
                      currencySymbol="$"
                      error={amountError === true}
                      helperText={amountErrorMessage}
                    />
                  </FormControl>
                  {hoursInputRequired && (
                    <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                      <TextField
                        label="Hours"
                        value={hours}
                        name="hours"
                        onChange={e => handleHoursChange(e)}
                        id="hours"
                        InputProps={{
                          inputComponent: NumberFormatHours,
                          // endAdornment: <InputAdornment position="end"></InputAdornment>,
                        }}
                        required={true}
                      />
                    </FormControl>
                  )}
                  {equipmentTypeInputRequired && (
                    <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                        Equipment Type
                      </InputLabel>
                      <Select
                        label="Equipment Type"
                        // classes={{ select: uiClasses.select }}
                        value={equipmentType}
                        onChange={e => handleEquipmentTypeChange(e)}
                        inputProps={{
                          name: "equipmentType",
                          id: "equipmentType",
                        }}
                        required={true}
                      >
                        <MenuItem value={"Backhoes"}>Backhoes</MenuItem>
                        <MenuItem value={"Compact Wheel Loader"}>Compact Wheel Loader</MenuItem>
                        <MenuItem value={"Dozers"}>Dozers</MenuItem>
                        <MenuItem value={"Excavators"}>Excavators</MenuItem>
                        <MenuItem value={"Mini Excavators"}>Mini Excavators</MenuItem>
                        <MenuItem value={"Wheel Loaders"}>Wheel Loaders</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                {vp.showProposal === "show_base_gbb" && (
                  <Grid item xs={12} md={3} lg={3}>
                    <FormControl className={classes.formControlLenders}>
                      <RadioGroup row>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedOption === "select"}
                              onChange={() => setSelectedOption("select")}
                              value="select"
                              name="radio button enabled"
                              aria-label="select"
                              icon={<FiberManualRecord className={classesRadio.radioUnchecked} />}
                              checkedIcon={<FiberManualRecord className={classesRadio.radioChecked} />}
                              classes={{
                                checked: classesRadio.radio,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                          }}
                          label="Select"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedOption === "base"}
                              onChange={() => setSelectedOption("base")}
                              value="base"
                              name="radio button enabled"
                              aria-label="base"
                              icon={<FiberManualRecord className={classesRadio.radioUnchecked} />}
                              checkedIcon={<FiberManualRecord className={classesRadio.radioChecked} />}
                              classes={{
                                checked: classesRadio.radio,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                          }}
                          label="Base"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedOption === "gbb"}
                              onChange={() => setSelectedOption("gbb")}
                              value="gbb"
                              name="radio button enabled"
                              aria-label="Select"
                              icon={<FiberManualRecord className={classesRadio.radioUnchecked} />}
                              checkedIcon={<FiberManualRecord className={classesRadio.radioChecked} />}
                              classes={{
                                checked: classesRadio.radio,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                          }}
                          label="GBB"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                {(selectedOption === "base" || selectedOption === "select") && (
                  <Grid item>
                    <FormControl className={classes.formControlLenders} fullWidth>
                      <Autocomplete
                        multiple
                        value={selectedCreditProducts}
                        size="small"
                        options={creditProducts}
                        onChange={(event, newValue) => {
                          setSelectedCreditProducts(newValue);
                        }}
                        getOptionLabel={option => `${option.productHeader} - (${option.productName})`}
                        renderInput={params => (
                          <TextField {...params} variant="standard" label="Credit Products" placeholder="" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
                {vp.showProposal === "show_base_gbb" && selectedOption === "gbb" && (
                  <>
                    <h6>Add Aftermarket Options</h6>
                    <Grid container>
                      <TableContainer component={Paper}>
                        <Table
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <TableHead>
                            <TableRow className={classes.row}>
                              <TableCell
                                style={{
                                  borderTop: "solid",
                                  borderRight: "solid",
                                  borderLeft: "solid",
                                }}
                              ></TableCell>
                              <TableCell
                                style={{
                                  borderTop: "solid",
                                  borderRight: "solid",
                                }}
                              >
                                Good{" "}
                              </TableCell>
                              <TableCell
                                style={{
                                  borderTop: "solid",
                                  borderRight: "solid",
                                }}
                              >
                                Better
                              </TableCell>
                              <TableCell
                                style={{
                                  borderTop: "solid",
                                  borderRight: "solid",
                                }}
                              >
                                Best
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                style={{
                                  borderRight: "solid",
                                  borderTop: "solid",
                                  borderLeft: "solid",
                                }}
                              >
                                Product
                              </TableCell>
                              <TableCell
                                style={{
                                  borderRight: "solid",
                                  borderTop: "solid",
                                }}
                                padding="none"
                              >
                                <CustomInput
                                  id="good-option-name"
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    name: "goodAftermarketName",
                                    value: goodAftermarket.name,
                                    onChange: e =>
                                      setGoodAftermarket({
                                        ...goodAftermarket,
                                        name: e.target.value,
                                      }),
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  borderRight: "solid",
                                  borderTop: "solid",
                                }}
                                padding="none"
                              >
                                <CustomInput
                                  id="better-option-name"
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    name: "betterAftermarketName",
                                    value: betterAftermarket.name,
                                    onChange: e =>
                                      setBetterAftermarket({
                                        ...betterAftermarket,
                                        name: e.target.value,
                                      }),
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  borderRight: "solid",
                                  borderTop: "solid",
                                }}
                                padding="none"
                              >
                                <CustomInput
                                  id="best-option-name"
                                  formControlProps={{ fullWidth: true }}
                                  inputProps={{
                                    type: "text",
                                    name: "bestAftermarketName",
                                    value: bestAftermarket.name,
                                    onChange: e =>
                                      setBestAftermarket({
                                        ...bestAftermarket,
                                        name: e.target.value,
                                      }),
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ border: "solid" }}>Cost</TableCell>
                              <TableCell
                                style={{
                                  borderRight: "solid",
                                  borderTop: "solid",
                                  borderBottom: "solid",
                                  borderLeft: "solid",
                                }}
                              >
                                <CurrencyTextField
                                  value={goodAftermarket.cost}
                                  onChange={(event, value) =>
                                    setGoodAftermarket({
                                      ...goodAftermarket,
                                      cost: value,
                                    })
                                  }
                                  required={true}
                                  currencySymbol="$"
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  borderRight: "solid",
                                  borderTop: "solid",
                                  borderBottom: "solid",
                                }}
                              >
                                <CurrencyTextField
                                  value={betterAftermarket.cost}
                                  onChange={(event, value) =>
                                    setBetterAftermarket({
                                      ...betterAftermarket,
                                      cost: value,
                                    })
                                  }
                                  required={true}
                                  currencySymbol="$"
                                />
                              </TableCell>
                              <TableCell
                                style={{
                                  borderRight: "solid",
                                  borderTop: "solid",
                                  borderBottom: "solid",
                                }}
                              >
                                <CurrencyTextField
                                  value={bestAftermarket.cost}
                                  onChange={(event, value) =>
                                    setBestAftermarket({
                                      ...bestAftermarket,
                                      cost: value,
                                    })
                                  }
                                  required={true}
                                  currencySymbol="$"
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <h6>Trac Terms</h6>
                    <Grid item xs={12} md={1} lg={1}>
                      <FormControl className={classes.formControlLenders} fullWidth>
                        <CustomInput
                          labelText="Rate"
                          id="rate"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            type: "text",
                            name: "rate",
                            value: rate,
                            onChange: e => setRate(e.target.value),
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                      <FormControl className={classes.formControlLenders} fullWidth>
                        <TextField
                          label="Residual Rate"
                          value={residual}
                          onChange={e => setResidual(e.target.value)}
                          name="residual"
                          id="residual"
                          InputProps={{
                            inputComponent: ResidualNumberFormatCustom,
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                      <FormControl className={classes.formControlLenders} fullWidth>
                        <CustomInput
                          labelText="Term"
                          id="term"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            type: "text",
                            name: "term",
                            value: 60,
                            disabled: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <h6>Finance Terms</h6>
                    <Grid item xs={12} md={1} lg={1}>
                      <FormControl className={classes.formControlLenders} fullWidth>
                        <CustomInput
                          labelText="APR"
                          id="apr"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            type: "text",
                            name: "apr",
                            value: apr,
                            onChange: e => setApr(e.target.value),
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                      <FormControl className={classes.formControlLenders} fullWidth>
                        <TextField
                          label="Sales Tax"
                          value={salesTax}
                          onChange={e => setSalesTax(e.target.value)}
                          name="salesTax"
                          id="salesTax"
                          InputProps={{
                            inputComponent: SalesTaxNumberFormatCustom,
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                      <FormControl className={classes.formControlLenders} fullWidth>
                        <CustomInput
                          labelText="Term"
                          id="term"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            type: "text",
                            name: "term",
                            value: 60,
                            disabled: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                      <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                        <CurrencyTextField
                          label="Total Financed Amount"
                          value={totalAmount(
                            amount,
                            goodAftermarket.cost ? goodAftermarket.cost : 0,
                            betterAftermarket.cost ? betterAftermarket.cost : 0,
                            bestAftermarket.cost ? bestAftermarket.cost : 0
                          )}
                          disabled
                          currencySymbol="$"
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}

                <br />
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <Button
                      color="primary"
                      className={classes.submitButton}
                      onClick={handleSubmit}
                      type="submit"
                      disabled={!amount || quoteGenerated}
                    >
                      Generate Proposal
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={12}>
          {loadingProposal && <CircularProgress />}
          {renderOptions &&
            (selectedOption === "base" || selectedOption === "select") &&
            quoteGenerated &&
            !!data &&
            !!data.quotePaymentCards && (
              <Paper>
                <CardHeader title="Generated Proposal" />
                <Divider />
                <CardContent style={{ padding: 0 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow className={classes.row}>
                          {rate && !data ? <TableCell>Credit Product</TableCell> : <TableCell />}
                          <TableCell>Term </TableCell>
                          <TableCell>Monthly Payment</TableCell>
                          <TableCell>Amount Due</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.quotePaymentCards.map(({ term, monthlyPayments, productHeader, amountDue }, i) => (
                          <>
                            {(i === 0 || term < data.quotePaymentCards[i - 1].term) && (
                              <TableRow key={i}>
                                <TableCell colSpan={3} align="left">
                                  Credit Product {productHeader}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow key={i}>
                              <TableCell></TableCell>
                              <TableCell>{term} Months</TableCell>
                              <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                              <TableCell>{formatCurrency(amountDue)}</TableCell>
                            </TableRow>
                          </>
                        ))}
                        <Divider />
                        <Button onClick={() => handleSaveOrSend()} color="primary">
                          {saveOrSend !== "save" ? "Save As Draft" : "Send Proposal"}
                        </Button>
                        <CardHeader title={saveOrSend == "save" ? "Save Proposal As A Draft" : "Send Proposal"} />
                        {saveOrSend === "save" ? (
                          <TableRow>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="Applicant Name"
                                  value={applicantName}
                                  onChange={handleChangedApplicantName}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="email"
                                  value={email}
                                  onChange={handleChangedEmail}
                                  required={true}
                                  error={emailError === true}
                                  helperText={emailErrorMessage}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="Business Name"
                                  value={businessName}
                                  onChange={handleChangedBusinessName}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Button color="primary" onClick={saveAsDraft}>
                                Save Draft
                              </Button>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="Applicant Name"
                                  value={applicantName}
                                  onChange={handleChangedApplicantName}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="email"
                                  value={email}
                                  onChange={handleChangedEmail}
                                  required={true}
                                  error={emailError === true}
                                  helperText={emailErrorMessage}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="Business Name"
                                  value={businessName}
                                  onChange={handleChangedBusinessName}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Button color="primary" onClick={sendLink}>
                                Send Proposal
                              </Button>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Paper>
            )}
          {renderOptions && selectedOption === "gbb" && gbbOptions && quoteGenerated && (
            <Paper>
              <CardHeader title="Generated Proposal" />
              <Divider />
              <CardContent style={{ padding: 0 }}>
                <TableContainer component={Paper}>
                  <Table
                    style={{
                      tableLayout: "fixed",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell>Trac GBB</TableCell>
                        <TableCell>Term </TableCell>
                        <TableCell>Monthly Payment</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {gbbOptions[0].trac.map(({ term, monthlyPayments, emailHeader, rate }, i) => (
                        <TableRow key={i}>
                          <TableCell>{emailHeader}</TableCell>
                          <TableCell>60 Months</TableCell>
                          <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <TableContainer component={Paper}>
                  <Table
                    style={{
                      tableLayout: "fixed",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell>Finance GBB</TableCell>
                        <TableCell>Term </TableCell>
                        <TableCell>Monthly Payment</TableCell>
                        <TableCell>Interest</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {gbbOptions[0].finance.map(({ term, monthlyPayments, emailHeader, rate }, i) => (
                        <TableRow key={i}>
                          <TableCell>{emailHeader}</TableCell>
                          <TableCell>60 Months</TableCell>
                          <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                          <TableCell>{rate}%</TableCell>
                        </TableRow>
                      ))}
                      <Divider />
                      <Button onClick={() => handleSaveOrSend()} color="primary">
                        {saveOrSend !== "save" ? "Save As Draft" : "Send Proposal"}
                      </Button>
                      <CardHeader title={saveOrSend == "save" ? "Save Proposal As A Draft" : "Send Proposal"} />
                      {saveOrSend === "save" ? (
                        <TableRow>
                          <TableCell>
                            <FormControl>
                              <TextField
                                label="Applicant Name"
                                value={applicantName}
                                onChange={handleChangedApplicantName}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl>
                              <TextField
                                label="email"
                                value={email}
                                onChange={handleChangedEmail}
                                required={true}
                                error={emailError === true}
                                helperText={emailErrorMessage}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl>
                              <TextField
                                label="Business Name"
                                value={businessName}
                                onChange={handleChangedBusinessName}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Button color="primary" onClick={saveAsDraft}>
                              Save Draft
                            </Button>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell>
                            <FormControl>
                              <TextField
                                label="Applicant Name"
                                value={applicantName}
                                onChange={handleChangedApplicantName}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl>
                              <TextField
                                label="email"
                                value={email}
                                onChange={handleChangedEmail}
                                required={true}
                                error={emailError === true}
                                helperText={emailErrorMessage}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <FormControl>
                              <TextField
                                label="Business Name"
                                value={businessName}
                                onChange={handleChangedBusinessName}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Button color="primary" onClick={sendLink}>
                              Send Proposal
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Paper>
          )}
        </Grid>
        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseModal}
          title={submissionMessage.title}
          message={submissionMessage.message}
        />
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  userProfile: state.userProfile,
  account: state.account,
  vp: state.vp,
  vo: state.vo,
});

export default connect(
  mapStateToProps,
  null
)(CreateProposalModal);
