import React, { useState } from "react";
import Chip from "@material-ui/core/Chip"; // Using Material-UI for chips
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}));

const ChipInput = ({ id, onChange, initialValues }: { id: string; onChange: any; initialValues: string[] }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState<string[]>(initialValues ? initialValues : []);

  const handleAddChip = () => {
    if (chips.includes(inputValue.trim())) {
      return;
    }
    if (inputValue.trim() !== "") {
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
      onChange(id, [...chips, inputValue.trim()]);
    }
  };

  const handleDeleteChip = (chipToDelete: string) => () => {
    setChips(chips => chips.filter(chip => chip !== chipToDelete));
    onChange(
      id,
      chips.filter(chip => chip !== chipToDelete)
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddChip();
    }
  };

  return (
    <div>
      <div className={classes.inputContainer}>
        <TextField
          label="Enter a value"
          variant="standard"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button variant="contained" color="primary" size="small" onClick={handleAddChip}>
          Add
        </Button>
      </div>
      <div className={classes.chipContainer}>
        {chips.map((chip, index) => (
          <Chip key={index} label={chip} onDelete={handleDeleteChip(chip)} color="primary" />
        ))}
      </div>
    </div>
  );
};

export default ChipInput;
