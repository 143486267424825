import React from "react";
import { Modal } from "@material-ui/core";
import NewCreditAppModal from "../../../pages/VendorOpportunity/NewCreditAppModal";
import { BaseModalProps } from "../../../global";

interface Props extends BaseModalProps {
  creditApp: any;
}

export const NewCreditAppDocumentsModal = ({ creditApp, isOpen, onClose }: Props) => {
  return (
    <Modal onClose={onClose} open={isOpen} aria-describedby="Summary" aria-labelledby="Credit Application">
      {creditApp ? <NewCreditAppModal {...creditApp} /> : <></>}
    </Modal>
  );
};
