import { useMutation, useQuery } from "@apollo/react-hooks";
//material UI
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PageviewIcon from "@material-ui/icons/Pageview";
//Core Components
import Button from "components/CustomButtons/Button";
import gql from "graphql-tag";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactTable from "react-table";
import Tile from "../components/Tile/Tile";
import TaskForm from "./TaskForm";
import { taskStatusMap } from "../utils";
import _ from "lodash";
import DragIndicator from "assets/img/drag-indicator.svg";
import TableSearch from "./TableSearch/TableSearch";
import { Assignment, Close as CloseIcon } from "@material-ui/icons";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { theme } from "../../../theme";

const UPSERT_TASK = gql`
  mutation($input: UpsertTaskInput!) {
    upsertTask(input: $input)
  }
`;

const DEACTIVATE_TASK = gql`
  mutation($taskId: ID!) {
    deactivateTask(taskId: $taskId)
  }
`;

const UPDATE_TASK = gql`
  mutation($taskId: ID!, $input: UpdateTaskInput!) {
    updateTask(taskId: $taskId, input: $input)
  }
`;

const styles = theme => ({
  root: {
    "& .MuiInput-underline:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  right: {
    textAlign: "right",
  },
  greenButton: {
    margin: 0,
    float: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    position: "absolute",
    minWidth: "400px",
  },
  closeButton: {
    position: "absolute",
    right: "5px",
    top: "35px",
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    top: "30px",
  },
  statusStyle: {
    width: "100px",
    marginLeft: "10px",
    padding: "5px 25px",
    borderRadius: "10px",
    textTransform: "capitalize",
    color: "#fff",
  },
  saveBtn: {
    marginTop: "25px",
  },
  margin: {
    row: {
      height: "20px",
    },
  },
  surface: {
    position: "absolute",
    minWidth: 700,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 700,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  previewDoc: {
    maxWidth: "60%",
  },
  priorityLabel: {
    display: "inline-block",
    margin: "10px 0 0 0",
  },
  smallIcon: {
    fontSize: "20px",
    margin: "15px",
  },
});

const priorityColors = {
  urgent: "#bb3354",
  high: "#bb3354",
  medium: "#71589a",
  low: "#69a1bd",
  in_progress: "#808080",
  closed: "#808080",
  open: "#ffd180",
  specify: "#c4c4c4",
  add: "#3bc173",
  complete: "#3bc173",
  requested: "#7a7a7a",
  cancel: "#dd5040",
};

const useStyles = makeStyles(styles);

const useUiStyles = makeStyles(styles);

export default function TaskList({
  vo,
  account,
  queryTasks,
  queryTasksOptions,
  tasksQueryKey,
  addTaskFlag,
  userProfile,
  tileTitle,
  refetchDocs,
  relatedUsers,
  creditSubId,
}) {
  const defaultStatusOptions = [
    { value: "open", text: "Open" },
    { value: "complete", text: "Complete" },
    { value: "in_progress", text: "In Progress" },
    { value: "cancel", text: "Cancel" },
    { value: "requested", text: "Requested" },
  ];

  const defaultPriorityOptions = [
    { value: "high", text: "High" },
    { value: "medium", text: "Medium" },
    { value: "low", text: "Low" },
  ];

  const defaultOptions = {
    createdBy: [],
    assignedTo: [],
    status: defaultStatusOptions,
    priority: defaultPriorityOptions,
  };

  const defaultFilters = [
    {
      header: "Created By",
      value: [""],
    },
    {
      header: "Assigned To",
      value: [""],
    },
    {
      header: "Status",
      value: [""],
    },
    {
      header: "Priority",
      value: [""],
    },
  ];

  const defaultEditTaskModal = {
    isOpen: false,
    taskId: null,
    value: null,
  };

  const uiClasses = useUiStyles();
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const searchRef = React.useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 5 });
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [editTaskPriorityModal, setEditTaskPriorityModal] = useState(defaultEditTaskModal);
  const [editTaskStatusModal, setEditTaskStatusModal] = useState(defaultEditTaskModal);
  const [isTaskUpdating, setIsTaskUpdating] = useState(false);
  const [columnsFilters, setColumnsFilters] = useState(defaultFilters);
  const [options, setOptions] = useState(defaultOptions);

  const { loading, error, data: tasksData, refetch } = useQuery(queryTasks, queryTasksOptions);

  if (loading) console.log("Loading tasks...");
  if (error) console.log(`Error! ${error.message}`);

  useEffect(() => {
    if (userProfile && !!tasksData && !!tasksData[tasksQueryKey]) {
      let loadedTasks = tasksData[tasksQueryKey];
      loadedTasks = loadedTasks.map(task => {
        task.createdDateMilliseconds = new Date(task.createdDateTime).getTime();
        return task;
      });
      //Filter only tasks assigned to user profile
      // if (tasksQueryKey === "userRelatedTasks") {
      //   loadedTasks = _.filter(loadedTasks, task => {
      //     return task.assignedToUserProfileId === userProfile.id || task.assignedByUserProfileId === userProfile.id;
      //   });
      // }

      setTasks(loadedTasks);
    }
  }, [tasksQueryKey, tasksData, userProfile]);

  useEffect(() => {
    setFilteredTasks(filterTasks(tasks));

    const createdByOptions = [];
    const assignedToOptions = [];

    if (tasksData?.userRelatedTasks) {
      tasksData.userRelatedTasks.map(task => {
        if (!createdByOptions.find(option => task.createdBy === option.value) && task.createdBy) {
          createdByOptions.push({ value: task?.createdBy, text: task?.createdBy });
        }
        if (!assignedToOptions.find(option => task.assignedToUser === option.value) && task.assignedToUser) {
          assignedToOptions.push({ value: task?.assignedToUser, text: task?.assignedToUser });
        }
      });
    }

    setOptions({ ...options, createdBy: createdByOptions, assignedTo: assignedToOptions });
  }, [tasks, searchRef.current.currentSearch]);

  const filterTasks = tasks => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return tasks;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = tasks.filter(el => {
      if (
        typeof el.createdBy === "string" ||
        typeof el.assignedToUser === "string" ||
        typeof el.regarding === "string"
      ) {
        return (
          (el.createdBy || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.assignedToUser || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.regarding || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return [...searchData];
  };

  const [upsertTask] = useMutation(UPSERT_TASK, {
    context: { authRequired: true },
  });

  const [updateTask] = useMutation(UPDATE_TASK, {
    context: { authRequired: true },
  });

  const [deactivateTaskRequest] = useMutation(DEACTIVATE_TASK, {
    context: { authRequired: true },
  });

  const handleCloseTaskModal = () => {
    setOpenTaskModal(false);
    setTaskId(null);
  };

  const handleAddTask = async payload => {
    try {
      await upsertTask({
        variables: {
          input: payload,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveTask = async payload => {
    try {
      await updateTask({
        variables: payload,
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteTask = async id => {
    // deactivateTaskRequest
    try {
      await deactivateTaskRequest({
        variables: {
          taskId: id,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenCS = task => {
    window.location.href = `/cs/${task.creditSubId}`;
  };

  const onUpdateTaskPriority = async () => {
    setIsTaskUpdating(true);
    const variables = {
      taskId: editTaskPriorityModal?.task?.original?.taskId,
      input: {
        priority: editTaskPriorityModal?.value,
      },
    };
    await updateTask({
      variables,
    });
    await refetch();
    setIsTaskUpdating(false);
    setEditTaskPriorityModal(defaultEditTaskModal);
  };

  const onUpdateTaskStatus = async () => {
    setIsTaskUpdating(true);
    const variables = {
      taskId: editTaskStatusModal?.task?.original?.taskId,
      input: {
        status: editTaskStatusModal?.value,
      },
    };
    await updateTask({
      variables,
    });
    await refetch();
    setIsTaskUpdating(false);
    setEditTaskStatusModal(defaultEditTaskModal);
  };

  const convertValueToText = str => {
    let selectText = str;
    const convertedOptions = [...defaultStatusOptions, ...defaultPriorityOptions];
    convertedOptions.forEach(option => {
      if (selectText.indexOf(option.value) !== -1) selectText = selectText.replace(option.value, option.text);
    });
    return selectText;
  };

  function createFiltersColumns(sel, header) {
    const filter = ({ filter, onChange }) => {
      return sel.length > 0 ? (
        <Select
          multiple
          className={classes.filterSelect}
          renderValue={() =>
            columnsFilters.find(col => col.header === header).value[0] !== ""
              ? convertValueToText(columnsFilters.find(col => col.header === header).value.join(", "))
              : "Show All"
          }
          onChange={e => {
            let value =
              e.target.value.length > 1 && e.target.value[0] === ""
                ? e.target.value.filter(val => val !== "")
                : e.target.value;
            if (value[value.length - 1] === "") {
              value = value.filter(val => val === "");
            }
            onChange(value);
            setColumnsFilters(prevArray =>
              prevArray.map(col =>
                col.header === header
                  ? {
                      ...col,
                      value: value,
                    }
                  : col
              )
            );
          }}
          value={_.get(columnsFilters.find(col => col.header === header), "value", [])}
          style={{ width: "100%" }}
          MenuProps={{
            getContentAnchorEl: null,
            variant: "menu",
          }}
        >
          <MenuItem value="">
            <Checkbox
              checked={
                _.get(columnsFilters.find(col => col.header === header), "value", null) &&
                _.get(columnsFilters.find(col => col.header === header), "value").includes("")
              }
              style={{ color: theme.palette.primary.main }}
            />
            <ListItemText primary="Show All" />
          </MenuItem>
          {sel.map((item, index) => {
            return (
              <MenuItem key={"stage-" + index} value={item.value} name={item.text}>
                <Checkbox
                  checked={
                    _.get(columnsFilters.find(col => col.header === header), "value", null) &&
                    _.get(columnsFilters.find(col => col.header === header), "value").includes(item.value)
                  }
                  style={{ color: theme.palette.primary.main }}
                />
                <ListItemText primary={item.text} />
              </MenuItem>
            );
          })}
        </Select>
      ) : null;
    };
    return filter;
  }

  const EditTaskPriorityModal = () => (
    <Modal
      open={editTaskPriorityModal?.isOpen}
      onClose={() => setEditTaskPriorityModal({ defaultEditTaskModal })}
      aria-labelledby="Task Form"
      aria-describedby="Input"
    >
      <div style={styles.modalStyle}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setEditTaskPriorityModal({ defaultEditTaskModal })}
        >
          <CloseIcon />
        </IconButton>
        <Tile title={"Update priority"} maxHeightLimitByScreen={false} titleIcon={<Assignment />}>
          <p>{`Created By: ${editTaskPriorityModal?.task?.original?.createdBy}`}</p>
          <p>{`Assigned to: ${editTaskPriorityModal?.task?.original?.assignedToUser}`}</p>
          <p>{`Created on: ${
            editTaskPriorityModal?.task?.original?.createdDateTime
              ? moment(editTaskPriorityModal?.task?.original?.createdDateTime).format("ll")
              : ""
          }`}</p>
          <p>
            Status:
            <span
              style={{
                backgroundColor: priorityColors?.[editTaskPriorityModal?.task?.original?.status],
                ...styles.statusStyle,
              }}
            >
              {editTaskPriorityModal?.task?.original?.status.replace("_", " ")}
            </span>
          </p>
          <FormControl fullWidth className={uiClasses.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
              Priority:
            </InputLabel>
            <Select
              disabled={taskId ? true : false}
              classes={{ select: uiClasses.select }}
              value={editTaskPriorityModal?.value}
              onChange={e => setEditTaskPriorityModal({ ...editTaskPriorityModal, value: e.target.value })}
              inputProps={{
                name: "priority",
                id: "priority",
              }}
            >
              <MenuItem value={"high"}>High</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"low"}>Low</MenuItem>
            </Select>
          </FormControl>
          <Button
            color={"success"}
            onClick={onUpdateTaskPriority}
            block
            disabled={isTaskUpdating}
            style={styles.saveBtn}
          >
            {`${isTaskUpdating ? "Updating..." : "Update"}`}
          </Button>
        </Tile>
      </div>
    </Modal>
  );

  const EditTaskStatusModal = () => (
    <Modal
      open={editTaskStatusModal?.isOpen}
      onClose={() => setEditTaskStatusModal({ defaultEditTaskModal })}
      aria-labelledby="Task Form"
      aria-describedby="Input"
    >
      <div style={styles.modalStyle}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setEditTaskStatusModal({ defaultEditTaskModal })}
        >
          <CloseIcon />
        </IconButton>
        <Tile title={"Update status"} maxHeightLimitByScreen={false} titleIcon={<Assignment />}>
          <p>{`Created By: ${editTaskStatusModal?.task?.original?.createdBy}`}</p>
          <p>{`Assigned to: ${editTaskStatusModal?.task?.original?.assignedToUser}`}</p>
          <p>{`Created on: ${
            editTaskStatusModal?.task?.original?.createdDateTime
              ? moment(editTaskStatusModal?.task?.original?.createdDateTime).format("ll")
              : ""
          }`}</p>
          <p>
            Priority:
            <span
              style={{
                backgroundColor: priorityColors?.[editTaskStatusModal?.task?.original?.priority],
                ...styles.statusStyle,
              }}
            >
              {editTaskStatusModal?.task?.original?.priority.replace("_", " ")}
            </span>
          </p>
          <FormControl fullWidth className={uiClasses.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
              Status:
            </InputLabel>
            <Select
              disabled={taskId ? true : false}
              classes={{ select: uiClasses.select }}
              value={editTaskStatusModal?.value}
              onChange={e => setEditTaskStatusModal({ ...editTaskStatusModal, value: e.target.value })}
              inputProps={{
                name: "status",
                id: "status",
              }}
            >
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="requested">Requested</MenuItem>
            </Select>
          </FormControl>
          <Button color={"success"} onClick={onUpdateTaskStatus} disabled={isTaskUpdating} block style={styles.saveBtn}>
            {`${isTaskUpdating ? "Updating..." : "Update"}`}
          </Button>
        </Tile>
      </div>
    </Modal>
  );

  return (
    <div className={classes.root}>
      {!!account && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tile title={!tileTitle ? "Tasks" : tileTitle} useCollapsing={true}>
              <TableSearch
                setSavedListSettings={setSavedListSettings}
                searchRef={searchRef}
                savedListSettings={savedListSettings}
              />
              <Grid container>
                {addTaskFlag ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button
                      className={classes.greenButton}
                      color="primary"
                      size={"sm"}
                      onClick={e => {
                        e.preventDefault();
                        setTaskId(null);
                        setOpenTaskModal(true);
                        return false;
                      }}
                    >
                      ADD
                    </Button>
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.fixReactTable}>
                  {!!tasks && tasks ? (
                    <ReactTable
                      color="primary"
                      data={filteredTasks}
                      getTrProps={() => {
                        return {
                          style: { cursor: "pointer" },
                        };
                      }}
                      filterable
                      columns={[
                        {
                          Header: "Created On",
                          accessor: "createdDateMilliseconds",
                          Cell: props => <div>{props.value ? moment(props.value).format("ll") : ""}</div>,
                          Filter: () => <></>,
                        },
                        {
                          Header: "Created By",
                          accessor: "createdBy",
                          Filter: createFiltersColumns(options.createdBy, "Created By"),
                          filterable: true,
                          filterMethod: (filter, row) => {
                            if (filter.value.includes("")) return true;
                            if (filter.value.includes(row.createdBy)) return true;
                            return false;
                          },
                          Cell: props => <div>{props.value ? props.value : "Trnsact"}</div>,
                        },
                        {
                          Header: "Assigned To",
                          accessor: "assignedToUser",
                          Filter: createFiltersColumns(options.assignedTo, "Assigned To"),
                          filterable: true,
                          filterMethod: (filter, row) => {
                            if (filter.value.includes("")) return true;
                            if (filter.value.includes(row.assignedToUser)) return true;
                            return false;
                          },
                          Cell: props => <div>{props.value ? props.value : "Trnsact"}</div>,
                        },
                        {
                          Header: "Regarding",
                          accessor: "regarding",
                          Filter: () => null,
                        },
                        {
                          Header: "Status",
                          accessor: "status",
                          Filter: createFiltersColumns(options.status, "Status"),
                          filterable: true,
                          filterMethod: (filter, row) => {
                            if (filter.value.includes("")) return true;
                            if (filter.value.includes(row.status)) return true;
                            return false;
                          },
                          Cell: props => (
                            <div
                              onClick={() =>
                                props?.original?.status !== "complete"
                                  ? setEditTaskStatusModal({ isOpen: true, task: props, value: props?.value })
                                  : null
                              }
                            >
                              {taskStatusMap[props.value] ? taskStatusMap[props.value] : props.value}
                            </div>
                          ),
                          getProps: (state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                              let color = priorityColors[rowInfo.row.status];

                              return {
                                style: {
                                  background: color,
                                  padding: "5px",
                                  borderRadius: "10px",
                                  textAlign: "center",
                                },
                              };
                            } else {
                              return {};
                            }
                          },
                        },
                        {
                          Header: "Priority",
                          Cell: props => (
                            <div
                              onClick={() =>
                                props?.original?.status !== "complete"
                                  ? setEditTaskPriorityModal({ isOpen: true, task: props, value: props?.value })
                                  : null
                              }
                            >
                              {props.value.toUpperCase()}
                            </div>
                          ),
                          getProps: (state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                              let color = priorityColors[rowInfo.row.priority];

                              return {
                                style: {
                                  background: color,
                                  padding: "5px",
                                  borderRadius: "10px",
                                  textAlign: "center",
                                },
                              };
                            } else {
                              return {};
                            }
                          },
                          accessor: "priority",
                          Filter: createFiltersColumns(options.priority, "Priority"),
                          filterable: true,
                          filterMethod: (filter, row) => {
                            if (filter.value.includes("")) return true;
                            if (filter.value.includes(row.priority)) return true;
                            return false;
                          },
                        },
                        {
                          show: tasksQueryKey === "userRelatedTasks",
                          Header: "Option",
                          Filter: () => null,
                          sortable: false,
                          Cell: props => {
                            const row = props.original;
                            const isUserTask = userProfile.id === row.assignedToUserProfileId;
                            if (!row.creditSubId || row.status === "complete") return null;
                            return (
                              <div className={classes.actionButtons}>
                                {!isUserTask ? (
                                  <Tooltip className="tooltip-top-start" title="View Task" placement="top">
                                    <IconButton
                                      style={{
                                        left: "50%",
                                        transform: "translate(-50%)",
                                      }}
                                      onClick={e => {
                                        e.preventDefault();
                                        handleOpenCS(row);
                                        return false;
                                      }}
                                      color="primary"
                                      aria-label="edit task"
                                      component="button"
                                    >
                                      <PageviewIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Fragment>
                                    <Tooltip className="tooltip-top-start" title="Edit Task" placement="top">
                                      <IconButton
                                        style={{
                                          left: "50%",
                                          transform: "translate(-50%)",
                                        }}
                                        onClick={e => {
                                          e.preventDefault();
                                          setTaskId(row.taskId);
                                          setOpenTaskModal(true);
                                          return false;
                                        }}
                                        color="primary"
                                        aria-label="edit task"
                                        component="button"
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip id="tooltip-top-start" title="Delete Task" placement="top">
                                      <IconButton
                                        style={{
                                          left: "50%",
                                          transform: "translate(-50%)",
                                        }}
                                        onClick={() => {
                                          handleDeleteTask(row.taskId);
                                        }}
                                        color="primary"
                                        aria-label="remove task"
                                        component="button"
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Fragment>
                                )}
                              </div>
                            );
                          },
                        },
                        {
                          show: tasksQueryKey === "vendorOpportunityTask",
                          Header: "Action",
                          Filter: () => null,
                          sortable: false,
                          Cell: props => {
                            const row = props.original;
                            const mine = userProfile.id == row.assignedToUserProfileId;
                            if (row.status == "complete" || !mine) return null;
                            return (
                              <div className={classes.actionButtons}>
                                <Tooltip id="tooltip-top-start" title="View Task" placement="top">
                                  <IconButton
                                    style={{
                                      left: "50%",
                                      transform: "translate(-50%)",
                                    }}
                                    onClick={e => {
                                      e.preventDefault();
                                      setTaskId(row.taskId);
                                      setOpenTaskModal(true);
                                      return false;
                                    }}
                                    color="primary"
                                    aria-label="edit task"
                                    component="button"
                                  >
                                    <PageviewIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            );
                          },
                        },
                      ]}
                      defaultPageSize={5}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      customCellClasses={[classes.right]}
                      customClassesForCells={[5]}
                      className="-striped -highlight"
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Modal
                open={openTaskModal}
                onClose={handleCloseTaskModal}
                aria-labelledby="Task Form"
                aria-describedby="Input"
              >
                <div>
                  <TaskForm
                    vo={vo}
                    handleClose={handleCloseTaskModal}
                    handleAddTask={handleAddTask}
                    handleSaveTask={handleSaveTask}
                    taskId={taskId}
                    tasksData={tasks}
                    creditSubId={creditSubId}
                    refetch={refetch}
                    refetchDocs={refetchDocs}
                    relatedUsers={relatedUsers}
                  />
                </div>
              </Modal>
              <EditTaskPriorityModal />
              <EditTaskStatusModal />
            </Tile>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
