import React from "react";
import { Task } from "@trnsact/trnsact-shared-types";
import FlagIcon from "@material-ui/icons/Flag";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "../../../../Table/TableCell";
import { priorityColors } from "../../../config/configuration";

interface Props {
  task: Task;
}

export const PriorityCell = ({ task }: Props) => {
  const classes = useStyles();

  const { taskId, priority } = task;

  return (
    <TableCell>
      <Box className={classes.cellContent}>
        <FlagIcon style={{ color: priorityColors[priority!] }} />

        <Typography className={classes.value} variant="body2">
          {priority!}
        </Typography>
      </Box>
    </TableCell>
  );
};

const useStyles = makeStyles({
  cellContent: {
    zIndex: 2,
    gap: "0.3rem",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  value: {
    textTransform: "capitalize",
  },
});
