import React, { PropsWithChildren, SyntheticEvent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  onCellClick?: (event: SyntheticEvent) => void;
}

export const TableCell = ({ children, onCellClick = () => {} }: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Box onClick={onCellClick} className={classes.cell}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles({
  cell: {
    padding: "1rem 0",
  },
});
